import { Fragment, useMemo } from "react";
import Draggable from "react-draggable";
import LineTo from "react-lineto";
import { useSelector } from "react-redux";
import { canvasModeSelector } from "../../slices/Canvas/canvasSlice";
import { GRID } from "../../utils/variables";
import { CanvasMode } from "../Canvas/types";
import { IBoundary } from "../interfaces";
import "./Design.scss";
import { DesignProps } from "./types";

export default function Design(props: DesignProps): JSX.Element {
  const canvasMode: CanvasMode = useSelector(canvasModeSelector);
  const editMode = useMemo(() => canvasMode === CanvasMode.EDIT, [canvasMode]);

  return (
    <Fragment key={props.id}>
      {props.boundaries.length &&
        props.boundaries.map((boundary: IBoundary, index: number) => (
          <Fragment key={props.id + index}>
            <Draggable
              key={props.id + index}
              disabled={props.isMultiline}
              position={{ x: boundary.x * GRID - 20, y: (boundary.y + props.offset) * GRID - 20 }}
              defaultClassName={`drag room room-${props.id} boundary boundary-${index}`}
              bounds={props.draggableBounds}
              onDrag={props.whileDrag}
              onStop={props.onDragStop}
            >
              <div
                key={`${props.id}_a`}
                onClick={(e) => props.onDesignPointClick(e, props.id)}
                id={props.id}
                style={{
                  pointerEvents: `${
                    !editMode || (props.isMultiline && index + 1 !== props.boundaries.length) || props.pointsEventsDisabled ? "none" : "all"
                  }`,
                }}
              />
            </Draggable>
            {index > 0 && (
              <LineTo
                key={`${props.id}_middle`}
                delay={0}
                borderWidth={props.lineWidth}
                borderColor={props.color}
                from={`room-${props.id} boundary-${index - 1}`}
                to={`room-${props.id} boundary-${index}`}
                zIndex={0}
                className={`lineTo room-${props.id} boundary-${index - 1}${index}`}
                within="react-grid-layout"
              />
            )}
            {index + 1 === props.boundaries.length && (
              <LineTo
                key={`${props.id}_last`}
                delay={0}
                borderWidth={props.lineWidth}
                borderColor={props.color}
                from={`room-${props.id} boundary-${index}`}
                to={props.isMultiline ? `multiline-guide${props.id}` : `room-${props.id} boundary-0`}
                zIndex={0}
                className={`lineTo room-${props.id} boundary-${index}0`}
                within="react-grid-layout"
              />
            )}
          </Fragment>
        ))}
    </Fragment>
  );
}
