import { CircularProgress } from "@mui/material";
import "./OverlayImage.scss";

interface IOverlayImageProps {
  imageSrc: string;
  loading: boolean;
  hoverDisabled: boolean;
  onImageClick: () => void;
}

export default function OverlayImage(props: IOverlayImageProps) {
  return (
    <div className="toolbar-default-image-container">
      {props.loading ? (
        <CircularProgress size={"2rem"} color="success" />
      ) : (
        <>
          <img className="default-toolbar-image" crossOrigin="" alt="" src={props.imageSrc} />
          {!props.hoverDisabled && <div onClick={props.onImageClick} className="default-toolbar-image-overlay" />}
        </>
      )}
    </div>
  );
}
