import { DeskAvailabilityColors, DeskColor } from "./types";

export const DESK_AVAILABILITY_STATES = ["FREE", "OCCUPIED", "OCCUPIED_BY_ME", "OCCUPIED_PARTIALLY", "OCCUPIED_BY_ME_AND_SOMEONE"] as const;

export const DEFAULT_DESK_COLORS: DeskAvailabilityColors = {
  FREE: "#46CB00",
  OCCUPIED: "#FE6648",
  OCCUPIED_BY_ME: "#179BED",
  OCCUPIED_PARTIALLY: "#FF27DE",
  OCCUPIED_BY_ME_AND_SOMEONE: "#AD00A6",
};

export const DEFAULT_DESK_SELECTED_COLOR = "#FFBA00" satisfies DeskColor;
