import client from "./axios.service";

const baseUrl = "/assignments";

export type AssignmentData = {
  desk: any;
  user: any;
  startTime: Date;
  endTime: Date;
};

export type AssignmentDataIntervals = {
  desk: any;
  user: any;
  times: Date[][];
};

export const getMyAssignments = (userId: string) => {
  const requestPath = `${baseUrl}/me`;

  return client.get(requestPath, null);
};

export const getAssignmentsForActiveUsers = () => {
  const requestPath = `${baseUrl}/activeUsers`;
  return client.get(requestPath, null);
};

export const getAssignmentsForActiveTeams = () => {
  const requestPath = `/teams/activeTeams`;
  return client.get(requestPath, null);
};

export const getAssignmentsByDate = (date: string) => {
  const requestPath = `${baseUrl}?date=${date}`;

  return client.get(requestPath, null);
};

export const getAssignmentsByInterval = (startDate: string, endDate: string, buildingId: string, floorId: string) => {
  const requestPath = `${baseUrl}/interval?startTime=${startDate}&endTime=${endDate}&buildingId=${buildingId}&floorId=${floorId}`;

  return client.get(requestPath, null);
};

export const addAssignment = (desk: any, user: any, startTime: Date, endTime: Date) => {
  const requestPath = `${baseUrl}/${desk}`;

  const data = {
    desk: desk,
    employee: user._id,
    startTime: startTime,
    endTime: endTime,
  };

  return client.post(requestPath, data, {});
};

export const addAssignmentDifferentIntervals = (desk: any, user: any, times: Date[][]) => {
  const requestPath = `${baseUrl}/${desk}/differentIntervals`;

  const data = {
    desk: desk,
    employee: user._id,
    times: times,
  };

  return client.post(requestPath, data, {});
};

export const addTeamAssignment = (teamId: string, desks: any, startTime: Date, endTime: Date) => {
  const requestPath = `/teams/${teamId}/assignment`;

  return client.post(requestPath, { startTime: startTime, endTime: endTime, desks: desks }, {});
};
export const addAssignmentForOthers = (label: string, desks: any, startTime: Date, endTime: Date) => {
  const requestPath = `${baseUrl}/others`;

  return client.post(
    requestPath,
    {
      startTime: startTime,
      endTime: endTime,
      desks: desks,
      label: label,
    },
    {}
  );
};

export const addAssignmentDifferentIntervalsTeam = (teamId: string, desks: any, times: Date[][]) => {
  const requestPath = `/teams/${teamId}/assignment/differentIntervals`;

  return client.post(requestPath, { times: times, desks: desks }, {});
};

export const addAssignmentDifferentIntervalsOthers = (label: string, desks: any, times: Date[][]) => {
  const requestPath = `${baseUrl}/others/differentIntervals`;

  return client.post(requestPath, { times: times, desks: desks, label: label }, {});
};

export const addAssignmentDifferentIntervalsColleagues = (desk: any, user: any, times: Date[][]) => {
  const requestPath = `${baseUrl}/${desk}/colleagues/differentIntervals`;

  const data = {
    desk: desk,
    employee: user._id,
    times: times,
  };

  return client.post(requestPath, data, {});
};

export const addAssignmentForColleagues = (desk: any, user: any, startTime: Date, endTime: Date) => {
  const requestPath = `${baseUrl}/${desk}/colleagues`;

  const data = {
    desk: desk,
    employee: user._id,
    startTime: startTime,
    endTime: endTime,
  };

  return client.post(requestPath, data, {});
};

export const deleteAssignment = (assignmentId: string) => {
  const requestPath = `${baseUrl}/${assignmentId}`;

  return client.delete(requestPath, {});
};

export const deleteAssignments = (assignmentIDs: string[]) => {
  const requestPath = `${baseUrl}/deleteMultiple`;
  return client.post(requestPath, assignmentIDs, {});
};

export const deleteAssignmentForUser = (assignmentId: string, userId: string) => {
  const requestPath = `${baseUrl}/${assignmentId}/forUser/${userId}`;

  return client.delete(requestPath, {});
};
