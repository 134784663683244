import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import { getAllGroups } from "../../services/groupsService";
import { Status } from "../StatusEnum";

type InitialStateType = {
  status: Status;
  error: SerializedError | null;
  groups: Array<string>;
};

const initialState: InitialStateType = {
  status: Status.Idle,
  error: null,
  groups: [],
};

export const allGroupsData = createAsyncThunk(
  "/groups/allGroupsData",
  async (_, thunkApi) => {
    try {
      const response = await getAllGroups();
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
  {
    condition: (_, store: any) => {
      const { groups } = store.getState();
      const fetchStatus = groups.status;
      if (fetchStatus === Status.Succeeded || fetchStatus === Status.Loading) {
        return false;
      }
    },
  }
);

const groupsSlice = createSlice({
  name: "groups",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(allGroupsData.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.groups = payload;
        }
      })
      .addCase(allGroupsData.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(allGroupsData.rejected, (state, action) => {
        state.status = Status.Failed;
        state.groups = [];
        state.error = action.error;
      });
  },
  reducers: {},
});

const { reducer, actions } = groupsSlice;

export default reducer;

export const allGroupsSelector = (state: any) => state.groups.groups;
