import { boxesIntersect, useSelectionContainer } from "@air/react-drag-to-select";
import ObjectID from "bson-objectid";
import { ContentState, convertToRaw, RawDraftContentState } from "draft-js";
import { fabric } from "fabric";
import _ from "lodash";
import { useCallback, useMemo, useRef, useState, useEffect } from "react";
import GridLayout from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";
import windowImg from "../../assets/images/window.png";
import { getFloorById } from "../../services/floorService";
import { userSelector } from "../../slices/Auth/authSlice";
import {
  canvasModeSelector,
  currentDateSelector,
  currentIntervalEndDateSelector,
  currentIntervalStartDateSelector,
  displayAdminModeSelector,
} from "../../slices/Canvas/canvasSlice";
import {
  assignmentsSelector,
  deleteAssignmentData,
  deleteAssignmentsMultiple,
  getAssignmentByDateInterval,
} from "../../slices/Entities/assignmentSlice";
import { selectedBuildingSelector } from "../../slices/Entities/buildingSlice";
import { ObjectType, selectedDesksSelector, selectedDesksState } from "../../slices/Entities/deskSlice";
import { selectedFloorSelector, setSelectedFloor as setFloorInfo, updateFloorData } from "../../slices/Entities/floorSlice";
import { getToolbarData } from "../../slices/Entities/toolbarSlice";
import {
  convertFromGridToPx,
  convertFromPxToGrid,
  convertLuxonDateToISOString,
  getDeskLabelNumber,
  getLayoutWidth,
} from "../../utils/canvas-helpers";
import { notifyCanvasSave, notifyError, notifyReservationDeleted, notifyUpdateError } from "../../utils/notify";
import { GRID } from "../../utils/variables";
import Design from "../Design/Design";
import Desk from "../Desk/Desk";
import { getDeskAvailability } from "../Desk/utils";
import FloorObject from "../FloorObject/FloorObject";
import { CanvasObject } from "../FloorObject/types";
import ObjectsToolbar from "../ObjectsToolbar/ObjectsToolbar";
import {
  DefaultToolbarAction,
  DefaultToolbarTool,
  Direction,
  ForwardedToolbarRef,
  IPlacedObject,
  ToolbarMeasurementUnit,
} from "../ObjectsToolbar/types";
import Popup from "../Popup/Popup";
import { IntervalTime, PopupMessageAndIntervals } from "../Popup/types";
import { ICoordinate, ISize } from "../interfaces";
import {
  Assignment,
  CanvasMode,
  CanvasProps,
  DesignType,
  FloorInfoChange,
  HoverObject,
  IBox,
  IDesign,
  IFloorInfo,
  IPopup,
  LineOptions,
} from "./types";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";

export default function Canvas(props: CanvasProps) {
  const [navBarY, setNavBarY] = useState(0);
  const [layoutWidth, setLayoutWidth] = useState<number | null>(null);
  const [hoverObject, setHoverObject] = useState<HoverObject | null>(null);
  const [popup, setPopup] = useState<IPopup | null>(null);
  const [selector, setSelector] = useState<any[]>([]);
  const [activeRoomId, setActiveRoomId] = useState<string | null>(null);
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);
  const [isCtrlPressed, setIsCtrlPressed] = useState<boolean>(false);
  const [showCanvas, setShowCanvas] = useState<boolean>(false);
  const [selectedDefaultTool, setSelectedDefaultTool] = useState<DefaultToolbarTool | DefaultToolbarAction | null>(null);
  const [draggingObjectID, setDraggingObjectID] = useState<string | null>(null);
  const [contentState, setContentState] = useState<RawDraftContentState>(convertToRaw(ContentState.createFromText("")));
  const [lineOptions, setLineOptions] = useState<LineOptions>({ width: 3, color: "#000000" });
  const [floorInfoChangesHistory, setFloorInfoChangesHistory] = useState<FloorInfoChange[]>([]);
  const [canvasOffset, setCanvasOffset] = useState<number>(0);

  const elementsContainerRef = useRef<HTMLDivElement | null>(null);
  const toolbarRef = useRef<ForwardedToolbarRef | null>(null);
  const shouldUpdateCanvas = useRef<boolean>(false);

  // selectors
  const floorInfo: IFloorInfo = useSelector(selectedFloorSelector);
  const building = useSelector(selectedBuildingSelector);
  const selectedDate = useSelector(currentDateSelector);
  const intervalStartDate = useSelector(currentIntervalStartDateSelector);
  const intervalEndDate = useSelector(currentIntervalEndDateSelector);
  const canvasMode: CanvasMode = useSelector(canvasModeSelector);
  const user = useSelector(userSelector);
  const assignments: Assignment[] = useSelector(assignmentsSelector);
  const selectedDesks: CanvasObject[] = useSelector(selectedDesksSelector);
  const isAdminModeActive: boolean = useSelector(displayAdminModeSelector);

  const dispatch = useDispatch<any>();

  let activeFloorInfoHistory: FloorInfoChange | null = null;
  activeFloorInfoHistory =
    floorInfoChangesHistory.find((change) => {
      return change.isActive;
    }) || null;

  const layout: GridLayout.Layout[] = useMemo(() => {
    if (!activeFloorInfoHistory) return [];
    const objectsArray = activeFloorInfoHistory?.canvas.objects.map((item) => ({
      i: item.id,
      x: item.position.x,
      y: item.position.y + canvasOffset,
      w: item.size.width,
      h: item.size.height,
    }));
    const designArray: GridLayout.Layout[] = [];
    activeFloorInfoHistory?.canvas.design.forEach((design) =>
      design.boundaries.forEach((boundary, index) =>
        designArray.push({
          i: `${design.id}_${index}`,
          x: boundary.x,
          y: boundary.y + canvasOffset,
          w: 1,
          h: 1,
          static: false,
        })
      )
    );
    return designArray.concat(objectsArray);
  }, [activeFloorInfoHistory]);

  const topBound = useMemo(() => (navBarY - (navBarY % GRID) + 2 * GRID - 1) / GRID, [navBarY]);

  useEffect(() => {
    const navbarHeight = document.getElementsByClassName("navbar-header")[0].clientHeight;
    setNavBarY(navbarHeight);
    dispatch(getToolbarData());
    updateCanvas();
    const keydownHandler = (ev: KeyboardEvent): void => {
      setIsCtrlPressed(ev.ctrlKey || ev.metaKey);
      if (ev.code === "Escape") {
        setHoverObject(null);
      }
    };

    document.addEventListener("keydown", keydownHandler);
    document.addEventListener("keyup", keydownHandler);

    return () => {
      document.removeEventListener("keydown", keydownHandler);
      document.removeEventListener("keyup", keydownHandler);
    };
  }, []);

  useEffect(() => {
    selectedDate && activeFloorInfoHistory && getAssignmentsForDate();
  }, [selectedDate, activeFloorInfoHistory?.id, intervalStartDate, intervalEndDate]);

  useEffect(() => {
    setSelectedIDs([]);
  }, [floorInfo?.id]);

  useEffect(() => {
    if (selectedDefaultTool !== DefaultToolbarAction.EDIT && canvasMode === CanvasMode.EDIT) return;
    if (!selectedIDs.length && activeRoomId) return;
    const lastItemSelected = selectedIDs[selectedIDs.length - 1];
    selectedIDs.length > 0 ? showPopup({ id: lastItemSelected }) : hidePopup();
  }, [selectedIDs, assignments, activeRoomId]);

  useEffect(() => {
    if (!activeFloorInfoHistory) return;
    const selectedDesks = activeFloorInfoHistory?.canvas.objects.filter((item) => item.type === ObjectType.DESK && selectedIDs.includes(item.id));
    dispatch(selectedDesksState(selectedDesks));
  }, [selectedIDs]);

  useEffect(() => {
    if (selectedDefaultTool) {
      setSelectedDefaultTool(null);
    }
    if (activeFloorInfoHistory) {
      setShowCanvas(false);
      updateCanvas();
    }
  }, [floorInfo?.id]);

  useEffect(() => {
    if (navBarY > 70 && canvasMode === CanvasMode.VIEW) {
      setCanvasOffset(Math.ceil(navBarY / GRID - 2));
    } else {
      setCanvasOffset(0);
    }
  }, [canvasMode, navBarY]);

  const updateCanvas = () => {
    if (!floorInfo) return;
    getFloorData().then((result: any) => {
      setShowCanvas(true);
      const serverFloorInfo: IFloorInfo = result.data;
      const serverLastModifiedAt = new Date(serverFloorInfo.canvas.modifiedAt);
      const storedFloorInfo: IFloorInfo | null = JSON.parse(
        localStorage.getItem(`floorInfo-${serverFloorInfo.building}-${serverFloorInfo.id}`) || "null"
      );
      const storedLastModifiedAt: Date | null = new Date(storedFloorInfo ? storedFloorInfo.canvas.modifiedAt : Date.now());

      if (!storedFloorInfo || storedLastModifiedAt < serverLastModifiedAt) {
        const { design, objects } = serverFloorInfo.canvas;
        setLayoutWidth(getLayoutWidth(design, objects));
        setFloorInfoChangesHistory([{ ...serverFloorInfo, isActive: true }]);
        return;
      }

      const { design, objects } = storedFloorInfo.canvas;
      setLayoutWidth(getLayoutWidth(design, objects));
      setFloorInfoChangesHistory([{ ...storedFloorInfo, isActive: true }]);
    });
  };

  const updateFloorInfo = (design?: IDesign[], objects?: CanvasObject[]) => {
    if (!activeFloorInfoHistory) return;
    const newObjects: CanvasObject[] = objects ? objects : activeFloorInfoHistory?.canvas.objects;
    const newDesign: IDesign[] = design ? design : activeFloorInfoHistory?.canvas.design;
    const newFloorInfo: IFloorInfo = {
      ...activeFloorInfoHistory,
      canvas: {
        ...activeFloorInfoHistory?.canvas,
        objects: newObjects,
        design: newDesign,
        modifiedAt: Math.floor(new Date().getTime() / 1000),
      },
    };

    dispatch(setFloorInfo(newFloorInfo));
    setFloorInfoChangesHistory((prev) => {
      let arrayOfChanges = [...prev];
      const activeFloorInfoHistoryIndex = arrayOfChanges.findIndex((change) => change.isActive);
      arrayOfChanges = arrayOfChanges.slice(0, activeFloorInfoHistoryIndex + 1);
      arrayOfChanges[arrayOfChanges.length - 1].isActive = false;
      arrayOfChanges.push({ ...newFloorInfo, isActive: true });
      return arrayOfChanges;
    });
    localStorage.setItem(`floorInfo-${newFloorInfo?.building}-${newFloorInfo?.id}`, JSON.stringify(newFloorInfo));
  };

  const getAssignmentsForDate = useCallback(async () => {
    if (!activeFloorInfoHistory) return;
    const data = {
      startDate: convertLuxonDateToISOString(intervalStartDate),
      endDate: convertLuxonDateToISOString(intervalEndDate || intervalStartDate),
      buildingId: activeFloorInfoHistory.building,
      floorId: activeFloorInfoHistory?.id,
    };
    dispatch(getAssignmentByDateInterval(data));
  }, [intervalEndDate, intervalStartDate, activeFloorInfoHistory?.id]);

  function getFloorData(): any {
    if (!floorInfo) return;
    return getFloorById(floorInfo?.building, floorInfo?.id);
  }

  //init for drag selector
  const { DragSelection } = useSelectionContainer({
    eventsElement: document.getElementById("root"),
    onSelectionChange: (box) => {
      if (canvasMode === CanvasMode.EDIT && selectedDefaultTool === DefaultToolbarTool.DRAWING && box.left !== undefined) {
        const newSelection = [
          { x: box.left, y: box.top },
          { x: box.left + box.width, y: box.top },
          { x: box.left + box.width, y: box.top + box.height },
          { x: box.left, y: box.top + box.height },
        ];

        const toSelector = newSelection.map((item) => {
          return { x: Math.round(item.x / GRID) * GRID - 20, y: Math.round(item.y / GRID) * GRID - 20 };
        });
        setSelector(toSelector);
        return;
      }

      const indexesToSelect: string[] = [];
      layout.forEach((item) => {
        const boxItem: IBox = {
          id: item.i,
          left: item.x * GRID,
          top: item.y * GRID,
          width: item.w * GRID,
          height: item.h * GRID,
        };
        if (boxesIntersect(box, boxItem) && !indexesToSelect.includes(item.i) && item.i !== "0") {
          if (canvasMode === CanvasMode.VIEW) {
            //? In view mode, when selecting by drag, only push the desk ids.
            const possibleDesk = activeFloorInfoHistory?.canvas.objects.find((o) => o.type === "desk" && o.id === item.i);
            possibleDesk && indexesToSelect.push(item.i);
          } else {
            indexesToSelect.push(item.i);
          }
        }
      });

      const newIndexes: string[] = []; //? We start building the new array of selected indexes, but maintaining the original order
      selectedIDs.forEach((index) => indexesToSelect.includes(index) && newIndexes.push(index)); //? Push the old selected values first, in the same order
      indexesToSelect.forEach((index) => !newIndexes.includes(index) && newIndexes.push(index)); //? Add the new values after

      //? update state only if the new values are different
      if (!_.isEqual(newIndexes, selectedIDs)) {
        setSelectedIDs(newIndexes);
      }
    },
    onSelectionEnd: () => {
      if (selectedDefaultTool === DefaultToolbarTool.DRAWING && selector.length > 0) {
        createRoom(selector);
        setSelector([]);
      }
    },
    onSelectionStart: () => {
      !isCtrlPressed && setSelectedIDs([]);
    },
    shouldStartSelecting: (target) => {
      if (target instanceof HTMLElement) {
        if (target.classList.contains("drag") || target.classList.contains("lineTo") || !target.classList.contains("elements-container"))
          return false;
        else return true;
      }
      return false;
    },
    isEnabled: true,
  });

  const clearCanvas = (): void => {
    if (canvasMode === CanvasMode.VIEW) return;
    updateFloorInfo([], []);
  };

  const deleteSelectedObjects = (): void => {
    if (canvasMode !== CanvasMode.EDIT) return;
    const newObjects = activeFloorInfoHistory?.canvas.objects.filter((desk) => !selectedIDs.includes(desk.id));
    updateFloorInfo(undefined, newObjects);
    setSelectedIDs([]);
  };

  const rotateSelectedObject = (rotatedObject: CanvasObject): void => {
    const newObjects = activeFloorInfoHistory?.canvas.objects.map((object) => {
      if (object.id !== rotatedObject.id) return object;
      return rotatedObject;
    });
    updateFloorInfo(undefined, newObjects);
  };

  const deleteSelectedRoom = (): void => {
    if (!activeRoomId) return;
    const newRooms = activeFloorInfoHistory?.canvas.design.filter((room) => room.id !== activeRoomId);
    updateFloorInfo(newRooms);
    setSelectedIDs([]);
    setActiveRoomId(null);
    hidePopup();
  };

  const getPopupMessageAndInterval = useCallback(
    (object: CanvasObject): PopupMessageAndIntervals => {
      if (object.type !== "desk") return { message: null, intervals: null };
      let message: PopupMessageAndIntervals["message"] = "Free";
      const deskAssignments = assignments.filter((assignment: Assignment) => assignment.desk._id === object.id);
      const reservers: string[] = [];
      const reservedBy: string[] = [];
      const intervals: IntervalTime[] = [];
      if (deskAssignments.length && canvasMode !== CanvasMode.EDIT) {
        deskAssignments.forEach((assignment) => {
          if (assignment.label) {
            reservers.push(assignment.label);
            reservedBy.push(`${assignment.employee?.firstName || "-"} ${assignment.employee?.lastName || ""}`.trim());
          } else if (assignment.team) {
            reservers.push(assignment.team?.name);
            reservedBy.push(`${assignment.employee?.firstName || "-"} ${assignment.employee?.lastName || ""}`.trim());
          } else if (assignment.reservedBy) {
            reservers.push(`${assignment.employee?.firstName || "-"} ${assignment.employee?.lastName || ""}`.trim());
            reservedBy.push(`${assignment.reservedBy?.firstName || "-"} ${assignment.reservedBy?.lastName || ""}`.trim());
          } else {
            reservers.push(`${assignment.employee?.firstName || "-"} ${assignment.employee?.lastName || ""}`.trim());
            reservedBy.push(`${assignment.employee?.firstName || "-"} ${assignment.employee?.lastName || ""}`.trim());
          }
          intervals.push({ startTime: new Date(assignment.startTime), endTime: new Date(assignment.endTime) });
        });
        message = { reservedBy: "", employee: "" };
        message.employee += `${reservers.map((r) => r).join(", ")}`;
        message.reservedBy += `Reserved by: ${reservedBy.map((r) => r).join(", ")}`;
      }
      return { message, intervals };
    },
    [assignments, canvasMode]
  );

  const showPopup = useCallback(
    (value: { id: string }): void => {
      const objectWithPopup = activeFloorInfoHistory?.canvas.objects.find((item) => item.id === value.id);
      if (!objectWithPopup) return;
      const width = objectWithPopup.size.width * GRID;
      const popupPosition: ICoordinate = {
        x: objectWithPopup.position.x * GRID + width / 2,
        y: objectWithPopup.position.y * GRID - GRID / 2 - 10,
      };

      const popupData: IPopup = {
        show: true,
        name: objectWithPopup.type,
        message: getPopupMessageAndInterval(objectWithPopup).message || "",
        position: popupPosition,
        type: objectWithPopup.type,
        imageSrc: objectWithPopup.src,
        label: objectWithPopup.label,
        hasBorder: objectWithPopup.hasBorder,
        hasBackground: objectWithPopup.hasBackground,
        hasImageStretched: objectWithPopup.hasImageStretched,
        object: objectWithPopup,
        intervals: getPopupMessageAndInterval(objectWithPopup).intervals,
        isEditingLabelInitial: objectWithPopup.type === ObjectType.TEXT ? true : false,
      };
      if (objectWithPopup.type === ObjectType.TEXT) {
        setContentState(objectWithPopup.editorContent);
      }
      setPopup(popupData);
    },
    [activeFloorInfoHistory?.canvas.objects, getPopupMessageAndInterval]
  );

  const hidePopup = (): void => {
    setPopup(null);
  };

  const deleteWindow = (e?: any): void => {
    const objectID = e.target.id;
    const canvasObject = activeFloorInfoHistory?.canvas.objects.find((item) => item.id === objectID);
    if (canvasObject && canvasObject.type === ObjectType.WINDOW && canvasMode === CanvasMode.EDIT) {
      const newObjects = activeFloorInfoHistory?.canvas.objects.filter((obj: CanvasObject) => obj.id !== objectID);
      updateFloorInfo(undefined, newObjects);
    }
  };

  //hides popup, except when mouse was dragged or clicked on a button
  const mouseClickCanva = (e?: any): void => {
    const className = e.target.className;
    if (!isCtrlPressed && typeof className === "string" && (className.includes("react-grid-layout") || className.includes("container-parent"))) {
      selectedIDs.length && setSelectedIDs([]);
      activeRoomId && setActiveRoomId(null);
      hidePopup();
      return;
    }
    if (
      typeof className === "string" &&
      className.includes("lineTo") &&
      canvasMode === CanvasMode.EDIT &&
      hoverObject &&
      selectedDefaultTool === DefaultToolbarTool.WINDOW
    ) {
      const newWindow: CanvasObject = {
        ...hoverObject,
        position: { x: (hoverObject.position.x + GRID) / GRID, y: hoverObject.position.y / GRID },
        rotation: hoverObject.rotation,
        id: ObjectID(new Date().getTime()).id,
        active: false,
        src: hoverObject.src,
        type: ObjectType.WINDOW,
        size: { width: 2, height: 0 },
        label: "",
        hasBorder: false,
        hasBackground: false,
        hasImageStretched: false,
        toolbarItemID: null,
      };
      const newObjects: CanvasObject[] = [...(activeFloorInfoHistory?.canvas.objects || []), newWindow];
      updateFloorInfo(undefined, newObjects);
    }
  };

  const renderWindowOnHover = (e?: any): void => {
    //TODO Make this function shorter
    if (selectedDefaultTool !== DefaultToolbarTool.WINDOW || !activeFloorInfoHistory) return;
    let objBounds = e.target?.getBoundingClientRect().toJSON();
    const targetClassName = e.target.className;
    if (
      typeof targetClassName === "string" &&
      targetClassName.includes("lineTo") &&
      canvasMode === CanvasMode.EDIT &&
      selectedDefaultTool === DefaultToolbarTool.WINDOW
    ) {
      let projectionX = 0;
      let projectionY = 0;
      const targetLineID = e.target.classList[1].split("room-")[1];
      const lineBoundaries = activeFloorInfoHistory?.canvas.design.filter((obj) => obj.id === targetLineID)[0].boundaries;
      const linePoints = e.target.classList[2].split("boundary-")[1];
      const point1 = convertFromGridToPx(lineBoundaries[Number(linePoints[0])]);
      const point2 = convertFromGridToPx(lineBoundaries[Number(linePoints[1])]);

      // snap to grid moving objects
      const cursorX = Math.round(e.clientX / GRID) * GRID;
      const cursorY = Math.round(e.clientY / GRID) * GRID;

      const distanceFromLine =
        Math.abs((point2.x - point1.x) * (cursorY - point1.y) - (point2.y - point1.y) * (cursorX - point1.x)) /
        Math.sqrt((point2.x - point1.x) * (point2.x - point1.x) + (point2.y - point1.y) * (point2.y - point1.y));
      if (point1.y === point2.y) {
        projectionX = cursorX;
        projectionY = point1.y;
      } else if (point1.x === point2.x) {
        projectionY = cursorY;
        projectionX = point1.x;
      } else {
        projectionX =
          (((point2.x - point1.x) * cursorX) / (point2.y - point1.y) + (-point2.x * point1.y + point1.x * point2.y) / (point2.x - point1.x)) /
          ((point2.y - point1.y) / (point2.x - point1.x) + (point2.x - point1.x) / (point2.y - point1.y));
        projectionY =
          ((point2.y - point1.y) / (point2.x - point1.x)) * projectionX + (point2.x * point1.y - point1.x * point2.y) / (point2.x - point1.x);
      }

      //if the mouse is over the line and it's in floor planner mode
      if (distanceFromLine < GRID / 2) {
        objBounds = {
          ...objBounds,
          y: objBounds.y + GRID,
        };

        let position = { x: -1, y: -1 };
        const objRotation: number = Number(e.target.style.transform.match(/-?\.?\d/g).join(""));
        const type = ObjectType.WINDOW;
        const src = windowImg;

        // we check the roration of object and depending on that do calculation on where the window object should appear
        if (Math.abs(objRotation) === 90) {
          position = { ...position, x: objBounds.x + 1, y: cursorY };
          if (objRotation > 1) position.x = position.x + GRID;
          if (position.y <= objBounds.y) position.y = objBounds.y;
          else if (position.y >= objBounds.bottom - GRID) position.y = objBounds.bottom - GRID;
          setHoverObject({ position, type: type, src, size: { width: GRID * 2, height: GRID }, rotation: objRotation });
        } else if (objRotation === 0 || objRotation === 180) {
          position = { ...position, x: cursorX, y: objBounds.y + 1 };
          if (objRotation < 1) position.y = position.y - GRID;
          if (position.x <= objBounds.x + GRID) position.x = objBounds.x + GRID;
          else if (position.x >= objBounds.right - GRID) position.x = objBounds.right - GRID;
          setHoverObject({ position, type: type, src, size: { width: GRID * 2, height: GRID }, rotation: objRotation });
        } else {
          projectionX =
            (e.clientY +
              ((point2.x - point1.x) * e.clientX) / (point2.y - point1.y) +
              (-point2.x * point1.y + point1.x * point2.y) / (point2.x - point1.x)) /
            ((point2.y - point1.y) / (point2.x - point1.x) + (point2.x - point1.x) / (point2.y - point1.y));
          projectionY =
            ((point2.y - point1.y) / (point2.x - point1.x)) * projectionX + (point2.x * point1.y - point1.x * point2.y) / (point2.x - point1.x);
          position = { ...position, x: projectionX, y: projectionY };
          if (
            Math.sqrt((point1.x - projectionX) * (point1.x - projectionX) + (point1.y - projectionY) * (point1.y - projectionY)) > GRID &&
            Math.sqrt((point2.x - projectionX) * (point2.x - projectionX) + (point2.y - projectionY) * (point2.y - projectionY)) > GRID
          ) {
            setHoverObject({ position, type: type, src, size: { width: GRID * 2, height: objBounds.height }, rotation: objRotation });
          }
        }
      }
    } else {
      setHoverObject(null);
    }
  };

  // we need this code to detect if user click on element or dragged it, we use deltaMouse movement
  const whileDesignPointDragging = (e: any): void => {
    if (!e.target.id.includes("room-")) return;
    if (document.getElementsByClassName("boundary") === null || document.getElementsByClassName("boundary") === undefined) return;
    let newBoundaries: ICoordinate[] = [];
    const newRooms: IDesign[] = [];
    // We get the elements, get their position then create a new object with the new positions
    // we also approximate the input values to a grid
    setDraggingObjectID("room");
    Object.values(document.getElementsByClassName("boundary")).forEach((item, index, object) => {
      const oldRoom = activeFloorInfoHistory?.canvas.design.find((room) => room.id === item.id);
      if (!oldRoom) return;
      const boundaryX = item?.getBoundingClientRect().x + 20;
      const boundaryY = item?.getBoundingClientRect().y + 20;
      newBoundaries.push({
        x: boundaryX / GRID,
        y: boundaryY / GRID,
      });

      if (object[index + 1] === undefined || item.id !== object[index + 1].id) {
        if (e.target.id === item.id) {
          const thisBoundaryId = e.target?.classList[4].match(/\d+$/)[0];
          const thisBoundary = newBoundaries[thisBoundaryId];
          newBoundaries[thisBoundaryId] = {
            x: Math.round(thisBoundary.x),
            y: Math.round(thisBoundary.y),
          };
        }
        newRooms.push({
          ...oldRoom,
          boundaries: newBoundaries,
        });
        newBoundaries = [];
      }
    });
    updateFloorInfo(newRooms, undefined);
  };

  const handleLineStopDrag = (): void => {
    setTimeout(() => {
      setDraggingObjectID(null);
    }, 100);
  };

  const createRoom = (locations: ICoordinate[]): void => {
    if (!activeFloorInfoHistory) return;
    // check if room created is out of canvas bounds. If so, the lines are drawn on the limits
    const roomBoundaries = locations.map((location) => ({
      x: location.x + 20 < 0 ? 0 : (location.x + 20) / GRID,
      y: location.y - 20 < topBound * GRID ? topBound : (location.y + 20) / GRID,
    }));
    const p1 = roomBoundaries[0]; //? upper, left point
    const p2 = roomBoundaries[2]; //? down, right point
    if (!(p1.x - p2.x) || !(p1.y - p2.y)) return; //? if room is smaller than 1 square, return
    const newRoom: IDesign = {
      id: `room-${ObjectID().toHexString()}`,
      boundaries: roomBoundaries,
      label: "",
      isMultiline: false,
      color: lineOptions.color,
      width: lineOptions.width,
    };
    const newRooms = [...activeFloorInfoHistory?.canvas.design, newRoom];
    updateFloorInfo(newRooms);
  };

  const saveObject = (objects: CanvasObject[], savedObject: CanvasObject): void => {
    if (!activeFloorInfoHistory) return;
    return dispatch(
      updateFloorData({
        ...(activeFloorInfoHistory || {}),
        canvas: {
          floorId: activeFloorInfoHistory.id,
          name: activeFloorInfoHistory.name,
          objects: objects,
          design: activeFloorInfoHistory?.canvas.design,
          modifiedAt: Math.floor(new Date().getTime() / 1000),
        },
      })
    ).then((result: any) => {
      if (result.error) {
        props.setErrorMessage(result.payload.response.data);
        return;
      }
      const responseFloorInfo: IFloorInfo = result.payload;
      notifyCanvasSave();
      const newObjects = activeFloorInfoHistory?.canvas.objects.filter((obj) => obj.id !== savedObject.id);
      const objectWithPossibleNewID = responseFloorInfo.canvas.objects.find(
        (obj) => obj.position.x === savedObject.position.x && obj.position.y === savedObject.position.y
      );
      dispatch(
        setFloorInfo({
          ...responseFloorInfo,
          canvas: {
            ...responseFloorInfo.canvas,
            objects: [...(newObjects || []), objectWithPossibleNewID],
          },
        })
      ); //? create new timestamp
    });
  };

  const saveSelectedObject = (): void => {
    if (!activeFloorInfoHistory || !floorInfo) return;
    shouldUpdateCanvas.current = false;
    const selectedObject = activeFloorInfoHistory?.canvas.objects.find((obj) => obj.id === selectedIDs[0]);
    if (selectedObject) {
      getFloorData().then((result: any) => {
        const payload: IFloorInfo = result.data;
        const serverObjects = payload.canvas.objects;
        const existingCanvasObjectOnServer = serverObjects.find((obj) => obj.id === selectedObject.id);
        if (existingCanvasObjectOnServer) {
          const filteredServerObjects = serverObjects.filter((obj) => obj.id !== selectedObject.id);
          saveObject([selectedObject, ...filteredServerObjects], selectedObject);
          return;
        }
        saveObject([selectedObject, ...serverObjects], selectedObject);
      });
    }
  };

  const objectPlaceCallback = (object: IPlacedObject): void => {
    if (object.position.y < topBound * GRID || !activeFloorInfoHistory) return;
    let deskLabel = "";
    if (object.type === ObjectType.DESK) {
      const desks = activeFloorInfoHistory?.canvas.objects.filter((obj) => obj.type === ObjectType.DESK);
      deskLabel = `${building.name}_${activeFloorInfoHistory?.name || ""}_${getDeskLabelNumber(desks)}`;
    }
    let newObject: any = {
      id: object.id,
      toolbarItemID: object._id,
      type: object.type,
      position: convertFromPxToGrid(object.position),
      label: deskLabel || object.label,
      size: object.size,
      rotation: 0,
      src: object.imageSrc || "",
      hasBorder: false,
      hasBackground: false,
    };
    if (object.type === ObjectType.TEXT) {
      newObject = { ...newObject, editorContent: convertToRaw(ContentState.createFromText("Enter your text :)")) };
      setSelectedIDs([newObject.id]);
    }
    updateFloorInfo(undefined, [...activeFloorInfoHistory?.canvas.objects, newObject]);
  };

  const objectClickHandler = useCallback(
    (e: any, object: CanvasObject): void => {
      e.stopPropagation();
      if (object.id === draggingObjectID) return;
      const isItemSelected: boolean = selectedIDs.includes(object.id);
      if (canvasMode === CanvasMode.VIEW && object.type !== ObjectType.DESK) return;
      setSelectedIDs((prevIDs) => {
        return isCtrlPressed
          ? isItemSelected
            ? prevIDs.filter((index) => index !== object.id) //? (CTRL pressed) + (item selected) -> remove item from the list
            : [...prevIDs, object.id] //? (CTRL pressed) + (item NOT selected) -> add item to the list
          : [object.id]; //? (CTRL NOT pressed) + (item NOT selected) -> only this item should be in the list
      });
    },
    [canvasMode, draggingObjectID, isCtrlPressed, selectedIDs]
  );

  const onMouseHoverCanvasObject = useCallback(
    (object: CanvasObject): void => {
      !selectedIDs.length && canvasMode === CanvasMode.VIEW && showPopup({ id: object.id });
    },
    [canvasMode, selectedIDs.length, showPopup]
  );

  const defaultToolSelectionHandler = (selectedTool: DefaultToolbarTool | DefaultToolbarAction | null): void => {
    if (selectedTool === DefaultToolbarAction.DELETE_ALL) {
      clearCanvas();
    }
    if (selectedTool === DefaultToolbarAction.UNDO) {
      undoCanvasChange();
    }
    if (selectedTool === DefaultToolbarAction.REDO) {
      redoCanvasChange();
    }
    setSelectedDefaultTool(selectedTool);
    hidePopup();
  };

  const handleObjectStartedToMove: GridLayout.ItemCallback = (_, __, item): void => {
    setDraggingObjectID(item.i);
  };

  const handleObjectMoved: GridLayout.ItemCallback = (_, __, newItem): void => {
    const item = activeFloorInfoHistory?.canvas.objects.find((obj) => obj.id === newItem.i);
    if (!item) return;
    const position: ICoordinate = { x: newItem.x, y: newItem.y < topBound ? topBound : newItem.y };
    const newObjects = activeFloorInfoHistory?.canvas.objects.map((object) => (object.id === item.id ? { ...object, position } : object));
    updateFloorInfo(undefined, newObjects);
    setTimeout(() => {
      setDraggingObjectID(null);
    }, 10);
  };

  const handleObjectResize: GridLayout.ItemCallback = (_, __, newItem): void => {
    const object = activeFloorInfoHistory?.canvas.objects.find((obj) => obj.id === newItem.i);
    if (!object) return;
    const newSize: ISize = { width: newItem.w, height: newItem.h };
    const newObjects = activeFloorInfoHistory?.canvas.objects.map((item) => (item.id === object.id ? { ...item, size: newSize } : item));
    showPopup({ id: object.id });
    updateFloorInfo(undefined, newObjects);
  };

  const handlePopupLabelSave = (newLabel: string): void => {
    const object = activeFloorInfoHistory?.canvas.objects.find((item) => item.id === selectedIDs[0]);
    const room = activeFloorInfoHistory?.canvas.design.find((item) => item.id === activeRoomId);

    if (!(object || room)) return;
    const newObjects = activeFloorInfoHistory?.canvas.objects.map((item) => (item.id === selectedIDs[0] ? { ...item, label: newLabel } : item));
    const newRooms = activeFloorInfoHistory?.canvas.design.map((item) => (item.id === activeRoomId ? { ...item, label: newLabel } : item));

    object && updateFloorInfo(undefined, newObjects);
    room && updateFloorInfo(newRooms);

    if (!popup) return;
    const newPopup: IPopup = { ...popup, label: newLabel };
    setPopup(newPopup);
  };

  const toggleFlipOnSelectedObject = (flippedObject: CanvasObject): void => {
    const newObjects = activeFloorInfoHistory?.canvas.objects.map((item) =>
      item.id === flippedObject.id ? { ...item, flippedY: !item.flippedY } : item
    );
    updateFloorInfo(undefined, newObjects);
  };

  const handleEditorSave = (editorContent: RawDraftContentState): void => {
    const id = selectedIDs[0];
    if (!id) return;
    const newObjects = activeFloorInfoHistory?.canvas.objects.map((obj) => (obj.id === id ? { ...obj, editorContent } : obj));
    updateFloorInfo(undefined, newObjects);
  };

  const handlePopupCheckboxToggle = (type: "background" | "border" | "stretchedImage", checked: boolean): void => {
    const itemID = selectedIDs[0];
    let newValue: { hasBackground?: boolean; hasBorder?: boolean; hasImageStretched?: boolean };
    switch (type) {
      case "background":
        newValue = { hasBackground: checked };
        break;
      case "border":
        newValue = { hasBorder: checked };
        break;
      case "stretchedImage":
        newValue = { hasImageStretched: checked };
        break;
    }
    const newObjects = activeFloorInfoHistory?.canvas.objects.map((item) => (item.id === itemID ? { ...item, ...newValue } : item));
    updateFloorInfo(undefined, newObjects);
  };

  const getCanDeleteReservation = (): boolean => {
    if (selectedDesks.length !== 1) return false;
    const selectedDeskID = selectedDesks[0].id;
    const allDeskAssignmentsIDs: string[] = [];
    let userAssignment: Assignment | null = null;

    assignments.forEach((assignment) => {
      if (assignment.desk._id !== selectedDeskID) return;
      allDeskAssignmentsIDs.push(assignment._id);
      if (assignment.employee?.username === user.username || (assignment.reservedBy && assignment.reservedBy.username === user.username)) {
        userAssignment = assignment;
      }
    });

    if (!allDeskAssignmentsIDs.length || !userAssignment) return false;
    return (
      isAdminModeActive ||
      (userAssignment as Assignment).employee?.username === user.username ||
      ((userAssignment as Assignment).reservedBy ? (userAssignment as Assignment).reservedBy?.username === user.username : false)
    );
  };

  const getCanAddReservation = (): boolean => {
    const selectedDeskID = selectedDesks[0]?.id;
    if (!selectedDeskID) return false;
    const deskAvailability = getDeskAvailability(assignments, intervalStartDate, intervalEndDate, selectedDeskID, user.username);
    if (deskAvailability === "FREE" || deskAvailability === "OCCUPIED_PARTIALLY") return true;
    return false;
  };

  const handleReservationDelete = () => {
    const selectedDeskID = selectedDesks[0].id;
    if (!selectedDeskID) return;

    let deletePromise;
    if (isAdminModeActive) {
      const selectedDeskAssignmentsIDs = assignments.filter((assignment) => assignment.desk._id === selectedDeskID).map((a) => a._id);
      deletePromise = dispatch(deleteAssignmentsMultiple(selectedDeskAssignmentsIDs));
    } else {
      const selectedDeskAssignment = assignments.find(
        (assignment) =>
          assignment.desk._id === selectedDeskID &&
          (assignment.employee?.username === user.username || (assignment.reservedBy && assignment.reservedBy?.username === user.username))
      );
      if (!selectedDeskAssignment) {
        notifyUpdateError();
        getAssignmentsForDate();
        return;
      }
      deletePromise = dispatch(deleteAssignmentData(selectedDeskAssignment.id));
    }
    deletePromise
      .then((result: any) => {
        const errorMessage = result.payload?.response.data.message;
        if (errorMessage) {
          notifyError(errorMessage);
          return;
        }
        notifyReservationDeleted();
      })
      .finally(() => {
        getAssignmentsForDate();
      });
  };

  const handleMultilinePointPlace = (multilinePoint: ICoordinate, lineSessionID: string) => {
    if (multilinePoint.y < topBound || !activeFloorInfoHistory) return;
    const linesAttached = activeFloorInfoHistory?.canvas.design.find((item) => item.id === lineSessionID);
    const newMultiline: IDesign = {
      id: lineSessionID,
      label: "Multiline",
      boundaries: [...(linesAttached?.boundaries || []), multilinePoint],
      isMultiline: true,
      color: lineOptions.color,
      width: lineOptions.width,
    };
    const oldDesign = activeFloorInfoHistory?.canvas.design.filter((item) => item.id !== lineSessionID);
    updateFloorInfo([...oldDesign, newMultiline]);
  };

  const handleLinePointClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    const activeRoom = activeFloorInfoHistory?.canvas.design.find((r) => r.id === id);
    if (!activeRoom) return;
    setActiveRoomId(id);
    setSelectedIDs([]);
    if (draggingObjectID) return;
    setPopup({
      show: true,
      name: "ROOM",
      message: "",
      position: { x: e.clientX, y: e.clientY },
      type: DesignType.ROOM,
      label: activeRoom.label,
      hasBackground: false,
      hasBorder: false,
      hasImageStretched: false,
      object: null,
      intervals: null,
      isEditingLabelInitial: false,
    });
  };

  const handleItemsMove = (direction: Direction, unit: ToolbarMeasurementUnit) => {
    const moveValue = unit === "sq" ? 1 : 1 / GRID;
    let addedOffset: ICoordinate = { x: 0, y: 0 };

    switch (direction) {
      case "up":
        addedOffset.y = -moveValue;
        break;
      case "down":
        addedOffset.y = moveValue;
        break;
      case "left":
        addedOffset.x = -moveValue;
        break;
      case "right":
        addedOffset.x = moveValue;
        break;
    }
    if (selectedIDs.length) {
      //? move only selected items;
      const newItems = activeFloorInfoHistory?.canvas.objects.map((obj) =>
        selectedIDs.includes(obj.id)
          ? {
              ...obj,
              position: { x: obj.position.x + addedOffset.x, y: obj.position.y + addedOffset.y },
            }
          : obj
      );
      updateFloorInfo(undefined, newItems);
      return;
    }
    //? move all items, if none are selected.
    const newItems = activeFloorInfoHistory?.canvas.objects.map((obj) => ({
      ...obj,
      position: { x: obj.position.x + addedOffset.x, y: obj.position.y + addedOffset.y },
    }));
    const newDesigns = activeFloorInfoHistory?.canvas.design.map((design) => ({
      ...design,
      boundaries: design.boundaries.map((point) => ({
        x: point.x + addedOffset.x,
        y: point.y + addedOffset.y,
      })),
    }));
    updateFloorInfo(newDesigns, newItems);
  };

  const handleLineWidthChange = (width: number) => {
    setLineOptions({ ...lineOptions, width });
  };

  const handleLineColorChange = (color: string) => {
    setLineOptions({ ...lineOptions, color });
  };

  const handleLineSessionDelete = (sessionID: string) => {
    const newDesign = activeFloorInfoHistory?.canvas.design.filter((point) => point.id !== sessionID);
    updateFloorInfo(newDesign);
    hidePopup();
  };

  const undoCanvasChange = () => {
    setFloorInfoChangesHistory((prev) => {
      let previousHistory = Array.from(prev);
      const activeFloorInfoHistoryIndex = prev.findIndex((history) => history.isActive);
      previousHistory[activeFloorInfoHistoryIndex].isActive = false;
      previousHistory[activeFloorInfoHistoryIndex - 1].isActive = true;
      return previousHistory;
    });
  };

  const redoCanvasChange = () => {
    setFloorInfoChangesHistory((prev) => {
      let previousHistory = Array.from(prev);
      const activeFloorInfoHistoryIndex = prev.findIndex((history) => history.isActive);
      previousHistory[activeFloorInfoHistoryIndex].isActive = false;
      previousHistory[activeFloorInfoHistoryIndex + 1].isActive = true;
      return previousHistory;
    });
  };

  const handleChangesDiscard = () => {
    getFloorData().then((result: any) => {
      const payload: IFloorInfo = result.data;
      setFloorInfoChangesHistory([{ ...payload, isActive: true }]);
    });
  };

  if (!showCanvas || !layoutWidth) {
    return <></>;
  }

  return (
    <div
      style={{
        width: window.screen.width < 700 ? layoutWidth : undefined,
      }}
      className="container-parent"
      onMouseDown={mouseClickCanva}
      onMouseMove={renderWindowOnHover}
    >
      <div className="container-buttons" style={{ top: `${navBarY}px` }}></div>
      {hoverObject && (
        <img
          alt="Logo"
          src={hoverObject.src}
          className={`${hoverObject.type}Object hoverObject`}
          style={{
            top: hoverObject.position.y,
            left: hoverObject.position.x,
            width: `${hoverObject.size.width}px`,
            transform: `translate(-50%, -50%) rotate(${hoverObject.rotation}deg)`,
          }}
        />
      )}
      <DragSelection />
      <div
        className={`container container1 elements-container ${canvasMode === CanvasMode.VIEW ? "userMode" : "editMode"}`}
        ref={elementsContainerRef}
      >
        {canvasMode === CanvasMode.EDIT && (
          <ObjectsToolbar
            historyPermission={{
              undo: floorInfoChangesHistory.findIndex((history) => history.isActive) !== 0,
              redo: floorInfoChangesHistory.findIndex((history) => history.isActive) < floorInfoChangesHistory.length - 1,
            }}
            ref={toolbarRef}
            selectedObjectsIDs={selectedIDs}
            hidden={false}
            onItemsMoveCallback={handleItemsMove}
            objectPlaceCallback={objectPlaceCallback}
            multilinePointCallback={handleMultilinePointPlace}
            onDefaultToolSelectionCallback={defaultToolSelectionHandler}
            onDefaultActionSelectionCallback={defaultToolSelectionHandler}
            updateFloorInfo={updateFloorInfo}
            changesDiscardCallback={handleChangesDiscard}
            lineWidthChangeCallback={handleLineWidthChange}
            colorChangeCallback={handleLineColorChange}
            deleteCurrentLineSessionCallback={handleLineSessionDelete}
          />
        )}
        {popup && (
          <Popup
            {...popup}
            navbarHeight={navBarY}
            contentState={contentState}
            numberOfItems={selectedIDs.length}
            canDeleteReservation={getCanDeleteReservation()}
            canAddReservation={getCanAddReservation()}
            deleteReservationCallback={handleReservationDelete}
            deleteSelectedObjects={deleteSelectedObjects}
            deleteSelectedRoom={deleteSelectedRoom}
            labelSaveCallback={handlePopupLabelSave}
            saveSelectedObject={saveSelectedObject}
            contentStateCallback={handleEditorSave}
            backgroundCheckboxCallback={(checked) => handlePopupCheckboxToggle("background", checked)}
            borderCheckboxCallback={(checked) => handlePopupCheckboxToggle("border", checked)}
            stretchedImageCheckboxCallback={(checked) => handlePopupCheckboxToggle("stretchedImage", checked)}
          />
        )}
        {
          <GridLayout
            onDrag={handleObjectStartedToMove}
            onResizeStop={handleObjectResize}
            onDragStop={handleObjectMoved}
            compactType={null}
            isResizable={canvasMode === CanvasMode.EDIT && selectedDefaultTool === DefaultToolbarAction.RESIZE}
            isDraggable={canvasMode === CanvasMode.EDIT && selectedDefaultTool === DefaultToolbarAction.MOVE}
            className={`layout-container elements-container${canvasMode === CanvasMode.EDIT ? " edit-mode" : ""}`}
            layout={layout}
            margin={[0, 0]}
            allowOverlap
            rowHeight={GRID}
            width={layoutWidth}
            cols={layoutWidth / GRID}
          >
            {[...(activeFloorInfoHistory?.canvas?.design || [])].map((item: IDesign) => (
              <Design
                //* children={item.children}
                id={item.id}
                key={item.id}
                offset={canvasOffset}
                draggableBounds={{
                  top: navBarY - (navBarY % GRID) + 2 * GRID,
                  right: elementsContainerRef.current?.offsetWidth,
                  bottom: elementsContainerRef.current?.offsetHeight,
                }}
                boundaries={item.boundaries}
                onDragStop={handleLineStopDrag}
                whileDrag={whileDesignPointDragging}
                isMultiline={item.isMultiline}
                onDesignPointClick={handleLinePointClick}
                pointsEventsDisabled={selectedDefaultTool === DefaultToolbarTool.POLY_LINE || !!popup}
                color={item.color || "#000000"}
                lineWidth={item.width || 3}
              />
            ))}
            {activeFloorInfoHistory?.canvas.objects
              .filter((item) => item.type === ObjectType.DESK)
              .map((item: CanvasObject) => (
                <Desk
                  flipCallback={toggleFlipOnSelectedObject}
                  rotationCallback={rotateSelectedObject}
                  selectedDefaultTool={selectedDefaultTool}
                  item={item}
                  key={item.id}
                  onClick={(e) => objectClickHandler(e, item)}
                  onMouseOver={onMouseHoverCanvasObject}
                  isSelected={!!selectedDesks.find((desk) => item.id === desk.id)}
                />
              ))}
            {activeFloorInfoHistory?.canvas.objects
              .filter((item) => item.type !== ObjectType.DESK)
              .map((item: CanvasObject) => (
                <FloorObject
                  flipCallback={toggleFlipOnSelectedObject}
                  rotationCallback={rotateSelectedObject}
                  selectedDefaultTool={selectedDefaultTool}
                  item={item}
                  key={item.id}
                  onClick={(e) => objectClickHandler(e, item)}
                  isSelected={selectedIDs.includes(String(item.id))}
                  onMouseDown={deleteWindow}
                  dragging={item.id === draggingObjectID}
                />
              ))}
          </GridLayout>
        }
      </div>
    </div>
  );
}

let canvasObject: fabric.Canvas;

export function getCanvasObject() {
  return canvasObject;
}
