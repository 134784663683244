import useMousePosition from "../../../utils/Hooks/useMousePosition";
import ToolbarObject from "../../ToolbarObject/ToolbarObject";
import { ISize } from "../../interfaces";
import { getObjectGridPosition } from "../ObjectsToolbar";

export type ObjectGuiderProps = {
  objectSize: ISize;
  onClick: (x: number, y: number) => void;
  imageSrc: string;
  objectLabel: string;
};

const ObjectGuider = (props: ObjectGuiderProps) => {
  const { clientX, clientY } = useMousePosition();

  return (
    <div
      data-testid="canvas-object-guider"
      className="objects-container"
      style={{
        position: "absolute",
        left: getObjectGridPosition(clientX, props.objectSize.width),
        top: getObjectGridPosition(clientY, props.objectSize.height),
        transform: "translate(-50%, -50%)",
        cursor: "pointer",
        zIndex: 1,
      }}
    >
      <ToolbarObject
        onClick={() => props.onClick(clientX, clientY)}
        imageSrc={props.imageSrc}
        label={props.objectLabel}
        size={props.objectSize}
        isInToolbar={false}
      />
    </div>
  );
};

export default ObjectGuider;
