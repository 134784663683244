import { LocalizationProvider, MobileDateRangePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { InputAdornment, Stack, TextField } from "@mui/material";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { userSelector } from "../../slices/Auth/authSlice";
import {
  calendarChangedSelector,
  intervalEndSelector,
  intervalStartSelector,
  setIntervalEnd,
  setIntervalStart,
} from "../../slices/Canvas/canvasSlice";
import {
  allBuildingsSelector,
  buildingNameSelector,
  getBuildings,
  selectedBuildingSelector,
  setSelectedBuilding,
} from "../../slices/Entities/buildingSlice";
import { defaultBuildingSelector, getAllConfigurations, myDefaultBuildingSelector } from "../../slices/Entities/configurationsSlice";
import { selectedDesksState } from "../../slices/Entities/deskSlice";
import { getFloorsFromBuilding, setSelectedFloor } from "../../slices/Entities/floorSlice";
import { useAuth } from "../../utils/AuthHook";
import Dropdown from "../GenericDropdown/genericDropdown";

export default function NavbarSelectableTools(props: any) {
  const user = useSelector(userSelector);
  const dispatch = useDispatch<any>();

  const { setErrorMessage } = props;
  const [buildingOptions, setBuildingOptions] = useState<any>([]);
  const [defaultBuilding, setDefaultBuilding] = useState<any>([]);
  const buildingSelector = useSelector(allBuildingsSelector);
  const selectedBuilding = useSelector(selectedBuildingSelector);
  const buildingName = useSelector(buildingNameSelector);
  const defaultBuildingConfig = useSelector(defaultBuildingSelector);
  const myDefaultBuildingConfig = useSelector(myDefaultBuildingSelector);
  const intervalEndDate = useSelector(intervalEndSelector);
  const intervalStartDate = useSelector(intervalStartSelector);
  const calendarChanged = useSelector(calendarChangedSelector);
  const { check } = useAuth();

  const changedDate = (dates: any) => {
    if (DateTime.fromISO(dates[0].toISOString()).hasSame(intervalStartDate, "day")) {
      if (dates[1] !== null) {
        if (!DateTime.fromISO(dates[1].toISOString()).hasSame(intervalEndDate, "day")) {
          dates[0] = dates[1];
          dispatch(setIntervalStart(DateTime.fromJSDate(dates[0])));
          dispatch(setIntervalEnd(DateTime.fromJSDate(dates[0]).plus({ days: 6 })));
          dates[1] = DateTime.fromJSDate(dates[0]).plus({ days: 6 }).toJSDate();
        } else {
          dispatch(setIntervalStart(DateTime.fromJSDate(dates[0])));
          dispatch(setIntervalEnd(DateTime.fromJSDate(dates[0]).plus({ days: 6 })));
          dates[1] = DateTime.fromJSDate(dates[0]).plus({ days: 6 }).toJSDate();
        }
      }
    } else {
      dispatch(setIntervalStart(DateTime.fromJSDate(dates[0])));
      dispatch(setIntervalEnd(DateTime.fromJSDate(dates[0]).plus({ days: 6 })));
    }
  };

  const disableWeekends = (date: any) => {
    return date.getDay() === 0 || date.getDay() === 6;
  };

  useEffect(() => {
    const getData = async () => {
      dispatch(setSelectedBuilding(null));
      dispatch(setSelectedFloor(null));
      await dispatch(getAllConfigurations());
      dispatch(getBuildings());
    };
    getData();
  }, [dispatch]);

  useEffect(() => {
    if (buildingName) {
      handleChangeBuilding(buildingName);
    }
    if (buildingSelector.length > 0) {
      const bOptions = buildingSelector?.map((building: any) => building.name);
      setBuildingOptions(bOptions);
      if (myDefaultBuildingConfig) {
        const building = buildingSelector.find((building: any) => building.id === myDefaultBuildingConfig.value);
        if (building) {
          setDefaultBuilding(building.name);
          if (!selectedBuilding) {
            dispatch(setSelectedBuilding(building));
            dispatch(getFloorsFromBuilding(building._id));
          }
        }
        return;
      }
      if (defaultBuildingConfig) {
        const building = buildingSelector.find((building: any) => building.id === defaultBuildingConfig.value);
        if (building) {
          setDefaultBuilding(building.name);
          if (!selectedBuilding) {
            dispatch(setSelectedBuilding(building));
            dispatch(getFloorsFromBuilding(building._id));
          }
        }
        return;
      }
      if (!myDefaultBuildingConfig && !defaultBuildingConfig) {
        setDefaultBuilding(bOptions[0]);
        dispatch(setSelectedBuilding(buildingSelector[0]));
        dispatch(getFloorsFromBuilding(buildingSelector[0]._id));
      }
    }
  }, [buildingSelector]);

  useEffect(() => {
    if (myDefaultBuildingConfig) {
      const building = buildingSelector.find((building: any) => building.id === myDefaultBuildingConfig.value);
      if (defaultBuilding) {
        setDefaultBuilding(defaultBuilding);
        if (building) {
          if (!selectedBuilding) {
            dispatch(setSelectedBuilding(building));
            dispatch(setSelectedFloor(null));
            dispatch(getFloorsFromBuilding(building._id));
          }
        }
      }
      return;
    }
    if (defaultBuildingConfig) {
      const building = buildingSelector.find((building: any) => building.id === defaultBuildingConfig.value);
      if (defaultBuilding) {
        setDefaultBuilding(defaultBuilding);
        if (building) {
          dispatch(setSelectedBuilding(building));
          dispatch(setSelectedFloor(null));
          dispatch(getFloorsFromBuilding(building._id));
        }
      }
      return;
    }
  }, [myDefaultBuildingConfig, defaultBuildingConfig]);

  useEffect(() => {
    if (!selectedBuilding && buildingSelector.length > 0) {
      if (myDefaultBuildingConfig) {
        const building = buildingSelector.find((building: any) => building.id === myDefaultBuildingConfig.value);
        if (building) {
          handleChangeBuilding(building.name);
        }
        return;
      }
      if (defaultBuildingConfig) {
        const building = buildingSelector.find((building: any) => building.id === defaultBuildingConfig.value);
        if (building) {
          handleChangeBuilding(building.name);
        }
        return;
      }
      if (!myDefaultBuildingConfig && !defaultBuildingConfig) {
        handleChangeBuilding(buildingSelector[0].name);
      }
    }
  }, [selectedBuilding]);

  const handleChangeBuilding = (buildingName: string) => {
    if (!buildingName || buildingName === "building") {
      return;
    }
    if (selectedBuilding && buildingName === selectedBuilding.name) {
      return;
    }
    if (!buildingSelector) {
      return;
    }
    const foundBuilding = buildingSelector?.find((b: any) => b.name === buildingName);
    dispatch(setSelectedFloor(null));
    dispatch(setSelectedBuilding(foundBuilding));
    const array: any[] = [];
    dispatch(selectedDesksState(array));
  };

  const { pathname } = useLocation();
  const paths = ["/dashboard", "/users", "/teams", "/settings", "/adminDashboard"];
  const showWidgets = paths.includes(pathname) ? true : false;
  const currentRoute = useLocation();

  return (
    <div className="dropdown-container-adminDash">
      <div className="dropdown-container-adminDash">
        <div className="calendar-textfield">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <MobileDateRangePicker
                value={[intervalStartDate.toJSDate(), intervalEndDate.toJSDate()]}
                onChange={(newValue: any) => {
                  dispatch(setIntervalStart(intervalStartDate));
                  dispatch(setIntervalStart(intervalEndDate));
                  changedDate(newValue);
                }}
                calendars={1}
                inputFormat="dd.MM"
                mask="__.__"
                shouldDisableDate={disableWeekends}
                renderInput={({ inputProps }, endProps) => {
                  const startValue = !calendarChanged ? inputProps?.value : intervalStartDate.toFormat("dd.MM");
                  const endValue = !calendarChanged ? endProps.inputProps?.value : intervalEndDate?.toFormat("dd.MM");
                  delete inputProps?.value;
                  return (
                    <TextField
                      className="input-with-icon-textfield-adminBoard"
                      placeholder="Dates"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <i className="fas fa-calendar"></i>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={inputProps}
                      value={`${startValue} - ${endValue}`}
                    />
                  );
                }}
              />
            </Stack>
          </LocalizationProvider>
        </div>
        <Dropdown
          testID=""
          value={selectedBuilding?.name}
          dropdownItems={buildingOptions}
          className={"building-dropdown"}
          placeholder={defaultBuilding}
          children={null}
          onChange={handleChangeBuilding}
          item={"building"}
          selectedBuilding={selectedBuilding?._id}
          setErrorMessage={setErrorMessage}
        />
      </div>
    </div>
  );
}
