import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAssignmentData, getMyAssignment, myAssignmentsSelector } from "../../slices/Entities/assignmentSlice";
import GenericModal from "../GenericModal/genericModal";
import { userSelector } from "../../slices/Auth/authSlice";
import { DateTime } from "luxon";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DeleteAlertDialog from "../DeleteAlertDialog/deleteAlertDialog";
import { getCanvasObject } from "../Canvas/Canvas";
import { currentDateSelector } from "../../slices/Canvas/canvasSlice";
import * as Colors from "../../assets/colors";
import { notifyReservationDeleted, notifyReservationsDeleted } from "../../utils/notify";
import Checkbox from "../../components/DeleteMultipleReservations/deleteMultipleReservations";

function MyReservations(props: any) {
  const { handleClose, setErrorMessage } = props;
  const myAssignments = useSelector(myAssignmentsSelector);
  const [showDeleteAssignmentDialog, setShowDeleteAssignmentDialog] = useState<boolean>(false);
  const [deleteAssignment, setDeleteAssignment] = useState<any>();
  const [itemDeleted, setItemDeleted] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<any>([]);
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);

  const dispatch = useDispatch<any>();
  const user = useSelector(userSelector);
  const myId = user._id;

  const canvas: fabric.Canvas = getCanvasObject();
  const currentDate = useSelector(currentDateSelector);

  useEffect(() => {
    dispatch(getMyAssignment(myId)).then((result: any) => {
      if (!result.error) {
      } else {
        setErrorMessage(result.payload.response.data);
      }
    });
  }, [dispatch, itemDeleted]);

  const getDeleteDialogData = (assignment: any) => {
    setDeleteAssignment(assignment);
    toggleShowDeleteDialog();
  };

  const toggleShowDeleteDialog = () => {
    setShowDeleteAssignmentDialog(!showDeleteAssignmentDialog);
  };

  const changeDeskColor = (assignment: any) => {
    if (DateTime.fromISO(assignment.startTime).hasSame(currentDate, "day")) {
      canvas.getObjects().forEach((obj: any) => {
        if (obj.deskId === assignment.desk.id) {
          obj.item(0).set({ fill: Colors.COLORFREEITEM });
        }
      });
    }
  };

  const handleDeleteAssignment = async () => {
    await dispatch(deleteAssignmentData(deleteAssignment._id)).then((result: any) => {
      if (result.error) {
        setErrorMessage(result.payload.response.data);
      } else {
        if (canvas) {
          changeDeskColor(deleteAssignment);
        }
        notifyReservationDeleted();
      }
    });
    setItemDeleted(!itemDeleted);
    toggleShowDeleteDialog();
  };

  const assignmentHours = (obj: any) => {
    return DateTime.fromISO(obj).toFormat("HH:mm");
  };
  const assignmentDate = (obj: any) => {
    return DateTime.fromISO(obj).toFormat("dd.MM.yyyy");
  };

  const toggleSelection = () => {
    setIsClicked(!isClicked);
  };

  const handleSelectAll = (e: any) => {
    setIsCheckedAll(!isCheckedAll);
    setIsChecked(myAssignments.map((obj: any) => obj.id));
    if (isCheckedAll) {
      setIsChecked([]);
    }
  };

  const handleClick = (e: any) => {
    const { id, checked } = e.target;
    setIsChecked([...isChecked, id]);
    if (!checked) {
      setIsChecked(isChecked.filter((item: any) => item !== id));
    }
  };

  const allDelete = async () => {
    await isChecked.forEach(async (item: any) => {
      await dispatch(deleteAssignmentData(item)).then((result: any) => {
        if (result.error) {
          setErrorMessage(result.payload.response.data);
        } else {
          if (canvas) {
            changeDeskColor(item);
          }
        }
      });
    });
    notifyReservationsDeleted();
    setIsChecked([]);
  };

  const renderMyReservations = () => {
    return (
      <div>
        <br></br>
        <button className="select-button" onClick={toggleSelection}>
          {isClicked ? "Cancel selection" : "Select multiple reservations"}
        </button>
        {isClicked ? (
          <button className="delete-selection-button" onClick={allDelete}>
            Delete selected items
          </button>
        ) : null}
        {isClicked ? (
          <div className="selectAll-checkbox">
            <br></br>
            <Checkbox type="checkbox" id="selectAll" handleClick={handleSelectAll} isChecked={isCheckedAll} />
            <b>Select All</b>
          </div>
        ) : null}
        {myAssignments.map((obj: any) => {
          return (
            <div className="assignment-container" key={obj._id}>
              {isClicked ? (
                <Checkbox key={obj._id} type="checkbox" id={obj.id} handleClick={handleClick} isChecked={isChecked.includes(obj.id)} />
              ) : null}
              <div className="assignment-data">
                <div className="desk-container"> {obj.desk.label}</div>
                <div className="assignment-location">{`${obj.desk.building.name} - ${obj.desk.floor.name}`}</div>
                {obj.reservedBy ? (
                  <div className="assignment-location">Reservation made by {`${obj.reservedBy.firstName}  ${obj.reservedBy.lastName}`}</div>
                ) : (
                  <></>
                )}
                {obj.team ? <div className="assignment-team">Team: {obj.team.name}</div> : <></>}
                {obj.label ? <div className="assignment-team">For: {obj.label}</div> : <></>}
                <div className="assignment-date">{assignmentDate(obj.startTime)}</div>
              </div>
              <div className="hours-container">{`${assignmentHours(obj.startTime)}-${assignmentHours(obj.endTime)}`}</div>

              <DeleteOutlinedIcon
                className="delete-button-reservation"
                onClick={() => {
                  getDeleteDialogData(obj);
                }}
              />
              {deleteAssignment && (
                <DeleteAlertDialog
                  type={"assignment"}
                  value={`assignment for desk ${deleteAssignment.desk.label}`}
                  open={showDeleteAssignmentDialog}
                  handleOpen={toggleShowDeleteDialog}
                  handleClose={toggleShowDeleteDialog}
                  handleDelete={handleDeleteAssignment}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <GenericModal
      handleClose={handleClose}
      title={"My Reservations"}
      initialValues={() => {}}
      hasFooter={false}
      hasForm={false}
      buttonAction={() => {}}
      renderModalContent={renderMyReservations}
      renderButton={() => {}}
    ></GenericModal>
  );
}
export default MyReservations;
