import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import Select from "react-select";

function AddNewMember(props: any) {
  const [user, setUser] = useState<any>();

  const onSaveHandler = () => {
    props.addMember(user);
    onCancelHandler();
  };

  const onCancelHandler = () => {
    props.handleClose();
  };

  const handleChangeUser = (user: any) => {
    setUser(user);
  };

  return (
    <div className="container">
      <Dialog open={props.open} onClose={onCancelHandler} disableEscapeKeyDown id="member-dialog">
        <DialogTitle>New Member</DialogTitle>
        <DialogContent>
          <Select
            options={props.users}
            formatOptionLabel={(user: any) => `${user.firstName} ${user.lastName}`}
            onChange={(user: any) => handleChangeUser(user)}
            maxMenuHeight={150}
          ></Select>
        </DialogContent>
        <DialogActions>
          <button className="save-button" onClick={onSaveHandler}>
            Save
          </button>
          <button className="cancel-button" onClick={onCancelHandler}>
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddNewMember;
