import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../Navbar/navbar";
import { Link } from "react-router-dom";
import editIcon from "../../assets/images/edit.png";
import cancelIcon from "../../assets/images/cancel.png";
import IconLabel from "../../components/IconLabel/iconLabel";
import DeleteAlertDialog from "../../components/DeleteAlertDialog/deleteAlertDialog";
import { getUsersList } from "../../slices/Entities/usersSlice";
import { isUndefined } from "lodash";
import ErrorNotification from "../../components/ErrorNotification/errorNotification";
import { notifyDeleteCompany } from "../../utils/notify";
import Copyright from "../../components/Copyright/Copyright";
import { useAuth } from "../../utils/AuthHook";
import { companiesSelector, deleteCompanyData, getAllCompanies } from "../../slices/Entities/companiesSlice";
import CompanyModal from "../../components/CompanyModal/companyModal";

const CompaniesList = () => {
  const dispatch = useDispatch<any>();
  const companies = useSelector(companiesSelector);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [edit, setEdit] = useState<boolean>(false);
  const [create, setCreate] = useState<boolean>(false);
  const [showCompanyModal, setShowCompanyModal] = useState<boolean>(false);
  const [showDeleteCompanyDialog, setShowDeleteCompanyDialog] = useState<boolean>(false);
  const [deleteCompany, setDeleteCompany] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { user, check } = useAuth();

  const resetErrorMessage = () => {
    setErrorMessage(undefined);
  };

  const getCompanyModalData = (company: any, isEdit: boolean, isCreate: boolean) => {
    if (company) setSelectedCompany(company);

    setEdit(isEdit);
    setCreate(isCreate);
    toggleCompanyModal();
  };

  const toggleCompanyModal = () => {
    setShowCompanyModal(!showCompanyModal);
  };

  const getDeleteDialogData = (company: any) => {
    setDeleteCompany(company);
    toggleShowDeleteDialog();
  };

  const toggleShowDeleteDialog = () => {
    setShowDeleteCompanyDialog(!showDeleteCompanyDialog);
  };

  const handleDeleteCompany = () => {
    dispatch(deleteCompanyData(deleteCompany._id)).then((result: any) => {
      if (result.error) {
        setErrorMessage(result.payload.response.data);
      } else {
        setDeleteCompany(null);
        notifyDeleteCompany();
      }
    });
    toggleShowDeleteDialog();
  };

  useEffect(() => {
    dispatch(getAllCompanies());
    dispatch(getUsersList());
  }, [dispatch]);

  return (
    <div className="dashboard-container">
      <Navbar />
      <div className="title-container">
        <h1>Companies</h1>
        {check("company.create") ? (
          <Link className="add-team" onClick={() => getCompanyModalData(undefined, false, true)} to={""}>
            + New Company
          </Link>
        ) : (
          <></>
        )}
      </div>

      <ul className="teams-list">
        {companies.map((data: any) => (
          <li className="team-container" key={data.id}>
            <h3 onClick={() => getCompanyModalData(data, false, false)}>{data.name}</h3>
            <div className="content">
              <div className="left">
                <p>
                  {data.owner.firstName} {data.owner.lastName}
                </p>
              </div>

              <div className="right">
                {check("super-admin") ? (
                  <div onClick={() => getCompanyModalData(data, true, false)}>
                    <IconLabel icon={editIcon} color="" text="" />
                  </div>
                ) : (
                  <></>
                )}
                <div onClick={() => getDeleteDialogData(data)}>
                  <IconLabel icon={cancelIcon} color="" text="" />
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {showCompanyModal && check("company.update") ? (
        <CompanyModal
          isEdit={edit}
          isCreate={create}
          company={selectedCompany}
          open={showCompanyModal}
          handleOpen={toggleCompanyModal}
          handleClose={toggleCompanyModal}
          setSelectedCompany={setSelectedCompany}
          setErrorMessage={setErrorMessage}
        />
      ) : (
        <></>
      )}

      {showDeleteCompanyDialog && check("company.delete") ? (
        <DeleteAlertDialog
          type={"company"}
          value={`company ${deleteCompany.name}`}
          open={showDeleteCompanyDialog}
          handleOpen={toggleShowDeleteDialog}
          handleClose={toggleShowDeleteDialog}
          handleDelete={handleDeleteCompany}
        />
      ) : (
        <></>
      )}
      {!isUndefined(errorMessage) && <ErrorNotification message={errorMessage} onClose={resetErrorMessage} />}
      <div className="copyright-wrapper">
        <Copyright />
      </div>
    </div>
  );
};

export default CompaniesList;
