const Checkbox = ({ id, type, handleClick, isChecked }: { id: any; type: any; handleClick: any; isChecked: any }) => {
  return (
    <label className="checkbox-label">
      <input id={id} type={type} onChange={handleClick} checked={isChecked} />
      <span className="checkmark"></span>
    </label>
  );
};

export default Checkbox;
