import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout, tokenSelector } from "../slices/Auth/authSlice";
import { withRouter } from "./with-router";

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props: any) => {
  let location = props.router.location;
  const token = useSelector(tokenSelector);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (token) {
      const decodedJwt = parseJwt(token);

      if (decodedJwt.exp * 1000 < Date.now()) {
        dispatch(logout());
      }
    }
  }, [location]);

  return <></>;
};

export default withRouter(AuthVerify);
