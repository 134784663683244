import client from "./axios.service";

export type User = {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  groups: [];
  company: string;
};

const baseUrl = "/auth";

export const login = async (username: string, password: string) => {
  const response = await client.post(
    `${baseUrl}/login`,
    {
      username,
      password,
    },
    {}
  );
  return response?.data ?? response;
};

export const register = async (
  username: string,
  password: string,
  email: string,
  firstName: string,
  lastName: string,
  avatar: string,
  company: string
) => {
  return client.post(
    `/users`,
    {
      username,
      password,
      email,
      firstName,
      lastName,
      avatar,
      company,
    },
    {}
  );
};

export const getUsers = async () => {
  const requestPath = `/users`;

  return client.get(requestPath, null);
};

export const getUserById = async (id: string) => {
  const requestPath = `/users/${id}`;
  return client.get(requestPath, null);
};
export const updateUserRole = (id: string, username: string, email: string, firstName: string, lastName: string) => {
  const requestPath = `/users/${id}`;

  return client.put(requestPath, { username, email, firstName, lastName }, {});
};

export const updateUser = (
  id: string,
  username: string,
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  groups: [],
  company: string
) => {
  const requestPath = `/users/${id}`;

  if (password === "") return client.put(requestPath, { username, email, firstName, lastName, groups, company }, {});
  else return client.put(requestPath, { username, email, firstName, lastName, password, groups, company }, {});
};

export const getMeInfo = async () => {
  const requestPath = `${baseUrl}/me`;

  return client.get(requestPath, null);
};

export const updateMeInfo = async (username: string, email: string, firstName: string, lastName: string) => {
  const requestPath = `${baseUrl}/me`;

  return client.put(requestPath, { username, email, firstName, lastName }, {});
};

export const deleteUser = (id: string) => {
  const requestPath = `/users/${id}`;

  return client.delete(requestPath, {});
};
