import { useSelector } from "react-redux";
import { isAuthenticatedSelector, tokenSelector, userSelector } from "../slices/Auth/authSlice";

export function useAuth() {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const user = useSelector(userSelector);
  const token = useSelector(tokenSelector);

  return {
    isAuthenticated,
    user,
    token,
    check: (permissions: string | Array<string> = []) => {
      if (!Array.isArray(permissions)) {
        permissions = [permissions];
      }
      if (user.permissions.includes("super-admin")) {
        return true;
      }

      for (const permission of permissions) {
        if (user.permissions.indexOf(permission) >= 0) {
          return true;
        }
      }
      return false;
    },
  };
}
