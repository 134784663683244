import Info from "@mui/icons-material/Info";
import { DEFAULT_DESK_COLORS, DEFAULT_DESK_SELECTED_COLOR } from "../../../components/Desk/constants";

import { DeskAvailability } from "../../../components/Desk/types";
import { hasObjectKey } from "../../../utils/Types/object.types";
import "./NavbarInfoButton.scss";
import { orderedListAvailabilityItems } from "./constants";

const getSeatingMessage = (availability: DeskAvailability | "SELECTED_DESK") => {
  switch (availability) {
    case "FREE":
      return "Free";
    case "OCCUPIED_BY_ME":
      return "Reserved by me";
    case "OCCUPIED":
      return "Reserved by someone else";
    case "OCCUPIED_BY_ME_AND_SOMEONE":
      return "Reserved by me and someone else";
    case "OCCUPIED_PARTIALLY":
      return "Reserved partially by someone else";
    case "SELECTED_DESK":
      return "Selected item";
  }
};

const getSeatingColor = (availability: DeskAvailability | "SELECTED_DESK") => {
  return hasObjectKey(availability, DEFAULT_DESK_COLORS) ? DEFAULT_DESK_COLORS[availability] : DEFAULT_DESK_SELECTED_COLOR;
};

const renderListItem = (availability: DeskAvailability | "SELECTED_DESK"): JSX.Element => {
  const message = getSeatingMessage(availability);
  const color = getSeatingColor(availability);

  return (
    <li key={color} className="navbar-info-list-item">
      <div style={{ backgroundColor: color }} className="info-list-bubble" />
      <span className="info-list-text">{message}</span>
    </li>
  );
};

const NavbarInfoButton = () => {
  return (
    <div className="navbar-info-container">
      <Info className="navbar-info-icon" />
      <div className="navbar-info-content">
        {orderedListAvailabilityItems.map(renderListItem)}
        <span>Desks can be selected by holding down the mouse button, and dragging the cursor to another location</span>
        <span>You can also hold Ctrl and select them one by one with the cursor by clicking</span>
      </div>
    </div>
  );
};

export default NavbarInfoButton;
