import client from "./axios.service";

const baseUrl = "/configurations";

export const addConfiguration = async (key: string, value: string, user?: string) => {
  return client.post(`${baseUrl}`, { key, value, user }, {});
};

export const getConfiguration = async (key: string) => {
  const requestPath = `${baseUrl}/${key}`;
  return client.get(requestPath, null);
};

export const getConfigurations = async () => {
  const requestPath = `${baseUrl}`;
  return client.get(requestPath, null);
};
