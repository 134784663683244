import client from "./axios.service";

export type User = {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  groups: [];
};

export const getAllGroups = () => client.get("/groups", null);
