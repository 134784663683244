import axios from "axios";
import { isUndefined } from "lodash";
import { loadStateFromSessionStorage } from "../utils/Storage/StorageState";

const instance = axios.create();
let baseUrl = process.env.REACT_APP_VAR_NAME || "/";

if (!baseUrl.endsWith("/")) {
  baseUrl += "/";
}
if (!baseUrl.endsWith("/api")) {
  baseUrl = `${baseUrl}api`;
}

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
      return Promise.reject(error.response);
    }
    return Promise.reject(error);
  }
);

const getAuthHeader = () => {
  const auth = loadStateFromSessionStorage("auth");

  if (isUndefined(auth)) {
    return undefined;
  }

  if (auth && auth?.isAuthenticated && auth?.token) {
    return { Authorization: `Bearer ${auth?.token}` };
  }

  return undefined;
};

const parseConfig = (config: any, requiresToken: boolean) => {
  let authHeader = undefined;
  if (requiresToken) {
    authHeader = getAuthHeader();
  }

  return {
    ...config,
    headers: {
      ...authHeader,
      ...((config && config.headers) || {}),
    },
  };
};

const axiosWrapper = {
  instance,
  get: (path: any, config: any, requiresToken = true) => {
    return instance.get(`${baseUrl}${path}`, parseConfig(config, requiresToken));
  },
  post: (path: any, data: any, config: any, requiresToken = true) => {
    return instance.post(`${baseUrl}${path}`, data, parseConfig(config, requiresToken));
  },
  put: (path: any, data: any, config: any, requiresToken = true) => {
    return instance.put(`${baseUrl}${path}`, data, parseConfig(config, requiresToken));
  },
  patch: (path: any, data: any, config: any, requiresToken = true) => {
    return instance.patch(`${baseUrl}${path}`, data, parseConfig(config, requiresToken));
  },
  delete: (path: any, config: any, requiresToken = true) => {
    return instance.delete(`${baseUrl}${path}`, parseConfig(config, requiresToken));
  },
};

export default axiosWrapper;
