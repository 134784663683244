import { getIntervalHours } from "../../utils/canvas-helpers";
import { Assignment } from "../Canvas/types";
import { DEFAULT_DESK_COLORS } from "./constants";
import { DeskAvailability, DeskColor } from "./types";

export const getDeskColorByAvailability = (color: DeskAvailability): DeskColor => {
  return DEFAULT_DESK_COLORS[color];
};

export const getDeskAvailability = (
  assignments: Assignment[],
  intervalEndDate: number,
  intervalStartDate: number,
  deskID: string,
  username: string
): DeskAvailability => {
  let availability: DeskAvailability = "FREE";
  const deskAssignments = assignments.filter((assignment) => assignment.desk._id === deskID);
  if (!deskAssignments.length) return availability; //* no assignments are made for the selected time, this means the desk is free for the desired interval
  if (intervalEndDate / 10 !== intervalStartDate / 10) {
    //* is the period selected an interval?
    return "OCCUPIED";
  } else {
    //* the period is just a single day
    const totalHours = deskAssignments.reduce(
      (accumulator, assignment) => accumulator + getIntervalHours(assignment.endTime, assignment.startTime),
      0
    );
    const totalUserHours = deskAssignments
      .filter((assignment) => assignment.employee?.username === username)
      .reduce((accumulator, assignment) => accumulator + getIntervalHours(assignment.endTime, assignment.startTime), 0);
    return totalUserHours === 0
      ? totalHours >= 8
        ? "OCCUPIED"
        : "OCCUPIED_PARTIALLY"
      : totalUserHours === totalHours
      ? "OCCUPIED_BY_ME"
      : "OCCUPIED_BY_ME_AND_SOMEONE";
  }
};
