import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import { addCompany, Company, deleteCompany, getCompanies, getCompany, updateCompany } from "../../services/companyService";
import { Status } from "../StatusEnum";

type InitialStateType = {
  status: Status;
  companies: [];
  myCompany: any;
  error: SerializedError | null;
};

const initialState: InitialStateType = {
  status: Status.Idle,
  companies: [],
  error: null,
  myCompany: null,
};

export const getCompanyById = createAsyncThunk("companies/getCompanyById", async (id: string, thunkApi) => {
  try {
    const response = await getCompany(id);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const getAllCompanies = createAsyncThunk("companies/getCompanies", async (_, thunkApi) => {
  try {
    const response = await getCompanies();

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const deleteCompanyData = createAsyncThunk("companies/deleteCompany", async (id: string, thunkApi) => {
  try {
    const response = await deleteCompany(id);

    if (response.status === 200 || response.status === 201) {
      return { message: response.data, id: id };
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const createCompany = createAsyncThunk("companies/addCompany", async (company: Company, thunkApi) => {
  try {
    const response = await addCompany(company);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const updateCompanyById = createAsyncThunk("companies/updateCompany", async ({ id, company }: { id: string; company: Company }, thunkApi) => {
  try {
    const response = await updateCompany(id, company);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

const companiesSlice = createSlice({
  name: "companies",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyById.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.myCompany = payload;
        }
      })
      .addCase(getCompanyById.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getCompanyById.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(getAllCompanies.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.companies = payload;
        }
      })
      .addCase(getAllCompanies.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getAllCompanies.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(deleteCompanyData.fulfilled, (state: any, action: any) => {
        state.status = Status.Succeeded;
        const index = state.companies.map((company: any) => company._id).indexOf(action.meta.arg);
        state.companies.splice(index, 1);
      })
      .addCase(deleteCompanyData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(deleteCompanyData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })

      .addCase(updateCompanyById.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        state.status = Status.Succeeded;
        const index = state.companies.map((company: any) => company._id).indexOf(payload._id);
        state.companies[index] = payload;
      })
      .addCase(updateCompanyById.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(updateCompanyById.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })

      .addCase(createCompany.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        state.status = Status.Succeeded;
        state.companies.push(payload);
      })
      .addCase(createCompany.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(createCompany.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      });
  },
  reducers: {},
});

const { reducer, actions } = companiesSlice;

export default reducer;

export const myCompanySelector = (state: any) => state.companies.myCompany;
export const companiesSelector = (state: any) => state.companies.companies;
