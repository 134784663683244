import { AxiosResponse } from "axios";
import { IToolbarItem } from "../components/ObjectsToolbar/types";
import { ISize } from "../components/interfaces";
import client from "./axios.service";

export interface IToolbarDefaultItemsImages {
  door: string;
  stairs: string;
}

export type ToolbarData = {
  images: IToolbarDefaultItemsImages;
  items: IToolbarItem[];
};

export type ToolbarItemData = {
  image: File | null;
  type: "custom" | "desk";
  category: string;
  size: ISize;
  label: string | null;
  popupInfo: string | null;
};

export type uploadImageForToolbarData = {
  image: File;
  tool: "stairs" | "door";
};

const baseUrl = "/toolbarItems";

export const getToolbarItems = (): Promise<AxiosResponse<ToolbarData, any>> => {
  const requestPath = `${baseUrl}`;
  return client.get(requestPath, null);
};

export const addToolbarItem = (itemData: ToolbarItemData) => {
  const requestPath = `${baseUrl}`;

  const formData = new FormData();
  itemData.image && formData.append("file", itemData.image);
  formData.append("category", itemData.category);
  formData.append("type", itemData.type);
  formData.append("size", JSON.stringify(itemData.size));
  formData.append("label", itemData.label || "");
  formData.append("popupInfo", itemData.popupInfo || "");
  return client.post(requestPath, formData, {});
};

export const addImageToDefaultToolbarItem = (data: uploadImageForToolbarData) => {
  const requestPath = `${baseUrl}`;

  const formData = new FormData();
  formData.append("file", data.image);
  formData.append("defaultTool", data.tool);
  return client.post(requestPath, formData, {});
};

export const deleteToolbarItem = (itemID: string) => {
  const requestPath = `${baseUrl}/${itemID}`;
  return client.delete(requestPath, {});
};

export const deleteDefaultImage = (tool: "stairs" | "door") => {
  const requestPath = `${baseUrl}/default/${tool}`;
  return client.delete(requestPath, {});
};

export const updateToolbar = (itemData: { id: string; data: ToolbarItemData }) => {
  const requestPath = `${baseUrl}/update`;
  const formData = new FormData();
  formData.append("id", itemData.id);
  itemData.data.image && formData.append("file", itemData.data.image);
  formData.append("category", itemData.data.category);
  formData.append("type", itemData.data.type);
  formData.append("size", JSON.stringify(itemData.data.size));
  formData.append("label", itemData.data.label || "");
  formData.append("popupInfo", itemData.data.popupInfo || "");
  return client.put(requestPath, formData, {});
};

export {};
