import { ExpandMore } from "@mui/icons-material";
import { Paper } from "@mui/material";
import ObjectID from "bson-objectid";
import React, { useCallback, useEffect, useImperativeHandle, useReducer, useRef } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import pointerSvg from "../../assets/images/pointer.svg";
import { canvasModeSelector } from "../../slices/Canvas/canvasSlice";
import { getToolbarData, toolbarCategoriesSelector, toolbarItemsSelector } from "../../slices/Entities/toolbarSlice";
import { GRID } from "../../utils/variables";
import { CanvasMode } from "../Canvas/types";
import ToolbarObject from "../ToolbarObject/ToolbarObject";
import { ICoordinate } from "../interfaces";
import MultilineGuider from "./MultilineGuider/MultilineGuider";
import ObjectGuider from "./ObjectGuider/ObjectGuider";
import "./ObjectsToolbar.scss";
import ToolbarDefaultItems from "./ToolbarDefaultItems/ToolbarDefaultItems";
import { lineWidthValues } from "./ToolbarDefaultItems/variables";
import { toolbarReducer } from "./reducer/toolbarReducer";
import {
  DefaultToolbarAction,
  DefaultToolbarTool,
  ForwardedToolbarRef,
  IObjectsToolbarProps,
  IToolbarItem,
  IToolbarState,
  ToolbarActionTypes,
} from "./types";

const initialState: IToolbarState = {
  isMinimized: false,
  selectedCustomTool: null,
  selectedDefaultTool: null,
  selectedDefaultAction: null,
  lineSessionID: null,
  lineWidth: lineWidthValues[0],
};

export const getObjectGridPosition = (axisValue: number, size: number): number =>
  size % 2 === 0 ? axisValue - (axisValue % GRID) : axisValue - (axisValue % GRID) + GRID / 2;

const ObjectsToolbar = React.forwardRef((props: IObjectsToolbarProps, ref: React.ForwardedRef<ForwardedToolbarRef>): JSX.Element => {
  const [toolbarState, toolbarActionDispatch] = useReducer(toolbarReducer, initialState);
  const { selectedCustomTool, isMinimized, selectedDefaultTool, selectedDefaultAction, lineSessionID } = toolbarState;

  const dispatch = useDispatch<any>();
  const toolbarCategories = useSelector(toolbarCategoriesSelector);
  const toolbarItems = useSelector(toolbarItemsSelector);
  const canvasMode: CanvasMode = useSelector(canvasModeSelector);

  const multilinePointsNumber = useRef<number>(0);

  useEffect(() => {
    dispatch(getToolbarData());
    if (canvasMode === CanvasMode.VIEW) {
      handleToolbarDeselect();
    }
  }, []);

  const handleToolbarDeselect = useCallback((): void => {
    deselectToolHandler();
    if (multilinePointsNumber.current === 1 && lineSessionID) {
      props.deleteCurrentLineSessionCallback(lineSessionID);
    }
    multilinePointsNumber.current = 0;
    props.onDefaultToolSelectionCallback(null);
  }, [props.onDefaultToolSelectionCallback]);

  useImperativeHandle(
    ref,
    () => {
      return {
        handleToolbarDeselect,
      };
    },
    [handleToolbarDeselect]
  );

  const onDefaultToolClickHandler = (tool: DefaultToolbarTool, item: IToolbarItem | null): void => {
    let selectedTool: DefaultToolbarTool | null = tool;
    let selectedItem = item;
    if (tool === selectedDefaultTool) {
      selectedTool = null;
      selectedItem = null;
    }
    toolbarActionDispatch({
      type: ToolbarActionTypes.ACTIVATE_DEFAULT_TOOL,
      payload: { tool: selectedTool, item: selectedItem },
    });
    props.onDefaultToolSelectionCallback(selectedTool);
  };

  const onDefaultActionClickHandler = (action: DefaultToolbarAction): void => {
    let selectedAction: DefaultToolbarAction | null = action;
    if (action === selectedDefaultAction && action !== DefaultToolbarAction.UNDO && action !== DefaultToolbarAction.REDO) {
      selectedAction = null;
    }
    toolbarActionDispatch({
      type: ToolbarActionTypes.ACTIVATE_DEFAULT_ACTION,
      payload: selectedAction,
    });
    props.onDefaultActionSelectionCallback(selectedAction);
  };

  const onToolbarItemClick = (object: IToolbarItem): void => {
    let selectedObject: IToolbarItem | null = object;
    if (selectedCustomTool && object._id === selectedCustomTool._id) {
      selectedObject = null;
    }
    toolbarActionDispatch({ type: ToolbarActionTypes.ITEM_CLICK, payload: { object: selectedObject } });
  };

  const objectPlaceHandler = (clientX: number, clientY: number): void => {
    toolbarActionDispatch({
      type: ToolbarActionTypes.PLACE_OBJECT,
      payload: {
        clientX,
        clientY,
        objectChangeCallback: props.objectPlaceCallback,
        objectID: ObjectID(new Date().getTime()).toHexString(),
      },
    });
  };

  const linePointPlaceHandler = (clientX: number, clientY: number): void => {
    if (!lineSessionID) return;
    const x = getObjectGridPosition(clientX + GRID / 2, 0) / GRID;
    const y = getObjectGridPosition(clientY + GRID / 2, 0) / GRID;
    const multilinePoint: ICoordinate = { x, y };
    props.multilinePointCallback(multilinePoint, lineSessionID);
    multilinePointsNumber.current += 1;
  };

  const minimizeHandler = (): void => {
    toolbarActionDispatch({ type: ToolbarActionTypes.TOGGLE_MINIMIZE });
  };

  const deselectToolHandler = (): void => {
    toolbarActionDispatch({ type: ToolbarActionTypes.DESELECT_TOOL });
  };

  const handleLineWidthChange = (lineWidth: number): void => {
    props.lineWidthChangeCallback(lineWidth);
  };

  const handleColorChange = (color: string): void => {
    props.colorChangeCallback(color);
  };

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent): void => {
      if (event.code !== "Escape") return;
      handleToolbarDeselect();
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, [handleToolbarDeselect, props]);

  if (!toolbarItems) {
    return <></>;
  }

  return (
    <>
      <Draggable handle=".toolbar-titlebar" defaultPosition={{ x: GRID, y: GRID * 4 }}>
        <Paper style={{ display: props.hidden ? "none" : undefined }} elevation={10} className="toolbar-container">
          <div className="toolbar-titlebar">
            <ExpandMore onClick={minimizeHandler} className={`titlebar-icon toolbar-${isMinimized ? "minimized" : "open"}`} />
          </div>
          <div className={`objects-container ${isMinimized ? "minimized" : ""}`}>
            <div onClick={handleToolbarDeselect} className="pointer-tool-container">
              <img className="cursor-image-icon" alt="" src={pointerSvg} />
              {"(Esc key)"}
            </div>
            <ToolbarDefaultItems
              changesDiscardCallback={props.changesDiscardCallback}
              itemMoveCallback={props.onItemsMoveCallback}
              selectedDefaultTool={selectedDefaultTool}
              selectedDefaultAction={selectedDefaultAction}
              onDefaultToolClickHandler={onDefaultToolClickHandler}
              onDefaultActionClickHandler={onDefaultActionClickHandler}
              defaultItemsImages={toolbarItems.images}
              initialLineWidth={3}
              lineWidthChangeCallback={handleLineWidthChange}
              initialColor={"#000000"}
              colorChangeCallback={handleColorChange}
              historyPermission={props.historyPermission}
            />
            {[...toolbarCategories].map((category) => (
              <>
                <div key={category} className="toolbar-category">
                  {category}
                </div>
                {toolbarItems &&
                  toolbarItems.items
                    .filter((obj) => obj.category === category)
                    .map((object) => (
                      <ToolbarObject
                        selected={(selectedCustomTool && object.id === selectedCustomTool._id) || false}
                        key={object._id}
                        imageSrc={object.imageSrc}
                        label={object.label || ""}
                        size={object.size}
                        tooltip={object.popupInfo || ""}
                        onClick={() => onToolbarItemClick(object)}
                        isInToolbar
                      />
                    ))}
              </>
            ))}
          </div>
        </Paper>
      </Draggable>
      {selectedCustomTool && (
        <ObjectGuider
          objectSize={selectedCustomTool.size}
          onClick={objectPlaceHandler}
          imageSrc={selectedCustomTool.imageSrc || ""}
          objectLabel={selectedCustomTool.label || ""}
        />
      )}
      {selectedDefaultTool === DefaultToolbarTool.POLY_LINE && lineSessionID && (
        <MultilineGuider lineSessionID={lineSessionID} linePointPlaceCallback={linePointPlaceHandler} updateFloorInfo={props.updateFloorInfo} />
      )}
    </>
  );
});

export default ObjectsToolbar;
