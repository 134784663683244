import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import { addPermission, getAllPermissions } from "../../services/permissionServices";
import { Status } from "../StatusEnum";

type InitialStateType = {
  status: Status;
  error: SerializedError | null;
  permissions: [];
};

const initialState: InitialStateType = {
  status: Status.Idle,
  error: null,
  permissions: [],
};

export const AllPermissionsData = createAsyncThunk("/permissions/allPermissionsData", async (_, thunkApi) => {
  try {
    const response = await getAllPermissions();
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const AddNewPermission = createAsyncThunk("/permissions/addPermission", async ({ key }: any, thunkApi) => {
  try {
    const response = await addPermission(key);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});
const permissionSlice = createSlice({
  name: "permissions",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(AllPermissionsData.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.permissions = payload;
        }
      })
      .addCase(AllPermissionsData.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(AllPermissionsData.rejected, (state, action) => {
        state.status = Status.Failed;
        state.permissions = [];
        state.error = action.error;
      });
  },
  reducers: {},
});

const { reducer } = permissionSlice;

export default reducer;

export const allPermissionsSelector = (state: any) => state.permissions.permissions;
