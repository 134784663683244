import * as Yup from "yup";
import { LoadingState, ToolbarForm } from "./types";

export const dropdownSizeValues = ["1", "2", "3", "4", "5"];
export const dropdownTypeValuesOnly = ["Booking Item", "Design Item"] as const;
export const dropdownTypeValues = [
  { key: "Desk", value: "Booking Item" },
  { key: "Custom", value: "Design Item" },
] as const;

export const initialFormValues: ToolbarForm = {
  type: dropdownTypeValuesOnly[0],
  category: "",
  width: "1",
  height: "1",
  label: "",
  popupInfo: "",
};

export const initialLoading: LoadingState = {
  stairsUploadLoading: false,
  doorUploadLoading: false,
  submitLoading: false,
  stairsDeleteLoading: false,
  doorDeleteLoading: false,
};

export const validationSchemaToolbarForm = Yup.object().shape({
  type: Yup.string().required("Required field"),
  category: Yup.string().required("Required field"),
  width: Yup.string().required("Required field"),
  height: Yup.string().required("Required field"),
  label: Yup.string().required("Required field"),
});

export const DEFAULT_STAIRS_IMAGE_SRC = `${process.env.REACT_APP_VAR_NAME || "/"}api/toolbarItems/default_stairs.png`;
export const DEFAULT_DOOR_IMAGE_SRC = `${process.env.REACT_APP_VAR_NAME || "/"}api/toolbarItems/default_door.png`;

export const getDefaultImageSrc = (tool: "door" | "stairs") => {
  return `${process.env.REACT_APP_VAR_NAME || "/"}api/toolbarItems/default_${tool}.png`;
};
