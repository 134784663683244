import { useState, useEffect } from "react";
import { isNull, isUndefined } from "lodash";
import personPlaceholder from "../../assets/images/personPlaceholder.png";
import { useDispatch, useSelector } from "react-redux";
import { logout, userSelector } from "../../slices/Auth/authSlice";
import MyProfile from "../MyProfile/myProfile";
import MyReservations from "../MyReservations/MyReservations";
import { createPopper } from "@popperjs/core";
import { useAuth } from "../../utils/AuthHook";

function UserAvatar(props: any) {
  const { setErrorMessage } = props;
  const dispatch = useDispatch<any>();
  const [showMyProfile, setShowMyProfile] = useState<boolean>(false);
  const [showMyReservations, setShowMyReservations] = useState<boolean>(false);
  const [showAvatarTooltip, setShowAvatarTooltip] = useState<boolean>(false);
  const user = useSelector(userSelector);
  const imgURL = user.avatar;
  const { check } = useAuth();

  const hasAvatar = !isNull(imgURL) && !isUndefined(imgURL);
  let tooltip: HTMLElement | null = null;
  let popper: any = null;
  let reference = document.querySelector(".avatar-container")!;
  function show() {
    if (tooltip && !showAvatarTooltip) {
      tooltip.setAttribute("data-show", "");
      setShowAvatarTooltip(true);
      if (popper) popper.update();
    } else if (tooltip && showAvatarTooltip) {
      tooltip.removeAttribute("data-show");
      setShowAvatarTooltip(false);
    }
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (showAvatarTooltip && tooltip && !tooltip.contains(event.target) && !reference.contains(event.target)) {
        tooltip.removeAttribute("data-show");
        setShowAvatarTooltip(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAvatarTooltip, tooltip]);

  const createPopover = () => {
    reference = document.querySelector(".avatar-container")!;
    if (tooltip)
      popper = createPopper(reference, tooltip, {
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              padding: { right: 10 },
            },
          },
          {
            name: "offset",
            options: {
              offset: [0, 15],
            },
          },
        ],
      });

    reference.addEventListener("click", show);
  };
  const handleLogout = () => {
    dispatch(logout());
  };

  const showProfile = () => {
    setShowMyProfile(true);
    show();
  };
  const showReservations = () => {
    setShowMyReservations(true);
    show();
  };
  const handleClose = () => {
    setShowMyProfile(false);
    setShowMyReservations(false);
  };

  return (
    <div>
      <div className="avatar-container">
        {hasAvatar ? <img src={imgURL} className="avatar" alt="avatar" /> : <img src={personPlaceholder} className="placeholder" alt="placeholder" />}
      </div>

      <div
        id="avatar-tooltip"
        ref={(ref) => {
          tooltip = ref as HTMLElement;
          createPopover();
        }}
      >
        <div className="right-container-avatar">
          {check("me.read") ? (
            <div className="profile" onClick={showProfile}>
              My Profile
            </div>
          ) : (
            <></>
          )}
          <div className="profile" onClick={showReservations}>
            My Reservations
          </div>
          <hr></hr>
          <div className="logout" onClick={handleLogout}>
            Logout
          </div>
        </div>
      </div>

      {showMyProfile ? <MyProfile user={user} handleClose={handleClose} setErrorMessage={setErrorMessage} /> : <></>}

      {showMyReservations ? <MyReservations handleClose={handleClose} setErrorMessage={setErrorMessage} /> : <></>}
    </div>
  );
}

export default UserAvatar;
