import { createTheme } from "@mui/material";

const GenericDropdownTheme = createTheme({
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: "0.875rem/1.1875rem Open Sans",
  },
  palette: {
    primary: {
      main: "#eaeaea",
    },
    text: {
      primary: "#656565",
    },
    background: {
      default: "#eaeaea",
    },
  },
});

export default GenericDropdownTheme;
