import { Box } from "@air/react-drag-to-select";
import { CanvasObject } from "../FloorObject/types";
import { DefaultToolbarAction } from "../ObjectsToolbar/types";
import { IntervalTime, PopupMessage } from "../Popup/types";
import { ObjectType } from "./../../slices/Entities/deskSlice";
import { ISize } from "./../interfaces";

export enum Mouse {
  NotMoved = -2,
  Moved = -3,
}

export interface IPopup {
  show: boolean;
  name?: string;
  label: string;
  message?: string | PopupMessage;
  position: ICoordinate;
  type: `${ObjectType}`;
  imageSrc?: string;
  hasBorder: boolean;
  hasBackground: boolean;
  hasImageStretched: boolean;
  isEditingLabelInitial: boolean;
  object: CanvasObject | null;
  intervals: IntervalTime[] | null;
}

export enum DesignType {
  TEXT = "text",
  ROOM = "room",
  CUSTOM = "custom",
}

export enum CanvasMode {
  VIEW = "view",
  EDIT = "edit",
}

export interface IDesign {
  id: string;
  isMultiline: boolean;
  boundaries: ICoordinate[];
  label: string;
  width: number;
  color: string;
}
export interface ICoordinate {
  x: number;
  y: number;
}

export interface IBox extends Box {
  id: string;
}

interface ICanvas {
  createdAt: number;
  design: IDesign[];
  modifiedAt: number;
  name: string;
  objects: CanvasObject[];
}

export interface IFloorInfo {
  building: string;
  canvas: ICanvas;
  canvasData: string;
  company: string;
  createdAt: string;
  id: string;
  name: string;
  orderNr: number;
  updatedAt: string;
}

export type FloorInfoChange = IFloorInfo & {
  isActive: boolean;
};

export type CanvasProps = {
  setErrorMessage: (message: string | null) => void;
};

type Identifiers = {
  _id: string;
  id: string;
};

type NameIdentity = {
  name: string;
} & Identifiers;

type TimestampData = {
  createdAt: string;
  updatedAt: string;
};

type AssignmentDesk = {
  floor: NameIdentity;
  building: NameIdentity;
  label: string;
  company: string;
  type: ObjectType;
} & Identifiers &
  TimestampData;

type Employee = {
  username: string;
  firstName: string;
  lastName: string;
} & Identifiers;

export type Assignment = {
  desk: AssignmentDesk;
  employee: Employee;
  team: NameIdentity;
  reservedBy: Employee | null;
  startTime: string;
  endTime: string;
  label: string | null;
  company: string;
} & Identifiers &
  TimestampData;

export type HoverObject = {
  position: ICoordinate;
  type: ObjectType;
  src: string;
  size: ISize;
  rotation: number;
};

export type HoverMouseTypes = Extract<`${DefaultToolbarAction}`, "ROTATE" | "FLIP"> | "CURSOR";

export type LineOptions = {
  width: number;
  color: string;
};
