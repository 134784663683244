import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import TextField from "@mui/material/TextField";
import { Field } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currentDateSelector, currentIntervalEndDateSelector, currentIntervalStartDateSelector } from "../../slices/Canvas/canvasSlice";

function Interval(props: any) {
  const { hoursIntervals, setHoursIntervals, checked, setChecked, setHoursComplete } = props;
  const [datesArray, setDatesArray] = useState<any>([]);
  const intervalEndDate = useSelector(currentIntervalEndDateSelector);
  const intervalStartDate = useSelector(currentIntervalStartDateSelector);
  const currentDate = useSelector(currentDateSelector);

  const checkHours = () => {
    for (const key in hoursIntervals) {
      if (hoursIntervals[key].includes(null)) {
        setHoursComplete(false);
        return;
      }
    }
    setHoursComplete(true);
  };
  const handleChange = () => {
    if (checked) {
      if (props.start && props.end) setHoursComplete(true);
      else setHoursComplete(false);
      let dict: any = {};
      datesArray.forEach((date: any) => {
        let start = new Date(date);
        let end = new Date(date);
        if (props.start) {
          start.setHours(props.start.getHours());
          start.setMinutes(props.start.getMinutes());
        }
        if (props.end) {
          end.setHours(props.end.getHours());
          end.setMinutes(props.end.getMinutes());
        }
        dict[date] = [props.start ? start : null, props.end ? end : null];
      });
      setHoursIntervals(dict);
    }
    setChecked(!checked);
  };

  useEffect(() => {
    if (intervalStartDate && intervalEndDate) {
      let array = [];
      let dict: any = {};
      let currentDate = intervalStartDate;
      while (currentDate <= intervalEndDate) {
        const dayOfWeek = new Date(currentDate).getDay();
        const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
        if (!isWeekend) {
          array.push(currentDate);
        }
        dict[currentDate] = [props.start ? props.start : null, props.end ? props.end : null];
        currentDate = currentDate.plus({ day: 1 });
      }
      if (props.start && props.end) setHoursComplete(true);
      else setHoursComplete(false);
      setHoursIntervals(dict);
      setDatesArray(array);
    } else {
      setDatesArray([currentDate]);
      setChecked(true);
      setHoursIntervals({});
    }
    checkHours();
  }, [intervalStartDate, intervalEndDate, currentDate]);

  return (
    <div className="others-container">
      {intervalStartDate && intervalEndDate && intervalStartDate.toString() !== intervalEndDate.toString() ? (
        <label className="checkbox-label">
          Same interval each day
          <input type="checkbox" checked={checked} onChange={handleChange} />
          <span className="checkmark"></span>
        </label>
      ) : (
        <></>
      )}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {!checked ? (
          datesArray.map((date: any) => {
            return (
              <div key={date}>
                <h5>{date.toFormat("dd.MM.yyyy")}</h5>
                <div className="interval-container">
                  <Field
                    component={TimePicker}
                    name="startTime"
                    ampm={false}
                    label="START TIME"
                    value={""}
                    onChange={(time: any) => {
                      let start = new Date(date);
                      start.setHours(time.getHours());
                      start.setMinutes(time.getMinutes());
                      hoursIntervals[date] = [start, hoursIntervals[date][1]];
                      setHoursIntervals({ ...hoursIntervals });
                      checkHours();
                    }}
                    renderInput={(params: any) => <TextField InputLabelProps={{ shrink: true }} {...params} />}
                  />
                  <Field
                    component={TimePicker}
                    name="endTime"
                    ampm={false}
                    label="END TIME"
                    value={hoursIntervals[date][1]}
                    onChange={(time: any) => {
                      let end = new Date(date);
                      end.setHours(time.getHours());
                      end.setMinutes(time.getMinutes());
                      hoursIntervals[date] = [hoursIntervals[date][0], end];
                      setHoursIntervals({ ...hoursIntervals });
                      checkHours();
                    }}
                    renderInput={(params: any) => <TextField InputLabelProps={{ shrink: true }} {...params} />}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className="interval-container">
            <Field
              component={TimePicker}
              name="startTime"
              ampm={false}
              label="START TIME"
              value={props.start ? props.start : new Date(Date.now() + 300000)}
              onChange={(time: any) => {
                props.changeHours("startTime", time);
                props.setValue("startTime", time);
              }}
              renderInput={(params: any) => <TextField InputLabelProps={{ shrink: true }} {...params} />}
            />
            <Field
              component={TimePicker}
              name="endTime"
              ampm={false}
              label="END TIME"
              value={props.end ? props.end : new Date("2022-01-01T16:00:00.000Z")}
              onChange={(time: any) => {
                props.changeHours("endTime", time);
                props.setValue("endTime", time);
              }}
              renderInput={(params: any) => <TextField InputLabelProps={{ shrink: true }} {...params} />}
            />
          </div>
        )}
      </LocalizationProvider>
    </div>
  );
}

export default Interval;
