import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAuthenticatedSelector } from "../../slices/Auth/authSlice";
interface PropType {
  component: React.FC;
}

const PrivateRoute = ({ component: Component }: PropType) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  return isAuthenticated ? <Component /> : <Navigate to="/" />;
};

export default PrivateRoute;
