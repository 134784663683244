import { DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

export default function DeleteAlertDialog(props: any) {
  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle id="alert-dialog-title">Delete {props.type}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete {props.value}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="yes-button" onClick={props.handleDelete}>
            Yes
          </button>
          <button className="no-button" onClick={props.handleClose}>
            No
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
