import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ThemeProvider } from "@mui/material/styles";
import { isUndefined } from "lodash";
import { ReactNode, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GenericDropdownTheme from "../../assets/styles/themes/genericDropdownTheme";
import { displayAdminModeSelector } from "../../slices/Canvas/canvasSlice";
import { deleteBuildingData, setSelectedBuilding } from "../../slices/Entities/buildingSlice";
import { removeDeskData } from "../../slices/Entities/deskSlice";
import { deleteFloorData, setSelectedFloor } from "../../slices/Entities/floorSlice";
import { useAuth } from "../../utils/AuthHook";
import AddNewItemPopup from "../AddNewItemPopup/addNewItemPopup";
import DeleteAlertDialog from "../DeleteAlertDialog/deleteAlertDialog";
import EditItemPopup from "../editItemPopup/editItemPopup";

type DropdownProps = {
  item: string;
  dropdownItems: string[];
  value: string;
  children: ReactNode;
  testID: string;
  buttonsDisabled?: boolean;
  selectedBuilding: string;
  setErrorMessage: any;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  onChange?: any;
  getNewItem?: any;
  selectedFloor?: string;
  selectedDesk?: string;
  footerDisabled?: boolean;
};

const Dropdown = ({
  value,
  dropdownItems,
  className,
  placeholder,
  disabled,
  readOnly,
  onChange,
  item,
  getNewItem,
  selectedBuilding,
  selectedFloor,
  selectedDesk,
  setErrorMessage,
  footerDisabled,
  buttonsDisabled,
  testID,
}: DropdownProps) => {
  const dispatch = useDispatch<any>();
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const displayAdminMode = useSelector(displayAdminModeSelector);
  const { check } = useAuth();

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleOpenEditDialog = () => {
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value);
  };

  const handleDelete = () => {
    switch (item) {
      case "building":
        dispatch(deleteBuildingData({ id: selectedBuilding })).then((result: any) => {
          if (result.error) {
            setErrorMessage(result.payload.response.data);
          } else {
            dispatch(setSelectedBuilding(undefined));
            dispatch(setSelectedFloor(undefined));
          }
        });
        break;
      case "floor":
        if (!selectedFloor) {
          break;
        }
        dispatch(
          deleteFloorData({
            buildingId: selectedBuilding,
            floorId: selectedFloor,
          })
        ).then((result: any) => {
          if (result.error) {
            setErrorMessage(result.payload.response.data);
          } else {
            dispatch(setSelectedFloor(undefined));
          }
        });
        break;
      case "desk":
        if (!selectedDesk) {
          break;
        }

        dispatch(
          removeDeskData({
            buildingId: selectedBuilding,
            floorId: selectedFloor as string,
            deskId: selectedDesk,
          })
        ).then((result: any) => {
          if (result.error) {
            setErrorMessage(result.payload.response.data);
          }
        });
        break;
    }

    handleCloseDeleteDialog();
  };

  const classes = `${className} dropdown-menu`;
  const placeholderOption = isUndefined(value) ? (
    <MenuItem value={"none"} disabled={true} hidden={true} key={-1}>
      {placeholder}
    </MenuItem>
  ) : null;

  return (
    <div data-testid={testID} className={className}>
      <ThemeProvider theme={GenericDropdownTheme}>
        <Select
          IconComponent={KeyboardArrowDownIcon}
          className={classes.trim()}
          value={value || "none"}
          onChange={handleChange}
          disabled={disabled}
          readOnly={readOnly}
          defaultValue={"none"}
        >
          {[placeholderOption].concat(
            dropdownItems.map((keyName, index) =>
              keyName !== value ? (
                <MenuItem key={index} value={keyName}>
                  {keyName}
                </MenuItem>
              ) : (
                <MenuItem sx={{ fontWeight: "bold" }} key={index} value={keyName}>
                  {keyName}
                </MenuItem>
              )
            )
          )}
          {(check("building.create") || check("floor.create")) && !footerDisabled ? (
            <MenuItem data-testid={`Add new ${item}`} className="add-button" onClick={handleClickOpen} value={item}>
              <AddIcon />
              Add new {item}
            </MenuItem>
          ) : null}
        </Select>
      </ThemeProvider>
      {!isUndefined(value) && !buttonsDisabled ? (
        <div className="buttons">
          {check("building.delete") && check("desk.create") && displayAdminMode ? (
            <button data-testid={`${item}-delete-button`} className="delete-button padding tooltip" onClick={handleOpenDeleteDialog}>
              <DeleteOutlinedIcon />
              <span className="tooltiptext">Delete {item}</span>
            </button>
          ) : (
            <></>
          )}
          {check("building.update") && check("desk.create") && displayAdminMode && !buttonsDisabled ? (
            <button className="edit-button padding tooltip" onClick={handleOpenEditDialog} value={item}>
              <EditIcon />
              <span className="tooltiptext">Edit {item}</span>
            </button>
          ) : (
            <></>
          )}
        </div>
      ) : null}
      <AddNewItemPopup
        getNewItem={getNewItem}
        selectedBuilding={selectedBuilding}
        item={item}
        handleClose={handleClose}
        open={open}
        setErrorMessage={setErrorMessage}
      />
      <EditItemPopup
        type={item}
        value={value}
        item={item}
        getNewItem={getNewItem}
        handleClose={handleCloseEditDialog}
        open={openEditDialog}
        handleOpen={handleOpenEditDialog}
        setErrorMessage={setErrorMessage}
      />
      <DeleteAlertDialog
        type={item}
        value={value}
        item={item}
        handleDelete={handleDelete}
        open={openDeleteDialog}
        handleOpen={handleOpenDeleteDialog}
        handleClose={handleCloseDeleteDialog}
      />
    </div>
  );
};
export default Dropdown;
