import { LocalizationProvider, MobileDateRangePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { InputAdornment, Stack, TextField } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../slices/Auth/authSlice";
import {
  calendarChangedSelector,
  currentDateSelector,
  currentIntervalEndDateSelector,
  currentIntervalStartDateSelector,
  setCalendarChanged,
  setCurrentDate,
  setIntervalEndDate,
  setIntervalLabel,
  setIntervalStartDate,
  updateCanvasJSON,
} from "../../slices/Canvas/canvasSlice";
import {
  addAssignmentData,
  addAssignmentForColleguesData,
  addAssignmentForDifferentIntervals,
  addAssignmentForDifferentIntervalsCollegues,
  addAssignmentForDifferentIntervalsOthers,
  addAssignmentForDifferentIntervalsTeam,
  addAssignmentForOthersData,
  addAssignmentForTeam,
  assignmentsSelector,
  getAssignmentByDateInterval,
} from "../../slices/Entities/assignmentSlice";
import { floorDesksOptionsSelector, selectedDesksSelector, selectedDesksState } from "../../slices/Entities/deskSlice";
import { selectedFloorSelector, updateFloorData } from "../../slices/Entities/floorSlice";
import BookDesk from "../BookDesk/bookDesk";
import { getCanvasObject } from "../Canvas/Canvas";
import GenericModal from "../GenericModal/genericModal";
import Interval from "../Interval/interval";
import ReserveForColleague from "../ReserveForColleague/ReserveForColleague";
import TeamReservation from "../TeamReservation/teamReservation";

import { useLocation } from "react-router-dom";
import * as Colors from "../../assets/colors";
import { teamMembers } from "../../slices/Entities/teamSlice";
import { useAuth } from "../../utils/AuthHook";
import { convertLuxonDateToISOString } from "../../utils/canvas-helpers";
import { notifyNotEnoughSeats, notifyReservationAdded } from "../../utils/notify";
import { IFloorInfo } from "../Canvas/types";
import ReserveForOthers from "../ReserveForOthers/ReserveForOthers";
import { CanvasObject } from "../FloorObject/types";

type ReservartionModalProps = {
  handleClose: any;
  selectedBuilding: any;
  setErrorMessage: any;
};
function ReservationModal(props: ReservartionModalProps) {
  const { handleClose, selectedBuilding, setErrorMessage } = props;
  const canvas: fabric.Canvas = getCanvasObject();

  const [hoursIntervals, setHoursIntervals] = useState<any>({});
  const [hoursComplete, setHoursComplete] = useState(false);
  const [checkedSameInterval, setCheckedSameInterval] = useState(true);
  const [checkedColleague, setCheckedColleague] = useState(false);
  const [forUser, setForUser] = useState<any>();
  const [forOther, setForOther] = useState({ name: "", seats: 1 });
  const [forTeam, setForTeam] = useState<any>();
  const [startHour, setStartHour] = useState<any>(
    new Date().getHours() <= 17
      ? new Date(new Date().setHours(new Date().getHours(), new Date().getMinutes() + 5))
      : new Date("2018-01-01T07:00:00.000Z")
  );
  const [freeInterval, setFreeInterval] = useState<boolean>(false);
  const [endHour, setEndHour] = useState<any>(new Date("2018-01-01T16:00:00.000Z"));
  const [checkedTeam, setCheckedTeam] = useState(false);
  const [checkedOthers, setCheckedOthers] = useState(false);

  const floorInfo: IFloorInfo = useSelector(selectedFloorSelector);
  const user = useSelector(userSelector);
  const selectedDesksOnCanvas: CanvasObject[] = useSelector(selectedDesksSelector);
  const desksOptions = useSelector(floorDesksOptionsSelector);
  const intervalEndDate = useSelector(currentIntervalEndDateSelector);
  const intervalStartDate = useSelector(currentIntervalStartDateSelector);
  const calendarChanged = useSelector(calendarChangedSelector);
  const selectedFloor = useSelector(selectedFloorSelector);
  const assignmentList = useSelector(assignmentsSelector);
  const selectedDate = useSelector(currentDateSelector);
  let currentDate = useSelector(currentDateSelector);
  const { check } = useAuth();

  const dispatch = useDispatch<any>();
  const { pathname } = useLocation();

  let initialValues: Record<string, any> = {
    building: selectedBuilding?.name,
    floor: selectedFloor?.name,
    desk: null,
    date: new Date(),
    startTime: null,
    endTime: null,
    startDate: new Date(),
    endDate: new Date(),
  };
  initialValues.desk = selectedDesksOnCanvas.map((desk) => desk.label).join(",");

  const getCurrentCanvasJSON = () => {
    return JSON.stringify(canvas?.toJSON(["selectable", "selection", "evented", "name", "deskId"]));
  };

  useEffect(() => {
    if (startHour && startHour !== "Invalid Date" && endHour && endHour !== "Invalid Date") checkInterval();
  }, []);

  useEffect(() => {
    setForOther({ ...forOther, seats: selectedDesksOnCanvas.length || 0 });
  }, [selectedDesksOnCanvas.length]);

  useEffect(() => {
    if (!selectedDate) return;
    if (selectedDate.c.day === new Date().getDate() && new Date().getHours() <= 17) {
      setStartHour(new Date(new Date().setHours(new Date().getHours(), new Date().getMinutes() + 5)));
      return;
    }
    setStartHour(new Date("2018-01-01T07:00:00.000Z"));
  }, [selectedDate]);

  const handleSubmit = async () => {
    if (!checkedSameInterval && intervalStartDate && intervalEndDate && intervalStartDate !== intervalEndDate) {
      let times = [];
      for (const key in hoursIntervals) {
        times.push(hoursIntervals[key]);
      }

      let assignmentData = {
        desk: selectedDesksOnCanvas[0].id,
        user: checkedColleague ? forUser : user,
        times: times,
      };
      if (checkedColleague) {
        dispatch(addAssignmentForDifferentIntervalsCollegues(assignmentData)).then((result: any) => {
          if (result.error) {
            setErrorMessage(result.payload.response.data);
            getAssignmentsForDate();
          } else {
            selectedDesksOnCanvas.forEach((desk) => {
              changeDeskColor(desk.id);
            });
            closeModal();
            dispatch(selectedDesksState([]));
            notifyReservationAdded();
          }
        });
        return;
      }
      if (checkedTeam) {
        dispatch(
          addAssignmentForDifferentIntervalsTeam({
            teamId: forTeam.id,
            desks: selectedDesksOnCanvas.map((desk) => desk.id),
            times: times,
          })
        ).then((result: any) => {
          if (result.error) {
            setErrorMessage(result.payload.response.data);
            getAssignmentsForDate();
          } else {
            selectedDesksOnCanvas.forEach((desk) => {
              changeDeskColor(desk.id);
            });
            closeModal();
            dispatch(selectedDesksState([]));
            notifyReservationAdded();
          }
        });
        return;
      }
      if (checkedOthers) {
        dispatch(
          addAssignmentForDifferentIntervalsOthers({
            label: forOther.name,
            desks: selectedDesksOnCanvas.map((desk: any) => desk.id),
            times: times,
          })
        ).then((result: any) => {
          if (result.error) {
            setErrorMessage(result.payload.response.data);
            getAssignmentsForDate();
          } else {
            selectedDesksOnCanvas.forEach((desk) => {
              changeDeskColor(desk.id);
            });
            closeModal();
            dispatch(selectedDesksState([]));
            notifyReservationAdded();
          }
        });
        return;
      }
      dispatch(addAssignmentForDifferentIntervals(assignmentData)).then((result: any) => {
        if (result.error) {
          setErrorMessage(result.payload.response.data);
          getAssignmentsForDate();
        } else {
          notifyReservationAdded();
          selectedDesksOnCanvas.forEach((desk) => {
            changeDeskColor(desk.id);
          });
          closeModal();
          dispatch(selectedDesksState([]));
        }
      });
      return;
    }
    let startTime, endTime;
    if (!intervalStartDate && !intervalEndDate) {
      startTime = startHour.toISO();
      endTime = endHour.toISO();
    } else {
      startTime = intervalStartDate.set({
        hour: startHour.getHours(),
        minute: startHour.getMinutes(),
      });
      endTime = intervalEndDate.set({
        hour: endHour.getHours(),
        minute: endHour.getMinutes(),
      });
    }

    let assignmentData: any = {
      desk: null,
      user: checkedColleague ? forUser : user,
      startTime: startTime,
      endTime: endTime,
    };
    assignmentData.desk = selectedDesksOnCanvas[0].id;
    if (checkedColleague) {
      dispatch(addAssignmentForColleguesData(assignmentData)).then((result: any) => {
        if (result.error) {
          setErrorMessage(result.payload.response.data);
          getAssignmentsForDate();
        } else {
          if (pathname === "/deskpicker") {
            selectedDesksOnCanvas.forEach((desk: any) => {
              changeDeskColor(desk.id);
            });
          }
          closeModal();
          dispatch(selectedDesksState([]));
          notifyReservationAdded();
        }
      });
      return;
    }
    if (checkedTeam) {
      dispatch(
        addAssignmentForTeam({
          teamId: forTeam.id,
          desks: selectedDesksOnCanvas.map((desk: any) => desk.id),
          startTime: startTime,
          endTime: endTime,
        })
      ).then((result: any) => {
        if (result.error) {
          setErrorMessage(result.payload.response.data);
          getAssignmentsForDate();
        } else {
          if (canvas) {
            selectedDesksOnCanvas.forEach((desk: any) => {
              changeDeskColor(desk.id);
            });
          }
          closeModal();
          dispatch(selectedDesksState([]));
          notifyReservationAdded();
        }
      });
      return;
    }
    if (checkedOthers) {
      let desksToReserve: CanvasObject[] = [];
      if (selectedDesksOnCanvas.length > 1) {
        desksToReserve = selectedDesksOnCanvas;
      }
      if (selectedDesksOnCanvas.length === 1) {
        const numberOfReservedDesks = new Set(assignmentList.map((a) => a.desk.id)).size;
        const numberOfFreeDesks = desksOptions.length - numberOfReservedDesks;
        if (numberOfFreeDesks < forOther.seats) {
          notifyNotEnoughSeats();
          return;
        }
        const selectedDesk = selectedDesksOnCanvas[0];
        const deskIndex = selectedDesk.label.match(/([0-9]+)$/g);
        if (!deskIndex) return;

        const deskIndexString = deskIndex[0];
        const deskIndexNumber = parseInt(deskIndexString);
        const deskLabelWithoutNumber = selectedDesk.label.slice(0, selectedDesk.label.length - deskIndexString.length);
        let direction = deskIndexNumber !== desksOptions.length ? "incremental" : "decremental";
        let count = 0;
        while (desksToReserve.length !== forOther.seats) {
          const nextDeskLabelIndex = deskIndexNumber + count;
          const nextDeskToReserveLabel = `${deskLabelWithoutNumber}${nextDeskLabelIndex < 10 ? `0${nextDeskLabelIndex}` : nextDeskLabelIndex}`;
          const nextDeskToReserve = desksOptions.find((d: any) => d.label === nextDeskToReserveLabel);
          if (!nextDeskToReserve) {
            count = 0;
            direction = "decremental";
          }
          const isDeskReserved = !!assignmentList.find((assig) => {
            return assig.desk.id === nextDeskToReserve?.id;
          });
          if (!isDeskReserved && nextDeskToReserve) {
            desksToReserve.push(nextDeskToReserve);
          }
          if (direction === "incremental") {
            count++;
          }
          if (direction === "decremental") {
            count--;
          }
        }
      }
      dispatch(
        addAssignmentForOthersData({
          label: forOther.name,
          desks: desksToReserve.map((d) => d.id),
          startTime: startTime,
          endTime: endTime,
        })
      ).then((result: any) => {
        if (result.error) {
          setErrorMessage(result.payload.response.data);
          getAssignmentsForDate();
        } else {
          if (canvas) {
            selectedDesksOnCanvas.forEach((desk: any) => {
              changeDeskColor(desk.id);
            });
          }
          closeModal();
          dispatch(selectedDesksState([]));
          notifyReservationAdded();
        }
      });
      return;
    }
    dispatch(addAssignmentData(assignmentData)).then((result: any) => {
      if (result.error) {
        setErrorMessage(result.payload.response.data);
        getAssignmentsForDate();
      } else {
        if (pathname === "/deskpicker" && window.innerWidth > 700) {
          selectedDesksOnCanvas.forEach((desk: any) => {
            changeDeskColor(desk.id);
          });
        }
        dispatch(selectedDesksState([]));
        closeModal();
        notifyReservationAdded();
      }
    });
    return;
  };
  const getAssignmentsForDate = useCallback(async () => {
    const data = {
      startDate: convertLuxonDateToISOString(intervalStartDate),
      endDate: convertLuxonDateToISOString(intervalEndDate || intervalStartDate),
      buildingId: floorInfo.building,
      floorId: floorInfo?.id,
    };
    dispatch(getAssignmentByDateInterval(data));
  }, [intervalEndDate, intervalStartDate, floorInfo?.id]);

  useEffect(() => {
    initialValues.floor = selectedFloor?.name;
    if (!selectedFloor) initialValues.floor = "";
  }, [selectedFloor]);

  const selectForTeamCanvas = (members: number) => {
    if (!canvas) {
      return;
    }
    if (members > desksOptions.length - 1) {
      members = desksOptions.length - 1;
    }

    let objects: any = [];
    let array = [...selectedDesksOnCanvas];
    selectedDesksOnCanvas.forEach((desk: any) => {
      objects.push(canvas?._objects.filter((object: any) => object.deskId === desk._id)[0]);
    });
    objects.forEach((obj: any) => {
      if (obj === objects[0]) {
        return;
      }
      obj.item(0).set({
        fill: Colors.COLORFREEITEM,
      });
      const index = array.findIndex((selectedDesk: any) => selectedDesk.id === obj.deskId);
      let deleteFromArray = [...array];
      deleteFromArray.splice(index, 1);
      array = [...deleteFromArray];
    });
    dispatch(selectedDesksState(array));
    objects = [objects[0]];
    canvas?.setActiveObject(objects[0]);

    const origX = objects[0].left;
    const origY = objects[0].top;
    for (let j = 0; j < members; j++) {
      let minLength = 10000000;
      let minObj: any = null;
      // eslint-disable-next-line no-loop-func
      canvas?._objects.forEach((obj: any) => {
        if (
          obj.type === "group" &&
          Math.pow((obj.left || 0) - (origX || 0), 2) + Math.pow((obj.top || 0) - (origY || 0), 2) < minLength &&
          obj !== objects[0] &&
          objects.indexOf(obj) === -1 &&
          (obj as any).item(0).fill === Colors.COLORFREEITEM
        ) {
          minObj = obj;
          minLength = Math.pow((obj.left || 0) - (origX || 0), 2) + Math.pow((obj.top || 0) - (origY || 0), 2);
        }
      });

      minObj?.item(0).set({
        fill: Colors.COLORSELECTEDITEM,
      });

      let addToArray = [...array];
      if (minObj) {
        const deskData = desksOptions.filter(
          (desk: any) => desk._id === minObj.deskId // eslint-disable-line no-loop-func
        );

        addToArray.push(deskData[0]);
        objects.push(minObj);
      }
      array = [...addToArray];
      dispatch(selectedDesksState(addToArray));
    }
    const obj = canvas?._objects.filter((object: any) => object.deskId === selectedDesksOnCanvas[0].id);
    canvas?.setActiveObject(obj[0]);
    canvas?.discardActiveObject();
    canvas?.renderAll();
  };

  const selectForTeam = (members: number) => {
    if (pathname === "/deskpicker") {
      selectForTeamCanvas(members);
      return;
    }
    let array: any[] = [];
    // select for team on desksList
    if (selectedDesksOnCanvas.length > 1) {
      dispatch(selectedDesksState([selectedDesksOnCanvas[0]]));
      array = [...[selectedDesksOnCanvas[0]]];
    }
    const floorAssignmentList = assignmentList.filter(
      (obj: any) => desksOptions.map((desk: any) => desk._id).includes(obj.desk._id) && obj.startTime.substring(0, 10) === selectedDate.toISODate()
    );
    const noReservation = desksOptions.filter((desk: any) => !floorAssignmentList.some((assignment: any) => desk.id === assignment.desk.id));
    noReservation.forEach((desk: any) => {
      if (!array.includes(desk) && array.length < members + 1) {
        array.push(desk);
      }
    });
    dispatch(selectedDesksState(array));
  };

  const changeDeskColor = async (deskId?: string) => {
    if (!deskId) {
      const desk: any = canvas?._objects.filter((object: any) => object.deskId === deskId)[0];

      desk.item(0).set({
        fill: Colors.COLORDARKRED,
      });
      canvas?.renderAll();
      await dispatch(updateCanvasJSON(getCurrentCanvasJSON()));
      const floorId = selectedFloor?._id;
      dispatch(
        updateFloorData({
          buildingId: selectedFloor.building,
          floorId: floorId,
          name: selectedFloor.name,
          canvas: getCurrentCanvasJSON(),
        })
      ).then((result: any) => {
        if (result.error) {
          setErrorMessage(result.payload.response.data);
        }
      });
      return;
    }
    const desk: any = canvas?.getObjects().find((obj: any) => obj.deskId === deskId);
    if (checkedColleague) {
      desk?.item(0).set({
        fill: Colors.COLORDARKRED,
      });
    } else {
      desk?.item(0).set({
        fill: Colors.COLORLIGHTGREEN,
      });
    }
    canvas?.renderAll();
  };

  // to show the user the days of his selected interval when his selected desks are already reserved
  const busyDays = assignmentList
    ?.filter((assign) => {
      let isOk = false;
      selectedDesksOnCanvas.forEach((desk: any) => {
        if (desk.id === assign.desk.id) {
          if (intervalStartDate && intervalEndDate) {
            const assignStartTime = assign.startTime.substring(0, 10);
            const assignEndTime = assign.endTime.substring(0, 10);
            if (
              (intervalStartDate.toISODate() <= assignStartTime && assignStartTime <= intervalEndDate.toISODate()) ||
              (intervalStartDate.toISODate() <= assignEndTime && assignEndTime <= intervalEndDate.toISODate())
            )
              isOk = true;
          }
        }
      });
      return isOk;
    })
    .map((element) => {
      return (
        <span key={element.id}>{new Date(element.startTime).getDate() + "." + ("0" + (new Date(element.startTime).getMonth() + 1)).slice(-2)}</span>
      );
    });
  const handleCheckedColleague = (check: boolean) => {
    if (check) {
      setCheckedTeam(false);
      setCheckedOthers(false);
    }
    setCheckedColleague(check);
  };

  const handleCheckedOthers = (check: boolean) => {
    if (check) {
      setCheckedTeam(false);
      setCheckedColleague(false);
    }
    setCheckedOthers(check);
    resetSelectdDesksToOne();
    setForOther({ name: "", seats: selectedDesksOnCanvas.length || 0 });
  };

  const handleCheckedTeam = (check: boolean) => {
    if (check) {
      setCheckedColleague(false);
      setCheckedOthers(false);
    }
    setCheckedTeam(check);
    resetSelectdDesksToOne();
  };

  const resetSelectdDesksToOne = () => {
    if (pathname !== "/deskpicker") {
      if (selectedDesksOnCanvas.length > 1) {
        dispatch(selectedDesksState([selectedDesksOnCanvas[0]]));
      }
      return;
    }
    let objects: any = [];
    let array = [...selectedDesksOnCanvas];
    selectedDesksOnCanvas.forEach((desk: any) => {
      objects.push(canvas?._objects.filter((object: any) => object.deskId === desk._id)[0]);
    });
    objects.forEach((obj: any) => {
      if (obj === objects[0]) {
        return;
      }
      obj.item(0).set({
        fill: Colors.COLORFREEITEM,
      });
      const index = array.findIndex((selectedDesk: any) => selectedDesk.id === obj.deskId);
      let deleteFromArray = [...array];
      deleteFromArray.splice(index, 1);
      array = [...deleteFromArray];
    });
    dispatch(selectedDesksState(array));
    canvas?.setActiveObject(objects[0]);
  };
  const isDeskSelected = selectedDesksOnCanvas.length > 0;
  const isForUserSelected = !checkedColleague || (checkedColleague && forUser);
  const isTeamSelected = !checkedTeam || (checkedTeam && forTeam);
  const isValidDate =
    DateTime.fromISO(intervalStartDate).weekday !== 6 &&
    DateTime.fromISO(intervalStartDate).weekday !== 7 &&
    DateTime.fromISO(intervalEndDate).weekday !== 6 &&
    DateTime.fromISO(intervalEndDate).weekday !== 7;

  const changedDate = (dates: any) => {
    if (dates[0] === null || dates[0].toString() === "Invalid Date" || dates[0] < DateTime.now().startOf("day")) {
      dispatch(setIntervalLabel(""));
      return;
    } else if (dates[1] === null || dates[1].toString() === "Invalid Date") {
      dispatch(setIntervalLabel(""));
      return;
    }
    if (dates[0] !== null) {
      dispatch(setIntervalStartDate(DateTime.fromJSDate(dates[0])));
      dispatch(setCurrentDate(DateTime.fromJSDate(dates[0])));
    }
    if (dates[1] !== null) {
      dispatch(setIntervalStartDate(DateTime.fromJSDate(dates[0])));
      dispatch(setIntervalEndDate(DateTime.fromJSDate(dates[1])));
      let createdLabel = ``;

      if (dates[0].toString() === dates[1].toString()) {
        createdLabel = `${DateTime.fromJSDate(dates[0]).toFormat("dd MMMM yyyy")}`;
      } else {
        createdLabel = `${DateTime.fromJSDate(dates[0]).toFormat("dd MMMM yyyy")} - ${DateTime.fromJSDate(dates[1]).toFormat("dd MMMM yyyy")}`;
      }
      dispatch(setIntervalLabel(createdLabel));
    }
  };

  useEffect(() => {
    if (startHour && startHour !== "Invalid Date" && endHour && endHour !== "Invalid Date") checkInterval();
  }, [startHour, endHour, intervalStartDate, intervalEndDate]);

  const changeHours = (intervalPart: "startTime" | "endTime", hour: any) => {
    if (intervalPart === "startTime") setStartHour(hour);
    if (intervalPart === "endTime") setEndHour(hour);
  };

  const checkInterval = () => {
    let intervalOk = true;
    selectedDesksOnCanvas.forEach((desk: any) => {
      if (intervalEndDate && intervalStartDate) {
        //check for the selected interval
        assignmentList
          .filter(
            (assign: any) =>
              assign.desk.id === desk.id &&
              assign.startTime.substring(0, 10) >= intervalStartDate.toISODate() &&
              assign.startTime.substring(0, 10) <= intervalEndDate.toISODate()
          )
          .forEach((assignment) => {
            if (
              (verifyIfTimeIsBefore(startHour, new Date(assignment.startTime)) && verifyIfTimeIsBefore(new Date(assignment.startTime), endHour)) ||
              (verifyIfTimeIsBefore(startHour, new Date(assignment.endTime)) && verifyIfTimeIsBefore(new Date(assignment.endTime), endHour)) ||
              (verifyIfTimeIsBefore(new Date(assignment.startTime), startHour) && verifyIfTimeIsBefore(startHour, new Date(assignment.endTime))) ||
              (verifyIfTimeIsBefore(new Date(assignment.startTime), endHour) && verifyIfTimeIsBefore(endHour, new Date(assignment.endTime)))
            ) {
              // the interval is not free
              intervalOk = false;
            }
          });
      }
    });
    setFreeInterval(intervalOk);
  };

  const verifyIfTimeIsBefore = (time1: any, time2: any) => {
    if (time1.getHours() < time2.getHours() || (time1.getHours() === time2.getHours() && time1.getMinutes() <= time2.getMinutes())) {
      return true;
    }
    return false;
  };

  const closeModal = () => {
    dispatch(setIntervalLabel(""));
    dispatch(teamMembers(0));
    dispatch(setCurrentDate(intervalStartDate));
    dispatch(setIntervalEndDate(intervalStartDate));
    handleClose();
  };

  const closeOnX = () => {
    dispatch(setCalendarChanged(false));
    closeModal();
    if (!checkedTeam && !checkedOthers) {
      return;
    }
    if (selectedDesksOnCanvas.length > 1) {
      dispatch(selectedDesksState([selectedDesksOnCanvas[0]]));
    }
  };

  const disableWeekends = (date: any) => {
    return date.getDay() === 0 || date.getDay() === 6;
  };

  const renderModal = () => {
    return (
      <div>
        <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
          {({ values, setFieldValue }) => (
            <div>
              <Form className="modal-form">
                <div>
                  <div className="content-label">RESERVATION DETAILS</div>
                  <div className="profile-form-container">
                    <Field name="desk">
                      {(props: any) => (
                        <TextField
                          id="input-desk"
                          label="DESK"
                          disabled={true}
                          {...props.field}
                          multiline={true}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className="fas fa-chair"></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                    <Field name="floor">
                      {(props: any) => (
                        <TextField
                          className="input-with-icon-textfield"
                          label="FLOOR"
                          disabled={true}
                          {...props.field}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className="fas fa-layer-group"></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                    <Field name="building">
                      {(props: any) => (
                        <TextField
                          className="input-with-icon-textfield"
                          label="BUILDING"
                          disabled={true}
                          {...props.field}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className="fas fa-building"></i>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </div>
                  <div className="content-label">DESIRED DATES</div>
                  <div className="dates-form-container">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3}>
                        <MobileDateRangePicker
                          startText="DATE(S)"
                          value={!intervalEndDate ? [intervalStartDate, intervalStartDate] : [intervalStartDate, intervalEndDate]}
                          onChange={(newValue: any) => {
                            dispatch(setIntervalStartDate(DateTime.fromJSDate(newValue[0])));
                            dispatch(setIntervalEndDate(null));
                            changedDate(newValue);
                          }}
                          calendars={1}
                          minDate={new Date(DateTime.local().toJSDate().toISOString())}
                          maxDate={new Date(DateTime.local().plus({ days: 60 }).toJSDate().toISOString())}
                          inputFormat="dd.MM.yyyy"
                          mask="__.__.____"
                          shouldDisableDate={disableWeekends}
                          renderInput={({ inputProps, ...startProps }: any, endProps: any) => {
                            const startValue = !calendarChanged ? inputProps?.value : intervalStartDate.toFormat("dd.MM.yyyy");
                            const endValue = !calendarChanged ? endProps.inputProps?.value : intervalEndDate?.toFormat("dd.MM.yyyy");
                            delete inputProps?.value;
                            return (
                              <TextField
                                className="input-with-icon-textfield"
                                placeholder="Dates"
                                {...startProps}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <i className="fas fa-calendar"></i>
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={inputProps}
                                value={
                                  !startValue
                                    ? currentDate.toFormat("dd.MM.yyyy")
                                    : startValue === endValue
                                    ? `${startValue}`
                                    : `${startValue} - ${endValue}`
                                }
                                error={!isValidDate}
                                helperText={!isValidDate && "Dates from weekend are invalid!"}
                              />
                            );
                          }}
                        />
                      </Stack>
                    </LocalizationProvider>
                    {busyDays.length > 0 ? (
                      <div className="reservedDays">
                        <>Selected desk is reserved in the following day(s):</>
                        <div className="busyDays">{busyDays}</div>
                      </div>
                    ) : null}
                    <Interval
                      setValue={setFieldValue}
                      changeHours={changeHours}
                      start={startHour}
                      end={endHour}
                      hoursIntervals={hoursIntervals}
                      setHoursIntervals={setHoursIntervals}
                      checked={checkedSameInterval}
                      setChecked={setCheckedSameInterval}
                      setHoursComplete={setHoursComplete}
                    />
                    {check("reserve.colleagues") ? (
                      <ReserveForColleague
                        checked={checkedColleague}
                        setChecked={handleCheckedColleague}
                        setUser={setForUser}
                        currentUser={user}
                      ></ReserveForColleague>
                    ) : (
                      <></>
                    )}
                    {check("reserve.team") ? (
                      <TeamReservation
                        checked={checkedTeam}
                        setChecked={handleCheckedTeam}
                        setTeam={setForTeam}
                        selectForTeam={selectForTeam}
                        currentUser={user}
                      />
                    ) : (
                      <></>
                    )}
                    {check("reserve.label") ? (
                      <ReserveForOthers
                        checked={checkedOthers}
                        setChecked={handleCheckedOthers}
                        setOther={setForOther}
                        selectForTeam={selectForTeam}
                        other={forOther}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    );
  };

  const renderButton = () => {
    return (
      <div>
        <BookDesk
          disabled={
            !isValidDate ||
            !isDeskSelected ||
            !isTeamSelected ||
            !isForUserSelected ||
            !intervalStartDate ||
            !intervalEndDate ||
            (checkedSameInterval && (!startHour || startHour === "Invalid Date" || !endHour || endHour === "Invalid Date")) ||
            (!checkedSameInterval && !hoursComplete) ||
            (checkedOthers && (forOther.name === "" || forOther.name.length < 3 || forOther.seats < 1 || forOther.seats > 50)) ||
            !freeInterval
          }
          onClick={handleSubmit}
        />
      </div>
    );
  };
  return (
    <GenericModal
      handleClose={closeOnX}
      title={"New Reservation"}
      initialValues={initialValues}
      hasFooter={true}
      hasForm={false}
      buttonAction={() => {}}
      renderModalContent={renderModal}
      renderButton={renderButton}
      modalClass="new-reservation-modal"
    ></GenericModal>
  );
}

export default ReservationModal;
