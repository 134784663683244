import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeams, allTeamsSelector, deleteTeamData } from "../../slices/Entities/teamSlice";
import Navbar from "../Navbar/navbar";
import { Link } from "react-router-dom";
import { Team } from "../../services/teamService";
import editIcon from "../../assets/images/edit.png";
import cancelIcon from "../../assets/images/cancel.png";
import envelopeIcon from "../../assets/images/envelope.png";
import globeIcon from "../../assets/images/globe.png";
import logoIcon from "../../assets/images/logo1.png";
import IconLabel from "../../components/IconLabel/iconLabel";
import DeleteAlertDialog from "../../components/DeleteAlertDialog/deleteAlertDialog";
import TeamModal from "../../components/TeamModal/teamModal";
import { getUsersList } from "../../slices/Entities/usersSlice";
import { WAYMAN_PROVIDER } from "../../utils/variables";
import { isUndefined } from "lodash";
import ErrorNotification from "../../components/ErrorNotification/errorNotification";
import { notifyDeleteTeam } from "../../utils/notify";
import Copyright from "../../components/Copyright/Copyright";
import { useAuth } from "../../utils/AuthHook";
import { getAllCompanies, getCompanyById } from "../../slices/Entities/companiesSlice";

const TeamList = () => {
  const dispatch = useDispatch<any>();
  const teams = useSelector(allTeamsSelector);
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [edit, setEdit] = useState<boolean>(false);
  const [create, setCreate] = useState<boolean>(false);
  const [showTeamModal, setShowTeamModal] = useState<boolean>(false);
  const [showDeleteTeamDialog, setShowDeleteTeamDialog] = useState<boolean>(false);
  const [deleteTeam, setDeleteTeam] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { user, check } = useAuth();

  const resetErrorMessage = () => {
    setErrorMessage(undefined);
  };

  const getTeamModalData = (team: any, isEdit: boolean, isCreate: boolean) => {
    if (team) setSelectedTeam(team);

    setEdit(isEdit);
    setCreate(isCreate);
    toggleTeamModal();
  };

  const toggleTeamModal = () => {
    setShowTeamModal(!showTeamModal);
  };

  const getDeleteDialogData = (team: any) => {
    setDeleteTeam(team);
    toggleShowDeleteDialog();
  };

  const toggleShowDeleteDialog = () => {
    setShowDeleteTeamDialog(!showDeleteTeamDialog);
  };

  const handleDeleteTeam = () => {
    dispatch(deleteTeamData(deleteTeam._id)).then((result: any) => {
      if (result.error) {
        setErrorMessage(result.payload.response.data);
      } else {
        setDeleteTeam(null);
        notifyDeleteTeam();
      }
    });
    toggleShowDeleteDialog();
  };

  useEffect(() => {
    dispatch(getTeams());
    dispatch(getUsersList());
    if (check("super-admin")) dispatch(getAllCompanies());
    if (check("myCompany.update")) dispatch(getCompanyById(user.company.id));
  }, [dispatch]);

  const checkProvider = (data: any) => {
    return data.provider === WAYMAN_PROVIDER;
  };

  return (
    <div className="dashboard-container">
      <Navbar />
      <div className="title-container">
        <h1>Teams</h1>
        {check("team.create") ? (
          <Link className="add-team" onClick={() => getTeamModalData(undefined, false, true)} to={""}>
            + New Team
          </Link>
        ) : (
          <></>
        )}
      </div>

      <ul className="teams-list">
        {teams.map((data: any) => (
          <li className="team-container" key={data.id}>
            <h3 onClick={() => getTeamModalData(data, false, false)}>{data.name}</h3>
            <div className="content">
              <div className="left">
                <p>
                  {data.manager.firstName} {data.manager.lastName}
                </p>
                {data.members.length === 1 && <p>{data.members.length + " Member"}</p>}
                {data.members.length > 1 && <p>{data.members.length + " Members"}</p>}
              </div>

              <div className="right">
                <div className="icon-wrapper-first">
                  <Link
                    to="#"
                    onClick={(e) => {
                      window.location.href = `mailto:${data.manager.email}`;
                    }}
                  >
                    <IconLabel icon={envelopeIcon} color="" text="" />
                  </Link>
                  {checkProvider(data) && <IconLabel icon={logoIcon} color="" text="" />}
                  {!checkProvider(data) && <IconLabel icon={globeIcon} color="" text="" />}
                </div>
                {check("team.update") ? (
                  <div onClick={() => getTeamModalData(data, true, false)}>
                    <IconLabel icon={editIcon} color="" text="" />
                  </div>
                ) : (
                  <></>
                )}
                <div onClick={() => getDeleteDialogData(data)}>
                  <IconLabel icon={cancelIcon} color="" text="" />
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {showTeamModal && check("team.update") ? (
        <TeamModal
          isEdit={edit}
          isCreate={create}
          team={selectedTeam}
          open={showTeamModal}
          handleOpen={toggleTeamModal}
          handleClose={toggleTeamModal}
          setSelectedTeam={setSelectedTeam}
          setErrorMessage={setErrorMessage}
        />
      ) : (
        <></>
      )}

      {showDeleteTeamDialog && check("team.delete") ? (
        <DeleteAlertDialog
          type={"team"}
          value={`team ${deleteTeam.name}`}
          open={showDeleteTeamDialog}
          handleOpen={toggleShowDeleteDialog}
          handleClose={toggleShowDeleteDialog}
          handleDelete={handleDeleteTeam}
        />
      ) : (
        <></>
      )}
      {!isUndefined(errorMessage) && <ErrorNotification message={errorMessage} onClose={resetErrorMessage} />}
      <div className="copyright-wrapper">
        <Copyright />
      </div>
    </div>
  );
};

export default TeamList;
