import { useState, useEffect } from "react";
import { ErrorMessage, Field } from "formik";
import GenericModal from "../GenericModal/genericModal";
import { addingTeam, refreshTeam, updateTeamById } from "../../slices/Entities/teamSlice";
import { useDispatch, useSelector } from "react-redux";
import { Button, InputAdornment, MenuItem, TextField } from "@mui/material";
import IconLabel from "../IconLabel/iconLabel";
import cancelIcon from "../../assets/images/cancel.png";
import { allUsersSelectors } from "../../slices/Entities/usersSlice";
import AddNewMember from "../AddNewMember/addNewMember";
import { validationSchemaTeam } from "../../utils/ValidationSchemas";
import { WAYMAN_PROVIDER } from "../../utils/variables";
import { notifyCreateTeam, notifyUpdateTeam } from "../../utils/notify";
import { useAuth } from "../../utils/AuthHook";
import { companiesSelector, myCompanySelector } from "../../slices/Entities/companiesSlice";

function TeamModal(props: any) {
  const dispatch = useDispatch<any>();
  const companies = useSelector(companiesSelector);
  const myCompany = useSelector(myCompanySelector);
  let disable = false;
  const [open, setOpen] = useState(false);
  const [manager, setManager] = useState<any>(props.isCreate ? "" : props.team.manager.id);
  const [company, setCompany] = useState<any>(props.isCreate ? (companies.length > 0 ? "" : myCompany.id) : myCompany.id);
  const [name, setName] = useState<any>("");
  const [newTeamMembers, setNewTeamMembers] = useState<any>([]);
  const users = useSelector(allUsersSelectors);
  const { user, check } = useAuth();

  let currentTeam = props.isCreate
    ? {
        name: name,
        manager: manager,
        members: [],
        company: company,
      }
    : {
        name: props.team.name,
        manager: manager,
        email: props.team.manager.email,
        members: props.team.members,
        company: company,
      };

  const addMember = (member: any) => {
    if (props.isCreate) {
      let array = [...newTeamMembers];
      array.push(member);
      setNewTeamMembers(array);
    } else {
      let team = { ...props.team };
      let array = [...team.members];
      array.push(member);
      team.members = array;
      props.setSelectedTeam(team);
    }
  };
  const refreshTeamById = () => {
    dispatch(refreshTeam(props.team.id)).then((result: any) => {
      if (!result.error) {
        props.setSelectedTeam(result.payload);
        setManager(result.payload.manager.id);
        props.handleClose();
      } else {
        props.setErrorMessage(result.payload.response.data);
      }
    });
  };

  const deleteMember = (member: any) => {
    let team = { ...props.team };
    let array = [...team.members];
    const index = array.findIndex((elem) => elem.id === member.id);
    array.splice(index, 1);
    team.members = array;
    props.setSelectedTeam(team);
  };

  const checkProvider = () => {
    if (!props.isCreate) if (props.team.provider === WAYMAN_PROVIDER || !props.isEdit) disable = true;
  };

  useEffect(() => {
    checkProvider();
  }, []);

  const renderButton = () => {
    return (
      <button className="button-modal" type="submit">
        {props.isCreate ? "Create Team" : "Update Team"}
      </button>
    );
  };

  const updateTeamData = (formValue: any) => {
    const { name } = formValue;
    const manager = currentTeam.manager;
    const members = props.team.members.map((member: any) => member.id).filter((member: any) => member !== manager.id);

    dispatch(
      updateTeamById({
        id: props.team.id,
        name: name,
        manager: manager,
        members: members,
        company: company,
      })
    ).then((result: any) => {
      if (!result.error) {
        props.handleClose();
        notifyUpdateTeam();
      } else {
        props.setErrorMessage(result.payload.response.data);
      }
    });
  };

  const createTeam = (formValue: any) => {
    const { manager, name } = formValue;

    const members = newTeamMembers.map((member: any) => member.id).filter((member: any) => member !== manager);
    dispatch(
      addingTeam({
        name: name,
        manager: manager,
        members: members,
        company: company,
      })
    ).then((result: any) => {
      if (!result.error) {
        props.handleClose();
        notifyCreateTeam();
      } else {
        props.setErrorMessage(result.payload.response.data);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChangeManager = (value: any) => {
    if (props.isCreate) {
      const idList = newTeamMembers.map((member: any) => member.id);
      const indexInList = idList.indexOf(currentTeam.manager);
      let array = [...newTeamMembers];
      if (indexInList !== -1) {
        array.splice(indexInList, 1);
      }
      if (!idList.includes(value.target.value)) array.push(users.filter((user: any) => user.id === value.target.value)[0]);
      setNewTeamMembers(array);
    }
    currentTeam.manager = value.target.value;
    setManager(value.target.value);
  };

  const handleChangeCompany = (value: any) => {
    currentTeam.company = value.target.value;
    setCompany(value.target.value);
  };

  const handleChangeName = (value: any) => {
    if (props.isCreate) {
      currentTeam.name = value.target.value;
      setName(value.target.value);
    }
  };

  const renderTeam = () => {
    checkProvider();
    return (
      <div>
        <p className="content-label">Team Information</p>
        <div className="profile-form-container">
          {props.isCreate ? (
            <></>
          ) : (
            <TextField
              className="input-with-icon-textfield"
              label="PROVIDER"
              defaultValue={props.team.provider}
              disabled={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <i className="fas fa-globe"></i>
                  </InputAdornment>
                ),
              }}
            />
          )}

          <div>
            <Field name="name">
              {({ field, meta: { touched, error } }: any) =>
                props.isCreate ? (
                  <TextField
                    className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                    label="NAME"
                    disabled={disable}
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="fas fa-signature"></i>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChangeName}
                  />
                ) : (
                  <TextField
                    className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                    label="NAME"
                    disabled={disable}
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="fas fa-signature"></i>
                        </InputAdornment>
                      ),
                    }}
                  />
                )
              }
            </Field>
            <ErrorMessage name="name" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>

          <div>
            <Field name="manager">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  id="select-textfield"
                  className={touched && error ? "invalid" : ""}
                  label="MANAGER"
                  select
                  disabled={disable}
                  value={manager}
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <i className="far fa-user"></i>
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChangeManager}
                >
                  {users
                    .filter((user: any) => user.role === "Manager" || check("team.update") || check("team.create"))
                    .map((user: any) => (
                      <MenuItem value={user.id} key={user.id}>
                        {user.firstName} {user.lastName}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Field>
            <ErrorMessage name="manager" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>

          {props.isCreate ? (
            <></>
          ) : (
            <Field name="email">
              {(props: any) => (
                <TextField
                  className="input-with-icon-textfield"
                  label="EMAIL"
                  disabled={true}
                  {...props.field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <i className="far fa-envelope"></i>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
          )}

          {companies.length > 0 ? (
            <div>
              <Field name="company">
                {({ field, meta: { touched, error } }: any) => (
                  <TextField
                    id="select-textfield"
                    className={touched && error ? "invalid" : ""}
                    label="COMPANY"
                    select
                    disabled={disable}
                    value={company}
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="far fa-building"></i>
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChangeCompany}
                  >
                    {companies.map((company: any) => (
                      <MenuItem value={company.id} key={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Field>
              <ErrorMessage name="company" render={(msg) => <span className="error-msg">{msg}</span>} />
            </div>
          ) : (
            <div>
              <Field name="company">
                {({ field, meta: { touched, error } }: any) => (
                  <TextField
                    id="select-textfield"
                    className={touched && error ? "invalid" : ""}
                    label="COMPANY"
                    disabled={true}
                    value={company}
                    {...field}
                    select
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="far fa-building"></i>
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value={myCompany.id} key={myCompany.id}>
                      {myCompany.name}
                    </MenuItem>
                  </TextField>
                )}
              </Field>
              <ErrorMessage name="company" render={(msg) => <span className="error-msg">{msg}</span>} />
            </div>
          )}
        </div>

        <div className="members-header">
          <p className="content-label">Team Members</p>
          {props.isEdit ? (
            disable ? (
              user.provider === WAYMAN_PROVIDER ? (
                <Button id="new-member" onClick={refreshTeamById}>
                  <i className="fas fa-undo fa-flip-horizontal"></i>
                  <span className="button-text"> &nbsp; Refresh Data</span>
                </Button>
              ) : (
                <></>
              )
            ) : (
              <Button id="new-member" onClick={handleClickOpen}>
                <i className="fas fa-plus"></i>
                <span className="button-text"> &nbsp; Add</span>
              </Button>
            )
          ) : props.isCreate ? (
            <Button id="new-member" onClick={handleClickOpen}>
              <i className="fas fa-plus"></i>
              <span className="button-text"> &nbsp; Add</span>
            </Button>
          ) : (
            ""
          )}
        </div>
        <AddNewMember
          users={
            props.isCreate
              ? users.filter((user: any) => !newTeamMembers.find((member: any) => member.id === user.id) && user.id !== currentTeam.manager)
              : users.filter((user: any) => !props.team.members.find((member: any) => member.id === user.id))
          }
          addMember={addMember}
          handleClose={handleClose}
          open={open}
        />
        <ul className="members-list">
          {props.isCreate
            ? newTeamMembers.map((member: any) => (
                <li className="team-member-container" key={member.id}>
                  <div className="left">
                    <img src={member.avatar} className="avatar-wrapper" alt="avatar" />
                    <div className="text-wrapper">
                      <h3>
                        {member.firstName} {member.lastName}
                      </h3>
                      <p>{member.username}</p>
                    </div>
                  </div>
                  {member.id === currentTeam.manager ? (
                    ""
                  ) : (
                    <div className="right">
                      <IconLabel
                        icon={cancelIcon}
                        color=""
                        text=""
                        onClick={() => {
                          deleteMember(member);
                        }}
                      />
                    </div>
                  )}
                </li>
              ))
            : props.team.members.map((member: any) => (
                <li className="team-member-container" key={member.id}>
                  <div className="left">
                    <img src={member.avatar} className="avatar-wrapper" alt="avatar" />
                    <div className="text-wrapper">
                      <h3>
                        {member.firstName} {member.lastName}
                      </h3>
                      <p>{member.username}</p>
                    </div>
                  </div>

                  {disable || !props.isEdit ? (
                    ""
                  ) : member.id === props.team.manager.id ? (
                    ""
                  ) : (
                    <div className="right">
                      <IconLabel
                        icon={cancelIcon}
                        color=""
                        text=""
                        onClick={() => {
                          deleteMember(member);
                        }}
                      />
                    </div>
                  )}
                </li>
              ))}
        </ul>
      </div>
    );
  };

  return (
    <GenericModal
      hasForm={true}
      renderButton={renderButton}
      handleClose={props.handleClose}
      title={props.isEdit ? "Manage Team" : props.isCreate ? "Create Team" : "View Team"}
      initialValues={currentTeam}
      validationSchema={validationSchemaTeam}
      hasFooter={props.isCreate || (props.team.provider !== WAYMAN_PROVIDER && props.isEdit)}
      buttonAction={props.isCreate ? createTeam : updateTeamData}
      renderModalContent={renderTeam}
    ></GenericModal>
  );
}
export default TeamModal;
