export const COLORLIGHTGREEN = "#8BC34A";

//items
export const COLORDARKRED = "#EC5555";
export const COLORREDFADED = "#FF6DA4";
export const COLORSELECTEDITEM = "#FBC93E";
export const COLORFREEITEM = "#AAA7A7";
export const COLORDARKGREEN = "#67A71D";
export const COLORINVALIDDESK = "#ff9100";
export const COLORNOTALWAYSOCCUPIED = "#9900cc";

//text
export const COLORWHITE = "#FFFFFF";
export const COLORTITLE = "#404040";
export const COLORTEXT = "#333333";
export const COLORLIGHTGRAY = "#CCCCCC";
export const COLORBLACK = "#000000";
export const COLORMEDIUMGRAY = "#999999";

export const fillingColors = [
  COLORLIGHTGREEN,
  COLORDARKRED,
  COLORREDFADED,
  COLORSELECTEDITEM,
  COLORFREEITEM,
  COLORDARKGREEN,
  COLORINVALIDDESK,
  COLORNOTALWAYSOCCUPIED,
  COLORWHITE,
  COLORTITLE,
  COLORTEXT,
  COLORLIGHTGRAY,
  COLORBLACK,
  COLORMEDIUMGRAY,
];
