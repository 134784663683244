import { Cancel } from "@mui/icons-material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Edit from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { IToolbarItem } from "../../../components/ObjectsToolbar/types";

import { capitalize } from "lodash";
import { ObjectType } from "../../../slices/Entities/deskSlice";
import { dropdownTypeValues } from "../variables";
import "./ToolbarManagerListItem.scss";

type Props = {
  item: IToolbarItem;
  loading: boolean;
  editing: boolean;
  deleteCallback: (itemID: string) => void;
  editCallback: (item: IToolbarItem) => void;
  cancelEditCallback: (item: IToolbarItem) => void;
};

const convertType = (type: ObjectType): string => {
  const value = dropdownTypeValues.find((value) => value.key === capitalize(type));
  if (!value) return "";
  return value.value;
};

export default function ToolbarManagerListItem({ item, loading, deleteCallback, editCallback, editing, cancelEditCallback }: Props) {
  return (
    <li className="list-item-container">
      <div className="left-list-item-container">
        <img className="list-image" crossOrigin="" alt="img" src={item.imageSrc} />
        <div className="list-item-info-container">
          <div className="info-row-container">
            <div className="key-info">Type:</div>
            <div className="capitalize">{convertType(item.type)}</div>
          </div>
          <div className="info-row-container">
            <div className="key-info">Category:</div>
            <div className="value-info">{item.category}</div>
          </div>
          <div className="info-row-container">
            <div className="key-info">Label:</div>
            <div className="capitalize">{item.label}</div>
          </div>
        </div>
      </div>
      <div className="right-list-item-container">
        {!editing && (
          <>
            {loading ? (
              <CircularProgress size={"1.25rem"} />
            ) : (
              <DeleteOutlinedIcon className="list-item-button list-item-delete-button" onClick={() => deleteCallback(item.id)} />
            )}
          </>
        )}
        {editing ? (
          <Cancel className="list-item-button list-item-cancel-button" onClick={() => cancelEditCallback(item)} />
        ) : (
          <Edit className="list-item-button list-item-edit-button" onClick={() => editCallback(item)} />
        )}
      </div>
    </li>
  );
}
