import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import keyIcon from "../../assets/images/keyIcon.png";
import logo from "../../assets/images/logo.png";
import userIcon from "../../assets/images/userIcon.png";
import Copyright from "../../components/Copyright/Copyright";
import { isAuthenticatedSelector, loginAndGetUserData } from "../../slices/Auth/authSlice";
import { notifyError } from "../../utils/notify";

const LoginPage = () => {
  const [message, setMessage] = useState<string>("");
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const initialValues: {
    username: string;
    password: string;
  } = {
    username: "",
    password: "",
  };
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
  });

  const handleLogin = async (formValue: { username: string; password: string }) => {
    const { username, password } = formValue;
    setMessage("");

    dispatch(loginAndGetUserData({ username, password })).then((result: any) => {
      if (!result.error) {
        navigate("/deskpicker");
        return;
      }
      switch (result.payload.response?.status) {
        case 401:
          notifyError("Invalid Credentials");
          break;
        default:
          notifyError(result.payload.response?.data.message || "Something went wrong");
      }
    });
  };

  const isAuthenticated = useSelector(isAuthenticatedSelector);

  if (isAuthenticated) {
    return <Navigate to="/deskpicker" />;
  }

  return (
    <div className="login-page">
      <div className="login-wrapper">
        <div id="loginLogo">
          <img src={logo} alt="small-logo" className="image-container" />
        </div>
        <div className="login-form-container">
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleLogin}>
            <Form className="login-form">
              <h1 className="login-title-container">Welcome</h1>
              <div className="input-icons">
                <Field name="username">
                  {({ field, meta: { touched, error } }: any) => (
                    <TextField
                      role="username"
                      className={touched && error ? "input-textfield-login invalid" : "input-textfield-login"}
                      placeholder="Use your Wayman account to login"
                      {...field}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img className="login-icon" src={userIcon} alt="icon" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="username" render={(msg) => <span className="error-msg">{msg}</span>} />
              </div>
              <div className="input-icons">
                <Field name="password">
                  {({ field, meta: { touched, error } }: any) => (
                    <TextField
                      role="password"
                      className={touched && error ? "input-textfield-login invalid" : "input-textfield-login"}
                      placeholder="Enter your password"
                      {...field}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img className="login-icon" src={keyIcon} alt="icon" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClickShowPassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="password" render={(msg) => <span className="error-msg">{msg}</span>} />
              </div>
              <button data-testid="homepage-login-button" className="login-button-container" type="submit">
                <span>Sign in</span>
              </button>
              {message && (
                <div>
                  <div className="alert-container">{message}</div>
                </div>
              )}
            </Form>
          </Formik>
          <Copyright />
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
