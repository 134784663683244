type IconLabelProps = {
  text: string;
  icon?: string;
  color: string;
  onClick?: any;
};

function IconLabel(props: IconLabelProps) {
  const { text, icon, color, onClick } = props;

  return (
    <div className={`iconLabel ${color}`} onClick={onClick}>
      {icon ? <img src={icon} className="iconLabel-icon" alt="icon" /> : null}
      {<span className="iconLabel-text">{text}</span>}
    </div>
  );
}

export default IconLabel;
