import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import { DateTime } from "luxon";
import {
  currentDateSelector,
  setCurrentDate,
  currentIntervalLabelSelector,
  currentIntervalStartDateSelector,
  currentIntervalEndDateSelector,
  setIntervalEndDate,
  setIntervalLabel,
  setIntervalStartDate,
  setCalendarChanged,
  showModalReservationSelector,
} from "../../slices/Canvas/canvasSlice";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import * as Colors from "../../assets/colors";
import { useLocation } from "react-router-dom";

function CalendarComponent(props: any) {
  const [showCalendar, setShowCalendar] = useState(false);
  let currentDate = useSelector(currentDateSelector);
  let createdLabel = useSelector(currentIntervalLabelSelector);
  let currentStartDate = useSelector(currentIntervalStartDateSelector);
  let currentEndDate = useSelector(currentIntervalEndDateSelector);
  const showModalReservation = useSelector(showModalReservationSelector);
  const { pathname } = useLocation();
  const dispatch = useDispatch<any>();
  const currentDateTime = currentDate;

  let labelDate: any;
  let endSelectedDate = currentEndDate?.plus({ day: 1 }).toJSDate();

  if (!createdLabel) {
    labelDate = currentDateTime.toFormat("dd MMMM yyyy");
  } else {
    labelDate = createdLabel;
  }
  const handlePrevious = () => {
    if (currentStartDate < currentEndDate) {
      if (!showModalReservation) {
        const prevDate = currentDate.minus({ day: 1 });
        dispatch(setCurrentDate(prevDate));
        labelDate = `${prevDate.toFormat("dd MMMM yyyy")}`;
        dispatch(setIntervalLabel(labelDate));
        dispatch(setIntervalStartDate(prevDate));
        dispatch(setIntervalEndDate(prevDate));
      } else {
        dispatch(setCalendarChanged(true));
        const prevDate = currentStartDate.minus({ day: 1 });
        labelDate = `${prevDate.toFormat("dd MMMM yyyy")} - ${currentEndDate.toFormat("dd MMMM yyyy")}`;
        dispatch(setIntervalStartDate(prevDate));
        dispatch(setIntervalLabel(labelDate));
      }
    } else {
      const nextDate = currentDate.minus({ day: 1 });
      dispatch(setCurrentDate(nextDate));
      labelDate = `${nextDate.toFormat("dd MMMM yyyy")}`;
      dispatch(setIntervalLabel(labelDate));
      dispatch(setIntervalStartDate(nextDate));
      dispatch(setIntervalEndDate(nextDate));
    }
  };
  const handleNext = () => {
    if (currentStartDate < currentEndDate) {
      if (!showModalReservation) {
        const nextDate = currentDate.plus({ day: 1 });
        dispatch(setCurrentDate(nextDate));
        labelDate = `${nextDate.toFormat("dd MMMM yyyy")}`;
        dispatch(setIntervalLabel(labelDate));
        dispatch(setIntervalStartDate(nextDate));
        dispatch(setIntervalEndDate(nextDate));
      } else {
        dispatch(setCalendarChanged(true));
        const nextDate = currentEndDate.plus({ day: 1 });
        labelDate = `${currentStartDate.toFormat("dd MMMM yyyy")} - ${nextDate.toFormat("dd MMMM yyyy")}`;
        dispatch(setIntervalLabel(labelDate));
        dispatch(setIntervalEndDate(nextDate));
      }
    } else {
      const nextDate = currentDate.plus({ day: 1 });
      dispatch(setCurrentDate(nextDate));
      labelDate = `${nextDate.toFormat("dd MMMM yyyy")}`;
      dispatch(setIntervalLabel(labelDate));
      dispatch(setIntervalStartDate(nextDate));
      dispatch(setIntervalEndDate(nextDate));
    }
  };

  let calendarRef = React.useRef<HTMLElement>();

  const goToToday = () => {
    const todayDate = DateTime.now();
    dispatch(setIntervalLabel(todayDate.toFormat("dd MMMM yyyy")));
    dispatch(setIntervalStartDate(todayDate));
    dispatch(setIntervalEndDate(todayDate));
    dispatch(setCurrentDate(todayDate));
  };

  useEffect(() => {
    dispatch(setIntervalEndDate(currentEndDate));
    let handler = (event: any) => {
      if (!calendarRef.current?.contains(event.target)) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [currentEndDate]);

  return (
    <div>
      <div className="calendar-and-button">
        <div className="custom-calendar">
          <ArrowBackIosIcon id="previous" sx={{ fontSize: 15, paddingRight: 2 }} onClick={handlePrevious} />
          <label id="calendar-label" className="label-calendar" onClick={() => setShowCalendar(true)}>
            {" "}
            {labelDate}{" "}
          </label>
          <ArrowForwardIosIcon id="next" sx={{ fontSize: 15, paddingLeft: 2 }} onClick={handleNext} />
        </div>
        <TodayOutlinedIcon id="today-button" sx={{ color: Colors.COLORFREEITEM, paddingTop: 1.75 }} onClick={goToToday} />
      </div>
      {showCalendar ? (
        <div className="calendar-cont" ref={calendarRef as React.RefObject<HTMLDivElement>}>
          <Calendar
            value={
              endSelectedDate && currentStartDate !== endSelectedDate && showModalReservation
                ? [currentStartDate, endSelectedDate]
                : currentDate.toJSDate()
            }
            next2Label=""
            prev2Label=""
            onChange={(date: any) => {
              dispatch(setIntervalStartDate(DateTime.fromJSDate(date)));
              dispatch(setIntervalEndDate(DateTime.fromJSDate(date)));
              dispatch(setCurrentDate(DateTime.fromJSDate(date)));
              setShowCalendar(false);
              labelDate = `${DateTime.fromJSDate(date).toFormat("dd MMMM yyyy")}`;
              dispatch(setIntervalLabel(labelDate));
            }}
            maxDate={new Date(DateTime.local().plus({ days: 60 }).toJSDate().toISOString())}
            formatShortWeekday={(locale: any, date: any) => ["S", "M", "T", "W", "T", "F", "S"][date.getDay()]}
          />
        </div>
      ) : null}
    </div>
  );
}

export default CalendarComponent;
