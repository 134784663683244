import { SaveOutlined } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { capitalize, isNull, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  canvasModeSelector,
  displayAdminModeSelector,
  resetCanvas,
  setCanvasMode,
  showModalReservation,
  showModalReservationSelector,
  updateCanvasJSON,
} from "../../slices/Canvas/canvasSlice";
import {
  allBuildingsSelector,
  buildingNameSelector,
  getBuildings,
  selectedBuildingSelector,
  setSelectedBuilding,
} from "../../slices/Entities/buildingSlice";
import {
  defaultBuildingSelector,
  defaultFloorSelector,
  getAllConfigurations,
  myDefaultBuildingSelector,
  myDefaultFloorSelector,
} from "../../slices/Entities/configurationsSlice";
import { getDesksByFloor, selectedDesksState, setFloorDesksOptions } from "../../slices/Entities/deskSlice";
import { floorsSelector, getFloorsFromBuilding, selectedFloorSelector, setSelectedFloor, updateFloorData } from "../../slices/Entities/floorSlice";
import { useAuth } from "../../utils/AuthHook";
import { notifyCanvasSave } from "../../utils/notify";
import { CanvasMode, IFloorInfo } from "../Canvas/types";
import Dropdown from "../GenericDropdown/genericDropdown";
import ReservationModal from "../ReservationModal/reservationModal";

const canvasModes = [capitalize(CanvasMode.VIEW), capitalize(CanvasMode.EDIT)];

export default function NavbarDropdowns(props: any) {
  const { setErrorMessage } = props;
  const [buildingOptions, setBuildingOptions] = useState<any>([]);
  const [floorOptions, setFloorOptions] = useState<any>([]);
  const [deskOptions, setDeskOptions] = useState<any>([]);

  const [newBuilding, setNewBuilding] = useState<string>("");
  const [defaultBuilding, setDefaultBuilding] = useState<any>([]);
  const [defaultFloor, setDefaultFloor] = useState<string>("");
  const [isSavingCanvas, setIsSavingCanvas] = useState<boolean>(false);
  const dispatch = useDispatch<any>();

  const displayAdminMode = useSelector(displayAdminModeSelector);
  const buildingSelector: any = useSelector(allBuildingsSelector);
  const floorSelector = useSelector(floorsSelector);
  const showModal = useSelector(showModalReservationSelector);
  const buildingName = useSelector(buildingNameSelector);
  const selectedBuilding = useSelector(selectedBuildingSelector);
  const selectedFloor = useSelector(selectedFloorSelector);
  const defaultBuildingConfig = useSelector(defaultBuildingSelector);
  const defaultFloorConfig = useSelector(defaultFloorSelector);
  const myDefaultBuildingConfig = useSelector(myDefaultBuildingSelector);
  const myDefaultFloorConfig = useSelector(myDefaultFloorSelector);
  const canvasMode = useSelector(canvasModeSelector);
  const floorInfo: IFloorInfo = useSelector(selectedFloorSelector);

  const { check } = useAuth();

  useEffect(() => {
    const getData = async () => {
      !floorInfo && dispatch(setSelectedBuilding(null));
      !floorInfo && dispatch(setSelectedFloor(null));
      await dispatch(getAllConfigurations());
      dispatch(getBuildings());
    };
    getData();
  }, [dispatch]);

  useEffect(() => {
    if (!floorSelector.length || !selectedBuilding) return;
    const floorOptions = floorSelector.filter((floor) => floor.building === selectedBuilding._id).map((floor: any) => floor.name);
    setFloorOptions(floorOptions);
  }, [floorSelector]);

  useEffect(() => {
    if (!buildingSelector && !buildingSelector.length) return;
    const buildingOptions = buildingSelector.map((building: any) => building.name);
    setBuildingOptions(buildingOptions);
  }, [buildingSelector]);

  useEffect(() => {
    if (!selectedFloor?._id) return;
    if (buildingName) {
      handleChangeBuilding(buildingName);
    }
    if (buildingSelector.length > 0) {
      const bOptions = buildingSelector?.map((building: any) => building.name);
      setBuildingOptions(bOptions);
      if (myDefaultBuildingConfig) {
        const building = buildingSelector.find((building: any) => building.id === myDefaultBuildingConfig.value);
        if (building) {
          setDefaultBuilding(building.name);
          if (!selectedBuilding) {
            dispatch(setSelectedBuilding(building));
            dispatch(getFloorsFromBuilding(building._id));
          }
        }
      } else if (defaultBuildingConfig) {
        const building = buildingSelector.find((building: any) => building.id === defaultBuildingConfig.value);
        if (building) {
          setDefaultBuilding(building.name);
          if (!selectedBuilding) {
            dispatch(setSelectedBuilding(building));
            dispatch(getFloorsFromBuilding(building._id));
          }
        }
      } else {
        setDefaultBuilding(bOptions[0]);
        dispatch(setSelectedBuilding(buildingSelector[0]));
        dispatch(getFloorsFromBuilding(buildingSelector[0]._id));
      }
    }
    if (newBuilding !== "") {
      const foundBuilding = buildingSelector?.find((b: any) => b.name === newBuilding);
      handleChangeBuilding(foundBuilding.name);
      setNewBuilding("");
    }
  }, [selectedFloor?._id]);

  useEffect(() => {
    if (selectedFloor?.canvasData) {
      dispatch(updateCanvasJSON(selectedFloor?.canvasData)).then((result: any) => {
        if (result.error) {
          setErrorMessage(result.payload.response.data);
        }
      });
    } else {
      dispatch(resetCanvas());
    }
  }, [selectedFloor?._id]);

  useEffect(() => {
    if (!selectedBuilding || !floorSelector || isUndefined(selectedBuilding)) {
      return;
    }
    const fOptions = floorSelector.filter((floor) => floor.building === selectedBuilding?._id).map((floor: any) => floor.name);
    setFloorOptions(fOptions);
    if (selectedFloor) {
      dispatch(
        getDesksByFloor({
          buildingId: selectedBuilding._id,
          floorId: selectedFloor._id,
        })
      )
        .then((result: any) => {
          if (result.error) {
            setErrorMessage(result.payload.response.data);
            return false;
          }
          const desksOptions = result.payload;
          const dOptions = desksOptions.filter((desk: any) => desk.floor === selectedFloor._id);
          setDeskOptions(dOptions);
          return dispatch(setFloorDesksOptions(dOptions));
        })
        .then((result: any) => {
          if (!result) {
            return;
          }
          handleChangeFloor(selectedFloor.floorName);
        });
    }
  }, [selectedFloor?._id]);

  useEffect(() => {
    if (selectedBuilding) {
      const fOptions = floorSelector.filter((floor) => floor.building === selectedBuilding._id);
      const fOptionsNames = fOptions.map((floor: any) => floor.name);
      setFloorOptions(fOptionsNames);
      let floor = null;
      if (myDefaultFloorConfig) {
        floor = floorSelector.find((floor: any) => floor.id === myDefaultFloorConfig.value && floor.building === selectedBuilding.id);
      } else if (defaultFloorConfig) {
        floor = floorSelector.find((floor: any) => floor.id === defaultFloorConfig.value && floor.building === selectedBuilding.id);
      }
      if (floor) {
        setDefaultFloor(floor.name);
        if (!selectedFloor && !floorInfo) {
          dispatch(setSelectedFloor(floor));
        }
      } else {
        setDefaultFloor(fOptionsNames[0]);
        if (!selectedFloor && !floorInfo) {
          dispatch(setSelectedFloor(fOptions[0]));
        }
      }
    }
  }, [selectedBuilding ? selectedBuilding._id : null]);

  useEffect(() => {
    if (myDefaultBuildingConfig === null || defaultBuildingConfig === null) return;
    let building = null;
    !floorInfo && dispatch(setSelectedFloor(null));
    if (myDefaultBuildingConfig) {
      building = buildingSelector.find((building: any) => building.id === myDefaultBuildingConfig.value);
      if (defaultBuilding) {
        setDefaultBuilding(defaultBuilding);
        if (building && !selectedBuilding) {
          dispatch(setSelectedBuilding(building));
          dispatch(getFloorsFromBuilding(building._id));
        }
      }
      return;
    }
    if (defaultBuildingConfig) {
      building = buildingSelector.find((building: any) => building.id === defaultBuildingConfig.value);
      if (defaultBuilding) {
        setDefaultBuilding(defaultBuilding);
        if (building && !floorInfo) {
          dispatch(setSelectedBuilding(building));
          dispatch(getFloorsFromBuilding(building._id));
        }
      }
      return;
    }
  }, [myDefaultBuildingConfig, defaultBuildingConfig]);

  useEffect(() => {
    let floor = null;
    if (selectedBuilding) {
      if (myDefaultFloorConfig) {
        floor = floorSelector.find((floor: any) => floor.id === myDefaultFloorConfig.value && floor.building === selectedBuilding.id);
        setDefaultFloor(defaultFloor);
        if (!selectedFloor && !floorInfo) {
          dispatch(setSelectedFloor(floor));
        }
        return;
      }
      if (defaultFloorConfig) {
        floor = floorSelector?.find((floor: any) => floor.id === defaultFloorConfig.value && floor.building === selectedBuilding.id);
        if (floor) {
          if (defaultFloor) {
            setDefaultFloor(defaultFloor);
          }
          !floorInfo && dispatch(setSelectedFloor(floor));
        }
        return;
      }
    }
  }, [myDefaultFloorConfig, defaultFloorConfig]);

  useEffect(() => {
    if (!selectedBuilding && buildingSelector.length > 0) {
      if (myDefaultBuildingConfig) {
        const building = buildingSelector.find((building: any) => building.id === myDefaultBuildingConfig.value);
        if (building) {
          handleChangeBuilding(building.name);
        }
      } else if (defaultBuildingConfig) {
        const building = buildingSelector.find((building: any) => building.id === defaultBuildingConfig.value);
        if (building) {
          handleChangeBuilding(building.name);
        }
      } else {
        handleChangeBuilding(buildingSelector[0].name);
      }
    }
  }, [selectedBuilding]);

  const handleChangeBuilding = (buildingName: string) => {
    if (!buildingName || buildingName === "building") {
      return;
    }
    if (selectedBuilding && buildingName === selectedBuilding.name) {
      return;
    }
    dispatch(resetCanvas());
    if (buildingSelector) {
      const foundBuilding = buildingSelector?.find((b: any) => b.name === buildingName);
      dispatch(setSelectedFloor(null));
      dispatch(setSelectedBuilding(foundBuilding));
      const array: any[] = [];
      dispatch(selectedDesksState(array));
      if (!isUndefined(foundBuilding)) {
        dispatch(getFloorsFromBuilding(foundBuilding._id)).then((result: any) => {
          if (!result.error) {
            const floors = result.payload;
            if (floors) {
              const fOptions = floors?.map((floor: any) => floor.name);
              let floor = null;
              if (myDefaultFloorConfig) {
                floor = floors.find((floor: any) => floor.id === myDefaultFloorConfig.value && floor.building === foundBuilding.id);
              } else if (defaultFloorConfig) {
                floor = floors.find((floor: any) => floor.id === defaultFloorConfig.value && floor.building === foundBuilding.id);
              }
              if (floor) {
                setDefaultFloor(floor.name);
                if (!selectedFloor) {
                  dispatch(setSelectedFloor(floor));
                }
                if (floor?.canvasData) {
                  dispatch(updateCanvasJSON(floor.canvasData)).then((result: any) => {
                    if (result.error) {
                      setErrorMessage(result.payload.response.data);
                    }
                  });
                } else {
                  dispatch(resetCanvas());
                }
              } else {
                setDefaultFloor(floors[0]?.name);
                dispatch(setSelectedFloor(floors[0]));
                setFloorOptions(fOptions);
                if (floors[0]?.canvasData) {
                  dispatch(updateCanvasJSON(floors[0].canvasData)).then((result: any) => {
                    if (result.error) {
                      setErrorMessage(result.payload.response.data);
                    }
                  });
                } else {
                  dispatch(resetCanvas());
                }
              }
            } else {
              setErrorMessage(result.payload.response.data);
            }
          }
        });
      }
    }
  };

  const handleChangeFloor = (floorName: string) => {
    if (!floorName || floorName === "floor") {
      return;
    }
    if (!floorSelector) {
      return;
    }
    const foundFloor = floorSelector?.find((f: any) => f.name === floorName && f.building === selectedBuilding._id);
    if (!foundFloor) {
      return;
    }
    dispatch(setSelectedFloor(foundFloor));

    const array: any[] = [];
    dispatch(selectedDesksState(array));
    dispatch(setFloorDesksOptions(deskOptions));
    if (!isUndefined(foundFloor?.canvasData) && !isNull(foundFloor?.canvasData) && foundFloor.canvasData !== "") {
      dispatch(resetCanvas());
      dispatch(updateCanvasJSON(foundFloor.canvasData)).then((result: any) => {
        if (result.error) {
          setErrorMessage(result.payload.response.data);
        }
      });
    } else {
      dispatch(resetCanvas());
    }
  };

  const handleCanvasModeChange = (canvasMode: string) => {
    dispatch(setCanvasMode(`${canvasMode}`.toLowerCase() as CanvasMode));
  };

  const getNewItem = (name: string, item: string) => {
    if (item === "building") {
      dispatch(getBuildings());
    } else {
      dispatch(getFloorsFromBuilding(selectedBuilding.id));
    }
  };

  const reservationModalHandler = (show: boolean): void => {
    dispatch(showModalReservation(show));
  };

  const saveCanvas = (): void => {
    setIsSavingCanvas(true);
    dispatch(
      updateFloorData({
        ...(floorInfo || {}),
        canvas: {
          floorId: floorInfo.id,
          name: floorInfo.name,
          objects: floorInfo?.canvas.objects,
          design: floorInfo?.canvas.design,
        },
      })
    )
      .then((result: any) => {
        if (result.error) {
          props.setErrorMessage(result.payload.response.data);
          return;
        }
        notifyCanvasSave();
        dispatch(setSelectedFloor(result.payload));
        localStorage.clear();
      })
      .finally(() => setIsSavingCanvas(false));
  };

  const { pathname } = useLocation();
  const paths = ["/dashboard", "/users", "/teams", "/settings", "/adminDashboard", "/custom"];
  const showWidgets = paths.includes(pathname) ? true : false;

  return (
    <div className="dropdown-container">
      {!showWidgets ? (
        <>
          {check("building.read") ? (
            <Dropdown
              testID="building-dropdown"
              value={selectedBuilding?.name}
              dropdownItems={buildingOptions}
              className={"building-dropdown"}
              placeholder={defaultBuilding}
              children={null}
              onChange={handleChangeBuilding}
              item={"building"}
              getNewItem={getNewItem}
              selectedBuilding={selectedBuilding?._id}
              setErrorMessage={setErrorMessage}
            />
          ) : (
            <></>
          )}
          {check("floor.read") ? (
            <Dropdown
              testID="floor-dropdown"
              value={selectedFloor?.name}
              dropdownItems={floorOptions}
              placeholder={defaultFloor}
              className={"floor-dropdown"}
              children={null}
              disabled={isUndefined(selectedBuilding)}
              onChange={handleChangeFloor}
              item={"floor"}
              selectedBuilding={selectedBuilding?.id}
              selectedFloor={selectedFloor?.id}
              getNewItem={getNewItem}
              setErrorMessage={setErrorMessage}
            />
          ) : (
            <></>
          )}
          {check("admin.mode") && displayAdminMode && (
            <Dropdown
              testID="canvas-mode-dropdown"
              buttonsDisabled
              value={capitalize(canvasMode)}
              dropdownItems={canvasModes}
              placeholder={"Canvas Mode"}
              className={"floor-dropdown canvas-mode-dropdown"}
              children={null}
              disabled={isUndefined(selectedBuilding)}
              onChange={handleCanvasModeChange}
              item={"Mode"}
              selectedBuilding={selectedBuilding?.id}
              selectedFloor={selectedFloor?.id}
              getNewItem={getNewItem}
              footerDisabled
              setErrorMessage={setErrorMessage}
            />
          )}
          {check("admin.mode") && displayAdminMode && (
            <>
              {isSavingCanvas ? (
                <CircularProgress className="navbar-spinner" size={"1.25rem"} />
              ) : (
                <SaveOutlined onClick={saveCanvas} className="navbar-save-canvas-button" />
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}
      {showModal ? (
        <ReservationModal handleClose={() => reservationModalHandler(false)} selectedBuilding={selectedBuilding} setErrorMessage={setErrorMessage} />
      ) : null}
    </div>
  );
}
