import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import { addConfiguration, getConfiguration, getConfigurations } from "../../services/configurationService";
import { Status } from "../StatusEnum";

type InitialStateType = {
  status: Status;
  configurations: [];
  defaultBuilding: String | null;
  defaultFloor: String | null;
  myDefaultBuilding: String | null;
  myDefaultFloor: String | null;
  error: SerializedError | null;
};

const initialState: InitialStateType = {
  status: Status.Idle,
  configurations: [],
  defaultBuilding: null,
  defaultFloor: null,
  myDefaultBuilding: null,
  myDefaultFloor: null,
  error: null,
};

export const getConfigurationByKey = createAsyncThunk("configurations/getConfiguration", async ({ key }: any, thunkApi) => {
  try {
    const response = await getConfiguration(key);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const getAllConfigurations = createAsyncThunk("configurations/getAllConfigurations", async (_, thunkApi) => {
  try {
    const response = await getConfigurations();

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const addConfigurationData = createAsyncThunk("configurations/addConfiguration", async ({ key, value, user }: any, thunkApi) => {
  try {
    const response = await addConfiguration(key, value, user);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

const configurationSlice = createSlice({
  name: "configurations",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getConfigurationByKey.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.configurations.push(payload);
        }
      })
      .addCase(getConfigurationByKey.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getConfigurationByKey.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(getAllConfigurations.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.defaultBuilding = payload.find((config: any) => config.key === "defaultBuilding");
          state.defaultFloor = payload.find((config: any) => config.key === "defaultFloor");
          state.myDefaultBuilding = payload.find((config: any) => config.key === "myDefaultBuilding");
          state.myDefaultFloor = payload.find((config: any) => config.key === "myDefaultFloor");
          state.status = Status.Succeeded;
          payload.forEach((config: any) => {
            state.configurations.push(config);
          });
        }
      })
      .addCase(getAllConfigurations.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getAllConfigurations.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(addConfigurationData.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        const index = state.configurations.map((config: any) => config.id).indexOf(payload.id);
        if (index >= 0) {
          state.configurations[index] = payload;
        } else {
          state.configurations.push(payload);
        }
        if (payload.key === "defaultBuilding") {
          state.defaultBuilding = payload;
        }
        if (payload.key === "defaultFloor") {
          state.defaultFloor = payload;
        }
        if (payload.key === "myDefaultBuilding") {
          state.myDefaultBuilding = payload;
        }
        if (payload.key === "myDefaultFloor") {
          state.myDefaultFloor = payload;
        }
        state.status = Status.Succeeded;
      })
      .addCase(addConfigurationData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(addConfigurationData.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      });
  },
  reducers: {},
});

const { reducer } = configurationSlice;

export default reducer;

export const configurationsSelector = (state: any) => state.configurations.configurations;
export const defaultBuildingSelector = (state: any) => state.configurations.defaultBuilding;
export const defaultFloorSelector = (state: any) => state.configurations.defaultFloor;
export const myDefaultBuildingSelector = (state: any) => state.configurations.myDefaultBuilding;
export const myDefaultFloorSelector = (state: any) => state.configurations.myDefaultFloor;
