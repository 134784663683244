import React, { CSSProperties, memo, useCallback, useState } from "react";

import { GRID, REM } from "../../utils/variables";
import { DEFAULT_DESK_SELECTED_COLOR } from "./constants";
import { IDeskProps } from "./types";
import { getDeskAvailability, getDeskColorByAvailability } from "./utils";

import { useSelector } from "react-redux";
import { userSelector } from "../../slices/Auth/authSlice";
import { canvasModeSelector, currentIntervalEndDateSelector, currentIntervalStartDateSelector } from "../../slices/Canvas/canvasSlice";
import { assignmentsSelector } from "../../slices/Entities/assignmentSlice";
import { toolbarItemsSelector } from "../../slices/Entities/toolbarSlice";
import { getElementCursorClassName } from "../../utils/canvas-helpers";
import { Assignment, CanvasMode } from "../Canvas/types";
import "./Desk.scss";

const Desk = React.forwardRef<HTMLDivElement, IDeskProps>((props, ref) => {
  const [isHovered, setIsHovered] = useState<boolean>(true);

  const canvasMode = useSelector(canvasModeSelector);
  const assignments: Assignment[] = useSelector(assignmentsSelector);
  const intervalStartDate = useSelector(currentIntervalStartDateSelector);
  const intervalEndDate = useSelector(currentIntervalEndDateSelector);
  const user = useSelector(userSelector);
  const toolbarItems = useSelector(toolbarItemsSelector);

  const handleDeskClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (props.selectedDefaultTool === "ROTATE") {
      props.rotationCallback({
        ...props.item,
        rotation: (props.item.rotation + 90) % 360,
        size: { width: props.item.size.height, height: props.item.size.width },
      });

      return;
    }
    if (props.selectedDefaultTool === "FLIP") {
      props.flipCallback({ ...props.item, flippedY: !props.item.flippedY });
      return;
    }
    props.onClick(e);
  };

  const getImageSrc = useCallback(() => {
    return (toolbarItems && toolbarItems.items.find((item) => item.id === props.item.toolbarItemID)?.imageSrc) || "";
  }, [props.item.toolbarItemID, toolbarItems]);

  const deskBubbleStyle: CSSProperties = {
    width: 1 * REM,
    height: 1 * REM,
    backgroundColor: props.isSelected
      ? DEFAULT_DESK_SELECTED_COLOR
      : canvasMode === CanvasMode.VIEW
      ? getDeskColorByAvailability(getDeskAvailability(assignments, intervalStartDate, intervalEndDate, props.item.id, user.username))
      : getDeskColorByAvailability("FREE"),
  };

  return (
    <div
      data-testid="canvas-desk"
      className={`desk-wrapper ${props.className}`}
      style={{ ...props.style }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={props.onMouseDown}
      onMouseUp={props.onMouseUp}
      onTouchEnd={props.onTouchEnd}
      ref={ref}
      onMouseOver={() => props.onMouseOver(props.item)}
      onClick={handleDeskClick}
      id={props.item.id}
    >
      <div style={deskBubbleStyle} className="desk-availability-bubble" />
      <div
        onClick={handleDeskClick}
        className={`canvas-desk-container ${props.isSelected ? "selected" : ""} ${
          isHovered ? getElementCursorClassName(props.selectedDefaultTool, props.item.type, canvasMode) : ""
        }`}
        style={{
          height: props.item.size ? props.item.size.height * GRID : "",
          width: props.item.size ? props.item.size.width * GRID : "",
          border: `1px solid ${props.item.hasBorder ? "black" : "transparent"}`,
          backgroundColor: `${props.item.hasBackground ? "white" : "transparent"}`,
          transform: `scaleY(${props.item.flippedY ? "-1" : "1"})`,
        }}
      >
        <div className="desk-inner-wrapper">
          {getImageSrc() && (
            <img
              crossOrigin=""
              style={{
                height: props.item.size ? props.item.size.height * GRID : "",
                width: props.item.size ? props.item.size.width * GRID : "",
                rotate: `${props.item.rotation}deg`,
                objectFit: `${props.item.hasImageStretched ? "fill" : "contain"}`,
              }}
              className="desk-image"
              alt="desk"
              src={getImageSrc()}
            />
          )}
        </div>
      </div>
      {props.children}
    </div>
  );
});

export default memo(Desk);
