import { Apartment, Business } from "@mui/icons-material";
import ChairAltIcon from "@mui/icons-material/ChairAlt";
import FormatListBulletedSharpIcon from "@mui/icons-material/FormatListBulletedSharp";
import GridViewIcon from "@mui/icons-material/GridView";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { isNull, isUndefined } from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import CalendarComponent from "../../components/CalendarComponent/calendarComponent";
import ErrorNotification from "../../components/ErrorNotification/errorNotification";
import NavbarDropdowns from "../../components/NavbarDropdowns/NavbarDropdowns";
import NavbarSelectableTools from "../../components/NavbarSelectableTools/navbarSelectableTools";
import UserAvatar from "../../components/UserAvatar/userAvatar";
import { userSelector } from "../../slices/Auth/authSlice";
import { displayAdminModeSelector, setDisplayAdminMode } from "../../slices/Canvas/canvasSlice";
import { useAuth } from "../../utils/AuthHook";
import NavbarInfoButton from "./NavbarInfoButton/NavbarInfoButton";

export default function Navbar() {
  const displayAdminMode = useSelector(displayAdminModeSelector);
  const [isAdminMode, setIsAdminMode] = useState<boolean>(displayAdminMode);
  const classes = ["navbar-header"];

  const dispatch = useDispatch<any>();
  const user = useSelector(userSelector);
  const { check } = useAuth();

  const firstName = user.firstName;
  const hasFirstName = !isNull(firstName) && !isUndefined(firstName);
  const { pathname } = useLocation();
  const mobile = window.innerWidth;

  const hideCalendar =
    (pathname === "/dashboard" && mobile < 700) ||
    (pathname === "/users" && mobile < 700) ||
    (pathname === "/teams" && mobile < 700) ||
    (pathname === "/settings" && mobile < 700);

  const showDropdowns =
    pathname === "/desksList" ||
    pathname === "/deskpicker" ||
    (pathname === "/users" && mobile < 700) ||
    (pathname === "/custom" && mobile < 700) ||
    (pathname === "/teams" && mobile < 700) ||
    (pathname === "/settings" && mobile < 700) ||
    (pathname === "/dashboard" && mobile < 700);

  const showElements = pathname === "/adminDashboard";

  const [errorMessage, setErrorMessage] = useState<string>();

  const toggleAdminMode = () => {
    setIsAdminMode((prev) => {
      dispatch(setDisplayAdminMode(!prev));
      return !prev;
    });
  };

  const resetErrorMessage = () => {
    setErrorMessage(undefined);
  };

  const currentRoute = useLocation();

  pathname === "/deskpicker" && classes.push("navbar-header-canvas");
  return (
    <div className={classes.join(" ")}>
      <div className="left-container-navbar">
        <div className="logo-calendar">
          <Link to="/deskpicker">
            <img className="logo" src={logo} alt={"navbar-logo"} />
          </Link>
          {showDropdowns && !hideCalendar ? (
            <div className="calendar-components">
              <CalendarComponent />
            </div>
          ) : (
            <></>
          )}
        </div>
        {showElements ? (
          <>
            <div className="dropdown-container-adminDash">
              <NavbarSelectableTools setErrorMessage={setErrorMessage} isAdminMode={isAdminMode} />
            </div>
          </>
        ) : (
          <></>
        )}
        {showDropdowns ? (
          <>
            <div className="dropdown-container">
              <NavbarDropdowns setErrorMessage={setErrorMessage} isAdminMode={isAdminMode} />
              <NavbarInfoButton />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="right-container-navbar">
        <div className="icons-container">
          <li className="nav-list mobile-restricted">
            <div className="tooltip">
              <Link className={currentRoute.pathname.includes("dashboard") ? "text-link active" : "text-link"} to="/dashboard">
                <GridViewIcon sx={{ paddingTop: 1.75 }} />
              </Link>
              <span className="tooltiptext-menu mobile-restricted">Dashboard</span>
            </div>
          </li>
          {check("admin.mode") ? (
            <li className="nav-list">
              <div className="tooltip">
                <Link className={currentRoute.pathname.includes("adminDashboard") ? "text-link active" : "text-link"} to="/adminDashboard">
                  <LeaderboardIcon sx={{ paddingTop: 1.75 }} />
                </Link>
                <span className="tooltiptext-menu mobile-restricted">Admin Dashboard</span>
              </div>
            </li>
          ) : (
            <></>
          )}
          <li className="nav-list">
            <div className="tooltip">
              <Link className={currentRoute.pathname.includes("deskpicker") ? "text-link active" : "text-link"} to="/deskpicker">
                <ChairAltIcon sx={{ paddingTop: 1.75 }} />
                <span className="tooltiptext-menu mobile-restricted">Deskpicker</span>
              </Link>
            </div>
          </li>
          {check("admin.mode") && (
            <li className="nav-list mobile-restricted">
              <div className="tooltip">
                <Link className={currentRoute.pathname.includes("toolbarManager") ? "text-link active" : "text-link"} to="/toolbarManager">
                  <ListAltIcon sx={{ paddingTop: 1.75 }} />
                </Link>
                <span className="tooltiptext-menu mobile-restricted">Toolbar Manager</span>
              </div>
            </li>
          )}
          <li className="nav-list">
            <div className="tooltip">
              <Link className={currentRoute.pathname.includes("desksList") ? "text-link active" : "text-link"} to="/desksList">
                <FormatListBulletedSharpIcon sx={{ paddingTop: 1.75 }} />
              </Link>
              <span className="tooltiptext-menu mobile-restricted">Desks List</span>
            </div>
          </li>
          {check("user.read") ? (
            <li className="nav-list">
              <div className="tooltip">
                <Link className={currentRoute.pathname.includes("users") ? "text-link active" : "text-link"} to="/users">
                  <PeopleOutlineIcon sx={{ paddingTop: 1.75 }} />
                </Link>
                <span className="tooltiptext-menu mobile-restricted">Users</span>
              </div>
            </li>
          ) : (
            <></>
          )}
          {check("team.read") ? (
            <li className="nav-list">
              <div className="tooltip">
                <Link className={currentRoute.pathname.includes("teams") ? "text-link active" : "text-link"} to="/teams">
                  <GroupsOutlinedIcon sx={{ paddingTop: 1.75 }} />
                </Link>
                <span className="tooltiptext-menu mobile-restricted">Teams</span>
              </div>
            </li>
          ) : (
            <></>
          )}
          {check("admin.mode") ? (
            <li className="text-link nav-list mobile-restricted ">
              <div className="tooltip">
                <HandymanOutlinedIcon
                  onClick={toggleAdminMode}
                  className={isAdminMode ? "text-link active" : "text-link"}
                  sx={{ paddingTop: 1.75 }}
                />
                <span className="tooltiptext-menu mobile-restricted">Admin Mode</span>
              </div>
            </li>
          ) : (
            <></>
          )}
          {check("configurations.create") ? (
            <li className="nav-list">
              <div className="tooltip">
                <Link className={currentRoute.pathname.includes("settings") ? "text-link  active" : " text-link "} to="/settings">
                  <SettingsOutlinedIcon sx={{ paddingTop: 1.75 }} />
                </Link>
                <span className="tooltiptext-menu mobile-restricted">Settings</span>
              </div>
            </li>
          ) : (
            <></>
          )}
          {check("myCompany.update") ? (
            <li className="nav-list mobile-restricted">
              <div className="tooltip">
                <Link className={currentRoute.pathname.includes("myCompany") ? "text-link active" : "text-link"} to="/myCompany">
                  <Apartment sx={{ paddingTop: 1.75 }} />
                  <span className="tooltiptext-menu mobile-restricted">My Company</span>
                </Link>
              </div>
            </li>
          ) : (
            <></>
          )}
          {check("super-admin") ? (
            <li className="nav-list">
              <div className="tooltip">
                <Link className={currentRoute.pathname.includes("companies") ? "text-link active" : "text-link"} to="/companies">
                  <Business sx={{ paddingTop: 1.75 }} />
                  <span className="tooltiptext-menu mobile-restricted">Companies</span>
                </Link>
              </div>
            </li>
          ) : (
            <></>
          )}
          <li className="greeting-container">
            Hey,&nbsp;
            {hasFirstName ? <span className="name">{firstName}!</span> : "hello!"}
          </li>
          <li className="nav-list-profile">
            <UserAvatar setErrorMessage={setErrorMessage} />
          </li>
        </div>
      </div>
      {!isUndefined(errorMessage) && <ErrorNotification message={errorMessage} onClose={resetErrorMessage} />}
    </div>
  );
}
