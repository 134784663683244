import client from "./axios.service";

const baseUrl = "customSeatingPoints";

export type customSeatingPointsData = {
  name?: string;
  imageURL?: any;
};

export const getCustomSeatingPoints = () => {
  const requestPath = `/${baseUrl}`;
  return client.get(requestPath, null);
};

export const getCustomSeatingPointById = (seatingPointId: string) => {
  const requestPath = `/${baseUrl}/${seatingPointId}`;
  return client.get(requestPath, {});
};

export const addCustomElement = (name: string, imageURL: any) => {
  const requestPath = `/${baseUrl}/addCustomElement`;
  return client.post(requestPath, { name, imageURL }, {});
};

export const deleteCustomElement = (id: string) => {
  const requestPath = `/${baseUrl}/${id}`;
  return client.delete(requestPath, {});
};
