import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { allTeamsSelector, getTeams, teamMembers } from "../../slices/Entities/teamSlice";
import { useAuth } from "../../utils/AuthHook";

function TeamReservation(props: any) {
  const { checked, setChecked, setTeam, currentUser, selectForTeam } = props;
  const dispatch = useDispatch<any>();
  const [teams, setTeams] = useState<any>([]);
  const [showAll, setShowAll] = useState<any>(false);
  const { user, check } = useAuth();

  const allTeams = useSelector(allTeamsSelector);

  useEffect(() => {
    dispatch(getTeams());
  }, []);

  const handleChange = () => {
    if (!checked) fetchData();
    else {
      setTeam(undefined);
      dispatch(teamMembers(0));
    }
    setChecked(!checked);
    setShowAll(true);
    if (check("reserve.team") && currentUser.role === "Manager") {
      setShowAll(false);
    }
  };

  const fetchData = async () => {
    if (currentUser.role === "Manager") {
      setTeams(allTeams.filter((team: any) => team.manager._id === currentUser._id).map((team: any) => ({ ...team, label: team.name })));
    } else if (check("reserve.team")) {
      setShowAll(true);
      setTeams(allTeams.map((team: any) => ({ ...team, label: team.name })));
    }
  };

  const handleViewAllTeams = () => {
    setTeams(allTeams.map((team: any) => ({ ...team, label: team.name })));
    setShowAll(true);
  };

  const handleChangeTeam = (team: any) => {
    setTeam(team);
    selectForTeam(team.members.length - 1);
    dispatch(teamMembers(team.members.length));
  };
  const CustomMenu = ({ innerRef, innerProps, isDisabled, children }: any) =>
    !isDisabled ? (
      <div ref={innerRef} {...innerProps} id="menu-open-top">
        {children}
        {!showAll ? (
          <Button id="view-all-btn" onClick={handleViewAllTeams}>
            View All
          </Button>
        ) : null}
      </div>
    ) : null;
  return (
    <div className="others-container">
      <label className="checkbox-label">
        Reserve for team
        <input type="checkbox" checked={checked} onChange={handleChange} />
        <span className="checkmark"></span>
      </label>
      {checked ? (
        <Select
          id="select-team"
          options={teams}
          onChange={(team: any) => handleChangeTeam(team)}
          components={{ Menu: CustomMenu }}
          inputId="team-input"
          placeholder="Search..."
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default TeamReservation;
