import LoadingButton from "@mui/lab/LoadingButton";
import { Autocomplete, Button, MenuItem, TextField } from "@mui/material";
import Paper from "@mui/material/Paper/Paper";
import { Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IToolbarItem } from "../../components/ObjectsToolbar/types";
import { ToolbarItemData } from "../../services/toolbarService";
import {
  addItemToToolbar,
  deleteDefaultItemImage,
  deleteItemFromToolbar,
  getToolbarData,
  toolbarCategoriesSelector,
  toolbarItemsSelector,
  updateToolbarItem,
  uploadImageForToolbarDefaultItem,
} from "../../slices/Entities/toolbarSlice";
import {
  notifyDeleteError,
  notifyDeleteSuccess,
  notifyError,
  notifyNoImageUploaded,
  notifyUploadError,
  notifyUploadSuccess,
} from "../../utils/notify";

import Navbar from "../Navbar/navbar";
import OverlayImage from "./OverlayImage/OverlayImage";
import "./ToolbarManager.scss";
import ToolbarManagerListItem from "./ToolbarManagerListItem/ToolbarManagerListItem";
import { IToolbarManager, LoadingState, PhotoType, ToolbarForm } from "./types";
import {
  DEFAULT_DOOR_IMAGE_SRC,
  DEFAULT_STAIRS_IMAGE_SRC,
  dropdownSizeValues,
  dropdownTypeValues,
  initialFormValues,
  initialLoading,
  validationSchemaToolbarForm,
} from "./variables";

export default function ToolbarManager(props: IToolbarManager) {
  const [loading, setLoading] = useState<LoadingState>(initialLoading);
  const [uploadedPhotoForForm, setUploadedPhotoForForm] = useState<File | null>(null);
  const [loadingItemIDs, setLoadingItemIDs] = useState<string[]>([]);
  const [currentlyEditingItemID, setCurrentlyEditingItemID] = useState<null | string>(null);

  const photoFor = useRef<PhotoType | null>(null);
  const imageInputRef = useRef<HTMLInputElement | null>(null);
  const formikRef = useRef<FormikProps<ToolbarForm> | null>(null);

  const toolbarCategories = useSelector(toolbarCategoriesSelector);
  const toolbarItems = useSelector(toolbarItemsSelector);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getToolbarData());
  }, []);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = e.target.files?.[0];
    if (!uploadedFile) return;
    switch (photoFor.current) {
      case "form": {
        setUploadedPhotoForForm(uploadedFile);
        break;
      }
      case "door": {
        uploadDefaultItemImage(uploadedFile, photoFor.current, e);
        break;
      }
      case "stairs": {
        uploadDefaultItemImage(uploadedFile, photoFor.current, e);
        break;
      }
    }
  };

  const uploadDefaultItemImage = (image: File, tool: "stairs" | "door", e: React.ChangeEvent<HTMLInputElement>) => {
    if (tool === "stairs") {
      setLoading((prev) => ({ ...prev, stairsUploadLoading: true }));
    }
    if (tool === "door") {
      setLoading((prev) => ({ ...prev, doorUploadLoading: true }));
    }
    dispatch(uploadImageForToolbarDefaultItem({ tool, image }))
      .then((a: any) => {
        if (a.error) {
          notifyError(a.error.message);
          return;
        }
        notifyUploadSuccess();
      })
      .catch(() => {
        notifyDeleteError();
      })
      .finally(() => {
        if (tool === "stairs") {
          setLoading((prev) => ({ ...prev, stairsUploadLoading: false }));
        }
        if (tool === "door") {
          setLoading((prev) => ({ ...prev, doorUploadLoading: false }));
        }
        e.target.value = null as any;
      });
  };

  const triggerPhotoSelector = (photoType: PhotoType) => {
    if (!imageInputRef.current || !formikRef.current) return;
    photoFor.current = photoType;
    imageInputRef.current.click();
  };

  const handleSubmit = (values: ToolbarForm) => {
    if (!uploadedPhotoForForm) {
      notifyNoImageUploaded();
      return;
    }

    const itemData: ToolbarItemData = {
      image: uploadedPhotoForForm,
      category: values.category,
      type: values.type === "Design Item" ? "custom" : "desk",
      size: { height: parseInt(values.height), width: parseInt(values.width) },
      label: values.label,
      popupInfo: values.popupInfo,
    };

    setLoading((prev) => ({ ...prev, submitLoading: true }));
    dispatch(currentlyEditingItemID ? updateToolbarItem({ id: currentlyEditingItemID, data: itemData }) : addItemToToolbar(itemData))
      .then((a: any) => {
        if (a.error) {
          notifyError(a.payload.response.data.message);
          return;
        }
        if (!formikRef.current) return;
        notifyUploadSuccess();
        setUploadedPhotoForForm(null);
        formikRef.current.resetForm();
        setCurrentlyEditingItemID(null);
      })
      .catch(notifyUploadError)
      .finally(() => {
        setLoading((prev) => ({ ...prev, submitLoading: false }));
      });
  };

  const itemDeleteHandler = (itemID: string) => {
    setLoadingItemIDs((prev) => [...prev, itemID]);
    dispatch(deleteItemFromToolbar(itemID))
      .then((a: any) => {
        if (a.error) {
          notifyDeleteError();
          return;
        }
        dispatch(getToolbarData());
        notifyDeleteSuccess();
      })
      .catch((e: any) => {
        notifyDeleteError();
      })
      .finally(() => {
        setLoadingItemIDs((prev) => prev.filter((id) => id !== itemID));
      });
  };

  const deleteDefaultToolImageHandler = (tool: "stairs" | "door") => {
    if (tool === "stairs") {
      setLoading((prev) => ({ ...prev, stairsDeleteLoading: true }));
    }
    if (tool === "door") {
      setLoading((prev) => ({ ...prev, doorDeleteLoading: true }));
    }
    dispatch(deleteDefaultItemImage(tool))
      .then((a: any) => {
        if (a.error) {
          notifyDeleteError();
          return;
        }
        dispatch(getToolbarData());
        notifyDeleteSuccess();
      })
      .catch((e: any) => {
        notifyDeleteError();
      })
      .finally(() => {
        if (tool === "stairs") {
          setLoading((prev) => ({ ...prev, stairsDeleteLoading: false }));
        }
        if (tool === "door") {
          setLoading((prev) => ({ ...prev, doorDeleteLoading: false }));
        }
      });
  };

  const handleItemEdit = (data: IToolbarItem) => {
    if (!formikRef.current) return;
    const capitalizedType = `${data.type.charAt(0).toUpperCase() + data.type.slice(1)}`;
    formikRef.current.setValues({
      category: data.category,
      height: data.size.height.toString(),
      width: data.size.width.toString(),
      type: capitalizedType === "Desk" ? "Booking Item" : "Design Item",
      label: data.label,
      popupInfo: data.popupInfo,
    });
    setCurrentlyEditingItemID(data.id);
  };

  const handleEditItemCancel = () => {
    if (!formikRef.current) return;
    setCurrentlyEditingItemID(null);
    formikRef.current.resetForm();
    setUploadedPhotoForForm(null);
  };

  if (!toolbarItems) {
    return <></>;
  }

  return (
    <div className="toolbar-manager-container">
      <input accept="image/png, image/jpeg" className="hidden" ref={imageInputRef} alt="door-image-input" type="file" onChange={handleImageUpload} />
      <Navbar />
      <div className="wrapper">
        <div className="surfaces-container">
          <div className="left-surfaces-wrapper">
            <Paper className="upload-photo-buttons-surface" elevation={5}>
              <div className="upload-image-button-container">
                <LoadingButton
                  loading={loading.stairsUploadLoading}
                  variant="outlined"
                  className="form-button"
                  onClick={triggerPhotoSelector.bind(null, "stairs")}
                >
                  Upload Stairs Image
                </LoadingButton>
                <div className="upload-image-button-lower-container">
                  <div>Currently uploaded: {!toolbarItems.images.stairs && "no image."}</div>
                  {toolbarItems.images.stairs && (
                    <OverlayImage
                      imageSrc={toolbarItems.images.stairs}
                      loading={loading.stairsDeleteLoading}
                      hoverDisabled={toolbarItems.images.stairs === DEFAULT_STAIRS_IMAGE_SRC}
                      onImageClick={deleteDefaultToolImageHandler.bind(null, "stairs")}
                    />
                  )}
                </div>
              </div>
              <div className="upload-image-button-container">
                <LoadingButton
                  loading={loading.doorUploadLoading}
                  variant="outlined"
                  className="form-button"
                  onClick={triggerPhotoSelector.bind(null, "door")}
                >
                  Upload Door Image
                </LoadingButton>
                <div className="upload-image-button-lower-container">
                  <div>Currently uploaded: {!toolbarItems.images.door && "no image."}</div>
                  {toolbarItems.images.door && (
                    <OverlayImage
                      imageSrc={toolbarItems.images.door}
                      loading={loading.doorDeleteLoading}
                      onImageClick={deleteDefaultToolImageHandler.bind(null, "door")}
                      hoverDisabled={toolbarItems.images.door === DEFAULT_DOOR_IMAGE_SRC}
                    />
                  )}
                </div>
              </div>
            </Paper>
            <Paper className="toolbar-added-items-surface" elevation={5}>
              {toolbarItems.items.map((item) => (
                <ToolbarManagerListItem
                  editing={currentlyEditingItemID ? currentlyEditingItemID === item.id : false}
                  cancelEditCallback={handleEditItemCancel}
                  editCallback={handleItemEdit}
                  key={item.id}
                  item={item}
                  loading={loadingItemIDs.includes(item.id)}
                  deleteCallback={itemDeleteHandler}
                />
              ))}
            </Paper>
          </div>
          <Paper elevation={5} className="form-surface">
            <div className="form-wrapper">
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchemaToolbarForm}
                innerRef={formikRef}
                initialValues={initialFormValues}
                onSubmit={handleSubmit}
              >
                {({ errors, handleChange, handleSubmit, values }) => (
                  <>
                    <TextField
                      value={values.type || ""}
                      helperText={errors.type || " "}
                      onChange={handleChange("type")}
                      error={!!errors.type}
                      select
                      required
                      label="Type"
                      defaultValue={""}
                    >
                      {dropdownTypeValues.map((option) => (
                        <MenuItem key={option.key} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                    <div className="size-dropdowns-container">
                      <TextField
                        value={values.width}
                        required
                        className="size-dropdown"
                        onChange={handleChange("width")}
                        select
                        label="Width"
                        defaultValue={dropdownSizeValues[0]}
                      >
                        {dropdownSizeValues.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        value={values.height}
                        required
                        className="size-dropdown"
                        onChange={handleChange("height")}
                        select
                        label="Height"
                        defaultValue={dropdownSizeValues[0]}
                      >
                        {dropdownSizeValues.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <Autocomplete
                      value={values.category}
                      freeSolo
                      disablePortal
                      onChange={(_, v) => handleChange("category")(v || "")}
                      options={[...toolbarCategories]}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={errors.category || null}
                          required
                          value={values.category || ""}
                          onChange={handleChange("category")}
                          label="Category"
                          variant="outlined"
                          error={!!errors.category}
                        />
                      )}
                    />

                    <Button onClick={triggerPhotoSelector.bind(null, "form")} variant="outlined" className="form-button">
                      {uploadedPhotoForForm ? uploadedPhotoForForm.name : `Choose Image ${!currentlyEditingItemID ? "*" : ""}`}
                    </Button>
                    <TextField
                      value={values.label}
                      onChange={handleChange("label")}
                      helperText={errors.label || ""}
                      required
                      label="Label"
                      variant="outlined"
                      error={!!errors.label}
                    />
                    <TextField
                      value={values.popupInfo}
                      onChange={handleChange("popupInfo")}
                      helperText={errors.popupInfo || ""}
                      label="Info"
                      variant="outlined"
                      error={!!errors.popupInfo}
                    />
                    <LoadingButton loading={loading.submitLoading} variant="outlined" className="submit-button" onClick={() => handleSubmit()}>
                      {currentlyEditingItemID ? "Update" : "Upload To Toolbar"}
                    </LoadingButton>
                  </>
                )}
              </Formik>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}
