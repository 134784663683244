function ErrorNotification(props: any) {
  const { message, onClose } = props;

  return (
    message && (
      <div className="error-notification">
        {typeof message === "string" ? message : message.message}
        <div className="close-error" onClick={onClose}>
          X
        </div>
      </div>
    )
  );
}

export default ErrorNotification;
