import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { isUndefined } from "lodash";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../pages/Navbar/navbar";
import { currentDateSelector, displayAdminModeSelector } from "../../slices/Canvas/canvasSlice";
import { assignmentsSelector, deleteAssignmentData, deleteAssignmentForUserData, getAssignmentByDate } from "../../slices/Entities/assignmentSlice";
import { floorDesksOptionsSelector, selectedDesksSelector, selectedDesksState } from "../../slices/Entities/deskSlice";
import { teamMembersSelector } from "../../slices/Entities/teamSlice";
import { useAuth } from "../../utils/AuthHook";
import Copyright from "../Copyright/Copyright";
import DeleteAlertDialog from "../DeleteAlertDialog/deleteAlertDialog";
import ErrorNotification from "../ErrorNotification/errorNotification";

const DesksList = (props: any) => {
  const [desksListWithReservations, setDesksListWithReservations] = useState<any>([]);
  const [notReserved, setNotReserved] = useState<any>([]);
  const [userAssignments, setUserAssignments] = useState<any>([]);
  const [showDeleteAssignmentDialog, setShowDeleteAssignmentDialog] = useState<boolean>(false);
  const [deleteAssignment, setDeleteAssignment] = useState<any>();
  const [itemDeleted, setItemDeleted] = useState<boolean>(false);
  const [selectedDesksType, setSelectedDesksType] = useState<string>("free");
  const dispatch = useDispatch<any>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [selectedDeskId, setSelectedDeskId] = useState<any>(null);

  const selectedDate = useSelector(currentDateSelector);
  const deskList = useSelector(floorDesksOptionsSelector);
  const assignmentList = useSelector(assignmentsSelector);
  const selectedDesksOnCanvas = useSelector(selectedDesksSelector);
  const teamMembersNumber = useSelector(teamMembersSelector);
  const isAdminMode = useSelector(displayAdminModeSelector);
  const date = selectedDate.toLocal();
  let timeZoneOffset = date.toJSDate().getTimezoneOffset() * 60000;
  let localISOTime = date.toJSDate().getTime() - timeZoneOffset;
  let stringDate = new Date(localISOTime).toISOString();

  const { user, check } = useAuth();

  const getDeleteDialogData = (assignment: any) => {
    setDeleteAssignment(assignment);
    toggleShowDeleteDialog();
  };
  const toggleShowDeleteDialog = () => {
    setShowDeleteAssignmentDialog(!showDeleteAssignmentDialog);
  };

  const handleDeleteAssignment = async () => {
    if (deleteAssignment.employee?.id === user.id || (deleteAssignment.reservedBy && deleteAssignment.reservedBy.id === user.id)) {
      await dispatch(deleteAssignmentData(deleteAssignment._id)).then((result: any) => {
        if (result.error) {
          setErrorMessage(result.payload.response.data);
        }
      });
    } else {
      await dispatch(deleteAssignmentForUserData({ assignmentId: deleteAssignment._id, userId: deleteAssignment.employee?.id })).then(
        (result: any) => {
          if (result.error) {
            setErrorMessage(result.payload.response.data);
          }
        }
      );
    }

    setItemDeleted(!itemDeleted);
    toggleShowDeleteDialog();
  };

  const assignHours = (obj: any) => {
    return DateTime.fromISO(obj).toFormat("HH:mm");
  };

  //update list when calendar date changes
  useEffect(() => {
    dispatch(getAssignmentByDate(stringDate)).then((result: any) => {
      if (result.error) {
        setErrorMessage(result.payload.response.data);
      } else {
        const assignemntList = result.payload;
        const floorAssignemntList = assignemntList.filter((obj: any) => deskList.map((desk: any) => desk?._id).includes(obj.desk?._id));

        const noReservation = deskList.filter(
          (desk: any) =>
            // a desk is not free if it has more than one reservation for a day or
            // if it has a reservation longer than 4 hours
            floorAssignemntList.filter((assignment: any) => desk.id === assignment.desk.id).length < 2 &&
            !floorAssignemntList.some(
              (assignment: any) =>
                desk.id === assignment.desk.id && new Date(assignment.endTime).getHours() - new Date(assignment.startTime).getHours() > 4
            )
        );
        setNotReserved(noReservation);
        const users = floorAssignemntList.filter((obj: any) => obj.employee?._id !== user._id);
        setDesksListWithReservations(users);
        const myAssignments = floorAssignemntList.filter((obj: any) => obj.employee?._id === user._id);
        setUserAssignments(myAssignments);
        const noReservationIds = noReservation.map((desk: any) => desk.id);
        dispatch(selectedDesksState(selectedDesksOnCanvas.filter((desk: any) => noReservationIds.includes(desk.id))));
      }
    });
  }, [stringDate, deskList, itemDeleted, selectedDesksType]);

  useEffect(() => {
    setSelectedDeskId(selectedDesksOnCanvas.map((desk: any) => desk.id));
  }, [selectedDesksOnCanvas]);

  useEffect(() => {
    const floorAssignemntList = assignmentList.filter(
      (obj: any) => deskList.map((desk: any) => desk._id).includes(obj.desk._id) && obj.startTime.substring(0, 10) === selectedDate.toISODate()
    );

    const noReservation = deskList.filter(
      (desk: any) =>
        // a desk is not free if it has more than one reservation for a day or
        // if it has a reservation longer than 4 hours
        floorAssignemntList.filter((assignment: any) => desk.id === assignment.desk.id).length < 2 &&
        !floorAssignemntList.some(
          (assignment: any) =>
            desk.id === assignment.desk.id && new Date(assignment.endTime).getHours() - new Date(assignment.startTime).getHours() > 4
        )
    );

    setNotReserved(noReservation);

    const users = floorAssignemntList.filter((obj: any) => obj.employee?._id !== user._id);
    setDesksListWithReservations(users);

    const myAssignments = floorAssignemntList.filter((obj: any) => obj.employee?._id === user._id);
    setUserAssignments(myAssignments);
  }, [deskList, assignmentList]);

  const assignedDesks = desksListWithReservations.map((obj: any) => {
    return (
      <div className={!selectedDesksType ? "assigned-container" : "hide"} key={obj.id}>
        <div className="reserved-container">
          <div className="label">{obj?.desk?.label}</div>
          <div className="reserved-hours">
            {" "}
            {`${DateTime.fromISO(obj.startTime).toFormat("HH:mm")}-${DateTime.fromISO(obj.endTime).toFormat("HH:mm")}`}
            {check("assignment.others.delete") || user.role === "Manager" ? (
              <div>
                <DeleteOutlinedIcon
                  className="delete-button mydesk"
                  onClick={() => {
                    getDeleteDialogData(obj);
                  }}
                />
              </div>
            ) : check("assignment.delete") && obj.reservedBy && obj.reservedBy.id === user.id ? (
              <div>
                <DeleteOutlinedIcon
                  className="delete-button mydesk"
                  onClick={() => {
                    getDeleteDialogData(obj);
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {obj.team ? (
          <div className="reservee">For team: {obj.team.name}</div>
        ) : obj.label ? (
          <div className="reservee">For: {obj.label}</div>
        ) : (
          <div className="reservee">
            {obj.employee?.firstName || "-"} {obj.employee?.lastName || ""}
          </div>
        )}
      </div>
    );
  });

  const updateState = (e: any, desk: any) => {
    if (selectedDeskId.includes(desk.id)) {
      let deleteFromArray = [...selectedDesksOnCanvas];
      let index = deleteFromArray.findIndex((selectedDesk: any) => selectedDesk.id === desk.id);
      deleteFromArray.splice(index, 1);

      dispatch(selectedDesksState(deleteFromArray));
      index = selectedDeskId.findIndex((deskId: any) => deskId === desk.id);
      selectedDeskId.splice(index, 1);
      setSelectedDeskId(selectedDeskId);
      return;
    }
    if (teamMembersNumber > 0 && selectedDesksOnCanvas.length === teamMembersNumber) {
      return;
    }
    if (teamMembersNumber === 0) {
      dispatch(selectedDesksState([desk]));
      setSelectedDeskId([desk.id]);
      return;
    }
    if (teamMembersNumber > 0 && selectedDesksOnCanvas.length < teamMembersNumber) {
      let addToArray = [...selectedDesksOnCanvas];
      addToArray.push(desk);
      dispatch(selectedDesksState(addToArray));
      selectedDeskId.push(desk.id);
      setSelectedDeskId(selectedDeskId);
    }
  };

  const freeDesks = notReserved?.map((desk: any) => {
    let freeIntervalsList: any;
    freeIntervalsList = userAssignments.filter((assign: any) => assign.desk.id === desk.id);
    freeIntervalsList.push(...desksListWithReservations.filter((assign: any) => assign.desk.id === desk.id));
    return (
      <div
        className={[!selectedDesksType ? "assignment-free-desks " : "hide desk-row", selectedDeskId.includes(desk.id) ? "free-item" : ""]
          .filter((e) => !!e)
          .join(" ")}
        key={desk.id}
        onClick={(e) => {
          updateState(e, desk);
        }}
      >
        <div className="free-item-container">
          <div className={["label", selectedDeskId.includes(desk.id) ? "free-item-text" : ""].filter((e) => !!e).join(" ")}>{desk.label} </div>
          <div className={["free-hours", selectedDeskId.includes(desk.id) ? "free-item-text" : ""].filter((e) => !!e).join(" ")}>
            {freeIntervalsList.length ? (
              //dont't show the free intervals smaller than 4 hours
              freeIntervalsList.map((filteredAssignment: any) => (
                <span>
                  {new Date(filteredAssignment.startTime).getHours() - 8 > 4 ? <p>08:00 - {assignHours(filteredAssignment.startTime)}</p> : null}
                  {20 - new Date(filteredAssignment.endTime).getHours() > 4 ? <p>{assignHours(filteredAssignment.endTime)} - 20:00</p> : null}
                </span>
              ))
            ) : (
              <>08:00 - 20:00</>
            )}
          </div>
        </div>
      </div>
    );
  });

  const myDesks = userAssignments?.map((assig: any) => {
    return (
      <div className={!selectedDesksType ? "assignment" : "hide grid"} key={assig._id}>
        <div className="label">{assig.desk.label}</div>
        <div>
          <div className="reserved-hours"> {`${assignHours(assig.startTime)}-${assignHours(assig.endTime)}`}</div>
          {!isAdminMode ? (
            <DeleteOutlinedIcon
              className="delete-button mydesk"
              onClick={() => {
                getDeleteDialogData(assig);
              }}
            />
          ) : (
            <></>
          )}
        </div>

        {assig.reservedBy && assig.reservedBy.id === user.id ? (
          <div className="made-by">Reservation made by {`${assig.reservedBy.firstName}  ${assig.reservedBy.lastName}`}</div>
        ) : (
          <></>
        )}
        {assig.team ? <div className="reservee">For team: {assig.team.name}</div> : <></>}
        {assig.label ? <div className="reservee">For: {assig.label}</div> : <></>}
      </div>
    );
  });

  const handleClickAssigned = () => {
    setSelectedDesksType("");
    setSelectedDesksType("assigned");
  };
  const handleClickFree = () => {
    setSelectedDesksType("");
    setSelectedDesksType("free");
  };
  const handleClickMy = () => {
    setSelectedDesksType("");
    setSelectedDesksType("my");
  };

  return (
    <>
      <div className="dashboard-container">
        <Navbar />
        <div className="list-with-right-component-container">
          <div className="list-container">
            {window.innerWidth > 700 ? (
              <div className="desk">
                {check("assignment.read") ? (
                  <div className="desk-reservation">
                    <h2 className="assignment-free-desks">Assigned Desks</h2>
                    {assignedDesks}
                  </div>
                ) : (
                  <></>
                )}
                <div>
                  <h2>Free Desks</h2>
                  {freeDesks}
                </div>
                <div>
                  <h2>My Desks</h2>
                  {myDesks}
                </div>
              </div>
            ) : (
              <div className="mobile-container">
                <div className="mobile-headers">
                  <h2 className={selectedDesksType === "assigned" ? "font-bold" : "font-normal"} onClick={handleClickAssigned}>
                    Assigned
                  </h2>
                  <h2 className={selectedDesksType === "free" ? "font-bold" : "font-normal"} onClick={handleClickFree}>
                    Free{" "}
                  </h2>
                  <h2 className={selectedDesksType === "my" ? "font-bold" : "font-normal"} onClick={handleClickMy}>
                    My{" "}
                  </h2>
                </div>
                <div className="mobile-content">
                  {selectedDesksType === "assigned" ? assignedDesks : selectedDesksType === "free" ? freeDesks : myDesks}
                </div>
              </div>
            )}
            {deleteAssignment && (
              <DeleteAlertDialog
                type={"assignment"}
                value={`assignment for desk ${deleteAssignment.desk.label}`}
                open={showDeleteAssignmentDialog}
                handleOpen={toggleShowDeleteDialog}
                handleClose={toggleShowDeleteDialog}
                handleDelete={handleDeleteAssignment}
              />
            )}
          </div>
        </div>
      </div>
      {!isUndefined(errorMessage) && <ErrorNotification message={errorMessage} onClose={setErrorMessage} />}
      <div className="copyright-wrapper">
        <Copyright />
      </div>
    </>
  );
};
export default DesksList;
