import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { ErrorMessage, Field } from "formik";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LayersIcon from "@mui/icons-material/Layers";
import { useDispatch, useSelector } from "react-redux";
import { allBuildingsSelector } from "../../slices/Entities/buildingSlice";
import { useEffect } from "react";
import { floorsSelector, getFloorsFromBuilding } from "../../slices/Entities/floorSlice";

export default function DefaultBuildingFloor(props: any) {
  const { settings, setSettings, setFloors, floors } = props;

  const buildings = useSelector(allBuildingsSelector);
  const allFloors = useSelector(floorsSelector);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (settings.building) {
      const buildingFloors = allFloors.filter((floor: any) => floor.building === settings.building);
      if (buildingFloors && buildingFloors.length > 0) {
        setFloors(buildingFloors);
      }
    }
  }, [allFloors]);

  const handleChangeBuilding = (event: any) => {
    const settingsCopy = { ...settings };
    settingsCopy.building = event.target.value;
    settingsCopy.floor = "";
    setSettings(settingsCopy);
    dispatch(getFloorsFromBuilding(settingsCopy.building)).then((result: any) => {
      if (!result.error) {
        setFloors(result.payload);
      }
    });
  };

  const handleChangeFloor = (event: any) => {
    const settingsCopy = { ...settings };
    settingsCopy.floor = event.target.value;
    setSettings(settingsCopy);
  };

  return (
    <div className="profile-form-container settings-form">
      <div>
        <Field name="building">
          {({ field, meta: { touched, error } }: any) => (
            <TextField
              id="select-textfield"
              className={touched && error ? "invalid" : ""}
              label="DEFAULT BUILDING"
              select
              defaultValue={""}
              {...field}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ApartmentIcon className="start-adornment-icon"></ApartmentIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleChangeBuilding}
            >
              {buildings.map((building: any) => (
                <MenuItem value={building.id} key={building.id}>
                  {building.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Field>
        <ErrorMessage name="building" render={(msg) => <span className="error-msg">{msg}</span>} />
      </div>

      {settings.building !== "" ? (
        <div>
          <Field name="floor">
            {({ field, meta: { touched, error } }: any) => (
              <TextField
                id="select-textfield"
                className={touched && error ? "invalid" : ""}
                label="DEFAULT FLOOR"
                select
                defaultValue={""}
                {...field}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LayersIcon className="start-adornment-icon"></LayersIcon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleChangeFloor}
              >
                {floors.map((floor: any) => (
                  <MenuItem value={floor.id} key={floor.id}>
                    {floor.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Field>
          <ErrorMessage name="floor" render={(msg) => <span className="error-msg">{msg}</span>} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
