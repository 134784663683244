import client from "./axios.service";

const baseUrl = "/teams";

export type teamMember = {
  id: string;
  firstName: string;
  lastName: string;
  avatar: string;
  username: string;
  email: string;
};

export type Team = {
  id: string;
  name: string;
  provider: string;
  providerId: string;
  manager: teamMember;
  members: teamMember[];
};

export const getAllTeams = () => {
  const requestPath = `${baseUrl}`;

  return client.get(requestPath, null);
};

export const addTeam = (name: string, manager: string, members: string[], company: string) => {
  const requestPath = `${baseUrl}`;

  return client.post(requestPath, { name, manager, members, company }, {});
};

export const updateTeam = (id: string, name: string, manager: teamMember, members: teamMember[], company: string) => {
  const requestPath = `${baseUrl}/${id}`;

  return client.put(requestPath, { name, manager, members, company }, {});
};

export const refreshTeamFromWayman = (id: string) => {
  const requestPath = `${baseUrl}/sync/${id}`;

  return client.post(requestPath, {}, {});
};

export const deleteTeam = (id: string | undefined) => {
  const requestPath = `${baseUrl}/${id}`;

  return client.delete(requestPath, {});
};
