import Tooltip from "@mui/material/Tooltip";
import { GRID } from "../../utils/variables";
import { ISize } from "../interfaces";

import "./ToolbarObject.scss";

export interface IToolbarObjectProps {
  imageSrc?: string;
  label: string;
  size: ISize;
  isInToolbar: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  tooltip?: string;
  selected?: boolean;
}

const convertPxToToolbarSize = (size: ISize): ISize => ({
  height: size.height * GRID,
  width: size.width * GRID,
});

export default function ToolbarObject(props: IToolbarObjectProps): JSX.Element {
  return (
    <Tooltip title={props.tooltip || ""}>
      <div
        style={props.isInToolbar ? undefined : convertPxToToolbarSize(props.size || { width: 1, height: 1 })}
        onClick={props.onClick}
        className={`object-container${!props.isInToolbar ? " canvas-object" : ""}${props.selected ? " selected" : ""}`}
      >
        {props.imageSrc && <img crossOrigin="" className="object-image" alt="object-toolbar" src={props.imageSrc} />}
        {props.isInToolbar && (
          <>
            <div className="object-label">{props.label}</div>
            <div className="object-size-text">{`${props?.size?.width || GRID}x${props?.size?.height || GRID}`}</div>
          </>
        )}
      </div>
    </Tooltip>
  );
}
