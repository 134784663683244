import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import { DEFAULT_DOOR_IMAGE_SRC, DEFAULT_STAIRS_IMAGE_SRC, getDefaultImageSrc } from "../../pages/ToolbarManager/variables";
import {
  addImageToDefaultToolbarItem,
  addToolbarItem,
  deleteDefaultImage,
  deleteToolbarItem,
  getToolbarItems,
  ToolbarData,
  ToolbarItemData,
  updateToolbar,
  uploadImageForToolbarData,
} from "../../services/toolbarService";
import { Status } from "../StatusEnum";

type ToolbarSliceType = {
  status: Status;
  error: SerializedError | null;
  toolbarItems: ToolbarData | null;
};

const initialState: ToolbarSliceType = {
  status: Status.Idle,
  error: null,
  toolbarItems: null,
};

export const getToolbarData = createAsyncThunk(
  "toolbarItems/getToolbarItems",
  async (_, thunkApi) => {
    try {
      const response = await getToolbarItems();
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
  {
    condition: (_, store: any) => {
      const { toolbarItems } = store.getState();
      const fetchStatus = toolbarItems.status;
      if (fetchStatus === Status.Loading) {
        return false;
      }
    },
  }
);

export const addItemToToolbar = createAsyncThunk("toolbarItems/addToolbarItem", async (itemData: ToolbarItemData, thunkApi) => {
  try {
    const response = await addToolbarItem(itemData);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const updateToolbarItem = createAsyncThunk(
  "toolbarItems/updateToolbarItem",
  async (itemData: { id: string; data: ToolbarItemData }, thunkApi) => {
    try {
      const response = await updateToolbar(itemData);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const uploadImageForToolbarDefaultItem = createAsyncThunk(
  "toolbarItems/addImageToDefaultToolbarItem",
  async (data: uploadImageForToolbarData, thunkApi) => {
    try {
      const response = await addImageToDefaultToolbarItem(data);
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const deleteItemFromToolbar = createAsyncThunk("toolbarItems/deleteToolbarItem", async (itemID: string, thunkApi) => {
  try {
    const response = await deleteToolbarItem(itemID);
    if (response.status === 204) {
      return itemID;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const deleteDefaultItemImage = createAsyncThunk("toolbarItems/deleteDefaultImage", async (tool: "stairs" | "door", thunkApi) => {
  try {
    const response = await deleteDefaultImage(tool);
    if (response.status === 204) {
      return tool;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

const toolbarSlice = createSlice({
  name: "toolbarItems",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getToolbarData.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.toolbarItems = {
            ...payload,
            images: {
              door: payload.images?.door || DEFAULT_DOOR_IMAGE_SRC,
              stairs: payload.images?.stairs || DEFAULT_STAIRS_IMAGE_SRC,
            },
          };
        }
      })
      .addCase(getToolbarData.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getToolbarData.rejected, (state, action) => {
        state.status = Status.Failed;
        state.toolbarItems = null;
        state.error = action.error;
      })
      .addCase(addItemToToolbar.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.toolbarItems = { ...state.toolbarItems, items: [...state.toolbarItems.items, payload] };
        }
      })
      .addCase(addItemToToolbar.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(addItemToToolbar.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(uploadImageForToolbarDefaultItem.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          const newImage = { [payload.label]: payload.imageSrc };
          state.toolbarItems = { ...state.toolbarItems, images: { ...state.toolbarItems.images, ...newImage } };
        }
      })
      .addCase(uploadImageForToolbarDefaultItem.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(uploadImageForToolbarDefaultItem.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(deleteItemFromToolbar.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        state.toolbarItems.items = state.toolbarItems.items.filter((item: any) => item.id !== payload);
        if (payload) {
          state.status = Status.Succeeded;
        }
      })
      .addCase(deleteItemFromToolbar.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(deleteItemFromToolbar.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(deleteDefaultItemImage.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.toolbarItems.images[payload] = getDefaultImageSrc(payload);
          state.status = Status.Succeeded;
        }
      })
      .addCase(deleteDefaultItemImage.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(deleteDefaultItemImage.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(updateToolbarItem.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          const newItems = state.toolbarItems.items.filter((item: any) => item.id !== payload.id);
          state.toolbarItems = { ...state.toolbarItems, items: [...newItems, payload] };
        }
      })
      .addCase(updateToolbarItem.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(updateToolbarItem.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      });
  },
  reducers: {},
});

const { reducer } = toolbarSlice;

export default reducer;

export const toolbarItemsSelector = (state: any) => state.toolbarItems.toolbarItems as ToolbarData | null;
export const toolbarCategoriesSelector = (state: { toolbarItems: { toolbarItems: ToolbarData | null } }) =>
  new Set(state.toolbarItems.toolbarItems ? state.toolbarItems.toolbarItems.items.map((obj) => obj.category) : []);
