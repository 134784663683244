type BookDeskProps = {
  disabled: boolean;
  onClick: any;
};

function BookDesk(props: BookDeskProps) {
  const { disabled, onClick } = props;

  return (
    <div className="book-btn-container">
      <button data-testid="desk booking button" type="submit" className="book" disabled={disabled} onClick={onClick}>
        BOOK DESK{" "}
      </button>
    </div>
  );
}

export default BookDesk;
