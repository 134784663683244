import { createAsyncThunk, createEntityAdapter, createSlice, SerializedError } from "@reduxjs/toolkit";
import { addCustomElement, deleteCustomElement, getCustomSeatingPointById, getCustomSeatingPoints } from "../../services/customElementsService";
import { Status } from "../StatusEnum";

type InitialStateType = {
  status: Status;
  error: SerializedError | null;
  elementName: string;
  elementsArray: any;
};

const customElementAdapter = createEntityAdapter({
  selectId: (element: any) => element._id,
  sortComparer: false,
});

const initialState: InitialStateType = customElementAdapter.getInitialState({
  status: Status.Idle,
  error: null,
  selectedElement: "",
  elementName: "",
  elementsArray: [],
});
export const getAllCustomSeatingPoint = createAsyncThunk("customSeatingPoints/getAllSeatingPointd", async (_, thunkApi) => {
  try {
    const response = await getCustomSeatingPoints();
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const getCustomSeatingPoint = createAsyncThunk("customSeatingPoints/getSeatingPoint", async (elementId: string, thunkApi) => {
  try {
    const response = await getCustomSeatingPointById(elementId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const addingCustomSeatingPoint = createAsyncThunk(
  "customSeatingPoints/addCustomElement",
  async ({ name, imageURL }: { name: string; imageURL: any }, thunkApi) => {
    try {
      const response = await addCustomElement(name, imageURL);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const deleteCustomElementData = createAsyncThunk("customSeatingPoints/delemeElement", async (customElementId: string, thunkApi) => {
  try {
    const response = await deleteCustomElement(customElementId);

    if (response.status === 200 || response.status === 201) {
      return { message: response.data, id: customElementId };
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

const customElementSlice = createSlice({
  name: "customElements",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomSeatingPoint.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.elementsArray = payload;
          customElementAdapter.setMany(state, payload);
        }
      })
      .addCase(getAllCustomSeatingPoint.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(getAllCustomSeatingPoint.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })

      .addCase(addingCustomSeatingPoint.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          customElementAdapter.addOne(state, payload);
        }
      })

      .addCase(addingCustomSeatingPoint.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })

      .addCase(deleteCustomElementData.fulfilled, (state: any, action: any) => {
        state.status = Status.Succeeded;
        customElementAdapter.removeOne(state, action.meta.arg.floorId);
        state.selectedFloor = null;
      })
      .addCase(deleteCustomElementData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(deleteCustomElementData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      });
  },
  reducers: {},
});

const { reducer } = customElementSlice;

export default reducer;

export const customElementSelectors = customElementAdapter.getSelectors((state: any) => state.customElements);

export const customElementsSelector = (state: any) => state.customElements.elementsArray;
