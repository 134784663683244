import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";

function GenericModal(props: any) {
  const { renderModalContent, title, hasFooter, buttonAction, handleClose, initialValues, hasForm, validationSchema, renderButton, modalClass } =
    props;

  return (
    <div className={`generic-modal ${modalClass || ""}`.trim()}>
      <div className="content-modal">
        <div className="header-modal">
          <span className="title-modal">{title}</span>
          <IconButton className="icon-button-modal" disableRipple onClick={handleClose}>
            <CloseIcon id="icon-modal" />
          </IconButton>
        </div>
        <Formik enableReinitialize={true} onSubmit={buttonAction} initialValues={initialValues} validationSchema={validationSchema}>
          {hasForm ? (
            <Form className="form-container-modal">
              <div className="main-modal">{renderModalContent()}</div>
              {hasFooter ? <div className="footer-modal">{renderButton()}</div> : ""}
            </Form>
          ) : (
            <div className="form-container-modal">
              <div className="main-modal">{renderModalContent()}</div>
              {hasFooter ? <div className="footer-modal">{renderButton()}</div> : ""}
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default GenericModal;
