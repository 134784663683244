import { convertFromRaw, EditorState, RawDraftContentState } from "draft-js";
import { CanvasObject } from "../components/FloorObject/types";
import { DefaultToolbarAction, DefaultToolbarTool } from "../components/ObjectsToolbar/types";
import { IntervalTime } from "../components/Popup/types";
import { ObjectType } from "../slices/Entities/deskSlice";
import { CanvasMode, IDesign } from "./../components/Canvas/types";
import { ICoordinate } from "./../components/interfaces";
import { GRID } from "./variables";

export const convertFromGridToPx = (position: ICoordinate) => {
  //* example: {x:5,y:10} --> {x:150, y:300} for GRID = 30
  return { x: Math.round(position.x * GRID), y: Math.round(position.y * GRID) };
};

export const convertFromPxToGrid = (position: ICoordinate) => {
  //* The inverse of convertFromPxToGrid
  return { x: Math.round(position.x / GRID), y: Math.round(position.y / GRID) };
};

//* convertFromRaw throws error if entityMap map is undefined
export const contentStateToEditorState = (editorContent: RawDraftContentState) => {
  return EditorState.createWithContent(convertFromRaw({ ...editorContent, entityMap: {} }));
};

export const convertLuxonDateToISOString = (date: any) => {
  //! Add type to 'date' parameter
  const isoString = date.toISODate().toString();
  return isoString;
};

export const getIntervalHours = (startDate: string, endDate: string) => {
  const difference = new Date(endDate).getTime() - new Date(startDate).getTime();
  const hours = difference / (1000 * 60 * 60);
  return Math.abs(hours);
};

export const getLayoutWidth = (rooms: IDesign[], objects: CanvasObject[]): number => {
  let width = window.screen.width;
  rooms.forEach((room) => {
    room.boundaries.forEach((boundary) => {
      const x = boundary.x * GRID;
      if (x > width) {
        width = x;
      }
    });
  });
  objects.forEach((object) => {
    const x = (object.position.x + object.size.width) * GRID;
    if (x > width) {
      width = x;
    }
  });
  const rightMargin = 5 * GRID;
  return width + rightMargin;
};

export const getDeskLabelNumber = (desks: CanvasObject[]) => {
  const usedNumbers: number[] = [];
  desks.forEach((desk) => {
    //? desk label pattern: "building_{floor}_{number}"
    const number = parseInt(desk.label.match(/(\d+)\D*$/g)?.[0] || "");
    usedNumbers.push(number);
  });
  usedNumbers.sort();
  let numberToUse = null;
  usedNumbers.forEach((usedNumber, index) => {
    if (usedNumber !== index + 1) {
      numberToUse = index + 1;
    }
  });
  !numberToUse && (numberToUse = usedNumbers.length + 1);
  return numberToUse;
};

export const getElementCursorClassName = (
  selectedDefaultTool: DefaultToolbarAction | DefaultToolbarTool | null,
  itemType: `${ObjectType}`,
  canvasMode: CanvasMode
) => {
  if (canvasMode === CanvasMode.VIEW) {
    return itemType === "desk" ? "pointer-cursor" : "default-cursor";
  }
  switch (selectedDefaultTool) {
    case "FLIP":
      if (itemType !== ObjectType.WINDOW) {
        return "flip-cursor";
      }
      return "pointer-cursor";
    case "ROTATE":
      if (itemType !== ObjectType.WINDOW) {
        return "rotate-cursor";
      }
      return "pointer-cursor";
    default:
      return "pointer-cursor";
  }
};

export const getInterval = (intervals: IntervalTime[]): string | null => {
  let intervalStringArray: string[] = [];
  intervals.forEach((interval) => {
    const sh = interval.startTime.getHours(); //? start hour
    const sm = interval.startTime.getMinutes(); //? start minutes
    const eh = interval.endTime.getHours(); //? end hour
    const em = interval.endTime.getMinutes(); //? end minutes
    const startTimeString = `${sh}:${sm < 10 ? `0${sm}` : sm}`;
    const endTimeString = `${eh}:${em < 10 ? `0${em}` : em}`;
    intervalStringArray.push(`${startTimeString} - ${endTimeString}`);
  });
  return `${intervalStringArray.map((i) => i).join(", ")}`;
};
