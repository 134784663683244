import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import { getAllTeams, addTeam, deleteTeam, updateTeam, refreshTeamFromWayman } from "../../services/teamService";
import { Status } from "../StatusEnum";

type InitialStateType = {
  status: Status;
  teams: [];
  teamMembersNr: number;
  error: SerializedError | null;
};

const initialState: InitialStateType = {
  status: Status.Idle,
  teams: [],
  teamMembersNr: 0,
  error: null,
};

export const getTeams = createAsyncThunk(
  "teams/getAllTeams",
  async (_, thunkApi) => {
    try {
      const response = await getAllTeams();

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
  {
    condition: (_, store: any) => {
      const { teams } = store.getState();
      const fetchStatus = teams.status;
      if (fetchStatus === Status.Succeeded || fetchStatus === Status.Loading) {
        return false;
      }
    },
  }
);

export const addingTeam = createAsyncThunk("teams/addTeam", async ({ name, manager, members, company }: any, thunkApi) => {
  try {
    const response = await addTeam(name, manager, members, company);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const refreshTeam = createAsyncThunk("teams/refreshTeam", async (id: string, thunkApi) => {
  try {
    const response = await refreshTeamFromWayman(id);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const deleteTeamData = createAsyncThunk("teams/deleteTeam", async (id: string, thunkApi) => {
  try {
    const response = await deleteTeam(id);

    if (response.status === 200 || response.status === 201) {
      return { message: response.data, id: id };
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const updateTeamById = createAsyncThunk("teams/updateTeam", async ({ id, name, manager, members, company }: any, thunkApi) => {
  try {
    const response = await updateTeam(id, name, manager, members, company);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

const teamsSlice = createSlice({
  name: "teams",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getTeams.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.teams = payload;
        }
      })
      .addCase(getTeams.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getTeams.rejected, (state, action) => {
        state.status = Status.Failed;
        state.teams = [];
        state.error = action.error;
      })
      .addCase(addingTeam.fulfilled, (state: any, action: any) => {
        const { payload } = action;

        if (payload) {
          state.status = Status.Succeeded;
          state.teams.push(payload);
        }
      })
      .addCase(addingTeam.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(addingTeam.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(refreshTeam.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        const index = state.teams.map((team: any) => team._id).indexOf(payload.id);
        state.teams[index] = payload;
        state.status = Status.Succeeded;
      })
      .addCase(refreshTeam.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(refreshTeam.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(deleteTeamData.fulfilled, (state: any, action: any) => {
        state.status = Status.Succeeded;
        const index = state.teams.map((team: any) => team._id).indexOf(action.meta.arg);
        state.teams.splice(index, 1);
      })
      .addCase(deleteTeamData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(deleteTeamData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(updateTeamById.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        state.status = Status.Succeeded;
        if (payload) {
          const index = state.teams.map((team: any) => team._id).indexOf(payload.id);
          state.teams[index] = payload;
        } else {
          const index = state.teams.map((team: any) => team._id).indexOf(action.meta.arg);
          state.teams.splice(index, 1);
        }
      })
      .addCase(updateTeamById.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(updateTeamById.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      });
  },
  reducers: {
    teamMembers(state, action) {
      state.teamMembersNr = action.payload;
    },
  },
});

const { reducer, actions } = teamsSlice;

export default reducer;

export const { teamMembers } = actions;

export const allTeamsSelector = (state: any) => state.teams.teams;

export const teamMembersSelector = (state: any) => state.teams.teamMembersNr;
