export const saveStateToSessionStorage = (name: string, state: any) => {
  try {
    const serializedState = JSON.stringify(state);

    sessionStorage.setItem(name, serializedState);
  } catch (e) {}
};

export const loadStateFromSessionStorage = (name: string) => {
  try {
    const serializedState = sessionStorage.getItem(name);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};
