import { configureStore } from "@reduxjs/toolkit";
import { loadStateFromSessionStorage } from "./utils/Storage/StorageState";

import authReducer from "./slices/Auth/authSlice";
import canvasReducer from "./slices/Canvas/canvasSlice";
import assignmentReducer from "./slices/Entities/assignmentSlice";
import buildingsReducer from "./slices/Entities/buildingSlice";
import companiesReducer from "./slices/Entities/companiesSlice";
import configurationsReducer from "./slices/Entities/configurationsSlice";
import customElementsReducer from "./slices/Entities/customElementSlice";
import desksReducer from "./slices/Entities/deskSlice";
import floorReducer from "./slices/Entities/floorSlice";
import groupsReducer from "./slices/Entities/groupsSlice";
import permissionsReducer from "./slices/Entities/permissionsSlice";
import teamsReducer from "./slices/Entities/teamSlice";
import toolbarReducer from "./slices/Entities/toolbarSlice";
import usersReducer from "./slices/Entities/usersSlice";

const store = configureStore({
  // Note: this only works for one level of reducers.
  // If you want to nest reducers, you'll need to call combineReducers to handle the nesting.
  reducer: {
    auth: authReducer,
    buildings: buildingsReducer,
    floors: floorReducer,
    canvas: canvasReducer,
    users: usersReducer,
    assignments: assignmentReducer,
    desks: desksReducer,
    teams: teamsReducer,
    configurations: configurationsReducer,
    customElements: customElementsReducer,
    groups: groupsReducer,
    permissions: permissionsReducer,
    companies: companiesReducer,
    toolbarItems: toolbarReducer,
  },
  middleware: (getDefaultMiddleware) => {
    let middlewares = getDefaultMiddleware({
      serializableCheck: false,
    });

    // middlewares = middlewares.concat(loggerMiddleware);

    return middlewares;
  },
  preloadedState: {
    auth: loadStateFromSessionStorage("auth"),
  },
});

export default store;
