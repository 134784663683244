import {
  ArrowLeft,
  CachedOutlined,
  Close,
  CropSquare,
  Delete,
  DoorBackOutlined,
  ModeEdit,
  OpenInFull,
  OpenWith,
  Polyline,
  Redo,
  Stairs,
  SwapVert,
  TextFields,
  Undo,
  WindowOutlined,
} from "@mui/icons-material";
import { Button, Dialog, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import { useState } from "react";
import { ColorResult, GithubPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { IToolbarDefaultItemsImages } from "../../../services/toolbarService";
import { selectedFloorSelector, setSelectedFloor } from "../../../slices/Entities/floorSlice";
import { IFloorInfo } from "../../Canvas/types";
import "../ObjectsToolbar.scss";
import { DEFAULT_UNIT_VALUE, defaultDoor, defaultStairs, defaultTextField } from "../defaults";
import { CanvasHistoryPermission, DefaultToolbarAction, DefaultToolbarTool, Direction, IToolbarItem, ToolbarMeasurementUnit } from "../types";
import { lineWidthValues, presetToolbarColors } from "./variables";
import { buildingNameSelector } from "../../../slices/Entities/buildingSlice";
import { getFloorById } from "../../../services/floorService";
import { LoadingButton } from "@mui/lab";

interface IDefaultToolbarItemProps {
  selectedDefaultTool: DefaultToolbarTool | null;
  selectedDefaultAction: DefaultToolbarAction | null;
  defaultItemsImages: IToolbarDefaultItemsImages;
  initialLineWidth: number;
  initialColor: string;
  historyPermission: CanvasHistoryPermission;
  onDefaultToolClickHandler: (tool: DefaultToolbarTool, item: IToolbarItem | null) => void;
  onDefaultActionClickHandler: (action: DefaultToolbarAction) => void;
  itemMoveCallback: (direction: Direction, unit: ToolbarMeasurementUnit) => void;
  lineWidthChangeCallback: (lineWidth: number) => void;
  colorChangeCallback: (color: string) => void;
  changesDiscardCallback: () => void;
}

const ToolbarDefaultItems: React.FC<IDefaultToolbarItemProps> = (props: IDefaultToolbarItemProps) => {
  const [unit, setUnit] = useState<ToolbarMeasurementUnit>(DEFAULT_UNIT_VALUE);
  const [lineWidth, setLineWidth] = useState<number>(props.initialLineWidth);
  const [color, setColor] = useState<string>(props.initialColor);
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false);
  const [isDiscardLoading, setIsDiscardLoading] = useState<boolean>(false);

  const floorInfo: IFloorInfo = useSelector(selectedFloorSelector);
  const buildingName = useSelector(buildingNameSelector);

  const dispatch = useDispatch<any>();

  const handleUnitChange = (event: SelectChangeEvent<ToolbarMeasurementUnit>) => {
    setUnit(event.target.value as ToolbarMeasurementUnit);
  };

  const handleLineWidthChange = (event: SelectChangeEvent<number>) => {
    setLineWidth(parseInt(event.target.value as any));
    props.lineWidthChangeCallback(event.target.value as any);
  };

  const handleItemsMove = (direction: Direction) => {
    props.itemMoveCallback(direction, unit);
  };

  const onColorChange = (color: ColorResult) => {
    setColor(color.hex);
    props.colorChangeCallback(color.hex);
  };

  const showDialog = () => {
    setShowDiscardDialog(true);
  };

  const hideDialog = () => {
    setShowDiscardDialog(false);
  };

  const handleChangesDiscard = () => {
    props.changesDiscardCallback();
    setIsDiscardLoading(true);
    localStorage.removeItem(`floorInfo-${floorInfo?.building}-${floorInfo?.id}`);
    getFloorById(floorInfo.building, floorInfo.id)
      .then((result) => {
        const payload: IFloorInfo = result.data;
        dispatch(setSelectedFloor(payload));
      })
      .finally(() => {
        setIsDiscardLoading(false);
        setShowDiscardDialog(false);
      });
  };

  return (
    <>
      <div className="default-tool-row">
        <div className="default-tools-undo-redo-container">
          <Tooltip title="Undo">
            <Undo
              onClick={() => props.onDefaultActionClickHandler(DefaultToolbarAction.UNDO)}
              className={`tool-icon${!props.historyPermission.undo ? " tool-disabled" : ""}`}
            />
          </Tooltip>
          <Tooltip title="Redo">
            <Redo
              onClick={() => props.onDefaultActionClickHandler(DefaultToolbarAction.REDO)}
              className={`tool-icon${!props.historyPermission.redo ? " tool-disabled" : ""}`}
            />
          </Tooltip>
        </div>
      </div>
      <div onClick={showDialog} className="default-tool-row">
        <Close className="tool-icon" />
        <div>Discard Changes</div>
      </div>
      <div onClick={() => props.onDefaultActionClickHandler(DefaultToolbarAction.DELETE_ALL)} className="default-tool-row">
        <Delete className="tool-icon" />
        <div>Clear Canvas</div>
      </div>
      <div className="toolbar-category">Actions</div>
      <div onClick={() => props.onDefaultActionClickHandler(DefaultToolbarAction.EDIT)} className="default-tool-row">
        <ModeEdit className="tool-icon" htmlColor={props.selectedDefaultAction === DefaultToolbarAction.EDIT ? "#67a71d" : "black"} />
        <div>Edit</div>
      </div>
      <div onClick={() => props.onDefaultActionClickHandler(DefaultToolbarAction.RESIZE)} className="default-tool-row">
        <OpenInFull className="tool-icon" htmlColor={props.selectedDefaultAction === DefaultToolbarAction.RESIZE ? "#67a71d" : "black"} />
        <div>Resize</div>
      </div>
      <div onClick={() => props.onDefaultActionClickHandler(DefaultToolbarAction.ROTATE)} className="default-tool-row">
        <CachedOutlined className="tool-icon" htmlColor={props.selectedDefaultAction === DefaultToolbarAction.ROTATE ? "#67a71d" : "black"} />
        <div>Rotate</div>
      </div>
      <div onClick={() => props.onDefaultActionClickHandler(DefaultToolbarAction.FLIP)} className="default-tool-row">
        <SwapVert className="tool-icon" htmlColor={props.selectedDefaultAction === DefaultToolbarAction.FLIP ? "#67a71d" : "black"} />
        <div>Flip</div>
      </div>
      <div onClick={() => props.onDefaultActionClickHandler(DefaultToolbarAction.MOVE)} className="default-tool-row">
        <OpenWith className="tool-icon" htmlColor={props.selectedDefaultAction === DefaultToolbarAction.MOVE ? "#67a71d" : "black"} />
        <div>Move</div>
      </div>
      {props.selectedDefaultAction === DefaultToolbarAction.MOVE && (
        <div className="move-control-container">
          <ArrowLeft onClick={() => handleItemsMove("up")} className="arrow-icon arrow-icon-up" />
          <div className="horizontal-arrows-container">
            <ArrowLeft onClick={() => handleItemsMove("left")} className="arrow-icon" />
            <ArrowLeft onClick={() => handleItemsMove("right")} className="arrow-icon arrow-icon-right" />
          </div>
          <ArrowLeft onClick={() => handleItemsMove("down")} className="arrow-icon arrow-icon-down" />
          <div className="toolbar-unit-dropdown-wrapper">
            <FormControl fullWidth>
              <InputLabel>Unit</InputLabel>
              <Select value={unit} onChange={handleUnitChange} style={{ height: "2rem" }} defaultValue={DEFAULT_UNIT_VALUE} label="Unit">
                <MenuItem value={"sq"}>sq</MenuItem>
                <MenuItem value={"px"}>px</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      )}
      <div className="toolbar-category">Drawing</div>
      <div onClick={() => props.onDefaultToolClickHandler(DefaultToolbarTool.DRAWING, null)} className="default-tool-row">
        <CropSquare className="tool-icon" htmlColor={props.selectedDefaultTool === DefaultToolbarTool.DRAWING ? "#67a71d" : "black"} />
        <div>Room</div>
      </div>
      <div onClick={() => props.onDefaultToolClickHandler(DefaultToolbarTool.POLY_LINE, null)} className="default-tool-row">
        <Polyline className="tool-icon" htmlColor={props.selectedDefaultTool === DefaultToolbarTool.POLY_LINE ? "#67a71d" : "black"} />
        <div>Free draw</div>
      </div>
      {(props.selectedDefaultTool === DefaultToolbarTool.POLY_LINE || props.selectedDefaultTool === DefaultToolbarTool.DRAWING) && (
        <div className="toolbar-line-control-wrapper">
          <div className="toolbar-upper-line-control-container">
            <FormControl className="toolbar-line-width-dropdown">
              <InputLabel>Line Width</InputLabel>
              <Select
                value={lineWidth}
                onChange={handleLineWidthChange}
                style={{ height: "2rem" }}
                defaultValue={props.initialLineWidth}
                label="Line Width"
              >
                {lineWidthValues.map((width) => (
                  <MenuItem key={width} value={width}>
                    {width}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ backgroundColor: color }} className="chosen-toolbar-color" />
          </div>
          <GithubPicker
            triangle="hide"
            className="toolbar-color-picker"
            colors={presetToolbarColors}
            color={color}
            onChangeComplete={onColorChange}
          />
        </div>
      )}
      <div className="toolbar-category">Elements</div>
      <div onClick={() => props.onDefaultToolClickHandler(DefaultToolbarTool.TEXT, defaultTextField)} className="default-tool-row">
        <TextFields className="tool-icon" htmlColor={props.selectedDefaultTool === DefaultToolbarTool.TEXT ? "#67a71d" : "black"} />
        <div>Text</div>
      </div>
      <div onClick={() => props.onDefaultToolClickHandler(DefaultToolbarTool.WINDOW, null)} className="default-tool-row">
        <WindowOutlined className="tool-icon" htmlColor={props.selectedDefaultTool === DefaultToolbarTool.WINDOW ? "#67a71d" : "black"} />
        <div>Window</div>
      </div>
      <div
        onClick={() => props.onDefaultToolClickHandler(DefaultToolbarTool.STAIRS, { ...defaultStairs, imageSrc: props.defaultItemsImages.stairs })}
        className="default-tool-row"
      >
        <Stairs className="tool-icon" htmlColor={props.selectedDefaultTool === DefaultToolbarTool.STAIRS ? "#67a71d" : "black"} />
        <div>Stairs</div>
      </div>
      <div
        onClick={() => props.onDefaultToolClickHandler(DefaultToolbarTool.DOOR, { ...defaultDoor, imageSrc: props.defaultItemsImages.door })}
        className="default-tool-row"
      >
        <DoorBackOutlined className="tool-icon" htmlColor={props.selectedDefaultTool === DefaultToolbarTool.DOOR ? "#67a71d" : "black"} />
        <div>Door</div>
      </div>
      <Dialog onClose={hideDialog} open={showDiscardDialog}>
        <DialogTitle>{`Are you sure you want to discard changes for ${buildingName} - ${floorInfo.name}?`}</DialogTitle>
        <div className="toolbar-default-items-dialog-buttons-container">
          <Button onClick={hideDialog} style={{ backgroundColor: "#8bc34a" }} variant="contained">
            No
          </Button>
          <LoadingButton loading={isDiscardLoading} onClick={handleChangesDiscard} style={{ color: "#8bc34a" }} variant="text">
            Discard
          </LoadingButton>
        </div>
      </Dialog>
    </>
  );
};

export default ToolbarDefaultItems;
