import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsersList, allUsersSelectors, deleteUserData } from "../../slices/Entities/usersSlice";
import Navbar from "../Navbar/navbar";
import { Link } from "react-router-dom";
import IconLabel from "../../components/IconLabel/iconLabel";
import editIcon from "../../assets/images/edit.png";
import cancelIcon from "../../assets/images/cancel.png";
import envelopeIcon from "../../assets/images/envelope.png";
import globeIcon from "../../assets/images/globe.png";
import logoIcon from "../../assets/images/logo1.png";
import DeleteAlertDialog from "../../components/DeleteAlertDialog/deleteAlertDialog";
import { WAYMAN_PROVIDER } from "../../utils/variables";
import { isUndefined } from "lodash";
import ErrorNotification from "../../components/ErrorNotification/errorNotification";
import { notifyDeleteUser } from "../../utils/notify";
import Copyright from "../../components/Copyright/Copyright";
import { useAuth } from "../../utils/AuthHook";

const UserList = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<any>();

  const dispatch = useDispatch<any>();
  const users = useSelector(allUsersSelectors);
  const { user, check } = useAuth();

  const checkProvider = (data: any) => {
    return data.provider === WAYMAN_PROVIDER;
  };

  useEffect(() => {
    dispatch(getUsersList());
  }, [dispatch]);

  const resetErrorMessage = () => {
    setErrorMessage(undefined);
  };

  const toggleShowDeleteDialog = () => {
    setShowDeleteUserDialog(!showDeleteUserDialog);
  };

  const handleDeleteUser = () => {
    dispatch(deleteUserData(deleteUser.id)).then((result: any) => {
      if (result.error) {
        setErrorMessage(result.payload.response.data);
      }
    });
    toggleShowDeleteDialog();
    notifyDeleteUser();
  };

  const getDeleteDialogData = (user: any) => {
    setDeleteUser(user);
    toggleShowDeleteDialog();
  };

  return (
    <div className="dashboard-container">
      <Navbar />
      <div className="title-container">
        <h1>Active Users</h1>
        {check("user.create") ? (
          <Link className="add-team" to={"/createNewUser"}>
            + New User
          </Link>
        ) : (
          <></>
        )}
      </div>
      <ul className="users-list">
        {(users || []).map((data: any) => (
          <li className="user-container" key={data.id}>
            <div className="content">
              <div className="left">
                <img src={data.avatar} className="list-item-image" alt="avatar" />
                <div className="user-data">
                  <h3>
                    {data.firstName} {data.lastName}
                  </h3>
                  <p>{data.username}</p>
                </div>
              </div>

              <div className="right">
                <div className="icon-wrapper-first">
                  <div>
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location.href = `mailto:${data.email}`;
                      }}
                    >
                      <IconLabel icon={envelopeIcon} color="" text="" />
                    </Link>
                  </div>
                  {checkProvider(data) && (
                    <div>
                      <IconLabel icon={logoIcon} color="" text="" />
                    </div>
                  )}
                  {!checkProvider(data) && (
                    <div>
                      <IconLabel icon={globeIcon} color="" text="" />
                    </div>
                  )}
                </div>
                <div className="row">
                  {check("user.update") ? (
                    <Link to={`${data.id}`}>
                      <IconLabel icon={editIcon} color="" text="" />
                    </Link>
                  ) : (
                    <></>
                  )}
                  {check("user.delete") ? (
                    <div onClick={() => getDeleteDialogData(data)}>
                      <IconLabel icon={cancelIcon} color="" text="" />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {showDeleteUserDialog && (
        <DeleteAlertDialog
          type={"user"}
          value={`user ${deleteUser.firstName} ${deleteUser.lastName}`}
          open={showDeleteUserDialog}
          handleOpen={toggleShowDeleteDialog}
          handleClose={toggleShowDeleteDialog}
          handleDelete={handleDeleteUser}
        />
      )}
      {!isUndefined(errorMessage) && <ErrorNotification message={errorMessage} onClose={resetErrorMessage} />}
      <div className="copyright-wrapper">
        <Copyright />
      </div>
    </div>
  );
};
export default UserList;
