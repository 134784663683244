import { IObject } from "../slices/Entities/deskSlice";
import client from "./axios.service";

const baseUrl = "/buildings";

export type DeskData = {
  deskId: string;
  floorId: string;
};

export const getDesksByFloorId = (buildingId: string, floorId: string) => {
  const requestPath = `${baseUrl}/${buildingId}/floors/${floorId}/desks`;

  return client.get(requestPath, null);
};

export const deleteDesk = (buildingId: string, floorId: string, deskId: string) => {
  const requestPath = `${baseUrl}/${buildingId}/floors/${floorId}/desks/${deskId}`;

  return client.delete(requestPath, {});
};

export const getDeskById = (deskId: string) => {
  const requestPath = `/desks/${deskId}`;

  return client.get(requestPath, {});
};

export const updateDesk = (buildingId: string, floorId: string, deskId: string, label: string) => {
  const requestPath = `${baseUrl}/${buildingId}/floors/${floorId}/desks/${deskId}`;

  const data = {
    label: label,
  };

  return client.put(requestPath, data, {});
};

export const availability = (availableDate: string) => {
  return client.get("/desks/availability", {
    params: {
      date: availableDate,
    },
  });
};

export const saveDesks = (buildingId: string, floorId: string, desks: IObject[]) => {
  const requestPath = `${baseUrl}/${buildingId}/floors/${floorId}/desks`;

  return client.put(requestPath, { desks: desks }, {});
};
export const averageUsage = (startDate: string, endDate: string) => {
  const requestPath = `/desks/averageUsage?startDate=${startDate}&endDate=${endDate}`;
  return client.get(requestPath, null);
};

export const dailyOccupancy = (buildingId: string) => {
  const requestPath = `/desks/dailyOccupancy?building=${buildingId}`;
  return client.get(requestPath, null);
};

export const floorUsage = (startDate: string, endDate: string, buildingId: string) => {
  const requestPath = `/desks/floorUsage?startDate=${startDate}&endDate=${endDate}&building=${buildingId}`;
  return client.get(requestPath, null);
};

export const busyDays = (startDate: string, endDate: string, buildingId: string) => {
  const requestPath = `/desks/busyDays?startDate=${startDate}&endDate=${endDate}&building=${buildingId}`;
  return client.get(requestPath, null);
};
