import { ObjectType } from "../../slices/Entities/deskSlice";
import { IToolbarItem, ToolbarMeasurementUnit } from "./types";

export const defaultDoor: IToolbarItem = {
  id: "a",
  _id: "a",
  category: "Door",
  type: ObjectType.DOOR,
  popupInfo: null,
  size: { width: 1, height: 1 },
  label: "",
  tooltip: "",
};

export const defaultStairs: IToolbarItem = {
  id: "b",
  _id: "b",
  category: "Stairs",
  type: ObjectType.STAIRS,
  popupInfo: null,
  size: { width: 1, height: 1 },
  label: "",
  tooltip: "",
};

export const defaultTextField: IToolbarItem = {
  id: "c",
  _id: "c",
  category: "Text",
  type: ObjectType.TEXT,
  popupInfo: null,
  size: { width: 3, height: 1 },
  label: "",
  tooltip: "",
  imageSrc: "",
};

export const DEFAULT_UNIT_VALUE: ToolbarMeasurementUnit = "sq";
