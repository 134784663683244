import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { allBuildingsSelector, getBuildings, orderBuildingsAndFloors, statusSelector } from "../../slices/Entities/buildingSlice";
import {
  addConfigurationData,
  configurationsSelector,
  defaultBuildingSelector,
  defaultFloorSelector,
  getAllConfigurations,
} from "../../slices/Entities/configurationsSlice";
import { notifyChangeOrder, notifySettingsChanged } from "../../utils/notify";
import Navbar from "../Navbar/navbar";
import { floorsSelector, getFloorsFromBuilding } from "../../slices/Entities/floorSlice";
import { Status } from "../../slices/StatusEnum";
import Loading from "../../components/Loading";
import DefaultBuildingFloor from "../../components/DefaultBuildingFloor/defaultBuildingFloor";
import { useAuth } from "../../utils/AuthHook";

const Settings = () => {
  const buildings = useSelector(allBuildingsSelector);
  const allFloors = useSelector(floorsSelector);
  const configurations = useSelector(configurationsSelector);
  const defaultBuilding = useSelector(defaultBuildingSelector);
  const defaultFloor = useSelector(defaultFloorSelector);
  const status = useSelector(statusSelector);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [settings, setSettings] = useState<any>({
    building: "",
    floor: "",
  });
  const [floors, setFloors] = useState<any>([]);
  const [buildingOptions, setBuildingOptions] = useState<any>([]);
  const [buildingsFloorsDict, setBuildingsFloorsDict] = useState<any>({});
  const { user, check } = useAuth();
  useEffect(() => {
    dispatch(getBuildings());
    dispatch(getAllConfigurations());
  }, []);

  useEffect(() => {
    if (buildingOptions.length === 0) {
      const allBuildings = [...buildings];
      allBuildings.sort((obj1: any, obj2: any) => obj1.orderNr - obj2.orderNr);
      setBuildingOptions(allBuildings);

      let dict: any = {};
      buildings.forEach((building: any) => {
        dispatch(getFloorsFromBuilding(building.id));
        dict[building.id] = [];
      });
      setBuildingsFloorsDict(dict);
    }
  }, [buildings]);

  useEffect(() => {
    let dict: any = {};
    allFloors.forEach((floor: any) => {
      const building: string = floor.building;
      if (building in dict) {
        dict[building].push(floor);
      } else {
        dict[building] = [floor];
      }
    });

    for (const key in dict) {
      dict[key] = dict[key].sort((obj1: any, obj2: any) => obj1.orderNr - obj2.orderNr);
    }
    setBuildingsFloorsDict(dict);
  }, [allFloors]);

  useEffect(() => {
    if (settings.building === "" && settings.floor === "") {
      const getData = async () => {
        let settingsCopy = { ...settings };
        if (defaultBuilding && defaultBuilding !== settings.building) {
          settingsCopy.building = defaultBuilding.value;
          await dispatch(getFloorsFromBuilding(settingsCopy.building)).then((result: any) => {
            if (!result.error) {
              setFloors(result.payload);
            }
          });
        }
        if (defaultFloor && defaultFloor !== settings.floor) {
          settingsCopy.floor = defaultFloor.value;
        }
        setSettings(settingsCopy);
      };
      getData();
    }
  }, [configurations]);

  const handleCancel = () => {
    navigate("/desksList");
  };

  const handleSave = async (formValue: { building: string; floor: string }) => {
    const { building, floor } = formValue;

    if (!defaultBuilding || (defaultBuilding && defaultBuilding.value !== building)) {
      await dispatch(addConfigurationData({ key: "defaultBuilding", value: building }));
    }

    if (!defaultFloor || (defaultFloor && defaultFloor.value !== floor)) {
      await dispatch(addConfigurationData({ key: "defaultFloor", value: floor }));
    }

    for (const key in buildingsFloorsDict) {
      const floors = buildingsFloorsDict[key];
      buildingsFloorsDict[key] = floors.map((floor: any, index: any) => {
        const floorObj = { ...floor };
        floorObj.orderNr = index;
        return floorObj;
      });
    }

    await dispatch(
      orderBuildingsAndFloors({
        buildings: buildingOptions.map((building: any, index: any) => {
          const build = { ...building };
          build.orderNr = index;
          return build;
        }),
        floors: buildingsFloorsDict,
      })
    );

    notifySettingsChanged();
  };

  const onDragEnd = (result: any) => {
    if (check("building.order")) {
      if (!result.destination) {
        return;
      }

      const items = reorder(buildingOptions, result.source.index, result.destination.index);

      setBuildingOptions(items);
    } else {
      notifyChangeOrder();
    }
  };

  const reorder = (list: Iterable<unknown> | ArrayLike<unknown>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragFloor = (result: any) => {
    if (check("floor.order")) {
      if (!result.destination) {
        return;
      }
      const buildingId = result.draggableId.split(" ")[1];
      buildingsFloorsDict[buildingId] = reorder(buildingsFloorsDict[buildingId], result.source.index, result.destination.index);
      setBuildingsFloorsDict(buildingsFloorsDict);
    } else {
      notifyChangeOrder();
    }
  };

  return (
    <div className="dashboard-container">
      <Navbar />
      {status !== Status.Loading ? (
        <Formik initialValues={settings} onSubmit={handleSave} enableReinitialize>
          <Form className="settings">
            <div className="settings-upper-container">
              <div className="settings-order-container">
                <h2>Order Buildings and Floors</h2>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable direction="horizontal" droppableId="droppableBuilding">
                    {(provided, snapshot) => (
                      <div className="draggable-container" {...provided.droppableProps} ref={provided.innerRef}>
                        {buildingOptions?.map((building: any, index: any) => (
                          <Draggable key={building.id} draggableId={building.id} index={index}>
                            {(provided, snapshot) => (
                              <div className="draggable-item-building" ref={provided.innerRef} {...provided.draggableProps}>
                                <span {...provided.dragHandleProps}>
                                  <b>{building.name}</b>
                                </span>
                                <div className="floors">
                                  <DragDropContext onDragEnd={onDragFloor}>
                                    <Droppable droppableId="droppableItem">
                                      {(provided, snapshot) => (
                                        <div {...provided.droppableProps} ref={provided.innerRef} style={{ height: "calc(100% - 2rem)" }}>
                                          {buildingsFloorsDict[building.id]?.map((floor: any, index: any) => (
                                            <Draggable key={floor.id} draggableId={`${floor.id} ${floor.building}`} index={index}>
                                              {(provided, snapshot) => (
                                                <div
                                                  className="draggable-item"
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                >
                                                  {floor.name}
                                                </div>
                                              )}
                                            </Draggable>
                                          ))}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              {check("configurations.create") ? (
                <div className="settings-wrapper">
                  <DefaultBuildingFloor settings={settings} setSettings={setSettings} floors={floors} setFloors={setFloors} />
                </div>
              ) : (
                <></>
              )}
            </div>
            {check("configurations.create") ? (
              <>
                <hr className="simple-line"></hr>
                <div className="settings-buttons">
                  <button type="button" onClick={handleCancel} className="settings-cancel">
                    Cancel
                  </button>
                  <button type="submit" className="settings-save">
                    Save Changes
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
          </Form>
        </Formik>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Settings;
