import { ObjectType } from "../../slices/Entities/deskSlice";
import { ICoordinate, ISize } from "../interfaces";

export interface IToolbarItem {
  id: string;
  _id: string;
  size: ISize;
  imageSrc?: string;
  label: string | null;
  tooltip: string | null;
  popupInfo: string | null;
  category: string;
  type: ObjectType;
}

export interface IPlacedObject extends IToolbarItem {
  position: ICoordinate;
  rotation: number;
  hasBorder: boolean;
  hasBackground: boolean;
}

export enum DefaultToolbarTool {
  DRAWING = "DRAWING",
  POLY_LINE = "POLY_LINE",
  WINDOW = "WINDOW",
  STAIRS = "STAIRS",
  DOOR = "DOOR",
  TEXT = "TEXT",
}

export enum DefaultToolbarAction {
  DISCARD = "DISCARD",
  EDIT = "EDIT",
  MOVE = "MOVE",
  RESIZE = "RESIZE",
  DELETE_ALL = "DELETE_ALL",
  ROTATE = "ROTATE",
  FLIP = "FLIP",
  UNDO = "UNDO",
  REDO = "REDO",
}

export type CanvasHistoryPermission = {
  undo: boolean;
  redo: boolean;
};

export type ToolbarMeasurementUnit = "px" | "sq";

export type Direction = "up" | "down" | "left" | "right";

export interface IToolbarState {
  isMinimized: boolean;
  selectedCustomTool: IToolbarItem | null;
  selectedDefaultTool: DefaultToolbarTool | null;
  selectedDefaultAction: DefaultToolbarAction | null;
  lineSessionID: string | null;
  lineWidth: number;
}

export interface ToolbarActions {
  type: ToolbarActionTypes;
  payload?: any;
}

export interface IObjectsToolbarProps {
  hidden?: boolean;
  selectedObjectsIDs: string[];
  draggingItemsDisabled?: boolean;
  historyPermission: CanvasHistoryPermission;
  initialPlacedObjects?: IToolbarItem[];
  updateFloorInfo: () => void;
  colorChangeCallback: (color: string) => void;
  onItemsMoveCallback: (direction: Direction, unit: ToolbarMeasurementUnit) => void;
  multilinePointCallback: (multilinePoint: ICoordinate, lineSessionID: string) => void;
  lineWidthChangeCallback: (lineWidth: number) => void;
  onDefaultToolSelectionCallback: (selectedTool: DefaultToolbarTool | null) => void;
  onDefaultActionSelectionCallback: (selectedAction: DefaultToolbarAction | null) => void;
  deleteCurrentLineSessionCallback: (sessionID: string) => void;
  changesDiscardCallback: () => void;
  objectPlaceCallback?: (object: IPlacedObject) => void;
  objectClickCallback?: (object: IPlacedObject, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export enum ToolbarActionTypes {
  MOVE_ITEMS = "MOVE_ITEMS",
  ITEM_CLICK = "TOOLBAR_ITEM_CLICK",
  PLACE_OBJECT = "TOOLBAR_PLACE_OBJECT",
  PLACE_LINE_POINT = "PLACE_LINE_POINT",
  DESELECT_TOOL = "TOOLBAR_DESELECT_TOOL",
  TOGGLE_MINIMIZE = "TOOLBAR_TOGGLE_MINIMIZE",
  ACTIVATE_DEFAULT_TOOL = "ACTIVATE_DEFAULT_TOOL",
  ACTIVATE_DEFAULT_ACTION = "ACTIVATE_DEFAULT_ACTION",
  CHANGE_LINE_WIDTH = "CHANGE_LINE_WIDTH",
}

export type ForwardedToolbarRef = {
  handleToolbarDeselect: () => void;
};
