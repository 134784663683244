import Lottie from "lottie-react";
import animationData from "../assets/loading.json";

export default function Loading() {
  return (
    <div
      style={{
        width: "500px",
        height: "500px",
        display: "flex",
        margin: "auto",
        alignItems: "center",
        alignContent: "center",
        justifyItems: "center",
        justifyContent: "center",
      }}
    >
      <Lottie animationData={animationData} loop={true} width={500} height={500} />
    </div>
  );
}
