import client from "./axios.service";

const baseUrl = "/buildings";

export type FloorData = {
  floorId?: string;
  buildingId?: string;
  name?: string;
  canvasData?: any;
};

export const getFloorsByBuildingId = (buildingId: string) => {
  const requestPath = `${baseUrl}/${buildingId}/floors`;

  return client.get(requestPath, null);
};
export const getFloorByBuildingId = (buildingId: string) => {
  const requestPath = `${baseUrl}/${buildingId}/floors`;

  return client.get(requestPath, null);
};

export const getFloorById = (buildingId: string, id: string) => {
  const requestPath = `${baseUrl}/${buildingId}/floors/${id}`;
  return client.get(requestPath, {});
};

export const updateFloor = (floorInfo: any) => {
  const requestPath = `${baseUrl}/${floorInfo.building}/floors/${floorInfo.id}`;
  return client.put(requestPath, floorInfo, {});
};

export const addFloor = (buildingId: string, name: string, canvasData: any) => {
  const requestPath = `${baseUrl}/${buildingId}/floors`;

  return client.post(requestPath, { name, canvasData }, {});
};

export const deleteFloor = (buildingId: string, id: string) => {
  const requestPath = `${baseUrl}/${buildingId}/floors/${id}`;

  return client.delete(requestPath, {});
};
