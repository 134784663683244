import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import { getMeInfo, login, register, updateMeInfo } from "../../services/authService";
import { saveStateToSessionStorage } from "../../utils/Storage/StorageState";
import { Status } from "../StatusEnum";

type LoginType = {
  username: string;
  password: string;
};

export type UserData = {
  username: string;
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  avatar: string;
  id?: string | undefined;
  groups: any;
  company: string;
};

type authStateType = {
  isAuthenticated: boolean;
  token: string | null;
  user: any;
  status: Status;
  error: SerializedError | null;
};

const initialState: authStateType = {
  isAuthenticated: false,
  token: null,
  user: null,
  status: Status.Idle,
  error: null,
};

export const loginAndGetUserData = createAsyncThunk("auth/getUserData", async ({ username, password }: LoginType, thunkApi) => {
  try {
    const response = await login(username, password);

    if (response) {
      return response;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const registerNewUser = async (
  username: string,
  password: string,
  email: string,
  firstName: string,
  lastName: string,
  avatar: string,
  company: string
) => {
  return register(username, password, email, firstName, lastName, avatar, company);
};

export const getInfoAboutMe = createAsyncThunk("auth/getInfoAboutMe", async (_, thunkApi) => {
  try {
    const response = await getMeInfo();

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const updateInfoAboutMe = createAsyncThunk("auth/updateInfoAboutMe", async ({ username, email, firstName, lastName }: UserData, thunkApi) => {
  try {
    const response = await updateMeInfo(username, email, firstName, lastName);

    if (response.status === 204) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loginAndGetUserData.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.isAuthenticated = !!payload.token;
          state.token = payload.token;
          state.user = payload.user;
          saveStateToSessionStorage("auth", state);
        }
      })
      .addCase(loginAndGetUserData.pending, (state) => {
        state.status = Status.Loading;
        state.isAuthenticated = false;
      })
      .addCase(loginAndGetUserData.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
        state.isAuthenticated = false;
        state.token = "";
        state.user = null;
      })

      .addCase(getInfoAboutMe.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.user = payload;
          saveStateToSessionStorage("auth", state);
        }
      })
      .addCase(getInfoAboutMe.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getInfoAboutMe.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(updateInfoAboutMe.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
        }
      })
      .addCase(updateInfoAboutMe.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(updateInfoAboutMe.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      });
  },
  reducers: {
    logout(state) {
      state.isAuthenticated = false;
      state.token = null;
      state.user = null;
      state.status = Status.Idle;
      state.error = null;
      sessionStorage.clear();
    },
  },
});

const { actions, reducer } = authSlice;

export default reducer;

export const { logout } = actions;

export const userSelector = (state: any) => state.auth.user;

export const isAuthenticatedSelector = (state: any) => state.auth.isAuthenticated;

export const tokenSelector = (state: any) => state.auth.token;
