import client from "./axios.service";

const baseUrl = "/buildings";

export type Building = {
  id?: string;
  name?: string;
};

export const getAllBuildings = () => {
  const requestPath = `${baseUrl}`;

  return client.get(requestPath, null);
};

export const addBuilding = (name: string | undefined) => {
  const requestPath = `${baseUrl}`;

  return client.post(requestPath, { name }, {});
};

export const editBuilding = (id: string | undefined, name: string | undefined) => {
  const requestPath = `${baseUrl}/${id}`;

  const data = {
    name: name,
  };
  return client.put(requestPath, data, {});
};

export const orderAllBuildingsFloors = (buildings: any[], floors: any) => {
  const requestPath = `${baseUrl}/orderAll`;

  const data = {
    buildings: buildings,
    floors: floors,
  };
  return client.put(requestPath, data, {});
};

export const deleteBuilding = (id: string | undefined) => {
  const requestPath = `${baseUrl}/${id}`;

  return client.delete(requestPath, {});
};
