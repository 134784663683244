import { toast } from "react-toastify";

export const notifyError = (message: string) => {
  toast.error(message, { position: "top-center" });
};

export const notifySuccess = (message: string) => {
  toast.success(message, { position: "top-center" });
};

export const notifyReservationDeleted = () => {
  toast.success(" Reservation deleted.", { position: "top-center" });
};

export const notifyReservationsDeleted = () => {
  toast.success(" Selected reservations deleted.", { position: "top-center" });
};

export const notifyReservationAdded = () => {
  toast.success(" Reservation successful.", { position: "top-center" });
};

export const notifyCreateUser = () => {
  toast.success("User created successfully.", { position: "top-center" });
};

export const notifyUpdateUser = () => {
  toast.success("User updated successfully.", { position: "top-center" });
};

export const notifyCreateCompany = () => {
  toast.success("Company created successfully.", { position: "top-center" });
};

export const notifyUpdateCompany = () => {
  toast.success("Company updated successfully.", { position: "top-center" });
};

export const notifyDeleteUser = () => {
  toast.success("User deleted successfully.", { position: "top-center" });
};

export const notifyCreateTeam = () => {
  toast.success("Team created successfully.", { position: "top-center" });
};

export const notifyUpdateTeam = () => {
  toast.success("Team updated successfully.", { position: "top-center" });
};

export const notifyDeleteTeam = () => {
  toast.success("Team deleted successfully.", { position: "top-center" });
};

export const notifyDeleteCompany = () => {
  toast.success("Company deleted successfully.", { position: "top-center" });
};

export const notifySettingsChanged = () => {
  toast.success("Settings changed successfully.", { position: "top-center" });
};

export const notifyCanvasSave = () => {
  toast.success("Saved successfully.", { position: "top-center" });
};

export const notifyUploadSuccess = () => {
  toast.success("Uploaded successfully.", { position: "top-center" });
};

export const notifyDeleteSuccess = () => {
  toast.success("Deleted successfully.", { position: "top-center" });
};

export const notifyUpdateSuccess = () => {
  toast.success("Updated successfully.", { position: "top-center" });
};

export const notifyCompleteAllFields = () => {
  toast.error("Fill in all fields.", { position: "top-center" });
};

export const notifyChangeOrder = () => {
  toast.error("You are not allowed to change the order.", { position: "top-center" });
};

export const notifyNoImageUploaded = () => {
  toast.error("Please upload an image for the toolbar item.", { position: "top-center" });
};

export const notifyUploadError = () => {
  toast.error("An error occurred while uploading.", { position: "top-center" });
};

export const notifyDeleteError = () => {
  toast.error("An error occurred while deleting.", { position: "top-center" });
};

export const notifyUpdateError = () => {
  toast.error("An error occurred while updating.", { position: "top-center" });
};

export const notifyNotEnoughSeats = () => {
  toast.error("There are not enough seats to fulfill your reservation", { position: "top-center" });
};
