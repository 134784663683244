import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addingBuilding } from "../../slices/Entities/buildingSlice";
import { addingFloor } from "../../slices/Entities/floorSlice";

function AddNewItemPopup(props: any) {
  const [input, setInput] = useState("");
  const dispatch = useDispatch<any>();

  const onChangeHandler = (event: any) => {
    setInput(event.target.value);
  };

  const onSaveHandler = () => {
    if (props.item === "building") {
      dispatch(addingBuilding({ name: input })).then((result: any) => {
        if (result.error) {
          props.setErrorMessage(result.payload.response.data);
        } else {
          props.getNewItem(input, props.item);
        }
      });
    } else if (props.item === "floor") {
      dispatch(
        addingFloor({
          buildingId: props.selectedBuilding,
          name: input,
          canvasData: "",
        })
      ).then((result: any) => {
        if (result.error) {
          props.setErrorMessage(result.payload.response.data);
        } else {
          props.getNewItem(input, props.item);
        }
      });
    }
    onCancelHandler();
  };

  const onCancelHandler = () => {
    setInput("");
    props.handleClose();
  };

  return (
    <div className="container">
      <Dialog open={props.open} onClose={onCancelHandler} disableEscapeKeyDown>
        <DialogTitle>New {props.item}</DialogTitle>
        <Formik initialValues={{}} onSubmit={onSaveHandler}>
          <Form>
            <DialogContent>
              <TextField
                autoFocus
                inputProps={{ "data-testid": `New ${props.item} input` }}
                margin="dense"
                label={"Enter " + props.item}
                type="text"
                fullWidth
                variant="standard"
                value={input}
                onChange={onChangeHandler}
              />
            </DialogContent>
            <DialogActions>
              <button data-testid="modal-save-button" type="submit" className="save-button">
                Save
              </button>
              <button type="button" className="cancel-button" onClick={onCancelHandler}>
                Cancel
              </button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </div>
  );
}

export default AddNewItemPopup;
