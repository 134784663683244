import { createAsyncThunk, createEntityAdapter, createSlice, SerializedError } from "@reduxjs/toolkit";
import { addFloor, deleteFloor, getFloorById, getFloorsByBuildingId, updateFloor } from "../../services/floorService";
import { Status } from "../StatusEnum";
import { orderBuildingsAndFloors } from "./buildingSlice";

type InitialStateType = {
  status: Status;
  error: SerializedError | null;
  selectedFloor: null;
  floorName: string;
  floorsArray: any;
  desksArray: any;
};

const floorAdapter = createEntityAdapter({
  selectId: (floor: any) => floor._id,
  sortComparer: false,
});

const initialState: InitialStateType = floorAdapter.getInitialState({
  status: Status.Idle,
  error: null,
  selectedFloor: null,
  floorName: "",
  floorsArray: [],
  desksArray: [],
});

export const getFloorsFromBuilding = createAsyncThunk("floors/getFloorsByBuildingId", async (buildingId: string, thunkApi) => {
  try {
    const response = await getFloorsByBuildingId(buildingId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const getSelectedFloor = createAsyncThunk(
  "floors/getFloorById",
  async ({ buildingId, floorId }: { buildingId: string; floorId: string }, thunkApi) => {
    try {
      const response = await getFloorById(buildingId, floorId);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const getFloorFromBuilding = createAsyncThunk("floors/getFloorsByBuildingId", async (buildingId: string, thunkApi) => {
  try {
    const response = await getFloorsByBuildingId(buildingId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const updateFloorData = createAsyncThunk("floors/updateFloor", async (floorInfo: any, thunkApi) => {
  try {
    const response = await updateFloor(floorInfo);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const addingFloor = createAsyncThunk(
  "floors/addFloor",
  async ({ buildingId, name, canvasData }: { buildingId: string; name: string; canvasData: any }, thunkApi) => {
    try {
      const response = await addFloor(buildingId, name, canvasData);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const deleteFloorData = createAsyncThunk(
  "floors/deleteFloor",
  async ({ buildingId, floorId }: { buildingId: string; floorId: string }, thunkApi) => {
    try {
      const response = await deleteFloor(buildingId, floorId);

      if (response.status === 200 || response.status === 201) {
        return { message: response.data, id: floorId };
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

const floorsSlice = createSlice({
  name: "floors",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getFloorsFromBuilding.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.floorsArray.push(payload);
          floorAdapter.setMany(state, payload);
        }
      })
      .addCase(getFloorsFromBuilding.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(getSelectedFloor.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.floorsArray.push(payload);
          state.desksArray = [];
          const desks = payload.canvas.objects.filter((item: any) => item.type === "desk");
          state.desksArray.push(desks);
          floorAdapter.setMany(state, payload);
        }
      })
      .addCase(getSelectedFloor.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(updateFloorData.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        floorAdapter.upsertOne(state, payload);
        state.selectedFloor = payload;
        state.status = Status.Succeeded;
      })
      .addCase(updateFloorData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(updateFloorData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(orderBuildingsAndFloors.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          floorAdapter.setAll(
            state,
            payload.floors.sort((obj1: any, obj2: any) => obj1.orderNr - obj2.orderNr)
          );
        }
      })
      .addCase(orderBuildingsAndFloors.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(orderBuildingsAndFloors.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(addingFloor.fulfilled, (state: any, action: any) => {
        const { payload } = action;

        if (payload) {
          state.status = Status.Succeeded;
          floorAdapter.addOne(state, payload);
        }
      })
      .addCase(addingFloor.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(deleteFloorData.fulfilled, (state: any, action: any) => {
        state.status = Status.Succeeded;
        floorAdapter.removeOne(state, action.meta.arg.floorId);
        state.selectedFloor = null;
      })
      .addCase(deleteFloorData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(deleteFloorData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      });
  },
  reducers: {
    setFloorsArray(state, action) {
      state.floorsArray = action.payload;
    },
    setSelectedFloor(state, action) {
      state.selectedFloor = action.payload;
    },
    setFloorName(state, action) {
      state.floorName = action.payload;
    },
    setDesksArray(state, action) {
      state.desksArray = action.payload;
    },
  },
});

const { reducer, actions } = floorsSlice;

export default reducer;
export const { setFloorsArray, setSelectedFloor, setFloorName } = actions;

const floorsSelectors = floorAdapter.getSelectors((state: any) => state.floors);

export const floorsArraySelector = (state: any) => state.floors.floorsArray;

export const desksArraySelector = (state: any) => state.floors.desksArray;

export const selectedFloorSelector = (state: any) => state.floors?.selectedFloor;

export const floorNameSelector = (state: any) => state.floors.floorName;

export const floorsSelector = (state: any) => {
  return Object.values(floorsSelectors.selectAll(state));
};
