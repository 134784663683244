import { User } from "./authService";
import client from "./axios.service";

const baseUrl = "/companies";

export type Company = {
  id: string;
  name: string;
  slug: string;
  owner: User;
  registrationCode: string;
  vatCode: string;
  address: string;
  city: string;
  county: string;
  country: string;
  bank: string;
  iban: string;
};

export const getCompany = (id: string) => {
  const requestPath = `${baseUrl}/${id}`;

  return client.get(requestPath, null);
};
export const getCompanies = () => {
  const requestPath = `${baseUrl}`;

  return client.get(requestPath, null);
};

export const deleteCompany = (id: string) => {
  const requestPath = `${baseUrl}/${id}`;

  return client.delete(requestPath, {});
};

export const addCompany = (company: Company) => {
  const requestPath = `${baseUrl}`;

  return client.post(requestPath, company, {});
};

export const updateCompany = (id: string, company: Company) => {
  const requestPath = `${baseUrl}/${id}`;

  return client.put(requestPath, company, {});
};
