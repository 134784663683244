import React, { CSSProperties, useCallback, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useSelector } from "react-redux";

import { ObjectType } from "../../slices/Entities/deskSlice";
import { GRID } from "../../utils/variables";
import { ObjectProps } from "./types";

import { canvasModeSelector } from "../../slices/Canvas/canvasSlice";
import { toolbarItemsSelector } from "../../slices/Entities/toolbarSlice";
import { contentStateToEditorState, getElementCursorClassName } from "../../utils/canvas-helpers";
import "./FloorObject.scss";

export default React.forwardRef<HTMLDivElement, ObjectProps>(function FloorObject(props, ref) {
  const [isHovered, setIsHovered] = useState<boolean>(true);

  const toolbarItems = useSelector(toolbarItemsSelector);
  const canvasMode = useSelector(canvasModeSelector);

  const handleFloorObjectClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (props.dragging) return;
    e.stopPropagation();
    if (isWindow) {
      props.onClick(e);
      return;
    }
    if (props.selectedDefaultTool === "ROTATE") {
      props.rotationCallback({
        ...props.item,
        rotation: (props.item.rotation + 90) % 360,
        size: { width: props.item.size.height, height: props.item.size.width },
      });

      return;
    }
    if (props.selectedDefaultTool === "FLIP") {
      props.flipCallback({ ...props.item, flippedY: !props.item.flippedY });
      return;
    }
    props.onClick(e);
  };

  const getImageSrc = useCallback(() => {
    if (!toolbarItems) return "";
    if (props.item.type === "custom" || props.item.type === "desk") {
      const item = toolbarItems.items.find((item) => item.id === props.item.toolbarItemID);
      return item && item.imageSrc ? item.imageSrc : "";
    }
    if (props.item.type === "door" || props.item.type === "stairs") {
      const item = toolbarItems.images[props.item.type];
      return item || "";
    }
    return props.item.src || "";
  }, [props.item.toolbarItemID, toolbarItems?.items]);

  const isWindow = props.item.type === ObjectType.WINDOW;
  const isText = props.item.type === ObjectType.TEXT;
  const editorIsEmpty =
    props.item.type === ObjectType.TEXT && props.item.editorContent.blocks.length === 1 && props.item.editorContent.blocks[0].text === "";

  const objectStyle: CSSProperties = {
    height: !isWindow ? props.item.size?.height * GRID : "",
    width: !isWindow ? props.item.size?.width * GRID : "",
    transform: !isWindow ? `scaleY(${props.item.flippedY ? "-1" : "1"})` : "",
    overflow: isText ? "visible" : "",
    borderRadius: editorIsEmpty ? "0px" : undefined,
    border: `1px solid ${props.item.hasBorder || editorIsEmpty ? "black" : "transparent"}`,
    backgroundColor: `${props.item.hasBackground ? "white" : "transparent"}`,
  };

  const wrapperStyle: CSSProperties = {
    width: isWindow ? 0 : props.style?.width,
  };

  if (!toolbarItems) {
    return <></>;
  }

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      draggable={!isWindow}
      style={{ ...props.style, ...wrapperStyle }}
      onMouseDown={!isWindow ? props.onMouseDown : undefined}
      onMouseUp={props.onMouseUp}
      onTouchEnd={props.onTouchEnd}
      ref={ref}
      className={`content-container ${props.className} ${
        isHovered ? getElementCursorClassName(props.selectedDefaultTool, props.item.type, canvasMode) : ""
      }`}
      onClick={handleFloorObjectClick}
      id={props.item.id}
    >
      <div
        onClick={handleFloorObjectClick}
        className={`placed-element ${props.item.type}-element${props.isSelected && !isWindow ? " active-custom" : ""}`}
        style={objectStyle}
      >
        {getImageSrc() && (
          <img
            crossOrigin=""
            alt="floor-object"
            src={getImageSrc()}
            id={props.item.id}
            className={`${isWindow ? "window-object" : "floor-object"}`}
            style={{
              width: isWindow ? `${props.item.size.width * GRID}px` : "",
              transform: isWindow ? `translate(-50%, 0) rotate(${props.item.rotation}deg)` : "",
              rotate: !isWindow ? `${props.item.rotation}deg` : "",
              objectFit: `${props.item.hasImageStretched ? "fill" : "contain"}`,
            }}
          />
        )}
        {props.item.type === ObjectType.TEXT && (
          <Editor
            readOnly
            stripPastedStyles
            wrapperStyle={{ transform: `rotate(${props.item.rotation}deg` }}
            editorClassName="hidden-scrollbar"
            toolbarClassName="hidden-toolbar"
            editorState={contentStateToEditorState(props.item.editorContent)}
          />
        )}
      </div>
      {!isWindow && props.children}
    </div>
  );
});
