import { useEffect } from "react";
import useMousePosition from "../../../utils/Hooks/useMousePosition";
import { GRID } from "../../../utils/variables";
import { getObjectGridPosition } from "../ObjectsToolbar";

import "./MultilineGuider.scss";

interface IMultilineGuider {
  lineSessionID: string;
  linePointPlaceCallback: (x: number, y: number) => void;
  updateFloorInfo: () => void;
}

function MultilineGuider(props: IMultilineGuider) {
  const { clientX, clientY } = useMousePosition();

  useEffect(() => {
    props.updateFloorInfo();
  }, [`${clientX}${clientY}`]);

  return (
    <div
      className={`multiline-guide${props.lineSessionID} guider-wrapper`}
      onClick={() => props.linePointPlaceCallback(clientX, clientY)}
      style={{
        left: getObjectGridPosition(clientX + GRID / 2, 0),
        top: getObjectGridPosition(clientY + GRID / 2, 0),
      }}
    >
      <div
        className="guider-container"
        style={{
          height: `${GRID}px`,
          width: `${GRID}px`,
        }}
      ></div>
    </div>
  );
}

export default MultilineGuider;
