import { useState, useEffect } from "react";
import { ErrorMessage, Field } from "formik";
import GenericModal from "../GenericModal/genericModal";
import { useDispatch, useSelector } from "react-redux";
import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { allUsersSelectors } from "../../slices/Entities/usersSlice";
import { notifyCreateCompany, notifyUpdateCompany } from "../../utils/notify";
import { useAuth } from "../../utils/AuthHook";
import {
  AccountBalance,
  Badge,
  CreditCard,
  DriveFileRenameOutline,
  Home,
  LocationCity,
  Map,
  Percent,
  Person,
  Public,
  QrCode2,
} from "@mui/icons-material";
import { Company } from "../../services/companyService";
import { createCompany, updateCompanyById } from "../../slices/Entities/companiesSlice";
import { validationSchemaCompany } from "../../utils/ValidationSchemas";

function CompanyModal(props: any) {
  const dispatch = useDispatch<any>();
  let disable = false;
  const users = useSelector(allUsersSelectors);
  const { user, check } = useAuth();
  const [owner, setOwner] = useState<any>(props.isCreate ? "" : props.company.owner.id);

  let currentCompany = props.isCreate
    ? {
        name: "",
        slug: "",
        owner: "",
        registrationCode: "",
        vatCode: "",
        address: "",
        city: "",
        county: "",
        country: "",
        bank: "",
        iban: "",
      }
    : ({
        registrationCode: "",
        address: "",
        city: "",
        county: "",
        country: "",
        bank: "",
        iban: "",
        ...props.company,
        owner: props.company.owner.id,
      } as Company);

  useEffect(() => {
    if (!props.isCreate && !props.isEdit) disable = true;
  }, []);

  const renderButton = () => {
    return (
      <button className="button-modal" type="submit">
        {props.isCreate ? "Create Company" : "Update Company"}
      </button>
    );
  };

  const handleChangeOwner = (value: any) => {
    setOwner(value.target.value);
  };

  const updateCompanyData = (formValue: any) => {
    dispatch(
      updateCompanyById({
        id: props.company.id,
        company: { ...formValue, owner: owner } as Company,
      })
    ).then((result: any) => {
      if (!result.error) {
        props.setSelectedCompany(result.payload);
        props.handleClose();
        notifyUpdateCompany();
      } else {
        props.setErrorMessage(result.payload.response.data);
      }
    });
  };

  const createCompanyFunction = (formValue: any) => {
    dispatch(createCompany(formValue as Company)).then((result: any) => {
      if (!result.error) {
        props.handleClose();
        notifyCreateCompany();
      } else {
        props.setErrorMessage(result.payload.response.data);
      }
    });
  };

  const renderCompany = () => {
    return (
      <div>
        <p className="content-label">Company Information</p>
        <div className="profile-form-container">
          <div>
            <Field name="name">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                  label="NAME"
                  disabled={disable}
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Badge />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="name" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>
          <div>
            <Field name="slug">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                  label="SLUG"
                  disabled={disable}
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutline />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="slug" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>
          <div>
            <Field name="owner">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  id="select-textfield"
                  className={touched && error ? "invalid" : ""}
                  label="OWNER"
                  select
                  disabled={disable}
                  {...field}
                  value={owner}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Person />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChangeOwner}
                >
                  {users.map((user: any) => (
                    <MenuItem value={user.id} key={user.id}>
                      {user.firstName} {user.lastName}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Field>
            <ErrorMessage name="owner" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>

          <div>
            <Field name="registrationCode">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                  label="REGISTRATION CODE"
                  disabled={disable}
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <QrCode2 />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="registrationCode" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>
          <div>
            <Field name="vatCode">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                  label="VAT CODE"
                  disabled={disable}
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Percent />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="vatCode" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>
          <div>
            <Field name="address">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                  label="ADDRESS"
                  disabled={disable}
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Home />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="address" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>

          <div>
            <Field name="city">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                  label="CITY"
                  disabled={disable}
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationCity />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="city" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>

          <div>
            <Field name="county">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                  label="COUNTY"
                  disabled={disable}
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Map />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="county" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>

          <div>
            <Field name="country">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                  label="COUNTRY"
                  disabled={disable}
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Public />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="country" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>

          <div>
            <Field name="bank">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                  label="BANK"
                  disabled={disable}
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountBalance />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="bank" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>

          <div>
            <Field name="iban">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                  label="IBAN"
                  disabled={disable}
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CreditCard />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="iban" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <GenericModal
      hasForm={true}
      renderButton={renderButton}
      handleClose={props.handleClose}
      title={props.isEdit ? "Manage Company" : props.isCreate ? "Create Company" : "View Company"}
      initialValues={currentCompany}
      validationSchema={validationSchemaCompany}
      hasFooter={props.isCreate || props.isEdit}
      buttonAction={props.isCreate ? createCompanyFunction : updateCompanyData}
      renderModalContent={renderCompany}
    ></GenericModal>
  );
}
export default CompanyModal;
