/* eslint-disable no-empty-pattern */
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Edit from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
import SaveOutlined from "@mui/icons-material/SaveOutlined";
import { Button, Checkbox, FormControlLabel, TextareaAutosize } from "@mui/material";
import { ContentState, EditorState, RawDraftContentState, convertToRaw } from "draft-js";
import { useCallback, useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useDispatch, useSelector } from "react-redux";

import { canvasModeSelector, displayAdminModeSelector, showModalReservation } from "../../slices/Canvas/canvasSlice";
import { ObjectType, selectedDesksSelector } from "../../slices/Entities/deskSlice";
import { contentStateToEditorState, getInterval } from "../../utils/canvas-helpers";
import { GRID, TEXT_EDITOR_INLINE_OPTIONS, TEXT_EDITOR_OPTIONS } from "../../utils/variables";
import { CanvasMode, ICoordinate } from "../Canvas/types";
import { ObjectPopup, PopupCheckboxes } from "./types";

import { createPortal } from "react-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { toolbarItemsSelector } from "../../slices/Entities/toolbarSlice";
import { useAuth } from "../../utils/AuthHook";
import { CanvasObject } from "../FloorObject/types";
import "./Popup.scss";
import { max } from "lodash";

export default function Popup(props: ObjectPopup) {
  const initialContent = props.type === "text" ? props.contentState : convertToRaw(ContentState.createFromText(""));

  const [isEditingLabel, setIsEditingLabel] = useState<boolean>(props.isEditingLabelInitial);
  const [label, setLabel] = useState<string>(props.label || "");
  const [content, setContent] = useState<RawDraftContentState>(initialContent);
  const [editor, setEditor] = useState<EditorState>(contentStateToEditorState(content));
  const [checkboxes, setCheckboxes] = useState<PopupCheckboxes>({
    background: props.hasBackground || false,
    border: props.hasBorder || false,
    stretchedImage: props.hasImageStretched || false,
  });

  const canvasMode = useSelector(canvasModeSelector);
  const toolbarItems = useSelector(toolbarItemsSelector);
  const isAdminModeActive: boolean = useSelector(displayAdminModeSelector);
  const selectedDesks: CanvasObject[] = useSelector(selectedDesksSelector);
  const dispatch = useDispatch<any>();

  const popupInnerRef = useRef<HTMLDivElement>(null);

  const { check } = useAuth();

  useEffect(() => {
    setIsEditingLabel(false);
  }, [selectedDesks]);

  useEffect(() => {
    setCheckboxes({ background: props.hasBackground, border: props.hasBorder, stretchedImage: props.hasImageStretched });
  }, [props.hasBackground, props.hasBorder, props.hasImageStretched]);

  useEffect(() => {
    props.label && setLabel(props.label);
  }, [props.label]);

  const getImageSrc = useCallback((): string | undefined => {
    if (!props.object || !toolbarItems) return;
    if (props.type === "custom" || props.type === "desk") {
      return toolbarItems.items.find((item) => item.id === props.object?.toolbarItemID)?.imageSrc || "";
    }
    if (props.type === "door" || props.type === "stairs") {
      return toolbarItems.images[props.type] || undefined;
    }
    return props.imageSrc;
  }, [props.object, toolbarItems]);

  const getPosition = useCallback(() => {
    if (!popupInnerRef.current || !props.object) {
      return props.position;
    }
    let position: ICoordinate = { x: props.position.x, y: props.position.y };
    if (props.position.y - popupInnerRef.current.offsetHeight - props.navbarHeight < 0) {
      const defaultPopupY = popupInnerRef.current.offsetHeight + props.object.size.height * GRID + props.position.y + GRID;
      const minPopupY = popupInnerRef.current.offsetHeight + props.navbarHeight;
      position.y = max([defaultPopupY, minPopupY]) || defaultPopupY;
    }
    if (props.position.x - popupInnerRef.current.offsetWidth / 2 < 0) {
      position.x = popupInnerRef.current.offsetWidth / 2 + GRID / 2;
    }
    return position;
  }, [props.position]);

  const handleReservationClick = (): void => {
    dispatch(showModalReservation(true));
  };

  const onEditHandler = (): void => {
    setIsEditingLabel(true);
  };

  const onSaveHandler = (): void => {
    setIsEditingLabel(false);
    props.type === ObjectType.TEXT ? props.contentStateCallback(content) : props.labelSaveCallback(label);
  };

  const handleLabelChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setLabel(e.target.value);
  };

  const onBackgroundToggle = ({}, checked: boolean): void => {
    setCheckboxes((prev) => ({ ...prev, background: checked }));
    props.backgroundCheckboxCallback(checked);
  };

  const onBorderToggle = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setCheckboxes((prev) => ({ ...prev, border: checked }));
    props.borderCheckboxCallback(checked);
  };

  const onImageStretchToggle = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    setCheckboxes((prev) => ({ ...prev, stretchedImage: checked }));
    props.stretchedImageCheckboxCallback(checked);
  };

  const hasCheckboxes = (type: `${ObjectType}`): boolean => {
    const deniedObjects: `${ObjectType}`[] = ["room", "window", "tool", "text"];
    return !deniedObjects.includes(type);
  };

  return (
    <>
      {createPortal(
        <>
          {props.position && (
            <div
              className="popup-container"
              style={{
                transform: `translate(${getPosition().x}px,${getPosition().y}px`,
                display: `${props.show ? "block" : "none"}`,
              }}
            >
              <div ref={popupInnerRef} className="popup-wrapper">
                <div className="popup">
                  <div className="spacer" />
                  <div className="popup-upper-container">
                    {!!getImageSrc() && <img crossOrigin="" className="popup-image" src={getImageSrc()} alt={props.name} />}
                    <div>
                      {props.numberOfItems <= 1 && (
                        <div className="popup-label-container">
                          {isEditingLabel && props.type === ObjectType.TEXT ? (
                            <Editor
                              onContentStateChange={setContent}
                              editorState={editor}
                              onEditorStateChange={setEditor}
                              editorClassName="popup-editor"
                              toolbar={{
                                options: TEXT_EDITOR_OPTIONS,
                                inline: { options: TEXT_EDITOR_INLINE_OPTIONS },
                              }}
                            />
                          ) : (
                            <TextareaAutosize
                              autoFocus
                              style={{ backgroundColor: "white" }}
                              disabled={!isEditingLabel}
                              className="textarea-label"
                              id="review-text"
                              onChange={handleLabelChange}
                              placeholder={canvasMode === CanvasMode.EDIT ? "Enter label..." : undefined}
                              value={isEditingLabel ? label : props.label}
                            />
                          )}
                          {canvasMode === CanvasMode.EDIT &&
                            (isEditingLabel ? (
                              <Save className="popup-label-icon" onClick={onSaveHandler} />
                            ) : (
                              <Edit className="popup-label-icon" onClick={onEditHandler} />
                            ))}
                        </div>
                      )}
                      <div className="popup-message-container">
                        {props.message &&
                          (typeof props.message === "string" ? (
                            <div>{props.message}</div>
                          ) : (
                            <div>
                              <div className="popup-employee-text">{props.message.employee}</div>
                              {props.message.reservedBy && <div className="popup-reserved-by-text">{props.message.reservedBy}</div>}
                            </div>
                          ))}
                        {props.intervals && canvasMode === CanvasMode.VIEW && <div>{getInterval(props.intervals)}</div>}
                        {!isAdminModeActive &&
                          canvasMode === CanvasMode.VIEW &&
                          props.canAddReservation &&
                          !props.canDeleteReservation &&
                          check("reserve.me") &&
                          props.numberOfItems >= 1 && (
                            <Button data-testid="popup-new-reservation-button" className="reservation-button" onClick={handleReservationClick}>
                              <i className="fas fa-plus reservation-icon" />
                              <span className="reservation-text">New Reservation</span>
                            </Button>
                          )}
                        {props.canDeleteReservation && canvasMode === CanvasMode.VIEW && props.numberOfItems === 1 && (
                          <Button
                            data-testid="popup-delete-reservation-button"
                            className="reservation-button"
                            onClick={props.deleteReservationCallback}
                          >
                            <i className="fas fa-trash delete-reservation-icon" />
                            <span className="delete-reservation-text">
                              {`${isAdminModeActive ? "Delete all reservations" : "Delete reservation"}`}
                            </span>
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="popup-content">
                    <div className="popup-lower-container">
                      {canvasMode === CanvasMode.EDIT && hasCheckboxes(props.type) && props.numberOfItems === 1 && (
                        <div className="popup-checkboxes-container">
                          <FormControlLabel
                            control={<Checkbox onChange={onBorderToggle} className="popup-checkbox" checked={checkboxes.border} />}
                            color="success"
                            label="Border"
                          />
                          <FormControlLabel
                            control={<Checkbox onChange={onBackgroundToggle} className="popup-checkbox" checked={checkboxes.background} />}
                            color="success"
                            label="Background"
                          />
                          <FormControlLabel
                            control={<Checkbox onChange={onImageStretchToggle} className="popup-checkbox" checked={checkboxes.stretchedImage} />}
                            color="success"
                            label="Stretched image"
                          />
                        </div>
                      )}
                      {canvasMode === CanvasMode.EDIT && props.numberOfItems === 1 && props.type === "desk" && (
                        <SaveOutlined onClick={props.saveSelectedObject} className="popup-button popup-save-button" />
                      )}
                      {canvasMode === CanvasMode.EDIT && (
                        <DeleteOutlinedIcon
                          className="popup-button popup-delete-button"
                          onClick={props.type === ObjectType.ROOM ? props.deleteSelectedRoom : props.deleteSelectedObjects}
                        />
                      )}
                    </div>
                  </div>
                  <div className="spacer" />
                </div>
              </div>
            </div>
          )}
        </>,
        document.getElementsByClassName("react-grid-layout")[0]
      )}
    </>
  );
}
