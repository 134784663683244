import { ErrorMessage, Field, Formik } from "formik";
import GenericModal from "../../components/GenericModal/genericModal";
import { Button, InputAdornment, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getInfoAboutMe, updateInfoAboutMe } from "../../slices/Auth/authSlice";
import { validationSchemaUserWithFullName } from "../../utils/ValidationSchemas";
import { WAYMAN_PROVIDER } from "../../utils/variables";
import DefaultBuildingFloor from "../DefaultBuildingFloor/defaultBuildingFloor";
import {
  addConfigurationData,
  configurationsSelector,
  getAllConfigurations,
  myDefaultBuildingSelector,
  myDefaultFloorSelector,
} from "../../slices/Entities/configurationsSlice";
import { getFloorsFromBuilding } from "../../slices/Entities/floorSlice";
import { updateUserById } from "../../slices/Entities/usersSlice";
import { useAuth } from "../../utils/AuthHook";

function MyProfile(props: any) {
  const { handleClose, user, setErrorMessage } = props;
  const dispatch = useDispatch<any>();
  const { check } = useAuth();
  let currentUser = {
    username: user.username,
    password: "",
    email: user.email,
    fullName: `${user.firstName} ${user.lastName}`,
  };
  let disable = false;
  const [settings, setSettings] = useState<any>({
    building: "",
    floor: "",
  });
  const [floors, setFloors] = useState<any>([]);
  const [userGroups, setUserGroups] = useState<any>([]);
  const configurations = useSelector(configurationsSelector);
  const myDefaultBuilding = useSelector(myDefaultBuildingSelector);
  const myDefaultFloor = useSelector(myDefaultFloorSelector);
  useEffect(() => {
    dispatch(getAllConfigurations());
  }, [dispatch]);

  useEffect(() => {
    if (settings.building !== "" || settings.floor !== "") {
      return;
    }
    const getData = async () => {
      let settingsCopy = { ...settings };
      if (myDefaultBuilding && myDefaultBuilding !== settings.building) {
        settingsCopy.building = myDefaultBuilding.value;
        await dispatch(getFloorsFromBuilding(settingsCopy.building)).then((result: any) => {
          if (!result.error) {
            setFloors(result.payload);
          }
        });
      }
      if (myDefaultFloor && myDefaultFloor !== settings.floor) {
        settingsCopy.floor = myDefaultFloor.value;
      }
      setSettings(settingsCopy);
    };
    getData();
  }, [configurations]);

  const getData = () => {
    dispatch(getInfoAboutMe());
    setUserGroups(user.groups);
  };
  const refreshData = async () => {
    await dispatch(
      updateInfoAboutMe({
        id: user._id,
        username: user.username,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        password: user.password,
        avatar: "",
        groups: undefined,
        company: user.company,
      })
    ).then((result: any) => {
      if (!result.error) {
        handleClose();
      } else {
        setErrorMessage(result.payload.response.data);
      }
    });
    getData();
  };
  const checkProvider = () => {
    if (user.provider === WAYMAN_PROVIDER) {
      disable = true;
    }
  };

  useEffect(() => {
    getData();
    checkProvider();
  }, []);

  const renderButton = () => {
    return (
      <button className="button-modal" type="submit">
        Save Data
      </button>
    );
  };

  const renderProfile = () => {
    checkProvider();
    return (
      <div>
        <p className="content-label">User Information</p>
        <div className="profile-form-container">
          <TextField
            className="input-with-icon-textfield"
            label="PROVIDER"
            defaultValue={user.provider}
            disabled={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-globe"></i>
                </InputAdornment>
              ),
            }}
          />
          <div>
            <Field name="fullName">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                  label="FULL NAME"
                  disabled={disable}
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <i className="fas fa-signature"></i>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="fullName" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>

          <div>
            <Field name="username">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                  label="USERNAME"
                  disabled={disable}
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <i className="far fa-user"></i>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="username" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>

          <div>
            <Field name="email">
              {({ field, meta: { touched, error } }: any) => (
                <TextField
                  className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                  label="EMAIL"
                  disabled={disable}
                  {...field}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <i className="far fa-envelope"></i>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
            <ErrorMessage name="email" render={(msg) => <span className="error-msg">{msg}</span>} />
          </div>

          {!disable ? (
            <div>
              <Field name="password">
                {({ field, meta: { touched, error } }: any) => (
                  <TextField
                    className={touched && error ? "input-with-icon-textfield invalid" : "input-with-icon-textfield"}
                    label="PASSWORD"
                    type="password"
                    disabled={disable}
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <i className="fa fa-key"></i>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </Field>
              <ErrorMessage name="password" render={(msg) => <span className="error-msg">{msg}</span>} />
            </div>
          ) : (
            <></>
          )}

          {disable ? (
            <Button id="refresh-button" onClick={refreshData}>
              <i className="fas fa-undo fa-flip-horizontal"></i>&nbsp; Refresh Data
            </Button>
          ) : (
            <></>
          )}
        </div>
        {check("configurations.create.me") ? (
          <>
            <p className="content-label">Settings</p>
            <Formik initialValues={settings} onSubmit={() => {}} enableReinitialize>
              <DefaultBuildingFloor settings={settings} setSettings={setSettings} floors={floors} setFloors={setFloors} />
            </Formik>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };
  const updateMyData = async (formValue: {
    username: string;
    password: string;
    email: string;
    fullName: string;
    building: string;
    floor: string;
  }) => {
    let hasError = false;
    if (user.provider !== WAYMAN_PROVIDER && check("me.update")) {
      const { username, password, email, fullName } = formValue;
      currentUser = formValue;
      const firstName = fullName.substring(0, fullName.lastIndexOf(" "));
      const lastName = fullName.substring(fullName.lastIndexOf(" ") + 1, fullName.length);
      dispatch(
        updateUserById({
          id: user._id,
          username: username,
          email: email,
          firstName: firstName,
          lastName: lastName,
          password: password,
          groups: userGroups.map((group: any) => group.id),
          company: user.company,
        })
      ).then((result: any) => {
        if (!result.error) {
          getData();
          formValue.password = "";
        } else {
          setErrorMessage(result.payload.response.data);
          hasError = true;
        }
      });
    }
    if (settings.building !== myDefaultBuilding?.value) {
      await dispatch(addConfigurationData({ key: "myDefaultBuilding", value: settings.building, user: user.id }));
    }

    if (settings.floor !== myDefaultFloor?.value) {
      await dispatch(addConfigurationData({ key: "myDefaultFloor", value: settings.floor, user: user.id }));
    }
    if (!hasError) {
      handleClose();
    }
  };

  return (
    <GenericModal
      handleClose={handleClose}
      title={"My Profile"}
      initialValues={currentUser}
      hasFooter={true}
      hasForm={true}
      validationSchema={validationSchemaUserWithFullName}
      buttonAction={updateMyData}
      renderModalContent={renderProfile}
      renderButton={renderButton}
    ></GenericModal>
  );
}

export default MyProfile;
