import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allGroupsData, allGroupsSelector } from "../../slices/Entities/groupsSlice";
import { addUser, editUserSelector, getUserByIdData, updateUserById } from "../../slices/Entities/usersSlice";
import Navbar from "../../pages/Navbar/navbar";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notifyCreateUser, notifyUpdateUser } from "../../utils/notify";
import { useAuth } from "../../utils/AuthHook";
import { WAYMAN_PROVIDER } from "../../utils/variables";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { validationSchemaNewUser } from "../../utils/ValidationSchemas";
import { companiesSelector, getAllCompanies, getCompanyById, myCompanySelector } from "../../slices/Entities/companiesSlice";

function Permissions() {
  const companies = useSelector(companiesSelector);
  const myCompany = useSelector(myCompanySelector);
  const userInfo = useSelector(editUserSelector);
  const allgroups = useSelector(allGroupsSelector);
  const { user, check } = useAuth();
  const { pathname } = useLocation();
  const { userId } = useParams();
  const [company, setCompany] = useState<any>("");
  const [availableGroups, setAvailableGroups] = useState<any[]>([]);
  const [userInformation, setUserInformation] = useState<any>({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    provider: "",
    confirmPassword: "",
    company: company,
  });
  const [userGroups, setUserGroups] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  let disable = false;

  const getAllGroups = async () => {
    await dispatch(allGroupsData());
  };

  useEffect(() => {
    getAllGroups();
    if (check("super-admin")) dispatch(getAllCompanies());
    if (check("myCompany.update")) dispatch(getCompanyById(user.company.id));
  }, [dispatch]);

  useEffect(() => {
    if (pathname !== "/createNewUser") {
      const companyToSet = myCompany ? myCompany.id : "";
      setCompany(companyToSet);
      const userInfo = { ...userInformation };
      userInfo.company = company;
      setUserInformation(userInfo);
      return;
    }
    const companyToSet = companies.length > 0 ? "" : myCompany ? myCompany.id : "";
    setCompany(companyToSet);
    const userInfo = { ...userInformation };
    userInfo.company = company;
    setUserInformation(userInfo);
  }, [myCompany, companies]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userId) {
          await dispatch(getUserByIdData(userId)).then((result: any) => {
            setUserGroups(result.payload.groups);
            setUserInformation(result.payload);
          });
        } else {
          const defaultGroup = allgroups.filter((group: any) => group.isDefault === true);
          setUserGroups(defaultGroup);
          setUserInformation({
            firstName: "",
            lastName: "",
            username: "",
            email: "",
            password: "",
            confirmPassword: "",
            groups: userGroups.map((group: any) => group.id),
          });
        }
      } catch (error: any) {
        setErrorMessage(error);
      }
    };
    fetchData();
  }, [dispatch, allgroups]);

  useEffect(() => {
    if (!availableGroups || !userInfo) {
      return;
    }
    let available = allgroups.filter((el: any) => userGroups?.every((f: any) => f._id !== el._id));
    setAvailableGroups(available);
  }, [userGroups, dispatch]);

  const checkProvider = () => {
    if (userInformation.provider === WAYMAN_PROVIDER) {
      disable = true;
    }
  };

  const handleChangeCompany = (value: any) => {
    const userInfo = { ...userInformation };
    userInfo.company = value.target.value;
    setUserInformation(userInfo);
    setCompany(value.target.value);
  };

  const handleDrop = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    let groupInfo;
    if (destination.droppableId === "userGroups") {
      groupInfo = availableGroups[source.index];
      const newUserGroups = [...userGroups];
      newUserGroups.splice(destination.index, 0, groupInfo);
      const newGroups = [...availableGroups];
      newGroups.splice(source.index, 1);
      setUserGroups(newUserGroups);
      setAvailableGroups(newGroups);
      return;
    } else {
      groupInfo = userGroups[source.index];
      const newUserGroups = [...userGroups];
      const newGroups = [...availableGroups];
      newGroups.splice(destination.index, 0, groupInfo);
      newUserGroups.splice(source.index, 1);
      setUserGroups(newUserGroups);
      setAvailableGroups(newGroups);
    }
  };

  const addNewUser = () => {
    dispatch(
      addUser({
        username: userInformation.username,
        password: userInformation.password,
        email: userInformation.email,
        firstName: userInformation.firstName,
        lastName: userInformation.lastName,
        avatar: "https://wayman.wayfare.ro/images/no_image_50x50.png",
        groups: userGroups.map((group: any) => group.id),
        company: company,
      })
    ).then((result: any) => {
      if (!result.error) {
        notifyCreateUser();
      } else {
        setErrorMessage(result.payload.response.data);
      }
    });
  };
  const updateUserInfo = () => {
    dispatch(
      updateUserById({
        id: userInfo.id,
        firstName: userInformation.firstName,
        lastName: userInformation.lastName,
        username: userInformation.username,
        email: userInformation.email,
        password: "",
        groups: userGroups.map((group: any) => group.id),
        company: company,
      })
    ).then((result: any) => {
      if (!result.error) {
        notifyUpdateUser();
      } else {
        setErrorMessage(result.payload.response.data);
      }
    });
  };

  const handleChangeFirstName = (event: any) => {
    setUserInformation({
      firstName: event.target.value,
      lastName: userInformation.lastName,
      username: userInformation.username,
      email: userInformation.email,
      provider: userInformation.provider,
      password: "",
      confirmPassword: "",
    });
  };

  const handleChangeLastName = (event: any) => {
    setUserInformation({
      firstName: userInformation.firstName,
      lastName: event.target.value,
      username: userInformation.username,
      email: userInformation.email,
      provider: userInformation.provider,
      password: "",
      confirmPassword: "",
    });
  };
  const handleChangeUsername = (event: any) => {
    setUserInformation({
      firstName: userInformation.firstName,
      lastName: userInformation.lastName,
      username: event.target.value,
      email: userInformation.email,
      provider: userInformation.provider,
      password: "",
      confirmPassword: "",
    });
  };
  const handleChangeEmail = (event: any) => {
    setUserInformation({
      firstName: userInformation.firstName,
      lastName: userInformation.lastName,
      username: userInformation.username,
      email: event.target.value,
      provider: userInformation.provider,
      password: "",
      confirmPassword: "",
    });
  };

  const handleChangePassword = (event: any) => {
    setUserInformation({
      firstName: userInformation.firstName,
      lastName: userInformation.lastName,
      username: userInformation.username,
      email: userInformation.email,
      password: event.target.value,
      confirmPassword: "",
    });
  };

  const handleChangeConfirmPassword = (event: any) => {
    setUserInformation({
      firstName: userInformation.firstName,
      lastName: userInformation.lastName,
      username: userInformation.username,
      email: userInformation.email,
      password: userInformation.password,
      confirmPassword: event.target.value,
    });
  };
  const toUsersList = () => {
    navigate("/users");
  };
  checkProvider();

  return (
    <div>
      <Navbar />
      <div className="content">
        <div className="btn-container">
          <button type="button" className="back-button" onClick={toUsersList}>
            Go to Users List
          </button>
          {pathname === "/createNewUser" ? (
            <button type="button" className="update-button" onClick={addNewUser}>
              Create
            </button>
          ) : (
            <button type="button" className="update-button" onClick={updateUserInfo}>
              Update
            </button>
          )}
        </div>
        <div className="informations">
          <div className="update-container">
            <div className="info-container">
              <p className="content-label">User Information</p>
              <Formik enableReinitialize={true} initialValues={userInformation} validationSchema={validationSchemaNewUser} onSubmit={() => {}}>
                <div>
                  <Form className="modal-form">
                    <div>
                      <div className="personal-data">
                        <div className="personal-data-title">First Name</div>
                        <Field name="firstName">
                          {({ field, meta: { touched, error } }: any) => (
                            <TextField
                              name="firstName"
                              autoComplete="none"
                              disabled={disable}
                              className={
                                touched && error ? "input-with-icon-textfield invalid input-container" : "input-with-icon-textfield input-container"
                              }
                              {...field}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <i className="far fa-user"></i>
                                  </InputAdornment>
                                ),
                              }}
                              onChange={handleChangeFirstName}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="firstName" render={(msg) => <span className="error-msg">{msg}</span>} />
                      </div>
                      <div className="personal-data">
                        <div className="personal-data-title">Last Name</div>
                        <Field name="lastName">
                          {({ field, meta: { touched, error } }: any) => (
                            <TextField
                              name="lastName"
                              autoComplete="none"
                              disabled={disable}
                              className={
                                touched && error ? "input-with-icon-textfield invalid input-container" : "input-with-icon-textfield input-container"
                              }
                              {...field}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <i className="far fa-user"></i>
                                  </InputAdornment>
                                ),
                              }}
                              onChange={handleChangeLastName}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="lastName" render={(msg) => <span className="error-msg">{msg}</span>} />
                      </div>
                      <div className="personal-data">
                        <div className="personal-data-title">Username</div>
                        <Field name="username">
                          {({ field, meta: { touched, error } }: any) => (
                            <TextField
                              name="username"
                              disabled={disable}
                              autoComplete="none"
                              className={
                                touched && error ? "input-with-icon-textfield invalid input-container" : "input-with-icon-textfield input-container"
                              }
                              {...field}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <i className="fas fa-signature"></i>
                                  </InputAdornment>
                                ),
                              }}
                              onChange={handleChangeUsername}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="username" render={(msg) => <span className="error-msg">{msg}</span>} />
                      </div>
                      <div className="personal-data">
                        <div className="personal-data-title">Email</div>
                        <Field name="email">
                          {({ field, meta: { touched, error } }: any) => (
                            <TextField
                              name="email"
                              disabled={disable}
                              autoComplete="none"
                              className={
                                touched && error ? "input-with-icon-textfield invalid input-container" : "input-with-icon-textfield input-container"
                              }
                              {...field}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <i className="far fa-envelope"></i>
                                  </InputAdornment>
                                ),
                              }}
                              onChange={handleChangeEmail}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="email" render={(msg) => <span className="error-msg">{msg}</span>} />
                      </div>
                      {pathname !== "/createNewUser" ? (
                        <div className="personal-data">
                          <div className="personal-data-title">Provider</div>
                          <Field name="provider">
                            {({ field, meta: { touched, error } }: any) => (
                              <TextField
                                disabled={true}
                                autoComplete="none"
                                name="provider"
                                className="input-with-icon-textfield  input-container"
                                {...field}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <i className="fas fa-globe"></i>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      ) : (
                        <>
                          <div className="personal-data">
                            <div className="personal-data-title">Password</div>
                            <Field name="password">
                              {({ field, meta: { touched, error } }: any) => (
                                <TextField
                                  name="password"
                                  className={
                                    touched && error
                                      ? "input-with-icon-textfield invalid input-container"
                                      : "input-with-icon-textfield input-container"
                                  }
                                  type="password"
                                  autoComplete="new-password"
                                  {...field}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <i className="fa fa-key"></i>
                                      </InputAdornment>
                                    ),
                                  }}
                                  onChange={handleChangePassword}
                                />
                              )}
                            </Field>
                            <ErrorMessage name="password" render={(msg) => <span className="error-msg">{msg}</span>} />
                          </div>
                          <div className="personal-data">
                            <div className="personal-data-title">Confirm Password</div>
                            <Field name="confirmPassword">
                              {({ field, meta: { touched, error } }: any) => (
                                <TextField
                                  name="confirm"
                                  className={
                                    touched && error
                                      ? "input-with-icon-textfield invalid input-container"
                                      : "input-with-icon-textfield input-container"
                                  }
                                  type="password"
                                  {...field}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <i className="fa fa-key"></i>
                                      </InputAdornment>
                                    ),
                                  }}
                                  onChange={handleChangeConfirmPassword}
                                />
                              )}
                            </Field>
                            <ErrorMessage name="confirmPassword" render={(msg) => <span className="error-msg">{msg}</span>} />
                          </div>
                        </>
                      )}
                      {companies.length > 0 ? (
                        <div className="personal-data">
                          <div className="personal-data-title">Company</div>
                          <Field name="company">
                            {({ field, meta: { touched, error } }: any) => (
                              <TextField
                                name="company"
                                id="select-textfield"
                                className={
                                  touched && error ? "input-with-icon-textfield invalid input-container" : "input-with-icon-textfield input-container"
                                }
                                disabled={!check("super-admin")}
                                select
                                {...field}
                                value={company}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <i className="fa fa-building"></i>
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={handleChangeCompany}
                              >
                                {companies.map((company: any) => (
                                  <MenuItem value={company.id} key={company.id}>
                                    {company.name}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          </Field>
                          <ErrorMessage name="company" render={(msg) => <span className="error-msg">{msg}</span>} />
                        </div>
                      ) : myCompany ? (
                        <div className="personal-data">
                          <div className="personal-data-title">Company</div>
                          <Field name="company">
                            {({ field, meta: { touched, error } }: any) => (
                              <TextField
                                name="company"
                                id="select-textfield"
                                className={
                                  touched && error ? "input-with-icon-textfield invalid input-container" : "input-with-icon-textfield input-container"
                                }
                                select
                                disabled={true}
                                {...field}
                                value={company}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <i className="fa fa-building"></i>
                                    </InputAdornment>
                                  ),
                                }}
                              >
                                <MenuItem value={myCompany.id} key={myCompany.id}>
                                  {myCompany.name}
                                </MenuItem>
                              </TextField>
                            )}
                          </Field>
                          <ErrorMessage name="company" render={(msg) => <span className="error-msg">{msg}</span>} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Form>
                </div>
              </Formik>
            </div>
          </div>
          <div className="permissions-container">
            <p className="content-label">User Groups</p>
            {check("user.groups.attach") ? (
              <DragDropContext onDragEnd={handleDrop}>
                <div className="droppable-content">
                  <Droppable droppableId="availableGroups" direction="vertical">
                    {(provided, snapshot) => (
                      <div className="all-groups" {...provided.droppableProps} ref={provided.innerRef}>
                        <span className="title-groups"> Available groups</span>
                        {availableGroups?.map((group: any, index: any) => (
                          <Draggable key={group?.id} draggableId={group?.id} index={index}>
                            {(provided, snapshot) => (
                              <div className="item-container" ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                                <span {...provided.dragHandleProps}>
                                  <b> {group?.name}</b>
                                </span>
                                <div></div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <Droppable droppableId="userGroups" direction="vertical">
                    {(provided, snapshot) => (
                      <div className="user-groups" {...provided.droppableProps} ref={provided.innerRef}>
                        <span className="title-groups"> Assigned groups</span>
                        {userGroups?.map((userGroup: any, index: any) => (
                          <Draggable key={userGroup?.id} draggableId={userGroup?.id} index={index}>
                            {(provided, snapshot) => (
                              <div className="item-container" ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
                                <span {...provided.dragHandleProps}>
                                  <b>{userGroup?.name}</b>
                                </span>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              </DragDropContext>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Permissions;
