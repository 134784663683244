import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { editBuildingData } from "../../slices/Entities/buildingSlice";
import { useState } from "react";
import { selectedFloorSelector, updateFloorData } from "../../slices/Entities/floorSlice";
import { Form, Formik } from "formik";
import { selectedBuildingSelector } from "../../slices/Entities/buildingSlice";

function EditItemPopup(props: any) {
  const [input, setInput] = useState("");
  const dispatch = useDispatch<any>();
  const selectedBuilding = useSelector(selectedBuildingSelector);
  const selectedFloor = useSelector(selectedFloorSelector);

  const onChangeHandler = (event: any) => {
    setInput(event.target.value);
  };
  const onSaveHandler = () => {
    if (props.item === "building") {
      dispatch(editBuildingData({ id: selectedBuilding.id, name: input })).then((result: any) => {
        if (result.error) {
          props.setErrorMessage(result.payload.response.data);
        } else {
          props.getNewItem(input, props.value);
        }
      });
    } else if (props.item === "floor") {
      dispatch(
        updateFloorData({
          buildingId: selectedBuilding.id,
          floorId: selectedFloor.id,
          name: input,
          canvas: selectedFloor.canvasData,
        })
      ).then((result: any) => {
        if (result.error) {
          props.setErrorMessage(result.payload.response.data);
        } else {
          props.getNewItem(input, props.value);
        }
      });
    }
    onCancelHandler();
  };

  const onCancelHandler = () => {
    setInput("");
    props.handleClose();
  };

  return (
    <div className="container">
      <Dialog open={props.open} onClose={onCancelHandler} disableEscapeKeyDown>
        <DialogTitle>Edit {props.item} name</DialogTitle>
        <Formik initialValues={{}} onSubmit={onSaveHandler}>
          <Form>
            <DialogContent>
              <TextField autoFocus margin="dense" type="text" fullWidth variant="standard" defaultValue={props.value} onChange={onChangeHandler} />
            </DialogContent>
            <DialogActions>
              <button type="submit" className="save-button">
                Save
              </button>
              <button type="button" className="cancel-button" onClick={onCancelHandler}>
                Cancel
              </button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </div>
  );
}

export default EditItemPopup;
