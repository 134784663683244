import { isUndefined } from "lodash";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Canvas from "../../components/Canvas/Canvas";

import { Button } from "@mui/material";
import ErrorNotification from "../../components/ErrorNotification/errorNotification";
import { easterEggEnabledSelector, toggleEasterEgg } from "../../slices/Canvas/canvasSlice";
import Navbar from "../Navbar/navbar";
import { IFloorInfo } from "../../components/Canvas/types";
import { selectedFloorSelector } from "../../slices/Entities/floorSlice";

export default function Dashboard() {
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [clickCount, setClickCount] = useState<number>(0);

  const dispatch = useDispatch<any>();
  const easterEggEnabled = useSelector(easterEggEnabledSelector);
  const floorInfo: IFloorInfo = useSelector(selectedFloorSelector);

  const resetErrorMessage = () => {
    setErrorMessage(null);
  };

  const checkMisterEgg = () => {
    if (clickCount < 5) {
      setClickCount(clickCount + 1);
      return;
    }
    if (!easterEggEnabled) {
      toast.success("Mircea vine in ajutorul tau!", { position: "top-center" });
    }
    dispatch(toggleEasterEgg());
    setClickCount(0);
  };

  return (
    <div className="dashboard-container">
      <Navbar />
      <div className="dashboard-content">
        <div className="legend">
          <Button id="legend-btn" onClick={checkMisterEgg} />
        </div>
        {!isUndefined(errorMessage) && <ErrorNotification message={errorMessage} onClose={resetErrorMessage} />}
      </div>
      <div>{floorInfo && <Canvas setErrorMessage={setErrorMessage} />}</div>
    </div>
  );
}
