import * as Yup from "yup";

export const validationSchemaTeam = Yup.object().shape({
  name: Yup.string().required("Team Name is required"),
  manager: Yup.string().required("Manager is required"),
});

export const validationSchemaUserWithFullName = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  email: Yup.string().email("This is not a valid email.").required("This field is required!"),
  fullName: Yup.string()
    .required("Full Name is required")
    .matches(/(\w\s\w)+/, "Full Name must contain First Name and Last Name"),
  password: Yup.string().min(6, "Password must be at least 6 characters").max(40, "Password must not exceed 40 characters"),
});

export const validationSchemaNewUser = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
  confirmPassword: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters")
    .oneOf([Yup.ref("password"), null as any], "Passwords must match"),
  email: Yup.string().email("This is not a valid email.").required("This field is required!"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
});

export const validationSchemaCompany = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  slug: Yup.string().required("Slug is required"),
  owner: Yup.string().required("Owner is required"),
  vatCode: Yup.string().required("VAT Code is required"),
});
