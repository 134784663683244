import ObjectID from "bson-objectid";
import { ObjectType } from "../../../slices/Entities/deskSlice";
import { GRID } from "../../../utils/variables";
import { getObjectGridPosition } from "../ObjectsToolbar";
import { DefaultToolbarTool, IPlacedObject, IToolbarState, ToolbarActions, ToolbarActionTypes } from "../types";

export const toolbarReducer = (state: IToolbarState, action: ToolbarActions): IToolbarState => {
  switch (action.type) {
    case ToolbarActionTypes.ITEM_CLICK:
      return {
        ...state,
        selectedDefaultTool: null,
        selectedCustomTool: action.payload.object,
      };

    case ToolbarActionTypes.PLACE_OBJECT:
      if (!state.selectedCustomTool) {
        return state;
      }
      const width = state.selectedCustomTool.size.width;
      const height = state.selectedCustomTool.size.height;
      const newObject: IPlacedObject = {
        ...state.selectedCustomTool,
        position: {
          x: getObjectGridPosition(action.payload.clientX, width) - (width * GRID) / 2 - 0.5,
          y: getObjectGridPosition(action.payload.clientY, height) - (height * GRID) / 2 - 0.5,
        },
        id: action.payload.objectID,
        rotation: 0,
        hasBorder: state.selectedCustomTool.type === ObjectType.DOOR || state.selectedCustomTool.type === ObjectType.STAIRS ? false : true,
        hasBackground: state.selectedCustomTool.type === ObjectType.TEXT ? false : true,
      };
      action.payload.objectChangeCallback?.(newObject);
      return state.selectedDefaultTool === DefaultToolbarTool.TEXT ? { ...state, selectedDefaultTool: null, selectedCustomTool: null } : state;

    case ToolbarActionTypes.PLACE_LINE_POINT:
      return state;

    case ToolbarActionTypes.TOGGLE_MINIMIZE:
      return { ...state, isMinimized: !state.isMinimized };

    case ToolbarActionTypes.DESELECT_TOOL:
      return {
        ...state,
        selectedCustomTool: null,
        selectedDefaultTool: null,
        selectedDefaultAction: null,
        lineSessionID: null,
      };

    case ToolbarActionTypes.ACTIVATE_DEFAULT_TOOL:
      let lineSessionID: string | null = state.lineSessionID;
      if (action.payload.tool !== DefaultToolbarTool.POLY_LINE) {
        lineSessionID = null;
      }
      if (state.lineSessionID === null && action.payload.tool === DefaultToolbarTool.POLY_LINE) {
        lineSessionID = ObjectID(new Date().getTime()).toHexString();
      }
      return {
        ...state,
        selectedDefaultTool: action.payload.tool,
        selectedDefaultAction: null,
        selectedCustomTool: action.payload.item,
        lineSessionID,
      };
    case ToolbarActionTypes.ACTIVATE_DEFAULT_ACTION:
      return {
        ...state,
        selectedDefaultTool: null,
        selectedCustomTool: null,
        selectedDefaultAction: action.payload,
      };
    default:
      return state;
  }
};
