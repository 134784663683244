import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { teamMembers } from "../../slices/Entities/teamSlice";

function ReserveForOthers(props: any) {
  const { checked, setChecked, setOther, other, selectForTeam } = props;
  const dispatch = useDispatch<any>();

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <div className="others-container">
      <label className="checkbox-label">
        Reserve for others
        <input type="checkbox" checked={checked} onChange={handleChange} />
        <span className="checkmark"></span>
      </label>
      {checked ? (
        <div className="profile-form-container">
          <TextField
            className="input-with-icon-textfield"
            label="NAME"
            value={other.name}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              const obj = { ...other };
              obj.name = event.target.value;
              setOther(obj);
            }}
          />
          <TextField
            type="number"
            className="input-with-icon-textfield"
            label="NUMBER OF SEATS"
            value={other.seats}
            InputProps={{ inputProps: { min: "1", step: "1" } }}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              const obj = { ...other };
              obj.seats = Math.floor(Number(event.target.value));
              if (obj.seats > 1 && obj.seats < 50) {
                dispatch(teamMembers(obj.seats));
                selectForTeam(obj.seats - 1);
              }
              if (obj.seats === 1) {
                dispatch(teamMembers(0));
                selectForTeam(0);
              }
              setOther(obj);
            }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ReserveForOthers;
