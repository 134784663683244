import { createAsyncThunk, createEntityAdapter, createSlice, SerializedError } from "@reduxjs/toolkit";
import {
  addAssignment,
  addAssignmentDifferentIntervals,
  addAssignmentDifferentIntervalsColleagues,
  addAssignmentDifferentIntervalsOthers,
  addAssignmentDifferentIntervalsTeam,
  addAssignmentForColleagues,
  addAssignmentForOthers,
  addTeamAssignment,
  AssignmentData,
  AssignmentDataIntervals,
  deleteAssignment,
  deleteAssignmentForUser,
  deleteAssignments,
  getAssignmentsByDate,
  getAssignmentsByInterval,
  getAssignmentsForActiveTeams,
  getAssignmentsForActiveUsers,
  getMyAssignments,
} from "../../services/assignmentService";
import { Status } from "../StatusEnum";

type InitialStateType = {
  status: Status;
  error: SerializedError | null;
  myAssignments: [];
  assignmentsForActiveUsers: [];
  assignmentsForActiveTeams: [];
};

const assignmentAdapter = createEntityAdapter({
  selectId: (assignment: any) => assignment._id,
  sortComparer: false,
});

const initialState: InitialStateType = assignmentAdapter.getInitialState({
  status: Status.Idle,
  error: null,
  myAssignments: [],
  assignmentsForActiveUsers: [],
  assignmentsForActiveTeams: [],
});

export const getMyAssignment = createAsyncThunk("desks/getMyAssignments", async (userId: string, thunkApi) => {
  try {
    const response = await getMyAssignments(userId);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const getAssignmentByDate = createAsyncThunk("desks/getAssignmentByDate", async (date: string, thunkApi) => {
  try {
    const response = await getAssignmentsByDate(date);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const getAssignmentByDateInterval = createAsyncThunk(
  "desks/getAssignmentByInterval",
  async ({ startDate, endDate, buildingId, floorId }: any, thunkApi) => {
    try {
      const response = await getAssignmentsByInterval(startDate, endDate, buildingId, floorId);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const getAssignmentForActiveUsers = createAsyncThunk("desks/getAssignmentsForActiveUsers", async (_, thunkApi) => {
  try {
    const response = await getAssignmentsForActiveUsers();

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const getAssignmentForActiveTeams = createAsyncThunk("teams/getAssignmentsForActiveTeams", async (_, thunkApi) => {
  try {
    const response = await getAssignmentsForActiveTeams();

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const addAssignmentData = createAsyncThunk("desks/addAssignments", async (assignmentData: AssignmentData, thunkApi) => {
  try {
    const response = await addAssignment(assignmentData.desk, assignmentData.user, assignmentData.startTime, assignmentData.endTime);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const addAssignmentForColleguesData = createAsyncThunk(
  "desks/addAssignmentsForCollegues",
  async (assignmentData: AssignmentData, thunkApi) => {
    try {
      const response = await addAssignmentForColleagues(assignmentData.desk, assignmentData.user, assignmentData.startTime, assignmentData.endTime);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const addAssignmentForOthersData = createAsyncThunk(
  "desks/addAssignmentsForOthers",
  async ({ label, desks, startTime, endTime }: any, thunkApi) => {
    try {
      const response = await addAssignmentForOthers(label, desks, startTime, endTime);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const addAssignmentForDifferentIntervals = createAsyncThunk(
  "desks/addAssignmentForDifferentIntervals",
  async (assignmentData: AssignmentDataIntervals, thunkApi) => {
    try {
      const response = await addAssignmentDifferentIntervals(assignmentData.desk, assignmentData.user, assignmentData.times);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const addAssignmentForDifferentIntervalsCollegues = createAsyncThunk(
  "desks/addAssignmentForDifferentIntervalsCollegues",
  async (assignmentData: AssignmentDataIntervals, thunkApi) => {
    try {
      const response = await addAssignmentDifferentIntervalsColleagues(assignmentData.desk, assignmentData.user, assignmentData.times);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const addAssignmentForDifferentIntervalsOthers = createAsyncThunk(
  "desks/addAssignmentForDifferentIntervalsOthers",
  async ({ label, desks, times }: any, thunkApi) => {
    try {
      const response = await addAssignmentDifferentIntervalsOthers(label, desks, times);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const deleteAssignmentData = createAsyncThunk("desks/deleteAssignment", async (assignmentId: string, thunkApi) => {
  try {
    const response = await deleteAssignment(assignmentId);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const deleteAssignmentsMultiple = createAsyncThunk("desks/deleteAssignments", async (assignmentIDs: string[], thunkApi) => {
  try {
    const response = await deleteAssignments(assignmentIDs);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const deleteAssignmentForUserData = createAsyncThunk("desks/deleteAssignmentForUser", async ({ assignmentId, userId }: any, thunkApi) => {
  try {
    const response = await deleteAssignmentForUser(assignmentId, userId);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const addAssignmentForDifferentIntervalsTeam = createAsyncThunk(
  "teams/addAssignmentsForDifferentIntervalsTeam",
  async ({ teamId, desks, times }: { teamId: string; desks: string[]; times: Date[][] }, thunkApi) => {
    try {
      const response = await addAssignmentDifferentIntervalsTeam(teamId, desks, times);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const addAssignmentForTeam = createAsyncThunk(
  "teams/addAssignmentsForTeam",
  async ({ teamId, desks, startTime, endTime }: { teamId: string; desks: string[]; startTime: Date; endTime: Date }, thunkApi) => {
    try {
      const response = await addTeamAssignment(teamId, desks, startTime, endTime);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

const assignmentsSlice = createSlice({
  name: "assignments",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getMyAssignment.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.myAssignments = payload;
          state.status = Status.Succeeded;
          assignmentAdapter.setMany(state, payload);
        }
      })
      .addCase(getMyAssignment.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getMyAssignment.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(getAssignmentForActiveUsers.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.assignmentsForActiveUsers = payload;
          state.status = Status.Succeeded;
        }
      })
      .addCase(getAssignmentForActiveUsers.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getAssignmentForActiveUsers.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(getAssignmentForActiveTeams.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.assignmentsForActiveTeams = payload;
          state.status = Status.Succeeded;
        }
      })
      .addCase(getAssignmentForActiveTeams.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getAssignmentForActiveTeams.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(getAssignmentByDate.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          assignmentAdapter.upsertMany(state, payload);
        }
      })
      .addCase(getAssignmentByDate.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getAssignmentByDate.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(getAssignmentByDateInterval.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          assignmentAdapter.removeAll(state);
          assignmentAdapter.upsertMany(state, payload);
        }
      })
      .addCase(getAssignmentByDateInterval.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getAssignmentByDateInterval.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(addAssignmentData.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          assignmentAdapter.addMany(state, payload);
          state.status = Status.Succeeded;
        }
      })
      .addCase(addAssignmentData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(addAssignmentData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(addAssignmentForColleguesData.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          assignmentAdapter.addMany(state, payload);
          state.status = Status.Succeeded;
        }
      })
      .addCase(addAssignmentForColleguesData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(addAssignmentForColleguesData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(addAssignmentForDifferentIntervals.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          assignmentAdapter.addMany(state, payload);
          state.status = Status.Succeeded;
        }
      })
      .addCase(addAssignmentForDifferentIntervals.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(addAssignmentForDifferentIntervals.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(addAssignmentForDifferentIntervalsCollegues.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          assignmentAdapter.addMany(state, payload);
          state.status = Status.Succeeded;
        }
      })
      .addCase(addAssignmentForDifferentIntervalsCollegues.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(addAssignmentForDifferentIntervalsCollegues.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(deleteAssignmentData.fulfilled, (state: any, action: any) => {
        const index = state.myAssignments.findIndex((assignment: any) => assignment.id === action.meta.arg);
        if (index >= 0) {
          state.myAssignments.splice(index, 1);
        }
        assignmentAdapter.removeOne(state, action.meta.arg);
        state.status = Status.Succeeded;
      })
      .addCase(deleteAssignmentData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(deleteAssignmentData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(deleteAssignmentsMultiple.fulfilled, (state: any, action: any) => {
        action.meta.arg.forEach((assignmentID: string) => {
          const index = state.myAssignments.findIndex((assignment: any) => assignment.id === assignmentID);
          if (index >= 0) {
            state.myAssignments.splice(index, 1);
          }
        });
        assignmentAdapter.removeMany(state, action.meta.arg);
        state.status = Status.Succeeded;
      })
      .addCase(deleteAssignmentsMultiple.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(deleteAssignmentsMultiple.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(deleteAssignmentForUserData.fulfilled, (state: any, action: any) => {
        assignmentAdapter.removeOne(state, action.meta.arg.assignmentId);
        state.status = Status.Succeeded;
      })
      .addCase(deleteAssignmentForUserData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(deleteAssignmentForUserData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(addAssignmentForTeam.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          assignmentAdapter.addMany(state, payload);
          state.status = Status.Succeeded;
        }
      })
      .addCase(addAssignmentForTeam.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(addAssignmentForTeam.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(addAssignmentForDifferentIntervalsTeam.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          assignmentAdapter.addMany(state, payload);
          state.status = Status.Succeeded;
        }
      })
      .addCase(addAssignmentForDifferentIntervalsTeam.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(addAssignmentForDifferentIntervalsTeam.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(addAssignmentForOthersData.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          assignmentAdapter.addMany(state, payload);
          state.status = Status.Succeeded;
        }
      })
      .addCase(addAssignmentForOthersData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(addAssignmentForOthersData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(addAssignmentForDifferentIntervalsOthers.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          assignmentAdapter.addMany(state, payload);
          state.status = Status.Succeeded;
        }
      })
      .addCase(addAssignmentForDifferentIntervalsOthers.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(addAssignmentForDifferentIntervalsOthers.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      });
  },
  reducers: {},
});

const { reducer } = assignmentsSlice;

export default reducer;

const assignmentsSelectors = assignmentAdapter.getSelectors((state: any) => state.assignments);

export const assignmentsForActiveUsersSelectors = (state: any) => state.assignments.assignmentsForActiveUsers;

export const assignmentsForActiveTeamsSelectors = (state: any) => state.assignments.assignmentsForActiveTeams;

export const myAssignmentsSelector = (state: any) => state.assignments.myAssignments;

export const assignmentsSelector = (state: any) => {
  return Object.values(assignmentsSelectors.selectAll(state));
};
