import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import { deleteUser, getUserById, getUsers, updateMeInfo, updateUser, updateUserRole, User } from "../../services/authService";
import { Status } from "../StatusEnum";
import { registerNewUser, UserData } from "../Auth/authSlice";

type InitialStateType = {
  status: Status;
  users: [];
  activeUser?: any;
  error: SerializedError | null;
  editedUser: [];
  groups: [];
};

const initialState: InitialStateType = {
  status: Status.Idle,
  users: [],
  error: null,
  editedUser: [],
  activeUser: null,
  groups: [],
};

export const getUsersList = createAsyncThunk("users/employees", async (_, thunkApi) => {
  try {
    const response = await getUsers();

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const getUserByIdData = createAsyncThunk("users/getUserById", async (id: string, thunkApi) => {
  try {
    const response = await getUserById(id);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const updateUserRoleById = createAsyncThunk("users/updateUserRole", async ({ id, username, email, firstName, lastName }: User, thunkApi) => {
  try {
    const response = await updateUserRole(id, username, email, firstName, lastName);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const deleteUserData = createAsyncThunk("users/deleteUser", async (id: string, thunkApi) => {
  try {
    const response = await deleteUser(id);

    if (response.status === 200 || response.status === 201) {
      return { message: response.data, id: id };
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const updateUserById = createAsyncThunk(
  "users/updateUser",
  async ({ id, username, email, firstName, lastName, password, groups, company }: User, thunkApi) => {
    try {
      const response = await updateUser(id, username, email, firstName, lastName, password, groups, company);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);
export const updateMeInfoData = createAsyncThunk("users/me", async ({ id, username, email, firstName, lastName }: User, thunkApi) => {
  try {
    const response = await updateMeInfo(username, email, firstName, lastName);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const addUser = createAsyncThunk("users/addUser", async (userData: UserData, thunkApi) => {
  try {
    const response = await registerNewUser(
      userData.username,
      userData.password,
      userData.email,
      userData.firstName,
      userData.lastName,
      userData.avatar,
      userData.company
    );

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

const usersSlice = createSlice({
  name: "users",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUsersList.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.users = payload;
        }
      })
      .addCase(getUsersList.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getUsersList.rejected, (state, action) => {
        state.status = Status.Failed;
        state.users = [];
        state.error = action.error;
      })

      .addCase(getUserByIdData.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.editedUser = payload;
        }
      })
      .addCase(getUserByIdData.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getUserByIdData.rejected, (state, action) => {
        state.status = Status.Failed;
        state.editedUser = [];
        state.error = action.error;
      })
      .addCase(updateUserRoleById.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        state.status = Status.Succeeded;
        const index = state.users.map((user: any) => user._id).indexOf(payload._id);
        state.users[index].isAdmin = payload.isAdmin;
      })
      .addCase(updateUserRoleById.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(updateUserRoleById.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(updateUserById.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        state.status = Status.Succeeded;
      })
      .addCase(updateUserById.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(updateUserById.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(addUser.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) state.users.push(payload);
        state.status = Status.Succeeded;
      })
      .addCase(addUser.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(addUser.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(deleteUserData.fulfilled, (state: any, action: any) => {
        state.status = Status.Succeeded;
        const index = state.users.map((user: any) => user.id).indexOf(action.meta.arg);
        state.users.splice(index, 1);
      })
      .addCase(deleteUserData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(deleteUserData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      });
  },
  reducers: {
    setEditedUser(state, action) {
      state.editedUser = action.payload;
    },
  },
});

const { reducer, actions } = usersSlice;

export default reducer;
export const { setEditedUser } = actions;

export const editUserSelector = (state: any) => state.users.editedUser;
export const allUsersSelectors = (state: any) => state.users.users;
