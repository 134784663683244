import { Navigate } from "react-router-dom";
import { useAuth } from "../AuthHook";
interface PropType {
  component: React.FC;
  permissions?: Array<string>;
}

const AuthorizedRoute = ({ component: Component, permissions }: PropType) => {
  const { user, isAuthenticated, check } = useAuth();
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return check(permissions || []) ? <Component /> : <Navigate to="/deskpicker" />;
};

export default AuthorizedRoute;
