import { useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { getUsersList } from "../../slices/Entities/usersSlice";

function ReserveForColleague(props: any) {
  const { checked, setChecked, setUser, currentUser } = props;
  const dispatch = useDispatch<any>();
  const [users, setUsers] = useState<any>([]);

  const handleChange = () => {
    if (!checked) fetchData();
    else {
      setUser(undefined);
    }
    setChecked(!checked);
  };

  const fetchData = async () => {
    const usersData = await dispatch(getUsersList());
    setUsers(
      usersData.payload
        .filter((user: any) => user._id !== currentUser._id)
        .map((user: any) => ({
          ...user,
          label: user.firstName + " " + user.lastName,
        }))
    );
  };

  return (
    <div className="others-container">
      <label className="checkbox-label">
        Reserve for a colleague
        <input type="checkbox" checked={checked} onChange={handleChange} />
        <span className="checkmark"></span>
      </label>
      {checked ? (
        <Select
          id="select"
          options={users}
          onChange={(user: any) => setUser(user)}
          menuPlacement="auto"
          menuPosition="fixed"
          inputId="user-input"
          placeholder="Search..."
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default ReserveForColleague;
