import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DesksList from "./components/DesksList/desksList";
import Permissions from "./components/Permissions/permissions";
import AdminDashboard from "./pages/AdminDashboard/adminDashboard";
import CompaniesList from "./pages/CompaniesList/companiesList";
import Dashboard from "./pages/Dashboard/dashboard";
import LoginPage from "./pages/LoginPage/LoginPage";
import MyCompany from "./pages/MyCompany/myCompany";
import PersonalDashboard from "./pages/PersonalDashboard/personalDashboard";
import Settings from "./pages/Settings/settings";
import TeamList from "./pages/TeamList/teamList";
import ToolbarManager from "./pages/ToolbarManager/ToolbarManager";
import UserList from "./pages/UserList/userList";
import { easterEggEnabledSelector } from "./slices/Canvas/canvasSlice";
import AuthorizedRoute from "./utils/PrivateRouting/AuthorizedRoute";
import PrivateRoute from "./utils/PrivateRouting/PrivateRoute";
import AuthVerify from "./utils/auth-verify";

function App() {
  const easterEggEnabled = useSelector(easterEggEnabledSelector);

  return (
    <div className={`App ${easterEggEnabled ? "ee-enabled" : "ee-disabled"}`}>
      <ToastContainer autoClose={3000} hideProgressBar={true} />
      <Router>
        <Fragment>
          <Routes>
            <Route path="/dashboard" element={<PrivateRoute component={PersonalDashboard} />} />
            <Route path="/adminDashboard" element={<AuthorizedRoute permissions={["admin.mode"]} component={AdminDashboard} />} />
            <Route path="/toolbarManager" element={<PrivateRoute component={ToolbarManager} />} />
            <Route path="/deskpicker" element={<PrivateRoute component={Dashboard} />} />
            <Route path="/desksList" element={<PrivateRoute component={DesksList} />} />
            <Route path="/users" element={<PrivateRoute component={UserList} />} />
            <Route path="/settings" element={<AuthorizedRoute component={Settings} />} />
            <Route path="/teams" element={<AuthorizedRoute permissions={["team.read"]} component={TeamList} />} />
            <Route path="/companies" element={<AuthorizedRoute permissions={["super-admin"]} component={CompaniesList} />} />
            <Route path="/myCompany" element={<AuthorizedRoute permissions={["myCompany.update"]} component={MyCompany} />} />
            <Route path="/users/:userId" element={<PrivateRoute component={Permissions} />} />
            <Route path="/createNewUser" element={<PrivateRoute component={Permissions} />} />
            <Route path="/settings" element={<AuthorizedRoute permissions={["configurations.read"]} component={Settings} />} />
            <Route path="/" element={<LoginPage />} />
            <Route path="/*" element={<Navigate to="/deskpicker" />} />
          </Routes>
        </Fragment>
        <AuthVerify />
      </Router>
    </div>
  );
}

export default App;
