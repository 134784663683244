import Navbar from "../Navbar/navbar";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  assignmentsForActiveTeamsSelectors,
  assignmentsForActiveUsersSelectors,
  getAssignmentForActiveTeams,
  getAssignmentForActiveUsers,
} from "../../slices/Entities/assignmentSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  averageUsageSelector,
  busyDaysSelector,
  dailyOccupancySelector,
  floorUsageSelector,
  getAverageUsage,
  getBusyDays,
  getDailyOccupancy,
  getFloorUsage,
} from "../../slices/Entities/deskSlice";
import { currentDateSelector, intervalEndSelector, intervalStartSelector, setIntervalLabel } from "../../slices/Canvas/canvasSlice";
import { selectedBuildingSelector } from "../../slices/Entities/buildingSlice";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Pie } from "react-chartjs-2";
import { DateTime } from "luxon";
import { allTeamsSelector } from "../../slices/Entities/teamSlice";
import Copyright from "../../components/Copyright/Copyright";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function AdminDashboard(props: any) {
  const [averageUsageForBuildings, setAverageUsage] = useState<any>([]);
  const [floorUsageForBuilding, setFloorUsage] = useState<any>([]);
  const [busyDaysForBuilding, setBusyDays] = useState<any>([]);
  const [dailyOccupancyForBuilding, setDailyOccupancy] = useState<any>([]);
  const teams = useSelector(allTeamsSelector);
  const [firstAssignmentsForUser, setFirstAssignmentsForUser] = useState<any>([]);
  const [firstAssignmentsForTeams, setFirstAssignmentsForTeams] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const currentDate = useSelector(currentDateSelector);
  const [datesArray, setDatesArray] = useState<any>([]);
  const selectedBuilding = useSelector(selectedBuildingSelector);

  const activeUsers = useSelector(assignmentsForActiveUsersSelectors);
  const activeTeams = useSelector(assignmentsForActiveTeamsSelectors);
  const averageUsage = useSelector(averageUsageSelector);
  const floorUsage = useSelector(floorUsageSelector);
  const busyDays = useSelector(busyDaysSelector);
  const dailyOccupancy = useSelector(dailyOccupancySelector);

  const [graph, setGraph] = useState<any>({
    labels: [],
    data: [],
  });

  const data = {
    labels: graph.labels,
    datasets: [
      {
        label: "# of Votes",
        data: graph.data,
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const intervalEndDate = useSelector(intervalEndSelector);
  const intervalStartDate = useSelector(intervalStartSelector);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    const getActiveUsers = async () => {
      const result = await dispatch(getAssignmentForActiveUsers());
      if (result.error) {
        setErrorMessage(result.payload.response.data);
      }
    };
    getActiveUsers();
  }, []);

  useEffect(() => {
    if (!activeUsers) {
      return;
    }
    setFirstAssignmentsForUser(activeUsers.slice(0, 4));
  }, [activeUsers]);

  useEffect(() => {
    const getActiveTeams = async () => {
      const result = await dispatch(getAssignmentForActiveTeams());
      if (result.error) {
        setErrorMessage(result.payload.response.data);
      }
    };
    getActiveTeams();
  }, []);

  useEffect(() => {
    if (!activeTeams) {
      return;
    }
    setFirstAssignmentsForTeams(activeTeams.slice(0, 4));
  }, [activeTeams]);

  const startDateFromJS = intervalStartDate.toLocal();
  const timeZoneOffsetSD = startDateFromJS.toJSDate().getTimezoneOffset() * 60000;
  const localISOTimeSD = startDateFromJS.toJSDate().getTime() - timeZoneOffsetSD;
  const stringDateSD = new Date(localISOTimeSD);

  const endDateFromJS = intervalEndDate.toLocal();
  const timeZoneOffsetED = endDateFromJS.toJSDate().getTimezoneOffset() * 60000;
  const localISOTimeED = endDateFromJS.toJSDate().getTime() - timeZoneOffsetED;
  const stringDateED = new Date(localISOTimeED);

  useEffect(() => {
    const getAverageUsageForBuildings = async () => {
      const result = await dispatch(getAverageUsage({ startDate: stringDateSD.toISOString(), endDate: stringDateED.toISOString() }));
      if (result.error) {
        setErrorMessage(result.payload.response.data);
      }
    };
    getAverageUsageForBuildings();
  }, [intervalStartDate, intervalEndDate]);

  useEffect(() => {
    if (!averageUsage) {
      return;
    }
    setAverageUsage(averageUsage);
  }, [averageUsage]);

  useEffect(() => {
    if (!selectedBuilding) {
      return;
    }
    const getFloorUsageForBuilding = async () => {
      const result = await dispatch(
        getFloorUsage({ startDate: stringDateSD.toISOString(), endDate: stringDateED.toISOString(), buildingId: selectedBuilding.id })
      );
      if (result.error) {
        setErrorMessage(result.payload.response.data);
      }
    };
    getFloorUsageForBuilding();
  }, [intervalStartDate, intervalEndDate, selectedBuilding]);

  useEffect(() => {
    if (!floorUsage) {
      return;
    }
    const labels: any[] = [];
    const data: any[] = [];
    floorUsage.map((obj: any) => {
      labels.push(obj.floor.name);
      data.push(obj.ratio);
    });

    setGraph({
      labels: labels,
      data: data,
    });

    setFloorUsage(floorUsage);
  }, [floorUsage]);

  useEffect(() => {
    if (!selectedBuilding) {
      return;
    }
    const getBusyDaysForBuilding = async () => {
      const result = dispatch(
        getBusyDays({ startDate: stringDateSD.toISOString(), endDate: stringDateED.toISOString(), buildingId: selectedBuilding.id })
      );
      if (result.error) {
        setErrorMessage(result.payload.response.data);
      }
    };
    getBusyDaysForBuilding();
  }, [intervalStartDate, intervalEndDate, selectedBuilding]);

  useEffect(() => {
    if (!busyDays) {
      return;
    }
    setBusyDays(busyDays);
  }, [busyDays]);

  useEffect(() => {
    if (!selectedBuilding) {
      return;
    }
    const getDailyOccupancyForBuilding = async () => {
      const result = dispatch(getDailyOccupancy({ buildingId: selectedBuilding.id }));
      if (result.error) {
        setErrorMessage(result.payload.response.data);
      }
    };
    getDailyOccupancyForBuilding();
  }, [selectedBuilding]);

  useEffect(() => {
    if (!dailyOccupancy) {
      return;
    }
    setDailyOccupancy(dailyOccupancy);
  }, [dailyOccupancy]);

  useEffect(() => {
    const date = new Date();
    const labelDate = `${DateTime.fromJSDate(date).toFormat("dd MMMM yyyy")}`;
    return () => {
      dispatch(setIntervalLabel(labelDate));
    };
  }, []);

  return (
    <div className="dashboard-container">
      <Navbar />
      <div className="features-container">
        <div className="card">
          <h4 className="active-users-currentWeek">Busiest days</h4>
          <Card sx={{ maxHeight: 200, maxWidth: 300, display: "inline-flex" }}>
            <CardContent>
              <ul className="list">
                {Object.keys(busyDaysForBuilding).map((day) => {
                  return Object.values(busyDaysForBuilding[day]).map((obj: any) => {
                    return (
                      <li key={obj.building.id}>
                        <div className="assignmentDays-container">
                          <div className="assignment-dataDays">
                            <div className="desk-container">{day}</div>
                            <div className="assignments-days">{obj.ratio} %</div>
                          </div>
                        </div>
                      </li>
                    );
                  });
                })}
              </ul>
            </CardContent>
          </Card>
        </div>

        <div className="card">
          <h4 className="active-users-currentWeek">Daily Occupancy</h4>
          <Card sx={{ maxHeight: 200, maxWidth: 300, display: "inline-flex" }}>
            <CardContent>
              <div className="all-days">
                {Object.keys(dailyOccupancyForBuilding).map((day) => {
                  return Object.values(dailyOccupancyForBuilding[day]).map((obj: any) => {
                    return (
                      <div className="wrap-days" key={obj.building.id}>
                        <div className="assignmentDays-container-dailyOcc">
                          <div className="flex-container">
                            <div className="desk-container">{day}</div>
                            <div className="assignments-days-dailyOcc">{obj.ratio} %</div>
                          </div>
                        </div>
                      </div>
                    );
                  });
                })}
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="card">
          <h4 className="active-users-currentWeek">Building average usage</h4>
          <Card sx={{ maxHeight: 200, maxWidth: 300, display: "inline-flex" }}>
            <CardContent>
              <ul className="list">
                {averageUsage.map((obj: any) => {
                  return (
                    <li key={obj.building.id}>
                      <div className="assignmentDays-container">
                        <div className="assignment-dataDays">
                          <div className="desk-container">{obj.building.name}</div>
                          <div className="assignments-days">{obj.rate} %</div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </CardContent>
          </Card>
        </div>
        <div className="card">
          <h4 className="active-users-currentWeek">Floor Usage</h4>
          <Card sx={{ maxHeight: 700, maxWidth: 400, display: "inline-flex" }}>
            <CardContent>
              <div style={{ height: "30vh", width: "30vh", position: "relative", marginBottom: "1%", padding: "1%" }}>
                <Pie data={data}></Pie>
              </div>
            </CardContent>
          </Card>
        </div>

        <div className="card">
          <h4 className="active-users-currentWeek">Active users</h4>
          <Card sx={{ maxHeight: 200, maxWidth: 300, display: "inline-flex" }}>
            <CardContent>
              <ul className="list">
                {firstAssignmentsForUser.map((obj: any) => {
                  return (
                    <li key={obj.employee?.id}>
                      <div className="assignmentDays-container">
                        <div className="assignment-dataDays">
                          <div className="desk-container">
                            {obj.employee?.firstName || "-"} {obj.employee?.lastName || ""}
                          </div>
                          <div className="assignments-days">{obj.totalDays} Days</div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </CardContent>
          </Card>
        </div>

        <div className="card">
          <h4 className="active-users-currentWeek">Active teams</h4>
          <Card sx={{ maxHeight: 200, maxWidth: 300, display: "inline-flex" }}>
            <CardContent>
              <ul className="list">
                {firstAssignmentsForTeams.map((obj: any) => {
                  return (
                    <li key={obj.team.id}>
                      <div className="assignmentDays-container">
                        <div className="assignment-dataDays">
                          <div className="desk-container">{obj.team.name}</div>
                          <div className="assignments-days">{obj.totalDays} Days</div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </CardContent>
          </Card>
        </div>
      </div>
      <div className="copyright-wrapper">
        <Copyright />
      </div>
    </div>
  );
}
