import { DeskAvailability } from "../../../components/Desk/types";

export const orderedListAvailabilityItems: Array<DeskAvailability | "SELECTED_DESK"> = [
  "FREE",
  "SELECTED_DESK",
  "OCCUPIED_BY_ME",
  "OCCUPIED",
  "OCCUPIED_BY_ME_AND_SOMEONE",
  "OCCUPIED_PARTIALLY",
];
