import Navbar from "../Navbar/navbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAssignmentData, getMyAssignment } from "../../slices/Entities/assignmentSlice";
import { userSelector } from "../../slices/Auth/authSlice";
import { DateTime } from "luxon";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { currentDateSelector } from "../../slices/Canvas/canvasSlice";
import * as Colors from "../../assets/colors";
import { notifyReservationDeleted } from "../../utils/notify";
import { getCanvasObject } from "../../components/Canvas/Canvas";
import DeleteAlertDialog from "../../components/DeleteAlertDialog/deleteAlertDialog";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { availabilitySelector, getAvailabilityData } from "../../slices/Entities/deskSlice";
import CalendarComponent from "../../components/CalendarComponent/calendarComponent";
import Copyright from "../../components/Copyright/Copyright";

export default function PersonalDashboard() {
  const localizer = momentLocalizer(moment);
  const [myAssignments, setMyAssignments] = useState<any>([]);
  const [firstAssignments, setFirstAssignments] = useState<any>([]);
  const [showDeleteAssignmentDialog, setShowDeleteAssignmentDialog] = useState<boolean>(false);
  const [deleteAssignment, setDeleteAssignment] = useState<any>();
  const [itemDeleted, setItemDeleted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const dispatch = useDispatch<any>();
  const user = useSelector(userSelector);
  const available = useSelector(availabilitySelector);

  const myId = user._id;

  const canvas: fabric.Canvas = getCanvasObject();
  const currentDate = useSelector(currentDateSelector);

  moment.locale("ko", {
    week: {
      dow: 1,
      doy: 1,
    },
  });
  let availabilityContent: any = [];
  let myEventsList: any[] = [];

  useEffect(() => {
    let firstFive: any[] = [];
    dispatch(getMyAssignment(myId)).then((result: any) => {
      if (!result.error) {
        const myAssignments = result.payload;
        setMyAssignments(myAssignments);
        firstFive = myAssignments.slice(0, 5);
        setFirstAssignments(firstFive);
      } else {
        setErrorMessage(result.payload.response.data);
      }
    });
  }, [dispatch, itemDeleted]);

  useEffect(() => {
    dispatch(getAvailabilityData(currentDate));
  }, [currentDate]);

  const availability = available.forEach((availableObject: any) => {
    availabilityContent.push(
      <div className="taken">
        <div className="av-content">
          <div className="av-floor">{availableObject.floor.name}</div>
          <div className="av-building">{availableObject.floor.building.name}</div>
        </div>
        <div className="nr-content">
          <div className="nr-taken">{availableObject.usedDesks} Taken </div>
          <div className="nr-free">{availableObject.freeDesks} Free</div>
        </div>
      </div>
    );
  });

  const getDeleteDialogData = (assignment: any) => {
    setDeleteAssignment(assignment);
    toggleShowDeleteDialog();
  };

  const toggleShowDeleteDialog = () => {
    setShowDeleteAssignmentDialog(!showDeleteAssignmentDialog);
  };

  const changeDeskColor = (assignment: any) => {
    if (DateTime.fromISO(assignment.startTime).hasSame(currentDate, "day")) {
      canvas.getObjects().forEach((obj: any) => {
        if (obj.deskId === assignment.desk.id) {
          obj.item(0).set({ fill: Colors.COLORFREEITEM });
        }
      });
    }
  };

  const handleDeleteAssignment = async () => {
    await dispatch(deleteAssignmentData(deleteAssignment._id)).then((result: any) => {
      if (result.error) {
        setErrorMessage(result.payload.response.data);
      } else {
        if (canvas) {
          changeDeskColor(deleteAssignment);
        }
        notifyReservationDeleted();
      }
    });
    setItemDeleted(!itemDeleted);
    toggleShowDeleteDialog();
  };
  const assignmentHours = (obj: any) => {
    return DateTime.fromISO(obj).toFormat("HH:mm");
  };
  const assignmentDate = (obj: any) => {
    return DateTime.fromISO(obj).toFormat("dd.MM.yyyy");
  };

  const calendarAssignments = myAssignments.forEach((assignment: any) => {
    myEventsList.push({
      start: new Date(assignment?.startTime),
      end: new Date(assignment?.endTime),
      title: (
        <div id="myEventList">
          <div>{assignment?.desk?.building?.name}</div>
          <div>{assignment?.desk?.floor?.name}</div>
          <div>{assignment?.desk?.label}</div>
        </div>
      ),
      colorEvento: Colors.COLORLIGHTGREEN,
      color: Colors.COLORWHITE,
    });
  });

  return (
    <div>
      <Navbar />
      <div className="main-container">
        <div className="left-container">
          <div className="next-container">
            <h3>Next 5 Reservations</h3>
            <div className="next-reservations">
              {firstAssignments.map((obj: any) => {
                return (
                  <div className="assignment-container" key={obj._id}>
                    <div className="assignment-data">
                      <div className="desk-container"> {obj.desk.label} </div>
                      <div className="assignment-location">{`${obj.desk.building.name} - ${obj.desk.floor.name}`}</div>
                      {obj.reservedBy ? (
                        <div className="assignment-location">Reservation made by {`${obj.reservedBy.firstName}  ${obj.reservedBy.lastName}`}</div>
                      ) : (
                        <></>
                      )}
                      {obj.team ? <div className="assignment-team">Team: {obj.team.name}</div> : <></>}
                      {obj.label ? <div className="assignment-team">For: {obj.label}</div> : <></>}
                      <div className="assignment-date">{assignmentDate(obj.startTime)}</div>
                    </div>
                    <div className="hours-container">{`${assignmentHours(obj.startTime)}-${assignmentHours(obj.endTime)}`}</div>
                    <DeleteOutlinedIcon
                      className="delete-button-reservation"
                      onClick={() => {
                        getDeleteDialogData(obj);
                      }}
                    />
                    {deleteAssignment && (
                      <DeleteAlertDialog
                        type={"assignment"}
                        value={`assignment for desk ${deleteAssignment.desk.label}`}
                        open={showDeleteAssignmentDialog}
                        handleOpen={toggleShowDeleteDialog}
                        handleClose={toggleShowDeleteDialog}
                        handleDelete={handleDeleteAssignment}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="availability">
            <div className="av-title">
              <h3>Availability</h3>
              <CalendarComponent />
            </div>
            <div className="item-container">{availabilityContent}</div>
          </div>
          <div className="copyright-wrapper">
            <Copyright />
          </div>
        </div>
        <div className="right-container">
          <Calendar
            localizer={localizer}
            events={myEventsList}
            startAccessor="start"
            endAccessor="end"
            defaultView={"month"}
            showAllEvents={false}
            popup={true}
            selectable={true}
            tooltipAccessor={(event) => {
              const content = `\nBuilding: ${event.title.props.children[0].props.children}\nFloor: ${event.title.props.children[1].props.children}\nDesk: ${event.title.props.children[2].props.children}`;
              return content;
            }}
            eventPropGetter={(myEventsList) => {
              const backgroundColor = myEventsList.colorEvento ? myEventsList.colorEvento : "blue";
              const color = myEventsList.color ? myEventsList.color : "blue";
              return { style: { backgroundColor, color } };
            }}
          />
        </div>
      </div>
    </div>
  );
}
