import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import { getAllBuildings, addBuilding, editBuilding, Building, deleteBuilding, orderAllBuildingsFloors } from "../../services/buildingService";
import { Status } from "../StatusEnum";

type InitialStateType = {
  status: Status;
  buildings: [];
  error: SerializedError | null;
  selectedBuilding: string;
  buildingName: string;
};

const initialState: InitialStateType = {
  status: Status.Idle,
  buildings: [],
  error: null,
  selectedBuilding: "",
  buildingName: "",
};

export const getBuildings = createAsyncThunk(
  "buildings/getAllBuildings",
  async (_, thunkApi) => {
    try {
      const response = await getAllBuildings();

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
  {
    condition: (_, store: any) => {
      const { buildings } = store.getState();
      const fetchStatus = buildings.status;
      if (fetchStatus === Status.Succeeded || fetchStatus === Status.Loading) {
        return false;
      }
    },
  }
);

export const addingBuilding = createAsyncThunk("buildings/addBuilding", async ({ name }: Building, thunkApi) => {
  try {
    const response = await addBuilding(name);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const editBuildingData = createAsyncThunk("buildings/editBuilding", async ({ id, name }: Building, thunkApi) => {
  try {
    const response = await editBuilding(id, name);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const orderBuildingsAndFloors = createAsyncThunk("buildings/orderAllBuildingsFloors", async ({ buildings, floors }: any, thunkApi) => {
  try {
    const response = await orderAllBuildingsFloors(buildings, floors);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const deleteBuildingData = createAsyncThunk("buildings/deleteBuilding", async ({ id }: Building, thunkApi) => {
  try {
    const response = await deleteBuilding(id);

    if (response.status === 200 || response.status === 201) {
      return { message: response.data, id: id };
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

const buildingsSlice = createSlice({
  name: "buildings",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getBuildings.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        state.selectedBuilding = "";
        if (payload) {
          state.status = Status.Succeeded;
          state.buildings = payload;
        }
      })
      .addCase(getBuildings.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getBuildings.rejected, (state, action) => {
        state.status = Status.Failed;
        state.buildings = [];
        state.error = action.error;
      })
      .addCase(addingBuilding.fulfilled, (state: any, action: any) => {
        const { payload } = action;

        if (payload) {
          state.status = Status.Succeeded;
          state.buildings.push(payload);
        }
      })
      .addCase(addingBuilding.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(addingBuilding.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(editBuildingData.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        const index = state.buildings.map((building: any) => building._id).indexOf(payload.id);
        state.buildings[index] = payload;
        state.selectedBuilding = payload;
        state.status = Status.Succeeded;
      })
      .addCase(editBuildingData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(editBuildingData.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(orderBuildingsAndFloors.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.buildings = payload.buildings.sort((obj1: any, obj2: any) => obj1.orderNr - obj2.orderNr);
        }
      })
      .addCase(orderBuildingsAndFloors.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(orderBuildingsAndFloors.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(deleteBuildingData.fulfilled, (state: any, action: any) => {
        state.status = Status.Succeeded;
        const index = state.buildings.map((building: any) => building._id).indexOf(action.meta.arg.id);
        state.buildings.splice(index, 1);
      })
      .addCase(deleteBuildingData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(deleteBuildingData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      });
  },
  reducers: {
    setSelectedBuilding(state, action) {
      state.selectedBuilding = action.payload;
    },
    setBuildingName(state, action) {
      state.buildingName = action.payload;
    },
  },
});

const { reducer, actions } = buildingsSlice;

export default reducer;

export const { setSelectedBuilding, setBuildingName } = actions;

export const statusSelector = (state: any) => state.buildings.status;

export const selectedBuildingSelector = (state: any) => state.buildings.selectedBuilding;

export const buildingNameSelector = (state: any) => state.buildings.selectedBuilding?.name;

export const allBuildingsSelector = (state: any) => state.buildings.buildings;
