import { createAsyncThunk, createEntityAdapter, createSlice, SerializedError } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import {
  availability,
  averageUsage,
  busyDays,
  dailyOccupancy,
  deleteDesk,
  floorUsage,
  getDeskById,
  getDesksByFloorId,
  updateDesk,
} from "../../services/deskService";
import { Status } from "../StatusEnum";
import { ISize } from "./../../components/interfaces";

export type IObject = {
  id: string;
  toolbarItemID: string | null;
  objectId?: string;
  type: ObjectType;
  position: {
    x: number;
    y: number;
  };
  rotation: number;
  label: string;
  active?: boolean;
  src?: string;
  flippedY?: boolean;
  size: ISize;
  hasBorder: boolean;
  hasBackground: boolean;
  hasImageStretched: boolean;
};

export enum ObjectType {
  DESK = "desk",
  WINDOW = "window",
  DOOR = "door",
  STAIRS = "stairs",
  TOOL = "tool",
  CUSTOM = "custom",
  TEXT = "text",
  ROOM = "room",
}

type InitialStateType = {
  status: Status;
  error: SerializedError | null;
  selectedId: any;
  floorDesksOptions: any;
  selectedDesks: any;
  availability: any;
  averageUsage: [];
  floorUsage: [];
  busyDays: [];
  dailyOccupancy: [];
};

const deskAdapter = createEntityAdapter({
  selectId: (desk: any) => desk.id,
  sortComparer: false,
});

const initialState: InitialStateType = deskAdapter.getInitialState({
  status: Status.Idle,
  error: null,
  selectedId: null,
  floorDesksOptions: [],
  selectedDesks: [],
  availability: [],
  averageUsage: [],
  floorUsage: [],
  busyDays: [],
  dailyOccupancy: [],
});

export const getDesksByFloor = createAsyncThunk(
  "desks/getDesksByFloorId",
  async ({ buildingId, floorId }: { buildingId: string; floorId: string }, thunkApi) => {
    try {
      const response = await getDesksByFloorId(buildingId, floorId);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const removeDeskData = createAsyncThunk(
  "desks/deleteDesks",
  async ({ buildingId, floorId, deskId }: { buildingId: string; floorId: string; deskId: string }, thunkApi) => {
    try {
      const response = await deleteDesk(buildingId, floorId, deskId);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const getDeskData = createAsyncThunk("desks/getDesk", async (deskId: string, thunkApi) => {
  try {
    const response = await getDeskById(deskId);

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const updateDeskData = createAsyncThunk(
  "desks/updateDesk",
  async ({ buildingId, floorId, deskId, label }: { buildingId: string; floorId: string; deskId: string; label: string }, thunkApi) => {
    try {
      const response = await updateDesk(buildingId, floorId, deskId, label);

      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const getAvailabilityData = createAsyncThunk("/desks/availability", async (date: DateTime, thunkApi) => {
  try {
    const response = await availability(date.toISO());
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const getAverageUsage = createAsyncThunk(`/desks/averageUsage`, async ({ startDate, endDate }: any, thunkApi) => {
  try {
    const response = await averageUsage(startDate, endDate);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const getFloorUsage = createAsyncThunk(`/desks/floorUsage`, async ({ startDate, endDate, buildingId }: any, thunkApi) => {
  try {
    const response = await floorUsage(startDate, endDate, buildingId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const getBusyDays = createAsyncThunk(`/desks/busyDays`, async ({ startDate, endDate, buildingId }: any, thunkApi) => {
  try {
    const response = await busyDays(startDate, endDate, buildingId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

export const getDailyOccupancy = createAsyncThunk(`/desks/dailyOccupancy`, async ({ buildingId }: any, thunkApi) => {
  try {
    const response = await dailyOccupancy(buildingId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (e) {
    return thunkApi.rejectWithValue(e);
  }
});

const desksSlice = createSlice({
  name: "desks",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAvailabilityData.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.availability = payload;
        }
      })
      .addCase(getAvailabilityData.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getAvailabilityData.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })

      .addCase(getAverageUsage.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.averageUsage = payload;
        }
      })
      .addCase(getAverageUsage.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getAverageUsage.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })

      .addCase(getFloorUsage.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.floorUsage = payload;
        }
      })
      .addCase(getFloorUsage.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getFloorUsage.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })

      .addCase(getBusyDays.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.busyDays = payload;
        }
      })
      .addCase(getBusyDays.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getBusyDays.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })

      .addCase(getDailyOccupancy.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.Succeeded;
          state.dailyOccupancy = payload;
        }
      })
      .addCase(getDailyOccupancy.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getDailyOccupancy.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })

      .addCase(getDesksByFloor.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        deskAdapter.setAll(state, []);
        if (payload) {
          state.status = Status.Succeeded;
          deskAdapter.setMany(state, payload);
        }
      })
      .addCase(getDesksByFloor.pending, (state) => {
        state.status = Status.Loading;
      })
      .addCase(getDesksByFloor.rejected, (state, action) => {
        state.status = Status.Failed;
        state.error = action.error;
      })

      .addCase(removeDeskData.fulfilled, (state: any, action: any) => {
        deskAdapter.removeOne(state, action.meta.arg.deskId);
        state.status = Status.Succeeded;
        const index = state.floorDesksOptions.map((desk: any) => desk._id).indexOf(action.meta.arg.deskId);
        state.floorDesksOptions.splice(index, 1);
      })
      .addCase(removeDeskData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(removeDeskData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(getDeskData.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        deskAdapter.addOne(state, payload);
        state.status = Status.Succeeded;
      })
      .addCase(getDeskData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(getDeskData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      })
      .addCase(updateDeskData.fulfilled, (state: any, action: any) => {
        const { payload } = action;
        deskAdapter.upsertOne(state, payload);
        state.status = Status.Succeeded;
        const index = state.floorDesksOptions.map((desk: any) => desk._id).indexOf(payload.id);
        state.floorDesksOptions[index] = payload;
      })
      .addCase(updateDeskData.pending, (state: any, action: any) => {
        state.status = Status.Loading;
      })
      .addCase(updateDeskData.rejected, (state: any, action: any) => {
        state.status = Status.Failed;
        state.error = action.error;
      });
  },
  reducers: {
    setFloorDesksOptions(state, action) {
      state.floorDesksOptions = action.payload;
    },
    selectedDesksState(state, action) {
      state.selectedDesks = action.payload;
    },
  },
});

const { reducer, actions } = desksSlice;

export default reducer;

export const { setFloorDesksOptions } = actions;

const desksSelectors = deskAdapter.getSelectors((state: any) => state.desks);

export const floorDesksOptionsSelector = (state: any) => state.desks.floorDesksOptions;
export const desksSelector = (state: any) => {
  return desksSelectors.selectAll(state);
};

export const { selectedDesksState } = actions;
export const averageUsageSelector = (state: any) => state.desks.averageUsage;
export const floorUsageSelector = (state: any) => state.desks.floorUsage;
export const busyDaysSelector = (state: any) => state.desks.busyDays;
export const dailyOccupancySelector = (state: any) => state.desks.dailyOccupancy;
export const selectedDesksSelector = (state: any) => state.desks.selectedDesks;
export const availabilitySelector = (state: any) => state.desks.availability;
