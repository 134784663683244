import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";
import { fabric } from "fabric";
import { DateTime } from "luxon";
import { CanvasMode } from "../../components/Canvas/types";
import { canvasHeight, canvasWidth } from "../../utils/variables";
import { Status } from "../StatusEnum";

const initCanvas = (width: number, height: number) => {
  fabric.Object.prototype.objectCaching = false;
  const fabricCanvas = new fabric.Canvas("canvas", {
    selection: true,
    controlsAboveOverlay: true,
    centeredScaling: true,
    allowTouchScrolling: true,
  });

  const gridSize = 20;
  const left = (canvasWidth % gridSize) / 2;
  const top = (canvasHeight % gridSize) / 2;
  const lines = [];
  const lineOption = {
    stroke: "rgba(0,0,0,.15)",
    strokeWidth: 1,
    selectable: false,
    evented: false,
    objectCaching: false,
  };
  for (let i = Math.ceil(canvasWidth / gridSize); i--; ) {
    lines.push(new fabric.Line([gridSize * i, -top, gridSize * i, canvasHeight], lineOption));
  }
  for (let i = Math.ceil(canvasHeight / gridSize); i--; ) {
    lines.push(new fabric.Line([-left, gridSize * i, canvasWidth, gridSize * i], lineOption));
  }
  let oGridGroup = new fabric.Group(lines, {
    left: -(canvasWidth - width) / 2,
    top: -(canvasHeight - height) / 2,
  });
  oGridGroup.set({
    selectable: false,
    evented: false,
  });
  fabricCanvas.add(oGridGroup);

  return JSON.stringify(fabricCanvas.toJSON(["selectable", "evented"]));
};

const initialCanvasData = initCanvas(window.innerWidth - 410, window.innerHeight - 200);

type InitialStateType = {
  status: Status;
  canvasData: any;
  currentDate: DateTime;
  error: SerializedError | null;
  show: boolean;
  modal: boolean;
  intervalLabel: "";
  intervalStartDate: DateTime | null;
  intervalEndDate: DateTime | null;
  intervalStart: DateTime | null;
  intervalEnd: DateTime | null;
  calendarChanged: boolean;
  easterEggEnabled: boolean;
  displayAdminMode: boolean;
  canvasMode: CanvasMode;
};

const initialState: InitialStateType = {
  status: Status.Idle,
  canvasData: initialCanvasData,
  currentDate: DateTime.now(),
  error: null,
  show: true,
  modal: false,
  intervalLabel: "",
  intervalStartDate: DateTime.now(),
  intervalEndDate: DateTime.now(),
  intervalStart: DateTime.now(),
  intervalEnd: DateTime.now().plus({ days: 6 }),
  calendarChanged: false,
  easterEggEnabled: false,
  displayAdminMode: false,
  canvasMode: CanvasMode.VIEW,
};

export const updateCanvasJSON = createAsyncThunk("canvas/updateCanvasJSON", (canvasData: any, _) => {
  return canvasData;
});

const canvasSlice = createSlice({
  name: "canvas",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(updateCanvasJSON.pending, (state, action) => {
        state.status = Status.Loading;
      })
      .addCase(updateCanvasJSON.fulfilled, (state, action) => {
        state.status = Status.Succeeded;
        state.canvasData = action.payload;
      })
      .addCase(updateCanvasJSON.rejected, (state, action) => {
        state.status = Status.Failed;
        state.canvasData = null;
      });
  },
  reducers: {
    setCurrentDate(state, action) {
      state.currentDate = action.payload;
    },
    setIntervalLabel(state, action) {
      state.intervalLabel = action.payload;
    },
    setIntervalStartDate(state, action) {
      state.intervalStartDate = action.payload;
    },
    setIntervalEndDate(state, action) {
      state.intervalEndDate = action.payload;
    },
    setIntervalStart(state, action) {
      state.intervalStart = action.payload;
    },
    setIntervalEnd(state, action) {
      state.intervalEnd = action.payload;
    },
    resetCanvas(state) {
      state.canvasData = initialCanvasData;
    },
    showCanvas(state, action) {
      state.show = action.payload;
    },
    showModalReservation(state, action) {
      state.modal = action.payload;
    },
    setCalendarChanged(state, action) {
      state.calendarChanged = action.payload;
    },
    toggleEasterEgg(state) {
      state.easterEggEnabled = !state.easterEggEnabled;
    },
    setDisplayAdminMode(state, action) {
      state.displayAdminMode = action.payload;
      if (action.payload === false && state.canvasMode === CanvasMode.EDIT) {
        state.canvasMode = CanvasMode.VIEW;
      }
    },
    setCanvasMode(state, action: { payload: CanvasMode }) {
      state.canvasMode = action.payload;
    },
  },
});

const { reducer, actions } = canvasSlice;

export default reducer;

export const {
  resetCanvas,
  setCurrentDate,
  setIntervalLabel,
  setIntervalStartDate,
  setIntervalEndDate,
  setIntervalStart,
  setIntervalEnd,
  showCanvas,
  showModalReservation,
  setCalendarChanged,
  toggleEasterEgg,
  setDisplayAdminMode,
  setCanvasMode,
} = actions;

export const canvasJSONSelector = (state: any) => state.canvas.canvasData;
export const currentDateSelector = (state: any) => state.canvas.currentDate;
export const currentIntervalLabelSelector = (state: any) => state.canvas.intervalLabel;
export const currentIntervalStartDateSelector = (state: any) => state.canvas.intervalStartDate;
export const currentIntervalEndDateSelector = (state: any) => state.canvas.intervalEndDate;
export const intervalStartSelector = (state: any) => state.canvas.intervalStart;
export const intervalEndSelector = (state: any) => state.canvas.intervalEnd;
export const showCanvasSelector = (state: any) => state.canvas.show;
export const calendarChangedSelector = (state: any) => state.canvas.calendarChanged;
export const showModalReservationSelector = (state: any) => state.canvas.modal;
export const easterEggEnabledSelector = (state: any) => state.canvas.easterEggEnabled;
export const displayAdminModeSelector = (state: any) => state.canvas.displayAdminMode;
export const canvasModeSelector = (state: any) => state.canvas.canvasMode;
